export default class ContactInfo {
  constructor(claims) {
    var parameters = {}
    if (claims && claims.Customer) {
      var cust = claims.Customer;
      parameters.name = `${cust.FirstName} ${cust.LastName}`;
      parameters.email = cust.UserName;
      parameters.company = cust.CustName;
    }
    if (parameters) {
      this.name = parameters.name;
      this.email = parameters.email;
      this.phone = parameters.phone;
      this.address = parameters.address;
      this.city = parameters.city;
      this.state = parameters.state;
      this.zipcode = parameters.zipcode;
      this.company = parameters.company;
    } else {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.address = "";
      this.city = "";
      this.state = "";
      this.zipcode = "";
      this.company = "";
    }
  }

  Merge(parameters) {
    if (parameters.name) {
      this.name = parameters.name;
    }
    if (parameters.email) {
      this.email = parameters.email;
    }
    if (parameters.phone) {
      this.phone = parameters.phone;
    }
    if (parameters.address) {
      this.address = parameters.address;
    }
    if (parameters.city) {
      this.city = parameters.city;
    }
    if (parameters.state) {
      this.state = parameters.state;
    }
    if (parameters.zipcode) {
      this.zipcode = parameters.zipcode;
    }
    if (parameters.company) {
      this.company = parameters.company;
    }
  }
}
