import React from "react";
import styled from "styled-components";

const Mount = styled.div`
  font-weight: normal;
  text-align: center;
  display: inline-block;
  writing-mode: vertical-lr;
  padding: 0.5em;
  height: 150px;
  color: ${props => (props.visible ? (props.selected ? "white" : "#f2f2f2") : "white")};
  background-color: ${props => (props.visible ? (props.selected ? "#606060" : "#f2f2f2") : "white")};
`;
const StandardMount = styled(Mount)`
  margin-right: 0.5em;
`;
const ReverseMount = styled(Mount)`
  margin-left: 0.5em;
`;
const SmallText = styled.span`
  font-size: 75%;
  font-style: italic;
`;
const Spacer = styled.div`
  min-height: ${props => (props.visible ? "1em" : "0.5em")};
`;

export default function ProfileImage({ url, isInvertable, inverted }) {
  return (
    <>
      <StandardMount visible={true} selected={!inverted}>
        STANDARD mount
        <br />
        <SmallText>-------- OUTSIDE EDGE --------</SmallText>
      </StandardMount>
      <img className="gasketImage" alt="gasket profile" src={url} height="150px;" style={{ maxWidth: "260px" }} />
      <ReverseMount visible={isInvertable} selected={inverted}>
        <SmallText> -------- OUTSIDE EDGE --------</SmallText>
        <br />
        REVERSE mount:
      </ReverseMount>
      <Spacer visible={!isInvertable} />
    </>
  );
}
