import React from "react";
import { Field } from "formik";
import DimensionEditor from "./DimensionEditor";
import { parseFraction, formatFraction } from "./dimensions";

const DimensionField = ({ fieldName, label, min, max, defaultValue, onBlur }) => (
  <>
    <Field name={fieldName} id={fieldName}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => (
        <>
          {touched[fieldName] && errors[fieldName] && <div className="errorMessage">{errors[fieldName]}</div>}
          <DimensionEditor
            label={label}
            value={value}
            update={text => {
              const value = parseFraction(text);
              if (value) {
                setFieldValue(fieldName, formatFraction(value));
              } else {
                setFieldValue(fieldName, text);
              }
            }}
            minValue={min}
            maxValue={max}
            defaultValue={defaultValue}
            isError={touched[fieldName] && errors[fieldName]}
            onBlur={onBlur}
          />
        </>
      )}
    </Field>
  </>
);

export default DimensionField;
