const lookup = {
  Latches: "latch",
  Hinges: "hinge",
  Gaskets: "gasket",
  "Heaters-Pans": "heater",
  "Walk-In Equipment": "walk-in",
  Electrical: "electrical",
  "Thermometers-Alarms": "thermometer",
  "Shelving-Miscellaneous": "shel",
  "American Panel": "American Panel",
  Anthony: "Anthony",
  "Arctic Air": "Arctic Air",
  Ardco: "Ardco",
  Bally: "Bally",
  Barr: "Barr",
  "Bastian Blessing": "Bastian Blessing",
  "Beverage Air": "Beverage Air",
  "Blue Air": "Blue Air gasket",
  Bohn: "Bohn",
  Brown: "Brown gasket",
  "Carter-Hoffman": "Carter-Hoffman",
  Continental: "Continental",
  Delfield: "Delfield",
  Displayrite: "Displayrite",
  Duke: "Duke",
  "Elliot-Williams": "Elliot-Williams",
  Everest: "Everest",
  Federal: "Federal",
  Foster: "Foster",
  Friedrich: "Friedrich",
  Glastender: "Glastender",
  "Glenco/Star Metal": "Glenco Star Metal",
  "Hobart/Koch": "Hobart Koch",
  "Howard/McCray": "Howard McCray",
  Hussmann: "Hussmann",
  "International Cold Storage": "International Cold Storage",
  "Jordon/Fogel": "Jordon Fogel",
  Kairak: "Kairak",
  Kalt: "Kalt",
  Kelvinator: "Kelvinator",
  Kolpak: "Kolpak",
  "Kool-Air": "Kool-Air",
  Leer: "Leer",
  "Master-Bilt": "Master-Bilt",
  McCall: "McCall",
  Migali: "Migali",
  Nelson: "Nelson",
  "Nor-Lake": "Nor-Lake",
  Northland: "Northland",
  Peerless: "Peerless",
  Perlick: "Perlick",
  Powers: "Powers",
  Progressive: "Progressive",
  Randell: "Randell",
  Russell: "Russell",
  "Silver King": "Silver King",
  "Stanley-Knight": "Stanley-Knight",
  "Star/Starrett": "Star Starrett",
  Styleline: "Styleline",
  Tafco: "Tafco",
  "Thermo-Kool": "Thermo-Kool",
  Traulsen: "Traulsen",
  True: "True",
  "Turbo Air": "Turbo Air",
  Tyler: "Tyler",
  "U-Line": "U-Line",
  "Universal Nolin": "Universal Nolin",
  Utility: "Utility",
  "Victory/Raetone": "Victory Raetone",
  Vollrath: "Vollrath",
  "Vulcan Hart": "Vulcan Hart",
  Witt: "Witt",
  "Zero Zone": "Zero Zone"
};

export default function GetQueryBySectionTitle(title) {
  return lookup[title] ? lookup[title] : title;
}
