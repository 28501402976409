import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PartDetails from "./PartDetails";
import DeleteOrUpdatePart from "../DeleteOrUpdatePart";
import "../../styling/styles.css";
import PriceLabel from "./PriceLabel";
import GasketSpecs from "../../../customParts/gasket/GasketSpecs";
import Inventory from "./Inventory";
import useSecureFetch from "../../../requests/hooks/useSecureFetch";
import AppSettings from "../../../AppSettings";
import { getGasket, profileUrl } from "../../../customParts/helpers";
import { MapSrc } from "../../../requests/hooks/ImageSrcMapper";

const Wrapper = styled.div`
  clear: both;
  width: 938px;
  margin-bottom: 30px;
  min-height: 80px;
  box-shadow: 0 2px 4px 0 var(--black-19);
  border: solid 1px var(--very-light-grey);
  background-color: "white";
  display:flex;
  flex-direction:row;
  align-items:center;
`;
const Row = styled.div`
  font-family: "Roboto", sans-serif;
  margin-left: 17px;
  margin-right: 17px;
  color: var(--black);
`;
const Row1 = styled(Row)`
  margin-top: 11px;
  width: 790px;
`;
const Description = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
const PriceLabelWrapper = styled.span`
  float: right;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  margin-right: 12px;
  vertical-align: text-bottom;
  color: var(--battleship-grey);
`;
const Price = styled.span`
  float: right;
  margin-top: 8px;
  margin-right: 12px;
  font-size: 16px;
  font-weight: bold;
`;
const CallForPrice = styled.span`
  float: right;
  margin-top: 8px;
  margin-right: 12px;
  font-size: 12px;
  font-weight: normal;
`;
const Row2 = styled(Row)`
  margin: 0px 0px 8px 0px;
`;
const PartLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: var(--greyish-brown);
`;
const PartNumber = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: var(--CP-blue);
`;
const ConfigureButton = styled.button`
  float: right;
  color: white;
  width: 96px;
  height: 34px;
  border-radius: 2px;
  border: solid 2px var(--reddish-orange);
  background-color: var(--reddish-orange);
  margin-right: 8px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
`;
const FlexDiv = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
`
const HeroImage = styled.img`
  max-width: 100px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: contain; 
  margin-left: 16px;
`
const formatPrice = price => (price ? `$${Number.parseFloat(price).toFixed(2)}` : "Call");

export default function CartPart({ part, inventory, history, showDetails, onUpdateQuantity, onRemoveFromCart }) {
  const gasket = getGasket(part.partId);
  const profileImage = gasket ? profileUrl(gasket) : null;

  const imagesApi = part && part.partId ? AppSettings.PartImagesRequest(part.partId) : null
  const result = profileImage || useSecureFetch(imagesApi, null, [imagesApi]);
  const image = profileImage ? result : (result.response && !result.isLoading && !result.error
                 && result.response.Images && result.response.Images[0])
                 ? MapSrc(result.response.Images[0], part.partId) : ""

  return (
    <Wrapper>
      <HeroImage src={image || '/images/placeholder.png'} />
      <div>
        <Row1>
          {/* <Icon src="/images/icon-wrench.svg" /> */}
          <Description>{part.title ? part.title : "No part description"}</Description>
          <DeleteOrUpdatePart
            partId={part.partId}
            uniqueId={part.uniqueId}
            title={part.title}
            quantity={part.quantity}
            onRemoveFromCart={onRemoveFromCart}
            onUpdateQuantity={onUpdateQuantity}
          />
          {!!part.price && (
            <>
              <Price>{formatPrice(part.price)}</Price>
              <PriceLabelWrapper>
                <PriceLabel />
              </PriceLabelWrapper>
            </>
          )}
          {!part.price && <CallForPrice>Call for price</CallForPrice>}
          {part.custom === "Gasket" && (
            <ConfigureButton onClick={() => history.push(`/custom/gasket/${part.uniqueId}`)}>Customize</ConfigureButton>
          )}
          {part.custom === "Wire" && (
            <ConfigureButton onClick={() => history.push(`/custom/wire/${part.uniqueId}`)}>Customize</ConfigureButton>
          )}
          <Row2>
            <PartLabel>Part #: </PartLabel>
            <Link to={`/Part/${part.partId}`}>
              <PartNumber>{part.partId}</PartNumber>
            </Link>
            {part.custom === "Gasket" && <GasketSpecs gasket={part} />}
          </Row2>
          <Inventory part={part} inventory={inventory} quantity={part.quantity} />
        </Row1>
        {showDetails && part.attributes && part.attributes.length > 0 && <PartDetails attributes={part.attributes} />}
      </div>
    </Wrapper>
  );
}
