import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import styled from "styled-components";
import PartDetails from "./PartDetails";
import Inventory from "./Inventory";
import AddToCart from "../AddToCart";
import "../../styling/styles.css";
import PriceLabel from "./PriceLabel";
import { getGasket, profileUrl } from "../../../customParts/helpers";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  width: ${props => props.image ? '938px' : '820px'}
  clear: both;
  margin-bottom: 20px;
  border: solid 1px var(--very-light-grey);
  background-color: white;
`;
const Row = styled.div`
  width: ${props => props.isGasket ? '670px;' : '790px;' }
  font-family: "Roboto", sans-serif;
  margin-left: 16px;
  color: var(--black);
`;
const Row1 = styled(Row)`
  margin-top: 16px;
  border-bottom: ${props => props.hasAttributes ? '1px solid #e0e0e0;' : 'none;'}
  padding-bottom:16px;
`;
const Description = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.isSelectable ? "var(--CP-blue)" : "#000"} ;
`;
const ErrorMessage = styled(Description)`
  color: red;
`;
const HelpMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: var(--greyish-brown);
  margin-top: 20px;
`;
const PriceLabelWrapper = styled.span`
  float: right;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  margin-right: 12px;
  vertical-align: text-bottom;
  color: var(--battleship-grey);
`;
const Price = styled.span`
  float: right;
  margin-top: 8px;
  margin-right: 12px;
  font-size: 16px;
  font-weight: bold;
`;
const CallForPrice = styled.span`
  float: right;
  margin-top: 8px;
  margin-right: 12px;
  font-size: 12px;
  font-weight: normal;
`;
const Row2 = styled(Row)`
  margin: 0px;
`;
const PartLabel = styled.span`
  font-size: .875rem;
  font-weight: 300;
`;
const PartNumber = styled.span`
  font-size: .875rem;
  font-weight: bold;
`;
const ExistingCount = styled.div`
  float: right;
  font-style: italic;
  font-size: .75rem;
  color: #f06721;
  margin-top: -6px;
  margin-right: 24px;
  &:hover {
    background-color: var(--highlight-orange);
    color: black;
  }
`;
const Button = styled.button`
  float: right;
  color: white;
  width: 180px;
  height: 34px;
  border-radius: 2px;
  border: solid 2px var(--reddish-orange);
  background-color: var(--reddish-orange);
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
`;
const FlexDiv = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
`
const HeroImage = styled.img`
  max-width: 100px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: contain; 
  margin-left: 16px;
`

const formatPrice = price => (price ? `$${Number.parseFloat(price).toFixed(2)}` : "Call");

export default function Part(props) {
  const [quantity, setQuantity] = useState(1);
  const context = useContext(AppContext);
  const { cartParts } = context;
  const cartQuantity = cartParts ? cartParts.reduce((q, p) => (p.partId === props.partId ? q + p.quantity : q), 0) : 0;
  const gasket = getGasket(props.partId);

  if(props.partId === "02-14801-0010"){
    console.log(props.partId, ":", props.attributes)
    console.log("Render attributes:", props.attributes && props.attributes.length > 0)
  }

  console.log(props.image);
  
  return (
    <Wrapper isHighlighted={props.isHighlighted} image={props.image}>
      {gasket && (
        <FlexDiv>
          <HeroImage src={profileUrl(gasket)} />
          <div>
            <Row1 hasAttributes={props.attributes && props.attributes.length > 0} isGasket={true}>
              <Description isSelectable={props.isSelectable}>{gasket.title}</Description>
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.history.push(`/custom/gasket/${gasket.partId}`);
                }}
              >
                Customize
              </Button>
              <Row2>
                <PartLabel>Part #: </PartLabel>
                <PartNumber>{gasket.partId}</PartNumber>
              </Row2>
            </Row1>
            <PartDetails
              attributes={[
                { name: "Color", value: gasket.color },
                { name: "Material", value: gasket.material }
              ]}
            />
            
          </div>
        </FlexDiv>
      )}
      {!gasket && (props.title || props.attributes) && (
        <FlexDiv>
          {props.image ? <HeroImage src={props.image} /> : null}
          <div>
            <Row1 hasAttributes={props.attributes && props.attributes.length > 0}>
              <Description isSelectable={props.isSelectable}>{props.title ? props.title : "No part description"}</Description>
              <AddToCart
                partId={props.partId}
                title={props.title}
                onAddToCart={props.onAddToCart}
                quantity={quantity}
                setQuantity={setQuantity}
                discontinued={props.partStatus === 3}
                inventory={props.inventory}
                cartQuantity={cartQuantity}
              />
              {!!props.price && (
                <>
                  <Price>{formatPrice(props.price)}</Price>
                  <PriceLabelWrapper>
                    <PriceLabel />
                  </PriceLabelWrapper>
                </>
              )}
              {!props.price && <CallForPrice>Call for price</CallForPrice>}
              <Row2>
                <PartLabel>Part #: </PartLabel>
                <PartNumber>{props.partId}</PartNumber>
              </Row2>
              {cartQuantity ? (
                <Link to="/cart">
                  <ExistingCount>{`${cartQuantity} in cart`}</ExistingCount>
                </Link>
              ) : (
                ""
              )}
              <Inventory inventory={props.inventory} quantity={quantity + cartQuantity} discontinued={props.partStatus === 3}/>
            </Row1>
            {props.attributes && props.attributes.length > 0 && <PartDetails attributes={props.attributes} />}
          </div>
        </FlexDiv>
      )}
      {props.partId && !props.title && !props.attributes && (
        <>
          <Helmet>
            <meta name="prerender-status-code" content="404" />
          </Helmet>
          <Row1>
            <ErrorMessage>PartId not found</ErrorMessage>
            <Row2>
              <PartLabel>Part #: </PartLabel>
              <PartNumber>{props.partId}</PartNumber>
              <HelpMessage>Please double-check your part number or search again for equivalent part.</HelpMessage>
            </Row2>
          </Row1>
        </>
      )}
    </Wrapper>
  );
}
