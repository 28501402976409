import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../context/AppContext";
import GasketEditorForm from "./GasketEditorForm";
import { getGasket } from "../helpers";
import Page from "../widgets/Page";

export default function GasketWizard(props) {
  const context = useContext(AppContext);
  const { addOrUpdatePartInCart, cartParts, discountLevel } = context;

  const partId = props.match.params.part ? props.match.params.part.toUpperCase() : "";
  // When editing, the timestamp is passed as the partId parameter to uniquely identify the part
  const cartPart = cartParts.find(part => part.uniqueId === partId);
  const part = cartPart ? cartPart : getGasket(partId);

  if (!part) {
    return <Redirect to="/custom/gasket" />;
  }

  return (
    <Page
      title="Customize Gasket"
      icon="Gasket"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <GasketEditorForm
        part={{ ...part }}
        addOrUpdatePartInCart={addOrUpdatePartInCart}
        history={props.history}
        discountLevel={discountLevel}
      />{" "}
    </Page>
  );
}
