import React from "react";
import styled from "styled-components";
import MainNav from "../navigation/MainNav";
const Wrapper = styled.div`
  grid-area: top;
  height: 67px; //124
`;
export default function TopSection(props) {
  return (
    <Wrapper>
      <MainNav location={props.location}/>
    </Wrapper>
  );
}
