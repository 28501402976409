import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "../styling/styles.css";
import AppSettings from "../../AppSettings";

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
`;
const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-bottom: 0px;
`;
const Subtitle = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  padding-top: 8px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;
const List = styled.ul`
  margin-top: 6px;
`;
const Item = styled.li`
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--greyish-brown);
`;
const Column = styled.div`
  max-width: 800px;
  float: left;
  margin: 12px;
`;
// const BodyText = styled.p`
//   font-family: "Roboto", sans-serif;
//   font-size: 16px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: var(--greyish-brown);
//   margin-bottom: 8px;s
//   max-width: 480px;
//   background-color: #ffdc64;
//   padding: 8px;
//   padding-left: 16px;
// `;

export default function Version(props) {
  return (
    <Wrapper>
      <Title>{AppSettings.AppVersion}</Title>

      <Column>
        <Subtitle>
          Release 3.18: Info Pages - <i>8/12/2022</i>
        </Subtitle>
        <List>
          <Item>
            Added new pages for:{" "}
            <ul>
              <li>Frequently Asked Questions: <Link to="/faq">/faq</Link></li>
              <li>Returns &amp; Warranty: <Link to="/returns">/returns</Link></li>
              <li>Privacy Policy: <Link to="/privacy">/privacy</Link></li>
              <li>Terms of Use: <Link to="/terms">/terms</Link></li>
              <li>About Case Parts: <Link to="/about">/about</Link></li>
              <li>50 Years of Service: <Link to="/cpc50">/cpc50</Link></li>
            </ul>
          </Item>
          <Item>Renamed the old About page <Link to="/version">/version</Link></Item>
          <Item>BUG FIX: Added logic to wait for customer information to be loaded from server
            before deciding on default payment method in checkout</Item>
        </List>
        <Subtitle>
          Release 3.17: Inventory Status - <i>3/17/2022</i>
        </Subtitle>
        <List>
          <Item>PartList page occassionally showed incorrect status due to two concurrent api calls to retrieve inventory
            and one cal with stale parameters. The extraneous call was eliminated so status is now consistently accurate.</Item>
        </List>
        <Subtitle>
          Release 3.16: Branch Addresses - <i>2/18/2022</i>
        </Subtitle>
        <List>
          <Item>Added tooltips to home page showing the address for each branch</Item>
        </List>
        <Subtitle>
          Release 3.15: Dynamic Branch Closure messages - <i>2/2/2022</i>
        </Subtitle>
        <List>
          <Item>Alert banners can now be shown on the home page</Item>
          <Item>A large banner can be displayed above the branch details</Item>
          <Item>Brief messages can also be shown benath each branch</Item>
          <Item>Posting or removing alerts can be done by editing files on the server, doesn't require the CI/CD pipeline</Item>
        </List>
        <Subtitle>
          Release 3.14: Shipping Recipient - <i>1/28/2022</i>
        </Subtitle>
        <List>
          <Item>Can now specify the name of the recipient person or company on the shipping label for web orders</Item>
          <Item>Removed the notice about sales tax from checkout but retained info page</Item>
        </List>
        <Subtitle>
          Release 3.13: Improved parts availability indications - <i>12/15/2021</i>
        </Subtitle>
        <List>
          <Item>Availability for specific warehouse now considers parts on hold for other orders</Item>
          <Item>Availability from enterprise now considers enterprise inventory</Item>
        </List>
        <Subtitle>
          Release 3.12: Sales Tax - <i>11/20/2021</i>
        </Subtitle>
        <List>
          <Item>Went live with Avalara integration charging sales tax in 21 additional states</Item>
          <Item>Added info page with details on sales tax linked to from checkout page</Item>
          <Item>Added banner to checkout page with alert about changes to sales tax</Item>
        </List>
        <Subtitle>
          Release 3.11: Reports - <i>6/29/2021</i>
        </Subtitle>
        <List>
          <Item>Added charts for Orders and Requests</Item>
          <Item>Reports provide drill-down from charts</Item>
        </List>
        <Subtitle>
          Release 3.10: Added Text RequestType - <i>3/30/2021</i>
        </Subtitle>
        <List>
          <Item>Added support for Text requests to order dashboard</Item>
        </List>
        <Subtitle>
          Release 3.9: Bug Fix for Bill Recipient Orders - <i>3/16/2021</i>
        </Subtitle>
        <List>
          <Item>Can now specify the name of the recipient person or company on the shipping label for web orders</Item>
          <Item>Removed the notice about sales tax from checkout but retained info page</Item>
        </List>
        <Subtitle>
          Release 3.8: Improved Shipping Estimates - <i>3/11/2021</i>
        </Subtitle>
        <List>
          <Item>Revised logic for shipping estimates to consider confidence level</Item>
        </List>
        <Subtitle>
          Release 3.7: Mobile Redirect - <i>1/28/2021</i>
        </Subtitle>
        <List>
          <Item>Added delayed loading for mobile site redirect</Item>
        </List>
        <Subtitle>
          Release 3.6: Approve Shipping Cost - <i>11/16/2020</i>
        </Subtitle>
        <List>
          <Item>
            When shipping costs are unknown, customers may approve shipping costs prior to having their order shipped.
          </Item>
          <Item>This option is not shown when shipping costs are already known</Item>
          <Item>
            There are a number of special cases in which this option is not offered:
            <ul>
              <li>Orders that include non-refundable (custom) parts</li>
              <li>Bill Recipient Customers for whom shipping is billed directly to their UPS account</li>
              <li>The custom has not yet specified a shipping address</li>
            </ul>
          </Item>
          <Item>
            <b>Part Research requests now include state/country</b>. This extra field makes it easier to determine which
            branch has priority for processing the request.
          </Item>
        </List>

        <Subtitle>
          Release 3.5: Part Research Request Integration with Part Search - <i>9/15/2020</i>
        </Subtitle>
        <List>
          <Item>We now display the Part Research form whenever a part search request returns no matching parts.</Item>
        </List>

        <Subtitle>
          Release 3.4: In-Stock Indicators and Will Call Orders - <i>9/3/2020</i>
        </Subtitle>
        <List>
          <Item>
            The parts now show an indication when they are in-stock. A green message says which warehouses have the
            desired quantity of that part. If no single warehouse has sufficient quantiy available, the indicator is not
            shown. In-Stock indications are shown for search results and in the shopping cart as well as when looking at
            an individual part on the Part page.
          </Item>
          <Item>
            Will Call orders have been reinstated. Customers may choose <b>Will Call</b> as a shipping option during
            checkout and will be shown branch-specific details about when and how pick-up is handled.
          </Item>
          <Item>
            Each part in the Shopping Cart now links to the associated Part page which provides a navigation path to
            find related parts and catalog pages.
          </Item>
        </List>

        <Subtitle>
          Release 3.3: Additional Custom Parts - <i>8/11/2020</i>
        </Subtitle>
        <List>
          <Item>
            Added new pages for:{" "}
            <ul>
              <li>Custom Formed Tubular Defrost Heaters</li>
              <li>Custom Coils</li>
              <li>Replacement Walk-In Doors</li>
              <li>Wire Shelving</li>
              <li>Commercial Cutting Boards</li>
            </ul>
          </Item>
          <Item>
            Each of those pages provide links to relevant catalog pages and order forms as well as including a contact
            form allowing the customer to submit a quote request
          </Item>
          <Item>A new /custom page lists all the custom parts</Item>
          <Item>The home page also lists all the custom parts</Item>
          <Item>
            The Gasket and Warmer Wire pages have been refined to comport with a refined graphic design used
            consistently across all custom part pages.
          </Item>
        </List>

        <Subtitle>
          Release 3.2: Custom Warmer Wires - <i>7/28/2020</i>
        </Subtitle>
        <List>
          <Item>Custom warmer wires can be configured via a wizard</Item>
          <Item>Options are provided for related installation accessories</Item>
          <Item>Custom wires and accessories can alo be edited in the wizard</Item>
        </List>

        <Subtitle>
          Release 3.1.1: Custom Gaskets - <i>7/7/2020</i>
        </Subtitle>
        <List>
          <Item>Fixed missing gasket specs in cart and email</Item>
        </List>

        <Subtitle>
          Release 3.1: Custom Gaskets - <i>6/26/2020</i>
        </Subtitle>
        <List>
          <Item>Custom gasket profiles can be selected from a visual list of profiles</Item>
          <Item>Visual feedback provided as gaskets are customized</Item>
          <Item>Gasket customizations are automatically transferred into Order Pad</Item>
        </List>
        <Subtitle>
          Release 3.0: New Checkout Process - <i>4/28/2020</i>
        </Subtitle>
        <p>
          <b>Much more intuitive and useful for Customers</b>
        </p>
        <List>
          <Item>Intuitive checkout design based on multi-step accordion control</Item>
          <Item>Customers and anonymous web visitors can enter credit card for their order</Item>
          <Item>Customers can also select from credit cards and shipping addresses on file</Item>
          <Item>Sales tax and shipping cost calculated during checkout</Item>
          <Item>Added shipping options for UPS Red &amp; Blue</Item>
          <Item>Customers and anonymous web visitors can place orders or request quotes</Item>
        </List>
        <p>
          <b>Much easier and faster for CSRs</b>
        </p>
        <List>
          <Item>No more copying data from emails!</Item>
          <ul>
            <Item>OP order/quote automatically created and linked to Request</Item>
            <Item>Includes line items, shipping address, payment method</Item>
            <Item>Status reflects whether order is Ready to Commit or Requires Authorization</Item>
          </ul>
          <Item>Emails still available as backup if OP auto-create fails</Item>
          <Item>
            Clicking requestIds on the dashboard copies them to the clipboard streamlining integration with OP
          </Item>
        </List>
        <Subtitle>
          Release 2.10.4: Metric reports enhancements <i>3/10/2020</i>
        </Subtitle>
        <List>
          <Item>
            <b>Request Chart can be filtered to show metrics for a single person</b>
          </Item>
          <Item>
            <b>Many refinements make Request Report more powerful, intuitive and useful</b>
          </Item>
          <ul>
            <Item>Added order information eliminates the need for a separate order report page</Item>
            <Item>RMAs are now shown and available in order filtering</Item>
            <Item>Flexible date filtering includes interactive calendar and presets for common choices</Item>
            <Item>Links in report allow you to pivot to related information</Item>
            <Item>Can view the transcript of any chat</Item>
          </ul>
          <Item>
            <b>Integrated visual elements from the Dashboard display</b>
          </Item>
          <ul>
            <Item>Added summary metrics of orders and quotes</Item>
            <Item>Profile pics shown for request owners</Item>
            <Item>Request type and id includes icon as on the dashboard</Item>
          </ul>
          <Item>
            <b>Many ways of filtering and sorting report:</b>
          </Item>
          <ul>
            <Item>Request type</Item>
            <Item>Order type - includes flexible handling of RMAs</Item>
            <Item>Owner</Item>
            <Item>Status</Item>
            <Item>Date - filter by submit date, sort by submit, update or complete date</Item>
            <Item>Account</Item>
            <Item>Email</Item>
            <Item>Owner</Item>
            <Item>Chat channel - find all requests related to a single chat channel</Item>
          </ul>
        </List>

        <Subtitle>
          Release 2.10.3: Metric reports grouping persistance - <i>1/31/2020</i>
        </Subtitle>
        <List>
          <Item>Retained preference for Month/Week/Day unit on Request and Order displays</Item>
        </List>

        <Subtitle>
          Release 2.10.2: Metric reports column sorting - <i>1/23/2020</i>
        </Subtitle>
        <List>
          <Item>Order report can now be sorted on any column</Item>
          <Item>Metrics report can now be sorted on any column</Item>
        </List>

        <Subtitle>
          Release 2.10.1: Metric reports - <i>1/14/2020</i>
        </Subtitle>
        <List>
          <Item>Added Metrics Report display</Item>
          <Item>Added Order Metrics display</Item>
        </List>

        <Subtitle>
          Release 2.10: Metric reports foundation - <i>1/6/2020</i>
        </Subtitle>
        <List>
          <Item>Enhanced integration linking orders with requests</Item>
        </List>

        <Subtitle>
          Release 2.9.2: Metrics display - <i>12/19/2019</i>
        </Subtitle>
        <List>
          <Item>Added summary count and total to dashboard</Item>
          <Item>Eliminated dupe requests due to double-clicking submit</Item>
        </List>
        <Subtitle>
          Release 2.9.1: Metrics display - <i>12/18/2019</i>
        </Subtitle>
        <p>
          <b>Order Linkage</b>: Dashboard now shows the status and total amount of info the quote or order associated
          with a request.
        </p>
        <List>
          <Item>Order number and total amount displayed on dashboard</Item>
          <Item>Special highlighting for committed orders</Item>
          <Item>Order status shown for non-committed orders/quotes</Item>
          <Item>Icons on dashboard are now color-coded consistently with bar graph on metrics display</Item>
        </List>
        <Subtitle>
          Release 2.9: Metrics display - <i>12/16/2019</i>
        </Subtitle>
        <p>
          <b>Dashboard Metrics</b>: A new display has been added to show metrics for the number of requests per
          day/week/month broken down by request type.{" "}
        </p>
        <List>
          <Item>Metrics can be aggregated by day, week or month</Item>
          <Item>Metrics are subdivided by type: Checkout, Research, Chat, Email and Account</Item>
          <Item>Each individual type can be included or hidden in display</Item>
          <Item>Hovering over chart displays detailed counts</Item>
          <Item>Clicking on any segment drills into the individual requests associated with the count</Item>
        </List>
        <Subtitle>
          Release 2.8: Account requests, smart mentions, fewer bugs - <i>12/9/2019</i>
        </Subtitle>
        <p>
          <b>Account requests</b> are now posted in Waiting on Customer state and related emails and notifications have
          been refined.
        </p>
        <p>
          <b>Smart mentions</b>: When a CSR is assigned or unassigned a request by someone else, they are now explicitly
          mentioned in the associated Slack notification so that they'll receive a notification of such request without
          having to be notified on all #customer-dashboard updates.
        </p>
        <p>
          <b>New Request Notifications</b>: A new Slack user group @new has been added. Users in this group will be
          notified when new requests are posted to #customer-dashboard.
        </p>
        <p>
          <b>Fewer bugs</b>: this build has a number of geeky changes listed below that help eliminate having duplicate
          or missing dashboard requests:
        </p>
        <List>
          <Item>Automatic retry logic added if requestId post fails</Item>
          <Item>All request calls and errors are now logged to Loupe along with related server logging</Item>
          <Item>
            Version number and release date now logged on all PartFinder sessions so logs show the exact software
            version a customer was running when debugging.
          </Item>
        </List>
        <Subtitle>
          Release 2.7: Request Manager - <i>12/2/2019</i>
        </Subtitle>
        <List>
          <Item>Incorporate updated pricing, especially for True parts</Item>
          <Item>Much smoother updates on Request Dashboard</Item>
          <Item>Various other UI refinements to Request Dashboard</Item>
        </List>
        <Subtitle>
          Release 2.6: Request Manager - <i>11/13/2019</i>
        </Subtitle>
        <List>
          <Item>Added limited dashboard access to all employees</Item>
          <Item>Added link to Review your order</Item>
          <Item>Added link for Accounting Forms</Item>
          <Item>Added support for Canadian zipcodes</Item>
        </List>
        <Subtitle>
          Release 2.5: Request Manager - <i>10/23/2019</i>
        </Subtitle>
        <List>
          <Item>New dashboard page to track customer requests</Item>
          <Item>Dashboard only available to designated staff</Item>
          <Item>Slack notifications include link to Request</Item>
          <Item>Customer emails include textual reference to Request Id</Item>
          <Item>New Slack notifications for all changes to requests</Item>
          <Item>Requests link to related chats &amp; emails</Item>
          <Item>CSR emails include links to requests</Item>
          <Item>Chat visibility can be enabled per-employee</Item>
        </List>
        <Subtitle>
          Release 2.4: SEO - <i>9/13/2019</i>
        </Subtitle>
        <List>
          <Item>onClick handlers replaced with Link components</Item>
          <Item>AppProvider refactored to synchronize state on page change</Item>
          <Item>Created sitemap.xml &amp; robots.txt files</Item>
          <Item>Integrated Google Search Console</Item>
          <Item>Integrated prerender.io</Item>
        </List>
        <Subtitle>
          Release 2.3: Account Request - <i>8/13/2019</i>
        </Subtitle>
        <List>
          <Item>Distinguish Net vs List price</Item>
          <Item>Add form to request info on creating an account</Item>
          <Item>Add routing to accept URLs from the old website</Item>
          <Item>Added fax numbers to office ihfo</Item>
        </List>
        <Subtitle>
          Release 2.2: Slack Notifications - <i>7/30/2019</i>
        </Subtitle>
        <List>
          <Item>Simplified list of Shipping Methods</Item>
          <Item>Enhanced messaging about canceling/changing orders</Item>
          <Item>Enhanced emails for Will Call orders</Item>
          <Item>Added Slack notifications on form submissions</Item>
          <Item>Updated catalog data</Item>
        </List>
        <Subtitle>
          Release 2.1: Live Chat - <i>7/29/2019</i>
        </Subtitle>
        <List>
          <Item>Live chat enabled M-F between 7:30 AM to 5:00 PM PDT</Item>
          <Item>User Mgr impersonate now shows correct customer pricing</Item>
          <Item>Quote Requests now include fields for PO &amp; Shipping Method</Item>
          <Item>Customer quote requests with PO &amp; Ship Method treated as orders</Item>
          <Item>Shipping Address not required for Will Call orders</Item>
          <Item>Contact info persisted between sessions for logged in users</Item>
          <Item>Improved phone number validation</Item>
          <Item>Research requests now trigger Thank You page with appropriate message</Item>
          <Item>Contact requests now trigger Thank You page with appropriate message</Item>
        </List>
        <Subtitle>
          Release 2.0: Launch! - <i>7/22/2019</i>
        </Subtitle>
        <List>
          <Item>Parts Search</Item>
          <Item>Shopping Cart</Item>
          <Item>Quote Requests</Item>
          <Item>Research Requests</Item>
          <Item>Login/Logoff Integration wih my.caseparts.com</Item>
        </List>
      </Column>
    </Wrapper>
  );
}
