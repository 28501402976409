import React from "react";
import styled from "styled-components";
import { formatFraction } from "./dimensions";

const Wrapper = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: normal;
  color: var(--greyish-brown);
`;

function GasketSpecsText(gasket) {
  // Be sure to change GasketSpecs if you change this!
  var text = "";
  if (gasket.specs.Height && gasket.specs.Width)
    text += `${formatFraction(gasket.specs.Width)} W x ${formatFraction(gasket.specs.Height)} H`;
  if (gasket.specs.Sides) text += `, ${gasket.specs.Sides}-sided`;
  if (gasket.specs.DartToDart) text += ", Dart-to-Dart";
  if (gasket.specs.Inverted) text += ", Inverted";
  if (gasket.specs.NoMagnetSide) text += `, No Magnet ${gasket.specs.NoMagnetSide} Side`;
  return text;
}

export default function GasketSpecs({ gasket }) {
  // Be sure to change GasketSpecsText if you change this!
  return (
    <Wrapper>
      Specs:&nbsp;
      <b>
        {gasket.specs.Height && gasket.specs.Width && (
          <span>
            {formatFraction(gasket.specs.Width)} W x {formatFraction(gasket.specs.Height)} H
          </span>
        )}
        {gasket.specs.Sides && <span>, {gasket.specs.Sides}-sided</span>}
        {gasket.specs.DartToDart && <span>, Dart-to-Dart</span>}
        {gasket.specs.Inverted && <span>, Inverted</span>}
        {gasket.specs.NoMagnetSide && <span>, No Magnet {gasket.specs.NoMagnetSide} Side</span>}
      </b>
    </Wrapper>
  );
}

export { GasketSpecsText };
