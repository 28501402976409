import React from "react";
import Select from "react-select";

const getOption = val => ({ label: val, value: val });
const options = [getOption("Phone"), getOption("Email")];

// doc: https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontFamily: "Roboto, sans-serif",
    width: "380px",
    height: "18px",
    color: "black",
    fontSize: "14px",
  }),
  input: (provided, state) => ({
    ...provided,
    height: "18px"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "22px",
    width: "380px"
  }),
  container: (provided, state) => ({
    ...provided,
    height: "18px",
    marginBottom: "32px"
  })
};

const ContactPreference = ({ field, form }) => (
  <Select
    className="text-input"
    placeholder="Select Phone or Email (optional)"
    styles={customStyles}
    defaultValue={field.value ? getOption(field.value) : null}
    isClearable
    options={options}
    onChange={option => form.setFieldValue(field.name, option ? option.value : "")}
    onBlur={() => form.setFieldTouched(field.name)}
  />
);

export default ContactPreference;
