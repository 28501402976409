import React from "react";
import Page from "../widgets/Page";
import ContactForm from "../../ui/checkout/ContactForm";

export default function BoardWizard({ title }) {
  return (
    <Page
      title="Commercial Cutting Boards"
      icon="Board"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <div className="flexContainer">
        <div className="customPartColumn">
          <h2>Cutting Boards built to your specifications</h2>
          <ul>
            <li>NSF approved high-density polyethylene</li>
            <li>Cut to your desired size</li>
            <li>Very competitive pricing</li>
            <li>Prompt delivery</li>
          </ul>
          <h2>Just let us know your specifications:</h2>
          <ul>
            <li>
              Width &amp; Depth <i>(up to 120" by 60")</i>
            </li>
            <li>
              Thickness <i>(1/2", 3/4", or 1")</i>
            </li>
            <li>Quantity</li>
          </ul>
        </div>
        <div className="customPartColumn">
          <h2>Let us know your specifications and we'll get you a quote within one business day</h2>
          <ContactForm
            topic="Commercial Cutting Boards"
            messageLabel="Cutting Board Specifications"
            messageHints="Please enter width, depth, thickness and quantity"
          />
        </div>
      </div>
    </Page>
  );
}
