import React from "react"
import InfoPage from "./InfoPage"

export default function Returns() {
    return <InfoPage>
        <h1>Returns</h1>
        <p>
            We understand that the most important element of our business relationship is for us to deliver the correct parts, quickly and reliably.
        </p><p>
            Orders shipped in error by Case Parts Company are returnable at no charge.
        </p><p>
            Damaged, missing, or incorrect parts will be replaced or refunded at no charge provided that you report the problem within 1 week of receiving your order.
        </p><p>
            <b>Returns are subject to the following limitations:</b></p>
        <ul>
            <li>Custom-made items such as wire shelves, bent heaters, and made-to-order gaskets are not returnable.</li>
            <li>Special order items are handled on an individual basis and may be subject to restocking fees.</li>
            <li>No returns after 60 days from invoice date.</li>
            <li>Customer is responsible for the cost of return shipping.</li>
            <li>Case Parts Company assumes no responsibility for incidental or consequential damages resulting from defective parts, parts shipped in error, or parts inaccurately represented in our catalog.</li>
        </ul>
        <p>To return merchandise, please request a Return Merchandise Authorization (RMA) number from our Customer Service Department. Please provide our order number, the part numbers, and the quantities you wish to return.  </p>
    </InfoPage>
}