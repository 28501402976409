import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import queryString from "query-string";
import useRequestReport from "../hooks/useRequestReport";
import ResultWrapper from "../ResultWrapper";
import "../../ui/styling/styles.css";
import "../requestManager.css";
import { Time } from "../RequestFormatting";
import moment from "moment";
import Breadcrumb from "../../ui/navigation/Breadcrumb";
import AppSettings from "../../AppSettings";
import { FindEmployee, IsAuthorized } from "../data/GetStaffInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectCsr from "../widgets/SelectCsr";
import DateRange from "../widgets/DateRange";
import SummaryMetrics from "../widgets/SummaryMetrics";
import MiniRequestType from "../widgets/MiniRequestType";
import RequestHistoryPopup from "../widgets/RequestHistoryPopup";
import MiniOwner from "../widgets/MiniOwner";

const SelectRequestType = ({ current, onSelect }) => {
  return (
    <select defaultValue={current} onChange={e => onSelect(e.target.value)}>
      <option key="all" value=" ">
        Any / All
      </option>
      <option key="chat">Chat</option>
      <option key="checkout">Checkout</option>
      <option key="orderlink">OrderLink</option>
      <option key="research">Research</option>
      <option key="email" value="contact">
        Email
      </option>
      <option key="account">Account</option>
    </select>
  );
};

const SelectOrderType = ({ current, onSelect }) => {
  return (
    <select defaultValue={current} onChange={e => onSelect(e.target.value)}>
      <option key="all" value=" ">
        Any / All
      </option>
      <option key="any" value="any">
        Orders &amp; Quotes
      </option>
      <option key="order" value="order">
        Orders
      </option>
      <option key="quote" value="quote">
        Quotes
      </option>
      <option key="committed" value="committed">
        Committed
      </option>
      <option key="rma" value="rma">
        RMA
      </option>
    </select>
  );
};

const SelectStatus = ({ current, onSelect }) => {
  return (
    <select defaultValue={current} onChange={e => onSelect(e.target.value)}>
      <option key="all" value=" ">
        Any / All
      </option>
      <option key="active" value="-done">
        Active
      </option>
      <option key="done" value="done">
        Completed
      </option>
      <option key="hidden" value="hidden">
        Hidden
      </option>
    </select>
  );
};

const ClearableFilter = ({ label, onSelect }) => {
  return (
    <span className="boxedText" onClick={onSelect}>
      {label} <span className="red">x</span>
    </span>
  );
};

export default function RequestsReport({ location, match, history }) {
  const context = useContext(AppContext);
  const { claims } = context;
  // Verify that this is an employee
  if (claims.UserType !== "Employee") {
    console.log(claims.UserType, "Not authorized");
    window.location = "/";
  }
  const employee = FindEmployee(claims.Employee.UserName);

  // First, we need to authenticate
  if (!IsAuthorized(employee)) {
    history.push("/");
  }

  const query = queryString.parse(location.search);
  if (!query.from) {
    query.from = moment()
      .clone()
      .subtract(1, "week")
      .format("M/D/YYYY");
  }
  if (!query.to) {
    query.to = moment()
      .clone()
      .format("M/D/YYYY");
  }
  query.sort = query.sort ? query.sort.toLowerCase() : "-submitted";

  const result = useRequestReport(
    query.customer,
    query.email,
    query.owner,
    query.status,
    query.request,
    query.chat,
    query.order,
    query.from,
    query.to,
    query.sort
  );

  const BuildUrl = props => {
    const request = props.request ? props.request : query.request ? query.request : "";
    const status = props.status ? props.status : query.status ? query.status : "";
    const order = props.order ? props.order : query.order ? query.order : "";
    const chat = props.chat ? props.chat : query.chat ? query.chat : "";
    const customer = props.customer ? props.customer : query.customer ? query.customer : "";
    const email = props.email ? props.email : query.email ? query.email : "";
    const owner = props.owner ? props.owner : query.owner ? query.owner : "";
    const from = props.from ? props.from : query.from ? query.from : "";
    const to = props.to ? props.to : query.to ? query.to : "";
    const sort = props.sort ? props.sort : query.sort ? query.sort : "";
    const url = `/requestsreport?request=${request.trim()}&status=${status.trim()}&order=${order.trim()}&chat=${chat.trim()}&customer=${customer.trim()}&email=${email.trim()}&owner=${owner.trim()}&from=${from.trim()}&to=${to.trim()}&sort=${sort.trim()}`;
    return url;
  };

  const BuildSortUrl = (label, isCurrentView) => {
    var sort;
    if (isCurrentView && query.sort) {
      sort = query.sort.substr(0, 1) === "-" ? query.sort.substr(1) : `-${query.sort}`;
    } else {
      switch (label) {
        case "Request":
          sort = "request";
          break;
        case "Status":
          sort = "status";
          break;
        case "Submitted":
          sort = "-submitted";
          break;
        case "Updated":
          sort = "-updated";
          break;
        case "Completed":
          sort = "-completed";
          break;
        case "Chat":
          sort = "chat";
          break;
        case "OP":
          sort = "op";
          break;
        case "Total":
          sort = "-total";
          break;
        case "Account":
          sort = "account";
          break;
        case "Contact":
          sort = "contact";
          break;
        case "Updated By":
          sort = "updatedby";
          break;
        case "Owner":
          sort = "owner";
          break;
        default:
          sort = "";
          break;
      }
    }

    return BuildUrl({ sort: sort });
  };

  const ColumnHeading = props => {
    var sort = query.sort;
    var descending = false;
    if (sort.substr(0, 1) === "-") {
      descending = true;
      sort = sort.substr(1);
    }
    const label = props.children;
    const isCurrentView = label.toLowerCase().replace(" ", "") === sort;
    const url = BuildSortUrl(label, isCurrentView);
    return (
      <Link className="columnHeading" to={url}>
        {isCurrentView && (
          <FontAwesomeIcon
            icon={["fas", descending ? "sort-down" : "sort-up"]}
            style={{ marginRight: "4px", fontSize: "16px" }}
          />
        )}
        {label}
      </Link>
    );
  };

  const ReportHeading = () => {
    return (
      <>
        <h1>
          Customer Requests&nbsp;&nbsp;&nbsp;
          <Breadcrumb className="requestLink" title="Return" />
        </h1>
        <div style={{ marginBottom: "8px" }}>
          <span>
            <b>Request Type</b>:{" "}
            <SelectRequestType
              current={query.request}
              onSelect={request => history.push(BuildUrl({ request: request }))}
            />
          </span>
          &nbsp;
          <span>
            <b>Order Type</b>:{" "}
            <SelectOrderType current={query.order} onSelect={order => history.push(BuildUrl({ order: order }))} />
          </span>
          &nbsp;
          <span>
            <b>Owner</b>: <SelectCsr current={query.owner} onSelect={csr => history.push(BuildUrl({ owner: csr }))} />
          </span>
          &nbsp;
          <span>
            <b>Status</b>:{" "}
            <SelectStatus current={query.status} onSelect={filter => history.push(BuildUrl({ status: filter }))} />
          </span>
          <b>Submitted</b>:{" "}
          <DateRange
            start={query.from}
            end={query.to}
            onSelect={(from, to) => history.push(BuildUrl({ from: from, to: to }))}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {query.customer && (
            <span>
              <b>Customer</b>:{" "}
              <ClearableFilter
                label={query.customer}
                onSelect={() => history.push(BuildUrl({ customer: " ", from: " ", to: " " }))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          )}
          {query.email && (
            <span>
              <b>Email</b>:{" "}
              <ClearableFilter
                label={query.email}
                onSelect={() => history.push(BuildUrl({ email: " ", from: " ", to: " " }))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          )}
          {query.chat && (
            <span>
              <b>Chat</b>:{" "}
              <ClearableFilter
                label={query.chat}
                onSelect={() => history.push(BuildUrl({ chat: " ", from: " ", to: " " }))}
              />
              &nbsp;
            </span>
          )}
        </div>
      </>
    );
  };

  function orderClassName(entry) {
    if (entry.Order.Status === "Committed") return "order";
    if (entry.Order.Status === "RMA") return "rma";
    return "quote";
  }

  function OrderInfo({ entry }) {
    if (!entry.Order) {
      return <td></td>;
    }

    const info = (
      <>
        {entry.Order.OrderId}
        <br />
        {entry.Order.Status}
      </>
    );
    return (
      <td className={orderClassName(entry)} style={{ maxWidth: "60px" }}>
        {info}
      </td>
    );
  }
  function currencyFormat(num) {
    return (
      "$" +
      Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
  function OrderTotal({ entry }) {
    if (!entry.Order) {
      return <td></td>;
    }
    const total = currencyFormat(entry.Order.Total);
    return (
      <td className={orderClassName(entry)} style={{ maxWidth: "60px" }}>
        {total}
      </td>
    );
  }
  function truncate(text, maxLength) {
    if (!text) return "";

    const limit = maxLength && Number(maxLength) > 0 ? Number(maxLength) : 100;
    if (text.length <= limit) return text;

    return text.substr(0, limit).concat("...");
  }

  return (
    <ResultWrapper
      result={result}
      render={() => {
        const requestList = result.response;
        const hasOrders = requestList.some(entry => entry.Order);
        const hasChats = requestList.some(entry => entry.ChannelId);

        return (
          <div className="page">
            <SummaryMetrics requests={requestList} />
            <ReportHeading />
            <table className="subtleTable">
              <thead>
                <tr>
                  <th>
                    <ColumnHeading>Request</ColumnHeading>
                  </th>
                  {hasChats && (
                    <>
                      <th>
                        <ColumnHeading>Chat</ColumnHeading>
                      </th>
                    </>
                  )}
                  {hasOrders && (
                    <>
                      <th>
                        <ColumnHeading>OP</ColumnHeading>
                      </th>
                      <th>
                        <ColumnHeading>Total</ColumnHeading>
                      </th>
                    </>
                  )}
                  <th>
                    <ColumnHeading>Owner</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Account</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Contact</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Status</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Submitted</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Updated</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Completed</ColumnHeading>
                  </th>
                </tr>
              </thead>
              <tbody>
                {requestList.map((entry, n) => (
                  <tr key={n}>
                    <td className="requestType" style={{ minWidth: "100px" }}>
                      <RequestHistoryPopup requestId={entry.RequestId} trigger={<MiniRequestType request={entry} />} />
                    </td>
                    {hasChats && (
                      <td style={{ minWidth: "70px", maxWidth: "80px" }}>
                        {entry.ChannelId && (
                          <>
                            <Link to={`/requestsreport/?chat=${entry.ChannelId}`}>{entry.ChannelId}</Link>
                            <br />
                            <button onClick={() => window.open(AppSettings.ViewSlackChannel(entry.ChannelId), "slack")}>
                              View Chat
                            </button>
                          </>
                        )}
                      </td>
                    )}
                    {hasOrders && (
                      <>
                        <OrderInfo entry={entry} />
                        <OrderTotal entry={entry} />
                      </>
                    )}
                    <td className="clickable" style={{ minWidth: "120px" }}>
                      <Link to={`/requests/?owner=${FindEmployee(entry.Owner).username}`}>
                        <MiniOwner entry={entry} />
                      </Link>
                    </td>
                    <td>
                      <Link className="requestLink" to={`/requestsreport/?customer=${entry.CustomerId}`}>
                        {entry.CustomerId}
                      </Link>
                      <br />
                      {entry.Company}
                    </td>
                    <td>
                      <Link className="requestLink" to={`/requestsreport/?email=${entry.Email}`}>
                        {entry.Email}
                      </Link>
                      <br />
                      {entry.Name}
                    </td>
                    <td style={{ minWidth: "150px" }}>
                      {entry.Status}
                      <br />
                      <span style={{ fontSize: "10px" }}>{truncate(entry.Note, 100)}</span>
                    </td>
                    <td style={{ minWidth: "100px" }}>{Time(entry.CreatedTime)}</td>
                    <td style={{ minWidth: "80px" }}>{Time(entry.UpdatedTime)}</td>
                    <td style={{ minWidth: "95px" }}>{entry.CompletedTime ? Time(entry.CompletedTime) : ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    />
  );
}
