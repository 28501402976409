import React from "react";

function isOrder(request) {
  return request && request.Order && (request.Order.Status === "Committed" || request.Order.Status === "RMA");
}
function isQuote(request) {
  return request && request.Order && request.Order.Status !== "Committed" && request.Order.Status !== "RMA";
}

export default function SummaryMetrics({ requests }) {
  const formatTotal = number => number.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const orderCount = requests.reduce((total, request) => total + (isOrder(request) ? 1 : 0), 0);
  const orderTotal = requests.reduce(
    (total, request) => total + (isOrder(request) ? Number(request.Order.Total) : 0),
    0
  );
  const quoteCount = requests.reduce((total, request) => total + (isQuote(request) ? 1 : 0), 0);
  const quoteTotal = requests.reduce(
    (total, request) => total + (isQuote(request) ? Number(request.Order.Total) : 0),
    0
  );

  return (
    <div className="summaryMetrics">
      <table>
        <tbody>
          <tr>
            <td>{orderCount} Orders&nbsp;</td>
            <td>&nbsp;$</td>
            <td>{formatTotal(orderTotal)}</td>
          </tr>
          <tr>
            <td>{quoteCount} Quotes</td>
            <td>&nbsp;$</td>
            <td>{formatTotal(quoteTotal)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
