import React from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../styling/styles.css";
import "../styling/formik.css";
import Makes from "../components/Makes";
import GasketSpecs from "../../customParts/gasket/GasketSpecs";
import Inventory from "../components/parts/Inventory";

const Wrapper = styled.div`
  clear: both;
  width: 100%;
  margin-botom: 4px;
  padding-top: 4px;
  border-top: solid 1px var(--very-light-grey);
`;
const Row = styled.div`
  font-family: "Roboto", sans-serif;
  margin-left: 0px;
  margin-right: 0px;
  color: var(--black);
`;
const Row1 = styled(Row)`
  margin-top: 6px;
`;
const Description = styled.span`
  font-size: 14px;
  font-weight: normal;
`;
const Price = styled.span`
  float: right;
  font-size: 14px;
  font-weight: normal;
  vertical-align: text-bottom;
`;
const Row2 = styled(Row)`
  margin: 0px 0px 8px 0px;
`;
const PartLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: var(--greyish-brown);
  margin-right: 4px;
`;
const PartNumber = styled.span`
  font-size: 12px;
  font-weight: normal;
  margin-right: 20px;
  color: var(--greyish-brown);
`;
const Add = styled.div`
  margin-top: 0px;
  padding: 0px;
  font-size: 12px;
  font-weight: normal;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: black;
  }
`;
const Edit = styled.span`
  margin-top: 0px;
  padding: 0px;
  font-size: 12px;
  font-weight: normal;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: black;
  }
`;
const PopupWrapper = styled.div`
  margin: 0px;
  padding: 16px 16px 0px 16px;
`;
const PopupHeading = styled.div`
  position: relative;
  top: -22px;
  left: -21px;
  width: 406px;
  margin: 0px 0px 4px 0px;
  padding 22px;
  background-color: #ffd564;
`;

const validations = {
  make: Yup.string().max(50, "Make may not exceed 50 characters"),
  model: Yup.string().max(50, "Model may not exceed 50 characters"),
  serial: Yup.string().max(50, "Serial number may not exceed 50 characters")
};
const inputSchema = Yup.object().shape(validations);

const FormField = ({ name, required, caption, type, component, placeholder, errors, touched, autoFocus }) => (
  <>
    <label htmlFor={name}>
      {caption}
      {required && <strong>*</strong>}
    </label>
    <ErrorMessage name={name} className="error" component="div" />
    <Field
      component={component || "input"}
      type={type || "text"}
      name={name}
      placeholder={placeholder}
      className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      autoFocus={autoFocus}
    />
  </>
);

const formatPrice = price => (price ? `$${Number.parseFloat(price).toFixed(2)}` : "TBD");
const popupContents = (part, close, update) => {
  const make = part && part.notes && part.notes.make ? part.notes.make : "";
  const model = part && part.notes && part.notes.model ? part.notes.model : "";
  const serial = part && part.notes && part.notes.serial ? part.notes.serial : "";
  return (
    <PopupWrapper className="checkout">
      <Formik
        initialValues={{ make: make, model: model, serial: serial }}
        validationSchema={inputSchema}
        onSubmit={values => {
          console.log(values, part);
          const notes = { ...values };
          setTimeout(() => update(part.partId, notes));
          close();
        }}
      >
        {props => {
          const { touched, errors } = props;
          return (
            <>
              <PopupHeading>
                <Description>
                  {part.quantity === 1 ? "" : `(${part.quantity}) `}
                  {part.title ? part.title : "No part description"}
                </Description>
                <br />
                <PartLabel>Part #:</PartLabel>
                <PartNumber>{part.partId}</PartNumber>
              </PopupHeading>
              <Form>
                <FormField
                  name="make"
                  caption="Make"
                  placeholder="Enter manufacturer name"
                  component={Makes}
                  errors={errors}
                  touched={touched}
                />
                <FormField name="model" caption="Model" placeholder="Enter Model" errors={errors} touched={touched} />
                <FormField
                  name="serial"
                  caption="Serial Number"
                  placeholder="Enter serial number, if known"
                  errors={errors}
                  touched={touched}
                />
                <button className="primaryButton2" type="submit">
                  Save
                </button>
                <button
                  className="secondaryButton2"
                  onClick={e => {
                    props.resetForm({ make: "", model: "", serial: "" });
                  }}
                >
                  Remove
                </button>
                <button className="secondaryButton2">Cancel</button>
              </Form>
            </>
          );
        }}
      </Formik>
    </PopupWrapper>
  );
};

const editor = (label, props, update) => (
  <Popup
    trigger={
      label === "Edit" ? (
        <Edit>
          {props.notes.make} {props.notes.model} {props.notes.serial}
        </Edit>
      ) : (
        <Add>{label}</Add>
      )
    }
    modal
    closeOnDocumentClick={false}
    closeOnEscape
    contentStyle={{ width: "440px", backgroundColor: "#f7e8bf" }}
  >
    {close => popupContents(props, close, update)}
  </Popup>
);

export default function CheckoutPart(props) {
  const part = props.part;
  const hasNotes = part.notes && (part.notes.make || part.notes.model || part.notes.serial);
  const gasket = part.custom === "Gasket" ? part : null;
  console.log("Notes", part.partId, hasNotes, part.notes)
  return (
    <Wrapper>
      <Row1>
        <Description>
          {part.quantity === 1 ? "" : `(${part.quantity}) `}
          {part.title ? part.title : "No part description"}
        </Description>
        <Price>{formatPrice(part.price * part.quantity)}</Price>
        <Row2>
          <PartLabel>Part #:</PartLabel>
          <PartNumber>
            <b>{part.partId}</b>
          </PartNumber>
          <Inventory part={part} inventory={props.inventory} quantity={part.quantity} />
          {gasket && <GasketSpecs gasket={gasket} />}

          {!hasNotes && editor("Add Make / Model / Serial #", part, props.update)}
          {hasNotes && (
            <>
              <PartLabel> Make&nbsp;/&nbsp;Model&nbsp;/&nbsp;Serial&nbsp;#: </PartLabel>
              {editor("Edit", part, props.update)}
            </>
          )}
        </Row2>
      </Row1>
    </Wrapper>
  );
}
