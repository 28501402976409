import AppSettings from "../AppSettings";
import * as loupe from "../Loupe.JavaScript.Agent";

var fetch = require("fetch-retry");

async function postRequest(request, token) {
  const requestJson = JSON.stringify(request, null, 2);
  const endpoint = AppSettings.RequestPost;
  const logMessage = `Post ${request.RequestType} Request from ${request.Email}`;

  const requestId = await fetch(endpoint, {
    // https://www.npmjs.com/package/fetch-retry
    // TODO: Add Loupe logging on retries and failed requests
    // Meanwhile, let's retry on anything that's not "ok".
    retryOn: function (attempt, error, response) {
      if (response.ok || response.status === 422) {
        return false;
      }
      if (attempt < 3) {
        loupe.Warning(
          `Retrying request after receiving status ${response.status}`,
          `Retrying ${logMessage} with status ${response.status}, attempt ${attempt + 1}\n${response.statusText}`
        );
        return true;
      }
    },
    retries: 3,
    retryDelay: 1000,
    method: "post",
    headers: {
      "Content-Type": "application/json", Authorization: `Bearer ${token}`
    },
    body: requestJson
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(json => {
          const context = {
            status: response.status,
            type: response.type,
            url: response.url,
            statusText: json.Message
          };
          throw context;
        });
      }
      loupe.Information(`Success with status ${response.status}: ${logMessage}`, requestJson);
      return response.text();
    })
    .catch(err => {
      loupe.Error(`FAILED with status ${err.status}: ${logMessage}`, err);
    });
  return requestId;
}

function formatRequest(requestType, customerId, company, name, email, phone, note) {
  return {
    RequestType: requestType,
    CustomerId: customerId ? customerId : "",
    Company: company ? company : "",
    Name: name ? name : "",
    Email: email ? email : "",
    Phone: phone ? phone : "",
    Note: note ? note : ""
  };
}

async function PostAccountRequest(customerId, company, name, email, phone, note, token) {
  return await postRequest(formatRequest("Account", customerId, company, name, email, phone, note), token);
}

async function PostContactRequest(customerId, company, name, email, phone, note, token) {
  return await postRequest(formatRequest("Contact", customerId, company, name, email, phone, note), token);
}

async function PostResearchRequest(customerId, company, name, email, phone, note, token) {
  return await postRequest(formatRequest("Research", customerId, company, name, email, phone, note), token);
}

async function PostCheckoutRequest(customerId, company, name, email, phone, note, token) {
  return await postRequest(formatRequest("Checkout", customerId, company, name, email, phone, note), token);
}

async function PostChatRequest(customerId, company, name, email, phone, note ,token) {
  return await postRequest(formatRequest("Chat", customerId, company, name, email, phone, note), token);
}

export { PostAccountRequest, PostContactRequest, PostResearchRequest, PostCheckoutRequest, PostChatRequest };
