import React from "react";
import "../requestManager.css";
import { FindEmployee } from "../data/GetStaffInfo";

export default function MiniOwner({ entry }) {
  // This method is designed to always return a non-null value
  const owner = FindEmployee(entry.Owner);
  const Info = () => (
    <div className="miniOwner">
      <img alt={owner.fullname} height="32" width="32" src={owner.avatar} />
      <div className="ownerName">
        {owner.shortname}
        <br />
        <span className="ownerBranch">{owner.branch}</span>
      </div>
    </div>
  );

  return <Info />;
}
