import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTop from "../../ui/components/ScrollToTop";

const StyledLink = styled(Link)`
  margin-left: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  color: var(--CP-blue);
  text-decoration: none;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    color: var(--black);
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  font-weight: bold;
  margin-right: 4px;
`;
const Button = styled.button`
  color: white;
  height: 24px;
  margin-left: 8px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;

export default function Page({ title, icon, description, history, children }) {
  const context = useContext(AppContext);
  const { cartParts, goto } = context;

  return (
    <ScrollToTop>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="pageHead">
        <img
          src={icon ? `/images/custom${icon}Page.svg` : "/images/customPartsIcon.png"}
          alt={title}
          width="45"
          height="45"
        />
        <h1>
          {title}
          <StyledLink to="" onClick={() => (history ? history.goBack() : window.history.back())}>
            <Icon icon={["fas", "arrow-left"]} />
            Go Back
          </StyledLink>
          {cartParts.length > 0 && <Button onClick={() => goto("/Cart")}>Proceed to Checkout</Button>}
        </h1>
      </div>
      <div className="pageBody">{children}</div>
    </ScrollToTop>
  );
}
