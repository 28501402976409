import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert } from "react-alert";

const requestColors = {
  Checkout: "#3d9665",
  Research: "#1380b1",
  Chat: "#7b77ee",
  OrderLink: "#63bf8d",
  Contact: "#de5449",
  Email: "#de5449",
  Account: "#f5a056"
};

const Icon = styled(FontAwesomeIcon)`
  font-size: 36px;
  margin-right: 4px;
  float: left;
  color: white;
  background: ${props => props.background}
  padding: 4px;
`;
const Container = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-right: 8px;
  margin-top: 0px;
  float: left;
  cursor: copy;
`;
const Label = styled.span`
  font-size: 11px;
  color: var(--greyish-brown);
  display: block;
  font-variant: small-caps;
`;
const Id = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: var(--black);
  letter-spacing: 1px;
`;
const Order = styled.span`
  font-size: 12px;
  font-weight: normal;
  padding: 4px;
  ${props => (props.committed ? "background: #3d9665;color: #ffffff;" : "border: 1px solid #3d9665;color: #3d9665")}
  display: block;
`;
const OP = styled.span`
  font-size: 13px;
  font-weight: 500;
  display: block;
`;
const Total = styled.span`
  font-size: 12px;
  text-align: right;
  display: block;
`;
const Status = styled.span`
  font-size: 11px;
  font-style: italic;
  display: block;
`;

const getIcon = requestType => {
  switch (requestType) {
    case "Contact":
      return "envelope";
    case "Research":
      return "books";
    case "Account":
      return "building";
    case "Chat":
      return "comments";
    case "OrderLink":
      return "cart-circle-arrow-down";
    case "Checkout":
      return "shopping-cart";
    default:
      return "question";
  }
};

export default function RequestType({ request, status, copyToClipboard }) {
  const AlertText = styled.div`
    text-transform: none;
    font-size: 14px;
  `;
  function FormatAlert(requestId) {
    return <AlertText>{`Copied RequestID ${requestId} to clipboard`}</AlertText>;
  }
  const alert = useAlert();
  const requestType =
    request.RequestType === "Chat" && !request.ChannelId
      ? "Offline Chat"
      : request.RequestType === "Contact"
      ? "Email"
      : request.RequestType;
  const icon = getIcon(request.RequestType);
  const label = status ? `${status} ${requestType}` : `${requestType}`;
  const handleCopy = (e, requestId) => {
    e.stopPropagation();
    e.preventDefault();
    copyToClipboard(requestId);
    alert.success(FormatAlert(requestId));
  };

  return (
    <>
      <Icon icon={["far", icon]} background={requestColors[request.RequestType]} />
      <Container onClick={e => handleCopy(e, request.RequestId)}>
        <Label>{label}</Label>
        <Id>{request.RequestId}</Id>
        {request.Order && (
          <Order committed={request.Order.Status === "Committed"}>
            {request.Order.Status !== "Committed" && <Status>{request.Order.Status}</Status>}
            <OP>op {request.Order.OrderId}</OP>
            <Total>$ {Number.parseFloat(request.Order.Total).toFixed(2)}</Total>
          </Order>
        )}
      </Container>
    </>
  );
}
