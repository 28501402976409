import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import "../styling/formik.css";
import SubmitEmail from "../SubmitEmail";
import { useAlert } from "react-alert";
import AppSettings from "../../AppSettings";

const Link = styled.a`
  text-decoration: none;
  color: white;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
const EmailLink = styled(Link)`
  color: var(--CP-blue);
`;
const AlertText = styled.div`
  text-transform: none;
  font-size: 14px;
`;

export default function ContactForm({ topic, messageLabel, messageHints }) {
  const context = useContext(AppContext);
  const { goto, account } = context;
  const alert = useAlert();
  const initialValues = {
    email: context.contactInfo.email ? context.contactInfo.email : "",
    name: context.contactInfo.name ? context.contactInfo.name : "",
    company: context.contactInfo.company ? context.contactInfo.company : "",
    phone: context.contactInfo.phone ? context.contactInfo.phone : "",
    notes: ""
  };

  const onSubmit = error => {
    if (error) {
      console.log(`Contact request failed for ${AppSettings.EmailRequest}: ${error}`);
      alert.error(
        <AlertText>
          Your request failed. Try again and contact us via{" "}
          <EmailLink href="mailto:sales@caseparts.com" target="_top">
            email
          </EmailLink>{" "}
          if error persists.
        </AlertText>
      );
    } else {
      goto("/ThankYou?message=contact");
    }
  };

  // Accept US, Canada and Mexican phone numbers
  const phoneRegExp = /^(\+?(011[. -]?)?\+?52[. -]?([0-9]{3}[. -]?[0-9]{3}[. -]?[0-9]{4}|[0-9]{2}[. -]?[0-9]{4}[. -]?[0-9]{4})|(\+?(1[. -]?)?(\(?[0-9]{3}\)?[. -]?[0-9]{3}[. -]?[0-9]{4})))(\s?(x|ext|ext.)\s?[0-9]{1,5})?$/;
  // Require first and last name
  const nameRegExp = /\w\w+\s+\w\w+/;

  let validations = {
    email: Yup.string().required("Email address is required").email("Invalid email"),
    name: Yup.string().required("Full name is required").matches(nameRegExp, "Please provide your first and last name"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid")
  };
  const inputSchema = Yup.object().shape(validations);

  const FormField = ({ name, required, caption, type, component, placeholder, errors, touched }) => (
    <>
      <label htmlFor={name}>
        {caption}
        {required && <strong>*</strong>}
      </label>
      <ErrorMessage name={name} className="error" component="div" />
      <Field
        component={component || "input"}
        type={type || "text"}
        name={name}
        placeholder={placeholder}
        className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      />
    </>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={inputSchema}
      onSubmit={(values, { setSubmitting }) => {
        context.updateContactInfo(values);
        SubmitEmail(values, onSubmit, account, context.token, topic);
        window._chatlio.identify(values.email, {
          name: values.name,
          account: account ? account : "none",
          email: values.email,
          company: values.company ? values.company : "none"
        });
      }}
    >
      {props => {
        const {
          //values,
          touched,
          errors,
          //dirty,
          isSubmitting
          //handleChange,
          //handleBlur,
          //handleSubmit,
          //handleReset
        } = props;
        return (
          <div className="checkout">
            <p className="formnote">
              <strong>*</strong> Indicates a required field
            </p>
            <Form>
              <div>
                <FormField
                  name="name"
                  required
                  caption="Name"
                  placeholder="Enter your full name"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="email"
                  required
                  caption="Email"
                  type="email"
                  placeholder="Enter email address"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="phone"
                  required
                  caption="Phone"
                  placeholder="Enter phone number"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="notes"
                  caption={messageLabel ? messageLabel : "Message"}
                  component="textarea"
                  rows="5"
                  placeholder={messageHints ? messageHints : "Enter your message here"}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
