import React from "react"
import InfoPage from "./InfoPage"

export default function About() {
    return <InfoPage>
        <h1>Celebrating 50 Years of Service</h1>
        <img className="fearless" src="/images/info/FearlessLeader.jpg" alt="" />
        <p>In 1972, my 63 year old grandfather opened Case Parts Company with inventory he acquired from his
            former employer, Weber Showcase and Fixture Company. My grandmother named the company after
            overhearing my grandfather refer to “meat cases”, “cheese cases”, and “dairy cases” when talking to
            our very first customers on the phone.</p>
        <p>I was commuting to college in my second year at UCLA and started helping my grandfather out on the
            three days each week I didn’t schedule any classes. I had grand visions of graduating from college and
            then attending either business or law school. But as fate would have it, In 1982, I ended up forming a
            partnership with my father and then purchasing Case Parts Company from my grandfather so that he could start to take it easier.</p>
        <p>It’s been a terrific and gratifying experience for me at Case Parts Company and I am very thankful to
            have enjoyed my career here. In 1986 we opened our St. Louis branch. Our first employee, Branch
            Manager Bob Goldstein, has not only become a life long friend, but continues to run the St. Louis shop to
            this day. For the last 30 years, he and his lovely wife have joined my wife and me for an annual long
            weekend trip in Las Vegas. Then, in 2000, we opened our third branch in Seattle.</p>
        <p>Without a doubt, the thing that I appreciate most about working here is the opportunity to join a team
            of incredibly talented and dedicated colleagues. Some fun facts: The average employee has been here
            for just over 18 years and 40% of our staff has at least 20 years of service. Also interesting is the fact that
            employees frequently recruit other members of their family to join our team. Over 20% of our staff have
            a family member also on board.</p>
        <p>As I reflect on our 50 years in business, I am profoundly grateful – not only for the staff – but also for our
            thousands of dedicated business partners like you! This is a (mostly) joyous adventure and I very much
            appreciate your continued interest in Case Parts Company. Thank you for your help in getting us to 50!
        </p>
        <p>
            Jon Jilg<br/>Fearless Leader
        </p>
    </InfoPage>
}