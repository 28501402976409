import React from "react";
import Page from "../widgets/Page";
import ContactForm from "../../ui/checkout/ContactForm";
import AppSettings from "../../AppSettings";

export default function DoorWizard({ title }) {
  return (
    <Page
      title="Replacement Walk-In Doors"
      icon="Door"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <div className="flexContainer">
        <div className="customPartColumn">
          <p>We offer replacement door and frame assemblies for virtually all walk-in doors.</p>
          <ul>
            <li>Custom made for your specific application</li>
            <li>Extra heavy duty construction</li>
            <li>4" foam-in-place polyurethane insulation</li>
            <li>3 pre-hung chrome cam-lift hinges</li>
            <li>Padlocking SAFETY latch with inside release</li>
            <li>UL listed and labeled</li>
            <li>Closed edge sponge rubber door gasket</li>
            <li>Freezer doors include heater cable</li>
          </ul>

          <p>Door assemblies are available in several finishes:</p>
          <ul>
            <li>26 gauge stucco embossed galvalume</li>
            <li>26 gauge stucco</li>
            <li>embossed white</li>
            <li>22 gauge smooth stainless steel</li>
            <li>Common frames are 6" and made of aluminum extrusion</li>
            <li>Other size frames are available but are wood-clad</li>
          </ul>
          <p>
            Please download and fill-in our{" "}
            <a href={`${AppSettings.PdfPath}/Walk-In Door Order Form.pdf`} target="_blank" rel="noopener noreferrer">
              Walk-In Door Order Form
            </a>{" "}
            and email it to us. Or, if it's more convenient, give us a call or fill in the form on this page with the
            relevant information.
          </p>
        </div>
        <div className="customPartColumn">
          {" "}
          <img
            alt=""
            src="https://my.caseparts.com/catalog/graphics/Drawings\CustomWalk-InDoor_Drw3.png?height=404&amp;width=258"
          />
        </div>
        <div className="customPartColumn">
          <h2>Let us help you replace your walk-in door</h2>
          <ContactForm
            topic="Walk-In Doors"
            messageLabel="Door Specifications"
            messageHints="Please enter specifications from the Walk&#8209;In&nbsp;Door&nbsp;Order&nbsp;Form"
          />
        </div>
      </div>
    </Page>
  );
}
