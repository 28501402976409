import React from "react";
import { Field } from "formik";

const WireDimension = ({ fieldName, label, min, max, defaultValue, onBlur }) => (
  <div>
    <Field name={fieldName} id={fieldName}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched, errors, touched } }) => (
        <div className="dimensionEditor">
          {touched[fieldName] && errors[fieldName] && <div className="errorMessage">{errors[fieldName]}</div>}
          <span className="label">{label}:</span>
          <input
            className={touched[fieldName] && errors[fieldName] ? "error" : ""}
            type="number"
            label={label}
            onChange={e => {
              setFieldValue(fieldName, e.target.value);
              onBlur(e);
            }}
            onFocus={e => setFieldTouched("sides", true)}
            min={min}
            max={max}
            defaultValue={defaultValue}
            onBlur={onBlur}
          />
        </div>
      )}
    </Field>
  </div>
);

export default WireDimension;
