import React from "react";
import CheckoutNote from "./CheckoutNote";

export default function MakeModelSerial() {
  return (
    <CheckoutNote icon="check" title="Including Make / Model / Serial #">
      Knowing what assembly you are repairing helps us ensure that you get the right parts.
    </CheckoutNote>
  );
}
