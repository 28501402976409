import React from "react";
import styled from "styled-components";
import "../styling/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  margin-top: 8px;
  margin-right: 8px;
  font-family: "Roboto", sans-serif;
  float: right;
  display: block;
  position: relative;
  z-index: 1;
`;

const Item = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  vertical-align: text-top;
  color: white;
  cursor: pointer;
  margin-left: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-left: 4px;
  vertical-align: text-bottom;
`;

export default function Expando(props) {
  return (
    <Wrapper>
      {props.canExpand && (
        <Item onClick={props.onExpandAll}>
          Show All
          <Icon icon={["far", "chevron-double-down"]} />
        </Item>
      )}
      {props.canCollapse && (
        <Item onClick={props.onCollapseAll}>
          Hide All
          <Icon icon={["far", "chevron-double-up"]} />
        </Item>
      )}
    </Wrapper>
  );
}
