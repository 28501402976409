import React from "react";
import { Field, ErrorMessage } from "formik";

const NoMagnet = ({ fieldName1, fieldName2, label1, label2, showOptions }) => (
  <div className="noMagnet">
    <Field name={fieldName1} id={fieldName1}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <label>
          <input
            type="checkbox"
            checked={value}
            onChange={() => {
              setFieldValue(fieldName1, !value);
              if (value) {
                setFieldValue(fieldName2, "");
              }
            }}
          />
          {label1}
        </label>
      )}
    </Field>
    <ErrorMessage name={fieldName2} className="errorMessage" component="div" />
    {showOptions && (
      <Field name={fieldName2} id={fieldName2}>
        {({ field: { value }, form: { getFieldValue, setFieldValue } }) => (
          <>
            <div>
              <b>{label2}</b>
            </div>
            <label>
              <input
                type="radio"
                name="lhs"
                checked={value === "Left"}
                onChange={() => setFieldValue(fieldName2, "Left")}
              />
              Left Side
            </label>
            <label>
              <input
                type="radio"
                name="rhs"
                checked={value === "Right"}
                onChange={() => setFieldValue(fieldName2, "Right")}
              />
              Right Side
            </label>
          </>
        )}
      </Field>
    )}
  </div>
);

export default NoMagnet;
