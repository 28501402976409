export default function findMatchingKeywords(arr, prefix) {
  const min = findFirstMatch(arr, prefix);
  if (min < 0) {
    return [];
  }

  const max = findLastMatch(arr, prefix, min);
  let keywords = [];

  // There's no point in offering more than 100 options.
  // The user will never scroll through them, so creating
  // a list that long only makes input response sluggish
  const MaxKeywordOptions = 100;
  let count = 0;
  for (let i = min; i <= max && count < MaxKeywordOptions; i++) {
    keywords.push(arr[i]);
    count++;
  }

  return keywords;
}

// Given a sorted array of unique strings and a prefix,
// return the index of the first matching element
function findFirstMatch(arr, prefix) {
  // Use binary search for efficiency
  let left = 0;
  let right = arr.length - 1;
  while (left <= right) {
    const mid = left + Math.floor((right - left) / 2);
    // when looking for the first occurrence, in a sorted array of unique string,
    // if we find an exact match, we are done.
    if (arr[mid] === prefix) {
      return mid;
    }

    // otherwise, divide and conquer
    if (arr[mid] < prefix) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // handle the edge case that there are no matching terms
  let min = left;
  if (!arr[min].startsWith(prefix)) {
    return -1;
  }

  return min;
}

// Given a sorted array of unique strings and a prefix,
// return the index of the first matching element
// The optional third argument is a starting index.
// This is typically the result of a call to findFirstMatch
function findLastMatch(arr, prefix, start) {
  let left = start || 0;
  let right = arr.length - 1;
  while (left <= right) {
    const mid = left + Math.floor((right - left) / 2);
    if (arr[mid].startsWith(prefix)) {
      let max = mid;
      while (max < arr.length - 1 && arr[max + 1].startsWith(prefix)) {
        max++;
      }
      return max;
    }
    if (arr[mid] === prefix) {
      return mid;
    }
    if (arr[mid] < prefix) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  let max = left;
  if (!arr[max].startsWith(prefix)) {
    return -1;
  }
  return max;
}
