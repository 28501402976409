import React from "react";
import gaskets from "./GasketInfo.json";
import { profileUrl } from "../helpers";
import Page from "../widgets/Page";

const Gasket = ({ gasket, onClick }) => (
  <div className="gasketProfile" onClick={onClick}>
    <p>
      <b>{gasket.partId}</b> - {gasket.color} {gasket.material}
    </p>
    <img src={profileUrl(gasket)} alt={`${gasket.partId} profile`} />
    <br />
  </div>
);

export default function ProfileSelector({ history }) {
  return (
    <Page
      title="Custom-Molded Gaskets"
      icon="Gasket"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <div>
        <div className="text">
          <p>There are two types of gaskets that we pre-mold:</p>
          <p>
            <b>Compression gaskets</b> are most commonly used on walk-ins and in drawer applications. If used on a
            walk-in, a positive closing latch should be used. When replacing compression gaskets, the general rule is
            that the gasket cushion should be compressed to a minimum of 1/8" and a maximum of 3/16". When measuring
            compression gaskets for replacement, the corner-to-corner and height measurements should be given.
          </p>
          <p>
            <b>Magnetic gaskets</b> are used in a wide variety of applications and can be used without a positive
            closing latch. When ordering a magnetic gasket, either the dart-to-dart or corner-to-corner dimension may be
            used. If you do not specify otherwise, we will assume you are giving us the corner-to-corner dimension. For
            walk-in units, please specify whether the gasket is three or four sided.
          </p>
        </div>
        <h1>Compression Gaskets</h1>
        <div className="gasketProfileList">
          {gaskets
            .filter(g => g.type === "Compression")
            .map(gasket => (
              <Gasket
                key={gasket.partId}
                gasket={gasket}
                onClick={() => history.push(`/custom/gasket/${gasket.partId}`)}
              />
            ))}
        </div>
        <h1>Snap-In/Push-In Magnetic Gaskets</h1>
        <div className="gasketProfileList">
          {gaskets
            .filter(g => g.type === "Magnetic" && g.hasDart)
            .map(gasket => (
              <Gasket
                key={gasket.partId}
                gasket={gasket}
                onClick={() => history.push(`/custom/gasket/${gasket.partId}`)}
              />
            ))}
        </div>
        <h1>Screw-In/Other Magnetic Gaskets</h1>
        <div className="gasketProfileList">
          {gaskets
            .filter(g => g.type === "Magnetic" && !g.hasDart)
            .map(gasket => (
              <Gasket
                key={gasket.partId}
                gasket={gasket}
                onClick={() => history.push(`/custom/gasket/${gasket.partId}`)}
              />
            ))}
        </div>
      </div>
    </Page>
  );
}
