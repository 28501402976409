import React from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import "../styling/styles.css";
import isNumber from "../../data/isNumber";

const Quantity = styled.fieldset`
  display: inline;
  float: right;
  margin: -6px 16px 0px 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  border: solid 1px #ccc;
  color: var(--battleship-grey);
  cursor: auto;
`;
const Input = styled.input`
  type: number;
  text-align: center;
  margin-right: 4px;
  margin-left: 4px;
  height: 22px;
  width: 36px;
  font-size: 12px;
  font-weight: normal;
  border: solid 0px var(--black);
`;
const Button = styled.button`
  float: right;
  color: white;
  width: 96px;
  height: 32px;
  border-radius: 4px;
  border: solid 2px var(--reddish-orange);
  background-color: var(--reddish-orange);
  font-size: .875rem;
  font-weight: bold;
  cursor: pointer;
`;
const DisabledButton = styled.button`
  float: right;
  color: white;
  width: 96px;
  height: 34px;
  border-radius: 2px;
  border: solid 2px #ccc;
  background-color: #ccc;
  font-size: 11px;
  font-weight: bold;
`;
const AlertText = styled.div`
  text-transform: none;
  font-size: 14px;
`;
const DimText = styled.span`
  font-size: 12px;
  color: #ccc;
`;

function FormatAlert(quantity, partId, title) {
  return (
    <AlertText>
      <DimText>Added{quantity > 1 ? ` (quantity = ${quantity})` : ""}: </DimText>
      <br />
      {`${partId}: ${title}`}
    </AlertText>
  );
}

export default function AddToCart({ partId, title, onAddToCart, quantity, setQuantity,
  cartQuantity, discontinued, inventory  }) {
    
  const alert = useAlert();
  const unreserved = whse => inventory
    ? inventory.Inventory
      ? inventory.Inventory[whse].Unreserved
      : inventory[whse]
    : 0;
  const total = unreserved("MPK") + unreserved("STL") + unreserved("SEA");

  return (
    <>
      {(discontinued && quantity + cartQuantity > total)
        ? <DisabledButton disabled={true}>Add</DisabledButton>
        : <Button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            alert.success(FormatAlert(quantity, partId, title));
            onAddToCart(partId, quantity);
          }}>
          Add
        </Button>
      }
      <Quantity
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <legend>Qty</legend>
        <Input
          type="number"
          min="1"
          max="999"
          defaultValue={quantity}
          onChange={e => {
            e.preventDefault();
            e.stopPropagation();
            const value = e.target.value;
            setQuantity(isNumber(value) ? Number(value) : 1);
          }}
        />
      </Quantity>
    </>
  );
}
