import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styling/styles.css";

const Wrapper = styled.div`
  background-color: var(--medium-blue);
  margin-left: 4px;
  border-bottom: 1px solid #ccc;
`;
const Title = styled.div`
  margin-top: 6px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: -0.3px;
  color: var(--black);
  padding-left: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange)};
  }
`;
const SearchLink = styled.div`
  margin: 0px;
  padding-top: 2px;
  padding-bottom: 4px;
  font-size: 11px;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
  }
  z-index: 1;
  text-align: center;
`;
const Chevron = styled(FontAwesomeIcon)`
  float: right;
  margin-top: 6px;
  margin-right: 10px;
  color: var(--greyish-brown);
  z-index: 300;
`;

export default function Section(props) {
  const context = useContext(AppContext);
  const { query } = context;
  return (
    <Wrapper>
      <Title onClick={props.onToggle}>
        {props.title}
        <Chevron icon={props.showDetails ? ["far", "chevron-up"] : ["far", "chevron-down"]} />
      </Title>
      {props.showDetails && props.query !== query && (
        <SearchLink onClick={props.onSearch}>Search for "{props.query}"</SearchLink>
      )}
      {props.showDetails && props.children}
    </Wrapper>
  );
}
