import React from "react";
import styled from "styled-components";

const Wrapper = styled.fieldset`
  display: inline;
  margin: -6px 8px 0px 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  border: solid 1px #ccc;
  color: var(--battleship-grey);
  cursor: auto;
`;
const Input = styled.input`
  type: number;
  text-align: center;
  margin-right: 4px;
  margin-left: 4px;
  height: 24px;
  width: 36px;
  font-size: 12px;
  font-weight: normal;
  border: solid 0px var(--black);
`;

export default function Quantity(props) {
  return (
    <>
      <Wrapper>
        <legend>Qty</legend>
        <Input
          type="number"
          min={props.allowZero ? "0" : "1"}
          max="999"
          defaultValue={props.quantity}
          onChange={e => {
            props.onUpdateQuantity(e.target.value);
          }}
        />
      </Wrapper>
    </>
  );
}
