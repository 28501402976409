import React from "react";
import CheckoutNote from "./CheckoutNote";
import { hasCustomParts } from "../../customParts/helpers";

export default function NonRefundable({parts}) {

  if (hasCustomParts(parts)) {
    return (
      <CheckoutNote title="Custom Parts are Non-refundable"
          icon="triangle-exclamation" 
          backgroundColor="#fff2f2" color={"#f80000"}>
        Any custom-made parts ordered are non-refundable.
        We will contact you confirming manufacturing details
        if necessary to fulfill your order.
      </CheckoutNote>
    );
  }

  return "";
}
