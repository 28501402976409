import React from "react";
import Select from "react-select/lib/Creatable";
import makesList from "../../data/Makes.json";

const makes = makesList.map(item => ({ label: item, value: item }));

// doc: https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontFamily: "Roboto, sans-serif",
    width: "380px",
    height: "18px",
    color: "black",
    fontSize: "14px"
  }),
  input: (provided, state) => ({
    ...provided,
    height: "18px"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "22px",
    width: "380px"
  }),
  container: (provided, state) => ({
    ...provided,
    height: "18px",
    marginBottom: "32px"
  })
};

const Makes = ({ field, form }) => (
  <Select
    className="text-input"
    placeholder="Manufacturer"
    styles={customStyles}
    defaultValue={field.value ? { label: field.value, value: field.value } : null}
    isClearable
    options={makes}
    onChange={option => form.setFieldValue(field.name, option ? option.value : "")}
    onBlur={() => form.setFieldTouched(field.name)}
  />
);

export default Makes;
