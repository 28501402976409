import React from "react";

function DimensionText({ value, onChange, isError, onBlur }) {
  return (
    <input
      className={isError ? "dimension error" : "dimension"}
      defaultValue={value}
      onBlur={e => {
        onChange(e.target.value);
        onBlur(e);
      }}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          onChange(e.target.value);
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    />
  );
}

export default function DimensionEditor({
  label,
  value,
  update,
  isError,
  setError,
  minValue,
  maxValue,
  defaultValue,
  onBlur
}) {
  return (
    <div className="dimensionEditor">
      <span className="label">{label}:</span>
      <DimensionText
        isError={isError}
        key={`${label}=${value}`}
        value={value}
        onChange={newValue => update(newValue)}
        setError={setError}
        onBlur={onBlur}
      />
    </div>
  );
}
