import React, { useState, useContext } from "react";
import AppContext from "../../context/AppContext";
import "../requestManager.css";
import moment from "moment";
import queryString from "query-string";
import CanvasJSReact from "../../assets/canvasjs.react";
import useOrderMetrics from "../hooks/useOrderMetrics";
import useWindowSize from "../hooks/useWindowSize";
import { Link } from "react-router-dom";
import useLocalStorage from "../../context/useLocalStorage";

const barColors = {
  Order: "#3d9665",
  Quote: "#a2c3a8"
};

//var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function OrdersChart(props) {
  const context = useContext(AppContext);
  const { claims } = context;

  // Verify that this is an employee
  if (claims.UserType !== "Employee") {
    console.log(claims.UserType, "Not authorized");
    window.location = "/";
  }

  const size = useWindowSize();
  const params = queryString.parse(props.location.search);

  // Retain default value for unit in local storage
  const [storedUnit, setUnit] = useLocalStorage("metricUnit", "day");
  const unit = params.unit ? params.unit.toLowerCase() : storedUnit;
  if (params.unit && storedUnit !== unit) setUnit(unit); // update localStorage if unit passed explicity

  const defaultCount = 12;
  const to = params.to ? moment(params.to, "MM-DD-YYYY") : moment();
  const from = params.from ? moment(params.from, "MM-DD-YYYY") : to.clone().add(-defaultCount, unit);
  const xToolTipValueFormatString = unit === "month" ? "MMMM YYYY" : "MMM D YY";
  const [initialized, setInitialized] = useState(false);
  const result = useOrderMetrics(unit, from.format("MM-D-YYYY"), to.format("MM-D-YYYY"), () => {
    if (!initialized) setInitialized(true);
  });
  const data = result.response ? result.response : [];

  const getSample = (metric, dimension, entry) => {
    const x = new Date(entry.StartDate);
    const value = entry[`${metric}${dimension}`];
    const y = value ? Math.round(Number(String(value).replace(",", ""))) : 0;
    return { x, y };
  };

  const getSamples = (metric, dimension) => {
    return data.map(entry => getSample(metric, dimension, entry));
  };
  const capitalize = word => word[0].toUpperCase() + word.slice(1);
  const dataClickHandler = e => {
    const baseDate = moment(e.dataPoint.x);
    const fromDate = baseDate.clone().startOf(unit);
    const toDate = baseDate.clone().endOf(unit);
    const type = e.dataSeries.name.startsWith("Order") ? "order" : "quote";
    const from = fromDate.format("MM-D-YYYY");
    const to = toDate.format("MM-D-YYYY");
    const url = `/requestsreport?order=${type}&from=${from}&to=${to}`;
    props.history.push(url);
  };

  const optionsTotal = {
    interactivityEnabled: true,
    animationEnabled: true,
    animationDuration: 2000,
    height: size.height - 100,
    title: {
      text: `Order Total by ${capitalize(unit)}`,
      fontFamily: "Roboto",
      fontSize: 32
    },
    axisX: {
      title: "Date",
      interval: 1,
      intervalType: unit,
      valueFormatString: unit === "month" ? "MMMM YYYY" : unit === "day" ? "DDD MM/D" : "MMM D",
      labelFontSize: 18,
      titleFontSize: 24
    },
    axisY: {
      title: "Total",
      indexLabel: "x:{x}, y:{y}",
      fontFamily: "Roboto",
      labelFontSize: 18,
      titleFontSize: 24
    },
    legend: {
      verticalAlign: "top",
      cursor: "pointer",
      fontFamily: "Roboto",
      fontSize: 20,
      itemclick: function (e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }

        e.chart.render();
      }
    },
    toolTip: {
      contentFormatter: function (e) {
        return `<b>$${e.entries[0].dataPoint.y.toLocaleString()}</b> ${e.entries[0].dataSeries.name}`;
      }
    },
    data: [
      {
        showInLegend: true,
        name: "Order Total",
        color: barColors.Order,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Order", "Total")
      },
      {
        showInLegend: true,
        name: "Quote Total",
        color: barColors.Quote,
        type: "stackedBar",
        fontSize: 12,
        visible: false,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Quote", "Total")
      },
      {
        showInLegend: false,
        name: "Nothing",
        color: "#FFFFFF",
        type: "stackedBar",
        indexLabel: "$#total",
        indexLabelPlacement: "outside",
        indexLabelFontSize: 18,
        indexLabelFontStyle: "bold",
        dataPoints: getSamples("Nothing", "")
      }
    ]
  };

  const optionsCount = {
    interactivityEnabled: true,
    animationEnabled: true,
    animationDuration: 2000,
    height: size.height - 100,
    title: {
      text: `Order Count by ${capitalize(unit)}`,
      fontFamily: "Roboto",
      fontSize: 32
    },
    axisX: {
      title: "",
      interval: 1,
      intervalType: unit,
      valueFormatString: unit === "month" ? "MMMM" : unit === "day" ? "MM/D" : "MMM D",
      labelFontSize: 18,
      titleFontSize: 24
    },
    axisY: {
      title: "Count",
      indexLabel: "x:{x}, y:{y}",
      fontFamily: "Roboto",
      labelFontSize: 18,
      titleFontSize: 24
    },
    legend: {
      verticalAlign: "top",
      cursor: "pointer",
      fontFamily: "Roboto",
      fontSize: 20,
      itemclick: function (e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }

        e.chart.render();
      }
    },
    toolTip: {
      contentFormatter: function (e) {
        return `<b>${e.entries[0].dataPoint.y}</b> ${e.entries[0].dataSeries.name}`;
      }
    },
    data: [
      {
        showInLegend: true,
        name: "Order Count",
        color: barColors.Order,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Order", "Count")
      },
      {
        showInLegend: true,
        name: "Quote Count",
        color: barColors.Quote,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Quote", "Count")
      },
      {
        showInLegend: false,
        name: "Nothing",
        color: "#FFFFFF",
        type: "stackedBar",
        indexLabel: "#total",
        indexLabelPlacement: "outside",
        indexLabelFontSize: 18,
        indexLabelFontStyle: "bold",
        dataPoints: getSamples("Nothing", "")
      }
    ]
  };

  return (
    <div className="metricspage">
      <b><span className="larger">Orders by:</span></b>
      {unit === "month" ? <span className="larger"> <b>Month</b> </span> : <Link to={`/orders/?unit=month`}> Month </Link>} |
      {unit === "week" ? <span className="larger"> <b>Week</b> </span> : <Link to={`/orders/?unit=week`}> Week </Link>} |
      {unit === "day" ? <span className="larger"> <b>Day</b> </span> : <Link to={`/orders/?unit=day`}> Day </Link>}
      <br />
      <span className="smaller"><Link to="/requests">Show Requests instead</Link></span>
      <div>
        <div className="halfWidth">{initialized && <CanvasJSChart key={`${unit}Total`} options={optionsTotal} />}</div>
        <div className="halfWidth">{initialized && <CanvasJSChart key={`${unit}Count`} options={optionsCount} />}</div>
      </div>
    </div>
  );
}
