const insufficientInventory = {
  shipping: {
    header: 'Some Parts Not in Stock',
    message: 'Please note that due to insufficient inventory, your order cannot be shipped today.\n\nWe will contact you to finalize your order.',
  },
  pickup: {
    header: 'Some Parts Not in Stock',
    message: 'Please note that due to insufficient inventory, your order cannot be picked up today.\n\nWe will notify you once it is available for pick up.'
  }
}

const multiwarehouseShipment = {
  header: 'Multi-Warehouse Shipment',
  message: 'We have all of your parts, just not in the same warehouse.  One of our experts will contact you with options to balance shipping time and cost.'
}

function getInventoryDisclaimer(shipMethod, inStockWhse, inStock, loading){
  const disclaimerData = {
    partsNotInStock: false,
    multiwarehouseShipment: false,
  }

  if(loading)
    return disclaimerData
    
  const isPickUp = shipMethod === "MPK" || shipMethod === "SEA" || shipMethod === "STL"
  const potentialSplit = inStockWhse === "ALL"
  const showPartsNotInStock = (potentialSplit && isPickUp) || !inStock

  if(showPartsNotInStock && isPickUp){
    disclaimerData.partsNotInStock = insufficientInventory.pickup
  } else if(showPartsNotInStock && !isPickUp){
    disclaimerData.partsNotInStock = insufficientInventory.shipping
  } else if(potentialSplit && !isPickUp){
    disclaimerData.multiwarehouseShipment = multiwarehouseShipment
  }

  return disclaimerData
}

export default {
  insufficientInventory: insufficientInventory,
  multiwarehouseShipment: multiwarehouseShipment,
  getInventoryDisclaimer: getInventoryDisclaimer
}