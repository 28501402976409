import useSecureFetch from "./useSecureFetch";
import AppSettings from "../../AppSettings";


export default function useOrderMetrics(unit, from, to, callback) {
  

  const url = `${AppSettings.OrderMetrics}?from=${from}&to=${to}&unit=${unit}`;
  const result = useSecureFetch(url, null, url, callback);
  return result;
}
