import React from "react";
import { Field } from "formik";

function validate(fieldName, input) {
  if (input !== 3 && input !== 4) {
    return "Must specify whether gasket is 3-sided or 4-sided";
  }
}

const DoorSides = ({ fieldName, label, defaultValue }) => (
  <>
    <Field name={fieldName} id={fieldName} validate={input => validate(fieldName, input)}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => (
        <div className="doorSides">
          {touched[fieldName] && errors[fieldName] && <div className="errorMessage">{errors[fieldName]}</div>}
          <label>{label}</label>
          <br />
          <label>
            <input
              type="radio"
              name="threeSides"
              value={3}
              checked={value === 3}
              onChange={() => setFieldValue(fieldName, 3)}
            />
            3-Sided
          </label>
          <label>
            <input
              type="radio"
              name="fourSides"
              value={4}
              checked={value === 4}
              onChange={() => setFieldValue(fieldName, 4)}
            />
            4-Sided
          </label>
        </div>
      )}
    </Field>
  </>
);

export default DoorSides;
