import AppSettings from "../AppSettings";
import { sendEmailNotification } from "../ui/checkout/SlackNotification";
import { PostContactRequest } from "../requests/PostRequest";
import * as loupe from "../Loupe.JavaScript.Agent";

async function SendEmail(email) {
  const endpoint = AppSettings.EmailRequest;
  console.log(`Sending email: ${email.Subject} to ${email.To} from ${email.From} using ${endpoint}`);
  await fetch(endpoint, {
    method: "post",
    headers: {
      "Content-Type": "text/plain"
    },
    body: JSON.stringify(email)
  });
  loupe.Information(`Sent email: ${email.Subject}`);
}

function formatCustomer(customer, account) {
  const formatRow = (name, value) =>
    value
      ? `<tr><td><i>${name}</i></td><td><b>${value}</b></td></tr>`
      : `<tr><td><i>${name}</i></td><td><i>not provided</i></td></tr>`;

  return `<pre style="font-family:Arial;font-size:20px;color:#0C0C0C">${customer.notes}</pre><table>
  ${account ? formatRow("CustID", account) : ""}
	${formatRow("Company", customer.company)}
	${formatRow("Name", customer.name)}
	${formatRow("Email", customer.email)}
	${formatRow("Phone", customer.phone)}
  </table>`;
}

async function sendRequestEmail(subject, message) {
  await SendEmail({
    To: AppSettings.Email.QuoteTo,
    From: AppSettings.Email.QuoteFrom,
    Subject: `${AppSettings.IsProd ? "" : "[TEST] "}${subject}`,
    Message: `${message}<p><i>Submitted from: ${AppSettings.AppVersion}</i></p>`,
    IsHtml: true,
    Cc: "",
    Bcc: AppSettings.Email.QuoteBcc
  });
}

async function sendAcknowledmentEmail(to, subject, message) {
  await SendEmail({
    To: `${to}`,
    From: AppSettings.Email.ReceiptFrom,
    Subject: `${AppSettings.IsProd ? "" : "[TEST] "}${subject}`,
    Message: `${message}`,
    IsHtml: true,
    Cc: "",
    Bcc: AppSettings.Email.ReceiptBcc
  });
}

export default async function SubmitEmail(customer, onSubmit, account, token, topic) {
  try {
    var comment = topic ? `${topic}: ` : topic;
    if (customer.phone) comment += ` (phone: ${customer.phone}) `;
    comment += customer.notes;
    const requestId = await PostContactRequest(
      account,
      customer.company,
      customer.name,
      customer.email,
      customer.phone,
      comment,
      token
    );
    const statusUrl = AppSettings.RequestStatusPage(requestId);
    const subject = topic
      ? topic
      : account
      ? `Contact Request from ${customer.email} of ${account} ref:${requestId}`
      : `Contact Request from ${customer.email} ref:${requestId}`;
    await sendRequestEmail(
      subject,
      `${formatCustomer(customer, account)}
  <p><a href=${statusUrl}>View Request ${requestId}</a></p>`
    );
    await sendAcknowledmentEmail(
      customer.email,
      `We'll be in touch!`,
      `<p>Dear ${customer.name},</p>
	<p>Thank you for reaching out.  One of our team members
  will be in touch shortly regarding your inquiry.</p>
  <p>You can reply to this email with any updates or call us referencing request: ${requestId}</p>`
    );
    await sendEmailNotification(subject, customer, requestId);
    onSubmit();
  } catch (error) {
    onSubmit(error);
  }
}
