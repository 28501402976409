import React, { useContext } from "react";
import AppContext from "../../../context/AppContext";
import styled from "styled-components";
import parse, { domToReact } from "html-react-parser";
import "../../styling/styles.css";
import "../../styling/Renderer.css";
import { Link } from "react-router-dom";

function clean(html) {
  if (!html) {
    return "";
  }

  let trimmed = html.replace(/^<div.*?<\/div>/, ""); // trim off block title
  const cleanHtml = trimmed.replace(/>\s+</g, "><"); // eliminate excess whitespace in table
  return cleanHtml;
}

const PartId = styled.td`
  background-color: var(--very-light-blue);
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: var(--black);
  }
`;
const SelectPartId = styled.td`
  background-color: var(--reddish-orange);
  color: white;
  font-size: 14px;
`;
const MatchingPartId = styled(PartId)`
  background-color: var(--highlight-yellow);
`;

const GasketPartId = styled.td`
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: var(--black);
  }
  background-color: #d1e9d4;
  font-weight: bold;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
`;
const SelectGasket = styled.td`
  cursor: pointer;
  background-color: var(--reddish-orange);
  color: white;
  font-weight: bold;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
`;
const MatchingGasket = styled.td`
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: var(--black);
  }
  background-color: var(--highlight-yellow);
  font-weight: bold;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
`;

const Block = props => {
  const context = useContext(AppContext);
  const { query } = context;

  const FormatPartId = (id, isGasket, isHighlighted, selectedPart, block, children) => {
    const selectedPartId = selectedPart && selectedPart.partId;
    let url = block ? `/Part/${id}/${block.path}` : `/Part/${id}`;
    if (query) {
      url += `?query=${query}`;
    }
    const link = (
      <Link to={url}>
        {children}
      </Link>
    );
    if (id === selectedPartId) {
      return isGasket ? <SelectGasket>{children}</SelectGasket> : <SelectPartId>{children}</SelectPartId>;
    }
    if (isHighlighted(id)) {
      return isGasket ? <MatchingGasket>{link}</MatchingGasket> : <MatchingPartId>{link}</MatchingPartId>;
    } else {
      return isGasket ? <GasketPartId>{link}</GasketPartId> : <PartId>{link}</PartId>;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* put anything to appear above the block here */}
      <div style={{ position: "relative" }}>
        <div>
          {parse(clean(props.blockHtml), {
            replace: node => {
              const name = node.name;
              const className = node.attribs && node.attribs.class;
              const isPart = className && (className.includes("itemid") || className.includes("gasketpartno"));

              // Rewrite part numbers as clickable links
              if (name === "td" && isPart && node.children && node.children[0] && node.children[0].data) {
                const isGasket = className.includes("gasketpartno");
                const partId = node.children[0].data.trim();
                return (
                  <>
                    {FormatPartId(
                      partId,
                      isGasket,
                      props.isHighlighted,
                      props.selectedPart,
                      props.block,
                      domToReact(node.children)
                    )}
                  </>
                );
              }
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Block;
