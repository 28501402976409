import isNumber from "../../data/isNumber";

const formatPrice = price =>
  price === 0 ? "TBD" : isNumber(price) ? `$${Number.parseFloat(price).toFixed(2)}` : price;

function calculateTotal(parts, shipping, salesTax, shippingMethod, shippingCustomerAccount) {
  // Ensure falsy values are numeric
  if (!shipping) shipping = 0;
  if (!salesTax) salesTax = 0;

  const showShipping =
    // eslint-disable-next-line eqeqeq
    shipping != 0 &&
    !(shippingCustomerAccount && String(shippingMethod).startsWith("UPS")) &&
    !String(shippingMethod).startsWith("Will Call");

  // if (!showShipping && shippingCustomerAccount) {
  //   console.log("Shipping charges not shown for this customer");
  // }

  // eslint-disable-next-line eqeqeq
  var showSalesTax = salesTax != 0;

  //calculate total price;
  const sum = (total, part) => total + part.price * part.quantity;
  const subtotal = parts.reduce(sum, 0);
  const totalPrice = formatPrice(
    subtotal +
      (showShipping && isNumber(shipping) ? Number(shipping) : 0) +
      (showSalesTax && isNumber(salesTax) ? Number(salesTax) : 0)
  );

  // Hide the total if any parts don't have a price defined
  const hideTotal = parts.some(part => !part.price);

  const totalLabel =
    (showSalesTax && salesTax === "TBD") || (showShipping && shipping === "TBD") ? "Subtotal" : "Total";

  const result = { showShipping, showSalesTax, totalPrice, hideTotal, totalLabel };
  //console.log("TOTAL", totalPrice, subtotal, shipping, salesTax);
  return result;
}

export { calculateTotal };
