import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import Parts from "../components/parts/Parts";
import { Helmet } from "react-helmet";
import useInventory from "../../requests/hooks/useInventory";

const Wrapper = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 16px;
`;
const HeadingArea = styled.div`
  margin: 8px 6px 18px 0px;
`;
const Icon = styled.img`
  margin-right: 8px;
  width: 50px;
  height: 45px;
  object-fit: contain;
  float: left;
`;
const HeadingTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: var(--black);
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  width: 810px;
`;
const Subtitle = styled.div`
  margin-top: 4px;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const Button = styled.button`
  color: white;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;
const StyledLink = styled.span`
  display: block;
  width: max-content;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: var(--CP-blue);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--black);
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default function PartList(props) {
  const context = useContext(AppContext);
  const { query, parts, partIds, block, blockHtml, goto, cartParts, addToCart, token } = context;

  // We noticed that React sometimes does an extra render of this page in which {parts} has stale data.
  // This can create a timing issue because two asynchronous API calls to retrieve inventory data could
  // be active concurrently and depending on the order the complete, we might show incorrect inventory data.
  // Fortunately, we can detect the extraneous render because in that case  {query} is falsy.
  // When wedetect that, we pass an empty array to useInventory which supresses the underlying fetch call.
  const shownParts = query ? parts.slice(0, 100) : [];
  //  console.log("useInventory from PartList", query, shownPartIds);
  
  const shownPartIds = shownParts.map(part => part.partId);
  const inventory = useInventory(shownPartIds, token);

  // Infer title and breadcrumbs from current location.pathname
  let breadcrumbs = [{ title: "Home", path: "/" }];
  if (query) {
    breadcrumbs.push({ title: `Search: ${query.toUpperCase()}`, path: `/Search/${query}` });
  }

  // When viewing for for a page block, highlight the parts that match the search query
  function isHighlighted(id) {
    return query && partIds ? partIds.has(id) : false;
  }

  // Tailor the message when only some of the displayed parts match the search query
  const partCountMessage = (function () {
    // count of parts that match the search query
    const matchCount = block ? parts.filter(part => partIds.has(part.partId)).length : parts.length;
    const partText = matchCount === 1 ? "part" : "parts";
    const matchText = matchCount === 1 ? "matches" : "match";
    if (block) {
      if (query) {
        if (matchCount < parts.length) {
          return `${matchCount} ${partText} (of ${parts.length}) on this page ${matchText} your search`;
        } else {
          return `${matchCount} ${partText} on this page ${matchText} your search`;
        }
      } else {
        return parts.length > 0 ? `${parts.length} ${partText} on this page` : "";
      }
    } else {
      return `${matchCount} ${partText}`;
    }
  })();

  const description =
    partCountMessage +
    ". We also can special order your part. If we can't get the part you need, it's simply not available.";

  return (
    <Wrapper>
      <Helmet>
        <title>{`"${query}" Search - Case Parts Catalog`}</title>
        <meta name="description" content={description} />
      </Helmet>
      <HeadingArea>
        {/* <Icon
          src={block ? (block.info ? "/images/icon-info2.svg" : "/images/icon-page.svg") : "/images/icon-wrench.svg"}
        /> */}
          <Breadcrumb
              title={`${block && query ? "Return to Search Results" : "Return to Home page"}`}
              path={block && query ? `/Search/${query}` : "/"}
              goto={goto}
            />
        <HeadingTitle>
          {block
            ? block.category === "Manufacturers"
              ? `Replacement Parts for ${block.title}`
              : block.title
            : "Search Results"}
  
          {cartParts.length > 0 && <Button onClick={() => goto("/Cart")}>Proceed to Checkout</Button>}
        </HeadingTitle>
        <Subtitle>{partCountMessage}</Subtitle>
      </HeadingArea>
      <Parts
        parts={shownParts}
        isHighlighted={isHighlighted}
        blockHtml={blockHtml}
        block={block}
        onAddToCart={addToCart}
        history={props.history}
        inventory={inventory}
      />
    </Wrapper>
  );
}





function Breadcrumb({ title, path, className }) {
  //  const content = path ? <Link to={path}>{title}</Link> : <span onClick={() => window.history.back()}>{title}</span>;
  const content = path ? (
    <span onClick={() => (window.location = path)}>{title}</span>
  ) : (
    <span onClick={() => window.history.back()}>{title}</span>
  );
  // Empty href because
  return className ? (
    <a href="." className={className}>
      {content}
    </a>
  ) : (
    <StyledLink>{content}</StyledLink>
  );
}