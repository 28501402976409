import React, { useState } from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import "../styling/styles.css";

const Quantity = styled.fieldset`
  display: inline;
  float: right;
  margin: -6px 8px 0px 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  border: solid 1px #ccc;
  color: var(--battleship-grey);
  cursor: auto;
`;
const Input = styled.input`
  type: number;
  text-align: center;
  margin-right: 4px;
  margin-left: 4px;
  height: 24px;
  width: 36px;
  font-size: 12px;
  font-weight: normal;
  border: solid 0px var(--black);
`;
const Button = styled.button`
  float: right;
  color: white;
  width: 96px;
  height: 34px;
  border-radius: 2px;
  border: solid 2px var(--CP-blue);
  background-color: var(--CP-blue);
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
`;
const AlertText = styled.div`
  text-transform: none;
  font-size: 14px;
`;
const DimText = styled.span`
  font-size: 12px;
  color: #ccc;
`;

function FormatAlert(quantity, partId, title) {
  return (
    <AlertText>
      <DimText>Removed {quantity > 1 ? ` (quantity = ${quantity})` : ""}: </DimText>
      <br />
      {`${partId}: ${title}`}
    </AlertText>
  );
}

export default function DeleteOrUpdatePart(props) {
  const [quantity, setQuantity] = useState(1);
  const alert = useAlert();

  return (
    <>
      <Button
        onClick={e => {
          alert.success(FormatAlert(quantity, props.partId, props.title));
          props.onRemoveFromCart(props.uniqueId ? props.uniqueId : props.partId);
          e.stopPropagation();
        }}
      >
        Remove
      </Button>
      <Quantity onClick={e => e.stopPropagation()}>
        <legend>Qty</legend>
        <Input
          type="number"
          min="1"
          max="999"
          defaultValue={props.quantity}
          onChange={e => {
            setQuantity(e.target.value);
            props.onUpdateQuantity(props.partId, e.target.value);
          }}
        />
      </Quantity>
    </>
  );
}
