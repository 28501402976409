import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import Part from "../components/parts/Part";
import Block from "../components/parts/Block";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { getGasket } from "../../customParts/helpers";
import useInventory from "../../requests/hooks/useInventory";
import AppSettings from "../../AppSettings";
import useSecureFetch from "../../requests/hooks/useSecureFetch";
import { MapSrc } from '../../requests/hooks/ImageSrcMapper';

const Wrapper = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 16px;
`;
const HeadingArea = styled.div`
  margin-bottom: 16px;
`;
const BlockHeadingArea = styled.div`
  padding-top: 12px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;
const Icon = styled.img`
  margin-right: 8px;
  margin-bottom: 18px;
  width: 50px;
  height: 45px;
  object-fit: contain;
  float: left;
`;
const PageIcon = styled(Icon)`
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: 50px;
  height: 45px;
  object-fit: contain;
  float: left;
`;
const HeadingTitle = styled.div`
  padding-top: 4px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 924px;
`;
const Subtitle = styled.div`
  margin-top: 2px;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
`;
const Note = styled.div`
  font-style: italic;
  font-size: 14px;
`;
const Button = styled.button`
  color: white;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;
const BlockDiv = styled.div`
  margin-left: 100px;
`
const StyledLink = styled.span`
  display: block;
  width: max-content;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: var(--CP-blue);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--black);
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default function PartPage(props) {
  var container = React.createRef();
  const context = useContext(AppContext);
  const { query, parts, selectedPart, partIds, unknownPage, block, blockHtml, goto, addToCart, cartParts, token } = context;
  const partId = selectedPart ? selectedPart.partId : null;
  const inventory = useInventory(partId, token);
  const imagesApi = partId ? AppSettings.PartImagesRequest(partId) : null
  
  const result = useSecureFetch(imagesApi, null, [imagesApi]);
  const images = (result.response && !result.isLoading && !result.error
                 && result.response.Images && result.response.Images[0])
                 ? MapSrc(result.response.Images[0], partId) : ""

  // When viewing for for a page block, highlight the parts that match the search query
  function isHighlighted(id) {
    return query && partIds ? partIds.has(id) : false;
  }

  // Tailor the message when only some of the displayed parts match the search query
  const partCountMessage = (function () {
    // count of parts that match the search query
    const matchCount = partIds ? (block ? parts.filter(part => partIds.has(part.partId)).length : parts.length) : 0;
    const partText = matchCount === 1 ? "part" : "parts";
    const matchText = matchCount === 1 ? "matches" : "match";

    if (block) {
      if (query) {
        if (matchCount < parts.length) {
          return <>{`${matchCount} ${partText} (of ${parts.length}) on this page ${matchText} your search`}</>;
        } else {
          return <>{`${matchCount} ${partText} on this page ${matchText} your search`}</>;
        }
      } else {
        return parts.length > 0 ? `${parts.length} ${partText} on this page` : "";
      }
    } else {
      return <>{`${matchCount} ${partText} ${matchText} your search`}</>;
    }
  })();

  const partTitle = selectedPart ? `${selectedPart.partId}: ${selectedPart.title} - ` : "";
  const title = `${partTitle ? partTitle : ""}Case Parts Commercial Refrigeration Parts Specialists`;
  const description = `${title}. Latches, Hinges, Gaskets, Heaters, Pans, Walk-In Equipment, Electrical, Thermometers, Alarms, Shelving`;

  if (selectedPart && getGasket(selectedPart.partId)) {
    return <Redirect to={`/custom/gasket/${selectedPart.partId}`} />;
  }

  return (
    <ScrollToTop container={container}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {unknownPage && <meta name="prerender-status-code" content="404" /> }
      </Helmet>
      <Wrapper ref={container}>
        <HeadingArea>
          <Breadcrumb title="Continue shopping" />
          <HeadingTitle>
            Selected Part
            {cartParts.length > 0 && <Button onClick={() => goto("/Cart")}>Proceed to Checkout</Button>}
          </HeadingTitle>
        </HeadingArea>
        <Part
          title={selectedPart ? selectedPart.title : ""}
          price={selectedPart ? selectedPart.price : ""}
          partId={selectedPart ? selectedPart.partId : ""}
          partStatus={selectedPart ? selectedPart.partStatus : ""}
          showDetails={"true"}
          isSelectable={false}
          attributes={selectedPart ? selectedPart.attributes : []}
          onAddToCart={addToCart}
          history={props.history}
          inventory={inventory}
          image={images || '/images/placeholder.png'}
        />
        <BlockDiv>
          {block && (
            <BlockHeadingArea>
              <PageIcon src={block.info ? "/images/icon-info2.svg" : "/images/icon-page.svg"} />
              <HeadingTitle>
                {block.category === "Manufacturers" ? `Replacement Parts for ${block.title}` : block.title}
              </HeadingTitle>
              <Subtitle>{partCountMessage}</Subtitle>
            </BlockHeadingArea>
          )}
          <Block
            blockHtml={blockHtml}
            partIds={partIds}
            isHighlighted={isHighlighted}
            selectedPart={selectedPart}
            block={block}
            goto={goto}
          />
        </BlockDiv>
      </Wrapper>
    </ScrollToTop>
  );
}

function Breadcrumb({ title, path, className }) {
  //  const content = path ? <Link to={path}>{title}</Link> : <span onClick={() => window.history.back()}>{title}</span>;
  const content = path ? (
    <span onClick={() => (window.location = path)}>{title}</span>
  ) : (
    <span onClick={() => window.history.back()}>{title}</span>
  );
  // Empty href because
  return className ? (
    <a href="." className={className}>
      {content}
    </a>
  ) : (
    <StyledLink>{content}</StyledLink>
  );
}
