import AppSettings from "../../AppSettings";
import useSecureFetch from "./useSecureFetch";
import getStateCode from "./getStateCode";
import isNumber from "../../data/isNumber";
import { customPartType } from "../../customParts/helpers";

function formatBody(custKey, parts, addressKey, address, zipcode, shippingMethod) {
  const items = parts.map(part => ({
    Quantity: isNumber(part.quantity) ? Number(part.quantity) : 1,
    ItemId: part.partId,
    Price: isNumber(part.price) ? Number(part.price) : 0,
    CustomPart: customPartType(part)
  }));
  const shipTo = { Key: addressKey, Street: address, State: getStateCode(zipcode), Zipcode: zipcode };
  const body = { CustKey: custKey, ShippingMethod: shippingMethod, Items: items, ShippingAddress: shipTo };
  return JSON.stringify(body);
}

export default function useVerifyOrder(
  custKey,
  parts,
  addressKey,
  address,
  zipcode,
  shippingMethod,
  callback,
  disabled
) {
  const url = AppSettings.ApiVerifyOrder;
  const body = formatBody(custKey, parts, addressKey, address, zipcode, shippingMethod);
  const options = {
    method: "post",
    body: body
  };
  const result = useSecureFetch(
    url,
    options,
    [custKey, addressKey, address, zipcode, shippingMethod],
    callback,
    disabled
  );
  return result;
}
