import React, { useContext } from "react";
import styled from "styled-components";
import "../../styling/styles.css";
import AppContext from "../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewAccountPopup from "./NewAccountPopup";

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
`;
const NetPriceLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: var(--greyish-brown);
`;
const ListPriceLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: var(--CP-blue);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default function PriceLabel() {
  const context = useContext(AppContext);
  const { account, discountLevel } = context;
  const showNetPrice = account && !account.includes("@");
  return showNetPrice ? (
    <NetPriceLabel>{discountLevel === "Employee" ? "List Price" : "Net Price"}</NetPriceLabel>
  ) : (
    /* This wrapper is needed to prevent click propagation to PartList */
    <span onClick={e => e.stopPropagation()}>
      <NewAccountPopup
        trigger={
          <ListPriceLabel>
            <Icon icon={["far", "info-circle"]} />
            List Price
          </ListPriceLabel>
        }
      />
    </span>
  );
}
