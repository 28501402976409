import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useState, useEffect } from "react";
import * as signalR from "@aspnet/signalr";
import AppSettings from "../../AppSettings";

export default function useRequestDashboard() {
  // These are related to reading the initial state of the dashboard
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // These are related to the popup details for a single request
  const [requestId, setRequestId] = useState(null);
  const [requestHistory, setRequestHistory] = useState(null);
  const [maxId, setMaxId] = useState(0);

  const context = useContext(AppContext);
  const { token } = context;
  const options = {
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
  };

  // This is called each time we get an update via SignalR
  const updateResponse = request => {
    setResponse(prevState => {
      setMaxId(request.Id);
      const match = prevState.filter(entry => entry.RequestId === request.RequestId);
      if (match.length > 0) {
        console.log(`SignalR update to ${request.RequestId}`);
        return prevState.map(entry => (entry.RequestId === request.RequestId ? request : entry));
      } else {
        console.log(`SignalR append ${request.RequestId}`);
        return [request, ...prevState];
      }
    });
  };

  // SignalR initialization
  useEffect(() => {
    console.log("SignalR initialization...");
    const connection = new signalR.HubConnectionBuilder().withUrl(AppSettings.SignalR).build();

    connection.on("lastRequest", updateResponse);

    // connect
    connection
      .start()
      .then(console.log("SignalR connected, connection ID=" + connection.id))
      .catch(err => console.log("Error while establishing connection :("));
  }, []);

  // Do the initial load of dashboard requests
  useEffect(() => {
    console.log("useRequestDashboard");
    const fetchData = async url => {
      setIsLoading(true);
      fetch(url, options)
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then(responseJson => {
          setResponse(responseJson);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(`ERROR on ${url}`, err);
          setError({ status: err.status, message: "Request failed" });
          setIsLoading(false);
        });
    };
    if (token) {
      fetchData(AppSettings.RequestReport);
    }
  }, [token]);

  useEffect(() => {
    if (requestId) {
      console.log(`Fetching history for ${requestId}...`);
      const url = `${AppSettings.RequestStatus(requestId)}`;
      const fetchData = async () => {
        fetch(url, options)
          .then(response => {
            if (!response.ok) {
              throw response;
            }
            return response.json();
          })
          .then(responseJson => {
            console.log(`Read ${responseJson.length} versions of ${responseJson[0].RequestId}`);
            setRequestHistory(responseJson);
          })
          .catch(err => {
            console.log(`ERROR on ${url}`, err);
            setError({ status: err.status, message: "Request failed" });
          });
      };
      if (token) {
        fetchData();
      }
    }
  }, [requestId, maxId, token]);

  return { response, error, isLoading, requestId, setRequestId, requestHistory };
}
