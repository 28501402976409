import React, { useContext } from "react";
import AppContext from "../../../context/AppContext";
import Part from "./Part";
import Block from "./Block";
import ResearchForm from "../../checkout/ResearchForm";
import { Helmet } from "react-helmet";

export default function Parts(props) {
  const context = useContext(AppContext);
  const { query, partIds, selectedPart, block } = context;

  if (props.block) {
    return (
      <Block
        blockHtml={props.blockHtml}
        partIds={partIds}
        isHighlighted={props.isHighlighted}
        selectedPart={selectedPart}
        block={block}
      />
    );
  } else if (props.parts.length === 0) {
    return (
      <div style={{ padding: "0 10px 10px 10px" }}>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
        <h1>
          Our search engine couldn't find "<i>{query}</i>", but our Parts Experts can!
        </h1>
        <ResearchForm />
      </div>
    );
  } else {
    return (
      <>
        {props.parts.map((part, n) => (
          <div key={n} className="selectable" style={{width: 'max-content'}} onClick={() => props.history.push(`/Part/${part.partId}`)}>
            <Part
              {...part}
              key={n}
              isHighlighted={props.isHighlighted(part.partId)}
              onAddToCart={props.onAddToCart}
              history={props.history}
              inventory={props.inventory ? props.inventory[n] : null}
              isSelectable={true}
            />
          </div>
        ))}
      </>
    );
  }
}
