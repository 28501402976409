import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import WireDimension from "./WireDimension";
import WireSides from "./WireSides";
import { FindWiresBySpecs, FeetRounded, GetWirePrice, calcTotalInches } from "./WarmerWireCalcs";
import OptionCheckbox from "./OptionCheckbox";
import "../customParts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = styled.button`
  color: white;
  margin-left: 8px;
  margin-top: 16px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  font-weight: normal;
  margin-top: 2px;
  margin-right: 4px;
`;

const Quantity = props => (
  <input
    className="quantity"
    type="number"
    min={props.allowZero ? "0" : "1"}
    max="999"
    defaultValue={props.quantity}
    onChange={e => {
      props.onUpdateQuantity(e.target.value);
    }}
  />
);
export default function WarmerWireForm({
  part,
  addOrUpdatePartInCart,
  removeFromCart,
  cartParts,
  history,
  discountLevel,
  accessories
}) {
  const initialValues = {
    isNew: true,
    sides: "",
    height: "",
    width: "",
    length: "",
    highVoltage: false,
    ohmsPerFoot: "",
    wattsPerFoot: "",
    quantity: 1,
    tape: false,
    thermostat: false,
    cover: false,
    coverWidth: "wide",
    tapeQty: 1,
    qty8Foot: 2,
    qty4Foot: 1,
    thermostatQty: 1
  };

  // There is a bit of tricky logic associated with handling edits to existing warmer wires
  // Part of it is that we need to initialize the form values properly.
  if (part && part.specs && initialValues.isNew) {
    initialValues.isNew = false;
    initialValues.sides = part.specs.Sides;
    initialValues.height = part.specs.Height;
    initialValues.width = part.specs.Width;
    initialValues.highVoltage = part.specs.Voltage === 230;
    initialValues.quantity = part.quantity;
    const tape = cartParts.find(p => p.uniqueId === `Tape:${part.uniqueId}`);
    initialValues.tape = !!tape;
    initialValues.tapeQty = tape ? tape.quantity : 1;
    const cover8 = cartParts.find(p => p.uniqueId === `Cover8:${part.uniqueId}`);
    const cover4 = cartParts.find(p => p.uniqueId === `Cover4:${part.uniqueId}`);
    initialValues.cover = !!cover8 || !!cover4;
    if (initialValues.cover) {
      initialValues.coverWidth =
        (cover8 && cover8.partId === accessories.wide8.partId) || (cover4 && cover4.partId === accessories.wide4.partId)
          ? "wide"
          : "narrow";
      initialValues.qty8Foot = cover8 && cover8.quantity ? cover8.quantity : 0;
      initialValues.qty4Foot = cover4 && cover4.quantity ? cover4.quantity : 0;
    }
    const thermostat = cartParts.find(p => p.uniqueId === `Thermostat:${part.uniqueId}`);
    initialValues.thermostat = !!thermostat;
  }

  // Another aspect of handling edit properly is correctly displaying the appropriate wires and selected wire
  function calcDefaultState(part) {
    if (part && part.specs) {
      const result = FindWiresBySpecs(part.specs.Sides === 4, part.specs.Width, part.specs.Height, part.specs.Voltage);
      return {
        wires: result.wires,
        recommendedIndex: result.bestIndex,
        selectedIndex: result.wires.findIndex(w => w.ohms === part.specs.OhmsPerFoot)
      };
    } else {
      return { wires: [], recommendedIndex: -1, selectedIndex: -1 };
    }
  }

  // defaultState ensures that the initial value of the form is correct for both new parts and editing existing parts
  const defaultState = calcDefaultState(part);
  const [wires, setWires] = useState(defaultState.wires);
  const [selectedIndex, setSelectedIndex] = useState(defaultState.selectedIndex);
  const [recommendedIndex, setRecommendedIndex] = useState(defaultState.recommendedIndex);

  const addWireToCart = values => {
    const wire = wires[selectedIndex];
    const isSinglePass = values.sides === 4;
    // const feet = FeetRounded(isSinglePass, values.width, values.height);
    const totalInches = calcTotalInches(isSinglePass, values.width, values.height);

    part.specs = {
      Sides: Number(values.sides),
      Width: Number(values.width),
      Height: Number(values.height),
      Voltage: values.highVoltage ? 230 : 115,
      OhmsPerFoot: wire.ohms
    };
    part.attributes = [
      { name: "Shape", value: isSinglePass ? "4-sided / Single Pass" : "3-sided / Double Pass" },
      { name: "Door Width", value: `${values.width} inches` },
      { name: "Door Height", value: `${values.height} inches` },
      // { name: "Wire Length", value: `${feet} feet` },
      { name: "Wire Length", value: `${totalInches} inches`},
      { name: "Ohms per foot", value: wire.ohms },
      { name: "Watts per foot", value: wire.wpf },
      { name: "Total Watts", value: wire.watts },
      { name: "Total Amps", value: wire.amps }
    ];
    part.partId = `WW-${wire.ohms}`;
    part.title = `${wire.ohms} Ohms/foot warmer wire (${totalInches}" + 18" lead wires)`;
    part.custom = "Wire";
    part.quantity = part.quantity ? part.quantity : 1;
    part.price = GetWirePrice(values.sides === 4, values.width, values.height, discountLevel);
    part.quantity = values.quantity;

    // Remove old items, if any, so new ones go in the right order
    if (part.uniqueId) {
      removeFromCart(part.uniqueId);
      removeFromCart(`Tape:${part.uniqueId}`);
      removeFromCart(`Cover8:${part.uniqueId}`);
      removeFromCart(`Cover4:${part.uniqueId}`);
    } else {
      part.uniqueId = Date.now().toString();
    }

    addOrUpdatePartInCart(part);

    const tape = cartParts.find(p => p.uniqueId === `Tape:${part.uniqueId}`);
    if (!!tape || (values.tape && values.tapeQty > 0)) {
      accessories.tape.quantity = values.tape ? values.tapeQty : 0;
      accessories.tape.uniqueId = `Tape:${part.uniqueId}`;
      addOrUpdatePartInCart(accessories.tape);
    }

    const prevCover8 = cartParts.find(p => p.uniqueId === `Cover8:${part.uniqueId}`);
    if (!!prevCover8 || (values.cover && values.qty8Foot > 0)) {
      const cover = values.coverWidth === "wide" ? accessories.wide8 : accessories.narrow8;
      cover.quantity = values.cover ? values.qty8Foot : 0;
      cover.uniqueId = `Cover8:${part.uniqueId}`;
      addOrUpdatePartInCart(cover);
    }

    const prevCover4 = cartParts.find(p => p.uniqueId === `Cover4:${part.uniqueId}`);
    if (!!prevCover4 || (values.cover && values.qty4Foot > 0)) {
      const cover = values.coverWidth === "wide" ? accessories.wide4 : accessories.narrow4;
      cover.quantity = values.cover ? values.qty4Foot : 0;
      cover.uniqueId = `Cover4:${part.uniqueId}`;
      addOrUpdatePartInCart(cover);
    }

    const thermostat = cartParts.find(p => p.uniqueId === `Thermostat:${part.uniqueId}`);
    if (!!thermostat || (values.thermostat && values.thermostatQty > 0)) {
      accessories.thermostat.quantity = values.thermostat ? values.thermostatQty : 0;
      accessories.thermostat.uniqueId = `Thermostat:${part.uniqueId}`;
      addOrUpdatePartInCart(accessories.thermostat);
    }

    history.push("/cart");
  };

  function validate(values) {
    const { sides, width, height, highVoltage } = values;
    const isValid = sides && height && width;
    if (isValid) {
      const result = FindWiresBySpecs(values.sides === 4, width, height, highVoltage ? 230 : 115);
      setWires(result.wires);
      setRecommendedIndex(result.bestIndex);
      setSelectedIndex(result.bestIndex);
    }
  }

  const minValue = 12;
  const maxValue = 144;

  let validations = {
    sides: Yup.number().required("Please chose between a 3-Sided or 4-Sided heater circuit"),
    width: Yup.number()
      .required(`Please enter the width of the door`)
      .integer("Door width must be an integer")
      .min(minValue, `Door width must be at least ${minValue}"`)
      .max(maxValue, `Door width must be no more than ${maxValue}"`),
    height: Yup.number()
      .required(`Please enter the height of the door`)
      .integer("Door height must be an integer")
      .min(minValue, `Door height must be at least ${minValue}"`)
      .max(maxValue, `Door height must be no more than ${maxValue}"`)
  };
  const inputSchema = Yup.object().shape(validations);
  const formatPrice = price => `$${Number.parseFloat(price).toFixed(2)}`;

  return (
    <Formik validate={validate} validationSchema={inputSchema} initialValues={initialValues}>
      {({ values, errors, touched, setFieldValue, setFieldTouched, handleBlur }) => {
        const isSinglePass = values.sides === 4;
        const hasErrors =
          (touched.sided && errors.sided) || (touched.height && errors.height) || (touched.width && errors.width);
        const hasAllValues = !!values.sides && !!values.width && !!values.height;
        const showPrice = hasAllValues && !hasErrors;
        const wirePrice = showPrice ? GetWirePrice(isSinglePass, values.width, values.height, discountLevel) : 0;
        // const feet = showPrice ? FeetRounded(isSinglePass, values.width, values.height) : 0;
        const totalInches = showPrice ? calcTotalInches(isSinglePass, values.width, values.height) : 0;
        const ohmsPerFoot = selectedIndex >= 0 && selectedIndex < wires.length ? wires[selectedIndex].ohms : 0;
        const partId = `WW-${ohmsPerFoot}`;
        const tapePrice = values.tape ? accessories.tape.price * values.tapeQty : 0;
        const narrowCoverPrice =
          values.cover && values.coverWidth === "narrow"
            ? values.qty8Foot * accessories.narrow8.price + values.qty4Foot * accessories.narrow4.price
            : 0;
        const wideCoverPrice =
          values.cover && values.coverWidth === "wide"
            ? values.qty8Foot * accessories.wide8.price + values.qty4Foot * accessories.wide4.price
            : 0;
        const thermostatPrice = values.thermostat ? accessories.thermostat.price * values.thermostatQty : 0;
        const formattedPrice = `$${Number(
          wirePrice * values.quantity + tapePrice + narrowCoverPrice + wideCoverPrice + thermostatPrice
        ).toFixed(2)}`;

        return (
          <Form>
            <div className="flexContainer">
              <div className="wireForm">
                <WireSides fieldName="sides" label={<h2>Should the circuit wrap all four sides of the door?</h2>} />
                <h2>
                  Enter door dimensions <span className="smaller">(inches)</span>
                </h2>
                <WireDimension
                  fieldName="width"
                  label="Width"
                  min={minValue}
                  max={maxValue}
                  defaultValue={initialValues.width}
                  onBlur={() => setFieldTouched("width")}
                />
                <WireDimension
                  fieldName="height"
                  label="Height"
                  min={minValue}
                  max={maxValue}
                  defaultValue={initialValues.height}
                  onBlur={() => setFieldTouched("height")}
                />
                <h2>Voltage</h2>
                <div className="voltage">
                  <OptionCheckbox fieldName="highVoltage" label="Use 230V instead of 115V voltage" />
                  {values.highVoltage && (
                    <div>
                      <Icon icon={["fas", "exclamation-triangle"]} />
                      <i>High voltage heater circuits have inherent risks!</i>
                    </div>
                  )}
                </div>
                {showPrice && (
                  <div className="accessories">
                    <h2>Installation Accessories</h2>
                    <OptionCheckbox fieldName="tape" label='Adhesive-backed foil tape (2" wide)' />
                    <OptionCheckbox fieldName="cover" label="Snap-on heater covers" />
                    {values.cover && (
                      <>
                        <img src="/images/heaterCover.png" alt="heater cover" className="heaterCover" />
                        <Field name={"coverWidth"} id={"coverWidth"}>
                          {({ field: { value }, form: { getFieldValue, setFieldValue } }) => (
                            <>
                              <label>
                                <input
                                  type="radio"
                                  name="wide"
                                  checked={value === "wide"}
                                  onChange={() => setFieldValue("coverWidth", "wide")}
                                />
                                1" Wide
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="narrow"
                                  checked={value === "narrow"}
                                  onChange={() => setFieldValue("coverWidth", "narrow")}
                                />
                                3/4" Wide
                              </label>
                            </>
                          )}
                        </Field>
                      </>
                    )}
                    <OptionCheckbox fieldName="thermostat" label="Thermostat" />
                  </div>
                )}
              </div>
              {showPrice && wires.length > 0 && (
                <div className="wireDetails">
                  <h2 className="first">Wire Choices</h2>
                  <table>
                    <thead>
                      <tr>
                        <th>Ohms Per Foot</th>
                        <th>Watts Per Foot</th>
                        <th>Total Watts</th>
                        <th>Total Amps</th>
                        <th className="hidden"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {wires.map((w, n) => (
                        <tr
                          className={n === selectedIndex ? "selected" : ""}
                          key={`wire${n}`}
                          onClick={() => setSelectedIndex(n)}
                        >
                          <td>{w.ohms}</td>
                          <td>{w.wpf}</td>
                          <td>{w.watts}</td>
                          <td>{w.amps}</td>
                          {n === recommendedIndex && (
                            <td className="hidden">
                              <Icon icon={["fas", "arrow-left"]} />
                              <i>Recommended</i>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h2>Order Details</h2>
                  <table>
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th className="left">PartId</th>
                        <th className="left">Description</th>
                        <th className="right">Unit Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="quantity">
                          <Quantity
                            quantity={values.quantity}
                            onUpdateQuantity={qty => setFieldValue("quantity", qty)}
                          />
                        </td>
                        <td className="left">{partId}</td>
                        <td className="left">
                          {ohmsPerFoot} Ohms/foot warmer wire ({totalInches}" + 18" lead wires)
                        </td>
                        <td>{formatPrice(wirePrice)}</td>
                      </tr>
                      {values.tape && (
                        <tr>
                          <td className="quantity">
                            <Quantity
                              quantity={values.tapeQty}
                              onUpdateQuantity={qty => setFieldValue("tapeQty", qty)}
                              allowZero={true}
                            />
                          </td>
                          <td className="left">{accessories.tape.partId}</td>
                          <td className="left">{accessories.tape.title}</td>
                          <td>{formatPrice(accessories.tape.price)}</td>
                        </tr>
                      )}
                      {values.cover && values.coverWidth === "wide" && (
                        <>
                          <tr>
                            <td className="quantity">
                              <Quantity
                                quantity={values.qty8Foot}
                                onUpdateQuantity={qty => setFieldValue("qty8Foot", qty)}
                                allowZero={true}
                              />
                            </td>
                            <td className="left">{accessories.wide8.partId}</td>
                            <td className="left">{accessories.wide8.title}</td>
                            <td>{formatPrice(accessories.wide8.price)}</td>
                          </tr>
                          <tr>
                            <td className="quantity">
                              <Quantity
                                quantity={values.qty4Foot}
                                onUpdateQuantity={qty => setFieldValue("qty4Foot", qty)}
                                allowZero={true}
                              />
                            </td>
                            <td className="left">{accessories.wide4.partId}</td>
                            <td className="left">{accessories.wide4.title}</td>
                            <td>{formatPrice(accessories.wide4.price)}</td>
                          </tr>
                        </>
                      )}
                      {values.cover && values.coverWidth === "narrow" && (
                        <>
                          <tr>
                            <td className="quantity">
                              <Quantity
                                quantity={values.qty8Foot}
                                onUpdateQuantity={qty => setFieldValue("qty8Foot", qty)}
                                allowZero={true}
                              />
                            </td>
                            <td className="left">{accessories.narrow8.partId}</td>
                            <td className="left">{accessories.narrow8.title}</td>
                            <td>{formatPrice(accessories.narrow8.price)}</td>
                          </tr>
                          <tr>
                            <td className="quantity">
                              <Quantity
                                quantity={values.qty4Foot}
                                onUpdateQuantity={qty => setFieldValue("qty4Foot", qty)}
                                allowZero={true}
                              />
                            </td>
                            <td className="left">{accessories.narrow4.partId}</td>
                            <td className="left">{accessories.narrow4.title}</td>
                            <td>{formatPrice(accessories.narrow4.price)}</td>
                          </tr>{" "}
                        </>
                      )}
                      {values.thermostat && (
                        <tr>
                          <td className="quantity">
                            <Quantity
                              quantity={values.thermostatQty}
                              onUpdateQuantity={qty => setFieldValue("thermostatQty", qty)}
                              allowZero={true}
                            />
                          </td>
                          <td className="left">{accessories.thermostat.partId}</td>
                          <td className="left">{accessories.thermostat.title}</td>
                          <td>{formatPrice(accessories.thermostat.price)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="norefund">
                    <h3>Notes:</h3>
                    <ol>
                      <li>You may provide any special instructions during the checkout process.</li>
                      <li>Custom warmer wires are non-returnable.</li>
                      <li>Check your measurements. We do not recommend shortening the heater length in the field.</li>
                      <li>
                        To extend the life of the heater, the wire should not cross itself at any point in the
                        installation.
                      </li>
                      {values.cover && (
                        <li>
                          Heater covers incur a $10 charge for special packaging needed to prevent damage during
                          shipping.
                        </li>
                      )}
                      {values.cover && values.qty8Foot > 0 && (
                        <li>
                          To avoid the high cost of shipping over-sized items, we cut 8' heater covers down to 93".
                        </li>
                      )}
                    </ol>
                  </div>
                  <div>
                    {formattedPrice && (
                      <div className="price">
                        Price: <b>{formattedPrice}</b>
                      </div>
                    )}
                    <Button type="button" onClick={() => addWireToCart(values)}>
                      {values.isNew ? "Add to Cart" : "Update"}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Link to="/" onClick={() => history.goBack()}>
                      Cancel
                    </Link>
                  </div>
                </div>
              )}
              <div className="hints">
                {wires.length === 0 && !hasAllValues && (
                  <p>
                    <Icon icon={["fas", "arrow-left"]} />
                    <b>Please enter your specifications.</b>
                    <br />
                    <br />
                    The recommended wire along with a list of alternatives will then be displayed here.
                  </p>
                )}
                {hasErrors && (
                  <p>
                    <Icon icon={["fas", "arrow-left"]} />
                    <b>
                      Please correct errors indicated in <span className="red">red</span>
                    </b>
                  </p>
                )}
                {wires.length === 0 && hasAllValues && !hasErrors && (
                  <p>
                    <span className="red">
                      <Icon icon={["fas", "arrow-left"]} />
                      <b>There are no available wires for your specifications.</b>
                    </span>
                    <br />
                    <br />
                    Please double-check your specifications for single/double pass, width, height, and voltage.
                  </p>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
