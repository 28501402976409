// Given two sorted arrays, it returns a new sorted array representing the
// union of the two sets
export function union(arr1, arr2) {
  let merged = [];
  let index1 = 0;
  let index2 = 0;
  let count = 0;

  while (count < arr1.length + arr2.length) {
    let isArr1Depleted = index1 >= arr1.length;
    let isArr2Depleted = index2 >= arr2.length;

    if (!isArr1Depleted && (isArr2Depleted || arr1[index1] < arr2[index2])) {
      merged.push(arr1[index1]);
      index1++;
    } else if (!isArr1Depleted && !isArr2Depleted && arr1[index1] === arr2[index2]) {
      merged.push(arr1[index1]);
      index1++;
      index2++;
      count++;
    } else {
      merged.push(arr2[index2]);
      index2++;
    }

    count++;
  }

  return merged;
}

// Given two sorted arrays, it returns a new sorted array representing the
// intersection of the two sets
export function intersection(arr1, arr2) {
  let merged = [];
  let index1 = 0;
  let index2 = 0;
  let count = 0;

  while (count < arr1.length + arr2.length) {
    let isArr1Depleted = index1 >= arr1.length;
    let isArr2Depleted = index2 >= arr2.length;

    if (!isArr1Depleted && (isArr2Depleted || arr1[index1] < arr2[index2])) {
      index1++;
    } else if (!isArr1Depleted && !isArr2Depleted && arr1[index1] === arr2[index2]) {
      merged.push(arr1[index1]);
      index1++;
      index2++;
      count++;
    } else {
      index2++;
    }

    count++;
  }

  return merged;
}
