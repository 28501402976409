import React from "react";
import styled from "styled-components";
import "../styling/styles.css";
//mport { Link } from "react-router-dom";

const StyledLink = styled.span`
  margin-left: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: var(--CP-blue);
  text-decoration: none;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    color: var(--black);
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default function Breadcrumb({ title, path, className }) {
  //  const content = path ? <Link to={path}>{title}</Link> : <span onClick={() => window.history.back()}>{title}</span>;
  const content = path ? (
    <span onClick={() => (window.location = path)}>{title}</span>
  ) : (
    <span onClick={() => window.history.back()}>{title}</span>
  );
  // Empty href because
  return className ? (
    <a href="." className={className}>
      {content}
    </a>
  ) : (
    <StyledLink>{content}</StyledLink>
  );
}
