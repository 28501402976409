import { useState } from "react";

export default function useStorage(store, key, initialValue) {
    const fullKey = "CPC." + key
    const [storedValue, _setValue] = useState(() => {
        try {
            const item = store.getItem(fullKey)
            return item ? JSON.parse(item) : initialValue
        } catch (e) {
            console.log("ERROR creating storage for ", fullKey)
            return initialValue
        }
    });

    const setValue = (value) => {
        const valueToStore =
            value instanceof Function ? value(storedValue) : value
        const denullified = value === null ? initialValue : valueToStore
        _setValue(denullified)

        // If value undefined, remove item, otherwise, update it
        if (value === undefined) {
            store.removeItem(fullKey)
        } else {
            store.setItem(fullKey, JSON.stringify(denullified))
        }
    };

    return [storedValue, setValue]
}
