import AppSettings from "../AppSettings";
import { FindEmployee } from "./data/GetStaffInfo";
import * as loupe from "../Loupe.JavaScript.Agent";

async function putRequest(request, token) {
  
  const requestId = request.RequestId;
  const endpoint = AppSettings.RequestUpdate(requestId);

  loupe.Information(`Updating ${requestId} v${request.Version}`);

  const response = await fetch(endpoint, {
    method: "put",
    headers: {
      "Content-Type": "application/json", Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(request)
  });

  console.log(`PUT status: ${response.status}`);
  return response;
}

function formatRequest(requestId, version, updatedBy, status, owner, note) {
  owner = FindEmployee(owner && owner !== "System" ? owner : updatedBy).username;
  updatedBy = FindEmployee(updatedBy).username;
  return {
    RequestId: requestId ? requestId : "",
    Version: version ? version : "1",
    UpdatedBy: updatedBy ? updatedBy : "",
    Status: status ? status : "",
    Owner: owner ? owner : "",
    Note: note ? note : ""
  };
}

export default async function PutRequest(callback, requestId, version, updatedBy, status, owner, note, token) {

  putRequest(formatRequest(requestId, version, updatedBy, status, owner, note), token).then(result =>
    callback ? callback(result) : null
  );
}
