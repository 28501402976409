import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Note = styled.div`
  margin-top: 20px;
  margin-left: 26px;
  width: 378px;
  font-size: 12px;
  font-style: normal;
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  border-color: ${(props) => props.color || "#000000"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.color || "#000000"};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 48px;
  float: left;
  font-size: 24px;
  margin-top: 4px;
  margin-right: 12px;
  color: ${(props) => props.color || "#000000"};
`;

const Title = styled.div`
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => props.color || "#000000"};
`;

const Text = styled.div`
  float: left;
  width: 310px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.color || "#000000"};
`;

export default function CheckoutNote({
  library,
  icon,
  color,
  backgroundColor,
  title,
  children
}) {
  var _icon = icon ? icon : 'circle-info'
  console.log(title, icon, _icon)
  return (
    <Note color={color} backgroundColor={backgroundColor}>
      <Icon
        icon={[library ? library : "far", icon ? icon : 'circle-info']}
        color={color}
      />
      <Text color={color}>
        {title && <Title color={color}>{title}</Title>}
        {children}
      </Text>
      <div style={{ clear: "both" }}></div>
    </Note>
  );
}
