import useSecureFetch from "./useSecureFetch";
import AppSettings from "../../AppSettings";

function deNullify(a, b) {
  return a && b ? a.localeCompare(b) : a && !b ? -1 : !a && b ? 1 : 0;
}

function getCompareFunction(sortName) {
  switch (sortName.toLowerCase()) {
    case "request":
      // This case is special because we want to sort by RequestId within RequestType
      return (a, b) =>
        a.RequestType === b.RequestType ? deNullify(a.RequestId, b.RequestId) : deNullify(a.RequestType, b.RequestType);
    case "opkey":
      return (a, b) => deNullify(a.OpKey, b.OpKey);
    case "status":
      return (a, b) => deNullify(a.Status, b.Status);
    case "requested":
      return (a, b) => (a.RequestCreated === b.RequestCreated ? 0 : a.RequestCreated > b.RequestCreated ? 1 : -1);
    case "quoted":
      return (a, b) => (a.QuoteCreated === b.QuoteCreated ? 0 : a.QuoteCreated > b.QuoteCreated ? 1 : -1);
    case "amount":
      return (a, b) => (Number(a.Total) === Number(b.Total) ? 0 : Number(a.Total) > Number(b.Total) ? 1 : -1);
    case "account":
      // This case is special because not all orders have an account.  Those go at the end
      // sorted by company name
      return (a, b) =>
        a.CustomerId || b.CustomerId ? deNullify(a.CustomerId, b.CustomerId) : deNullify(a.Company, b.Company);
    case "contact":
      return (a, b) => deNullify(a.Email, b.Email);
    case "owner":
      return (a, b) => deNullify(a.Owner, b.Owner);
    default:
      // leave in "natural" order
      return (a, b) => 0;
  }
}

function combineCompareFunctions(primaryCompare, secondaryCompare, descending) {
  // Convert boolean into a multiplicative factor
  const direction = descending ? -1 : 1;

  // Define a function that will apply the primarySort, reversed if descending,
  // then apply secondarySort if records match on primarySort
  return (a, b) => {
    const primaryComparison = primaryCompare(a, b) * direction;
    return primaryComparison ? primaryComparison : secondaryCompare(a, b);
  };
}

export default function useOrderReport(query) {
  const customerId = query.account ? query.account : "";
  const email = query.email ? query.email : "";
  const owner = query.owner ? query.owner : "";
  const status = query.status ? query.status : "";
  const type = query.type ? query.type : "";
  const request = query.request ? query.request : "";
  const from = query.from ? query.from : "";
  const to = query.to ? query.to : "";
  var sort = query.sort ? query.sort : "-requested";
  var descending = false;
  if (sort.substr(0, 1) === "-") {
    descending = true;
    sort = sort.substr(1);
  }

  
  const url = `${AppSettings.OrderReport}?customerId=${customerId}&email=${email}&owner=${owner}&status=${status}&optype=${type}&requesttype=${request}&from=${from}&to=${to}`;
  const result = useSecureFetch(url, null, window.location.href);

  if (result.response) {
    const primaryCompare = getCompareFunction(sort);
    const secondaryCompare = (a, b) => (a.CreatedTime > b.CreatedTime ? -1 : 1);
    const compare = combineCompareFunctions(primaryCompare, secondaryCompare, descending);
    const sorted = result.response.sort(compare);
    result.response = sorted;
  }
  return result;
}
