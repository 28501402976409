import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const Icon = styled(FontAwesomeIcon)`
  font-size: 13px;
  margin-top: 3px;
  margin-right: 4px;
  float: right;
  color: #404040;
`;
const Label = styled.div`
  font-size: 11px;
  color: #404040;
  margin-right: 4px;
  margin-top: 3px;
  float: right;
`;

const getIcon = (icon, label) => (
  <div className="requestTimestamp">
    <Icon icon={["far", icon]} />
    <Label>{label}</Label>
  </div>
);

const Format = utc => {
  const timestamp = moment(utc);
  const time = timestamp.format("LT");

  if (timestamp.isSame(new Date(), "day")) {
    return time;
  }

  const date = timestamp.format("MMM D");
  return `${date} ${time}`;
};

export default function Timestamp({ attribute, time }) {
  if (!time) {
    return "";
  }
  switch (attribute) {
    case "CreatedTime":
      return getIcon("stopwatch", Format(time));
    case "UpdatedTime":
      return getIcon("hourglass-half", Format(time));
    case "CompletedTime":
      return getIcon("check", Format(time));
    default:
      return "";
  }
}
