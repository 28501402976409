import React from "react";
import "../requestManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSettings from "../../AppSettings";
import PutRequest from "../PutRequest";
import { sendUpdateNotification } from "../../ui/checkout/SlackNotification";
import {
  FindEmailByRequestId,
  FindEmailByEmailAddress,
  FindEmailByCustomerId,
  IsAuthorized
} from "../data/GetStaffInfo";
import { useContext } from "react";
import AppContext from "../../context/AppContext";

async function handleClaim(entry, employee, close, token) {
  PutRequest(
    null,
    entry.RequestId,
    entry.Version,
    employee.shortname,
    "Active",
    employee.shortname,
    `Claimed by ${employee.shortname}`,
    token
  );
  await sendUpdateNotification(entry, "Claim", employee.shortname);
  if (close) {
    close();
  }
}
async function handleComplete(entry, employee, close, token) {
  PutRequest(
    null,
    entry.RequestId,
    entry.Version,
    employee.shortname,
    "Done",
    entry.Owner,
    `Completed by ${employee.shortname}`,
    token
  );
  await sendUpdateNotification(entry, "Complete", employee.shortname);
  if (close) {
    close();
  }
}

export default function RequestCommands({ employee, entry, close }) {
  const context = useContext(AppContext);
  const { token } = context;

  const isClaimable =
    entry.Status !== "Active" || (entry.Owner !== employee.shortname && entry.Owner !== employee.username);
  return (
    <div className={close ? "request-menu" : "requestEditorMenuOptions"}>
      {close && isClaimable && (
        <div className="menu-item" onClick={() => handleClaim(entry, employee, close, token)}>
          <FontAwesomeIcon icon={["far", "play"]} /> Claim
        </div>
      )}
      {close && entry.Status !== "Done" && (
        <div className="menu-item" onClick={() => handleComplete(entry, employee, close, token)}>
          <FontAwesomeIcon icon={["far", "stop"]} /> Complete
        </div>
      )}
      {entry.ChannelId && (
        <div className="menu-item">
          <a href={AppSettings.ViewSlackChannel(entry.ChannelId)} target="slack">
            <FontAwesomeIcon icon={["far", "comments"]} /> View Chat Transcript
          </a>
        </div>
      )}
      {entry.RequestType !== "Chat" && IsAuthorized(employee) && (
        <div className="menu-item">
          <a href={FindEmailByRequestId(employee, entry.RequestId)} target="gmail">
            <FontAwesomeIcon icon={["far", "envelope"]} /> Search by Request ID
          </a>
        </div>
      )}
      {entry.CustomerId && IsAuthorized(employee) && (
        <div className="menu-item">
          <a href={FindEmailByCustomerId(employee, entry.CustomerId)} target="gmail">
            <FontAwesomeIcon icon={["far", "envelope"]} /> Search by Customer ID
          </a>
        </div>
      )}
      {entry.Email && IsAuthorized(employee) && (
        <div className="menu-item">
          <a href={FindEmailByEmailAddress(employee, entry.Email)} target="gmail">
            <FontAwesomeIcon icon={["far", "envelope"]} /> Search by Email Address
          </a>
        </div>
      )}
    </div>
  );
}
