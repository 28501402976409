import React, { useState, useContext } from "react";
import AppContext from "../../context/AppContext";
//import ResultWrapper from "../ResultWrapper";
//import styled from "styled-components";
import "../requestManager.css";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
//import { Time } from "../RequestFormatting";
//import AppSettings from "../../AppSettings";
import queryString from "query-string";
import CanvasJSReact from "../../assets/canvasjs.react";
import useRequestMetrics from "../hooks/useRequestMetrics";
import useWindowSize from "../hooks/useWindowSize";
import { Link } from "react-router-dom";
import useLocalStorage from "../../context/useLocalStorage";
import SelectCsr from "../widgets/SelectCsr";

const requestColors = {
  Checkout: "#3d9665",
  Research: "#1380b1",
  Chat: "#7b77ee",
  OrderLink: "#63bf8d",
  Email: "#de5449",
  Account: "#f5a056"
};

//var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function RequestsChart(props) {
  const context = useContext(AppContext);
  const { claims } = context;

  // First, we need to authenticate
  if (claims.UserType !== "Employee") {
    console.log(claims.UserType, "Not authorized");
    window.location = "/";
  }

  const size = useWindowSize();
  const params = queryString.parse(props.location.search);

  // Retain default value for unit in local storage
  const [storedUnit, setUnit] = useLocalStorage("metricUnit", "day");
  const unit = params.unit ? params.unit.toLowerCase() : storedUnit;
  if (params.unit && storedUnit !== unit) setUnit(unit); // update localStorage if unit passed explicity
  const owner = params.owner ? params.owner : "";

  const defaultCount = 12;
  const to = params.to ? moment(params.to, "MM-DD-YYYY") : moment();
  const from = params.from ? moment(params.from, "MM-DD-YYYY") : to.clone().add(-defaultCount, unit);
  const xToolTipValueFormatString = unit === "month" ? "MMMM YYYY" : "MMM D YY";
  const yAxisInterval = unit === "month" ? 50 : unit === "week" ? 10 : 5;
  const [initialized, setInitialized] = useState(false);
  const result = useRequestMetrics(owner, unit, from.format("MM-D-YYYY"), to.format("MM-D-YYYY"), () => {
    if (!initialized) setInitialized(true);
  });
  const data = result.response ? result.response : [];

  const getSample = (requestType, entry) => {
    const x = new Date(entry.StartDate);
    var y;
    switch (requestType) {
      case "Checkout":
        y = entry.Checkout;
        break;
      case "Research":
        y = entry.Research;
        break;
      case "Chat":
        y = entry.Chat;
        break;
      case "OrderLink":
          y = entry.OrderLink;
        break;
      case "Contact":
        y = entry.Contact;
        break;
      case "Account":
        y = entry.Account;
        break;
      default:
        y = 0;
        break;
    }
    return { x, y };
  };

  const getSamples = requestType => data.map(entry => getSample(requestType, entry));
  const capitalize = word => word[0].toUpperCase() + word.slice(1);
  const title = `Dashboard Requests by ${capitalize(unit)}`;
  const dataClickHandler = e => {
    const baseDate = moment(e.dataPoint.x);
    const fromDate = baseDate.clone().startOf(unit);
    const toDate = baseDate.clone().endOf(unit);
    const type = e.dataSeries.name === "Email" ? "Contact" : e.dataSeries.name;
    const from = fromDate.format("MM-D-YYYY");
    const to = toDate.format("MM-D-YYYY");
    const url = `/requestsreport?request=${type}&owner=${owner}&from=${from}&to=${to}`;
    props.history.push(url);
  };

  const options = {
    interactivityEnabled: true,
    animationEnabled: true,
    animationDuration: 2000,
    height: size.height - 100,
    title: {
      text: title,
      fontFamily: "Roboto",
      fontSize: 32
    },
    axisX: {
      title: "Date",
      interval: 1,
      intervalType: unit,
      valueFormatString: unit === "month" ? "MMMM YYYY" : unit === "day" ? "DDD MM/D" : "MMM D",
      labelFontSize: 18,
      titleFontSize: 24
    },
    axisY: {
      title: "Request Count",
      interval: yAxisInterval,
      indexLabel: "x:{x}, y:{y}",
      fontFamily: "Roboto",
      labelFontSize: 18,
      titleFontSize: 24
    },
    legend: {
      verticalAlign: "top",
      cursor: "pointer",
      fontFamily: "Roboto",
      fontSize: 20,
      itemclick: function (e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }

        e.chart.render();
      }
    },
    toolTip: {
      contentFormatter: function (e) {
        return `<b>${e.entries[0].dataPoint.y}</b> ${e.entries[0].dataSeries.name} request${
          e.entries[0].dataPoint.y > 1 ? "s" : ""
        }`;
      }
    },
    data: [
      {
        showInLegend: true,
        name: "Checkout",
        color: requestColors.Checkout,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Checkout")
      },
      {
        showInLegend: true,
        name: "OrderLink",
        color: requestColors.OrderLink,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("OrderLink")
      },
      {
        showInLegend: true,
        name: "Research",
        color: requestColors.Research,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Research")
      },
      {
        showInLegend: true,
        name: "Chat",
        color: requestColors.Chat,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Chat")
      },
      {
        showInLegend: true,
        name: "Email",
        color: requestColors.Email,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Contact")
      },
      {
        showInLegend: true,
        name: "Account",
        color: requestColors.Account,
        type: "stackedBar",
        fontSize: 12,
        xValueFormatString: xToolTipValueFormatString,
        click: dataClickHandler,
        dataPoints: getSamples("Account")
      },
      {
        showInLegend: false,
        name: "Nothing",
        color: "#FFFFFF",
        type: "stackedBar",
        indexLabel: "#total",
        indexLabelPlacement: "outside",
        indexLabelFontSize: 18,
        indexLabelFontStyle: "bold",
        dataPoints: getSamples("Nothing")
      }
    ]
  };

  const BuildUrl = csr => {
    var url = "/requests";
    if (csr && csr.trim()) {
      url += `?owner=${csr}`;
    }
    return url;
  };

  console.log("Length", data.length);
  return (
    <div className="metricspage">
     <b><span className="larger">Requests by:</span></b>
        {unit === "month" ? <span className="larger"> <b>Month</b> </span> : <Link to={`/requests/?unit=month&owner=${owner}`}> Month </Link>} |
        {unit === "week" ? <span className="larger"> <b>Week</b> </span> : <Link to={`/requests/?unit=week&owner=${owner}`}> Week </Link>} |
        {unit === "day" ? <span className="larger"> <b>Day</b> </span> : <Link to={`/requests/?unit=day&owner=${owner}`}> Day </Link>}
      <span style={{marginLeft:"2em"}}>
        <b>Owner</b>: <SelectCsr current={owner} onSelect={csr => props.history.push(BuildUrl(csr))} />
      </span>
      <br/>
      <span className="smaller"><Link to="/orders">Show Orders instead</Link></span>
      {initialized && <CanvasJSChart options={options} />}
    </div>
  );
}
