import useSecureFetch from "./useSecureFetch";
import AppSettings from "../../AppSettings";

function deNullify(a, b) {
  return a && b ? a.localeCompare(b) : a && !b ? -1 : !a && b ? 1 : 0;
}

function getCompareFunction(sortName) {
  switch (sortName.toLowerCase()) {
    case "request":
      // This case is special because we want to sort by RequestId within RequestType
      return (a, b) =>
        a.RequestType === b.RequestType ? deNullify(a.RequestId, b.RequestId) : deNullify(a.RequestType, b.RequestType);
    case "status":
      return (a, b) => deNullify(a.Status, b.Status);
    case "submitted":
      return (a, b) => (a.CreatedTime === b.CreatedTime ? 0 : a.CreatedTime > b.CreatedTime ? 1 : -1);
    case "updated":
      return (a, b) => (a.UpdatedTime === b.UpdatedTime ? 0 : a.UpdatedTime > b.UpdatedTime ? 1 : -1);
    // We need to handle the case of null CompletedTime
    case "completed":
      return (a, b) =>
        deNullify(a.CompletedTime, b.CompletedTime) ||
        (a.CompletedTime === b.CompletedTime ? 0 : a.CompletedTime > b.CompletedTime ? 1 : -1);
    case "chat":
      return (a, b) =>
        a.ChannelId === b.ChannelId ? deNullify(a.ChannelId, b.ChannelId) : deNullify(a.ChannelId, b.ChannelId);
    case "op":
      return (a, b) => {
        if (!a.Order && !b.Order) return 0;
        if (a.Order && !b.Order) return -1;
        if (!a.Order && b.Order) return 1;
        return a.Order.OrderId === b.Order.OrderId ? 0 : Number(a.Order.OrderId) > Number(b.Order.OrderId) ? 1 : -1;
      };
    case "total":
      return (a, b) => {
        if (!a.Order && !b.Order) return 0;
        if (a.Order && !b.Order) return 1;
        if (!a.Order && b.Order) return -1;
        return a.Order.Total === b.Order.Total ? 0 : Number(a.Order.Total) > Number(b.Order.Total) ? 1 : -1;
      };
    case "account":
      // This case is special because not all orders have an account.  Those go at the end
      // sorted by company name
      return (a, b) =>
        a.CustomerId || b.CustomerId ? deNullify(a.CustomerId, b.CustomerId) : deNullify(a.Company, b.Company);
    case "contact":
      return (a, b) => deNullify(a.Email, b.Email);
    case "updatedby":
      return (a, b) => deNullify(a.UpdatedBy, b.UpdatedBy);
    case "owner":
      return (a, b) => deNullify(a.Owner, b.Owner);
    default:
      // leave in "natural" order
      return (a, b) => 0;
  }
}

function combineCompareFunctions(primaryCompare, secondaryCompare, descending) {
  // Convert boolean into a multiplicative factor
  const direction = descending ? -1 : 1;

  // Define a function that will apply the primarySort, reversed if descending,
  // then apply secondarySort if records match on primarySort
  return (a, b) => {
    const primaryComparison = primaryCompare(a, b) * direction;
    return primaryComparison ? primaryComparison : secondaryCompare(a, b);
  };
}

function filterByChannel(list, chat) {
  return chat ? list.filter(entry => entry.ChannelId === chat) : list;
}
function orderFilter(orderType) {
  switch (orderType) {
    case "order":
      return e => e.Order && (e.Order.Status === "Committed" || e.Order.Status === "RMA");
    case "committed":
      return e => e.Order && e.Order.Status === "Committed";
    case "rma":
      return e => e.Order && e.Order.Status === "RMA";
    case "quote":
      return e => e.Order && e.Order.Status !== "Committed" && e.Order.Status !== "RMA";
    case "any":
      return e => e.Order && e.Order.Status;
    default:
      return e => true;
  }
}
function filterByOrder(list, orderType) {
  return orderType ? list.filter(orderFilter(orderType)) : list;
}
function filterRequests(list, channel, orderType) {
  return filterByOrder(filterByChannel(list, channel), orderType);
}

export default function useRequestReport(customerId, email, owner, status, type, chat, order, from, to, sort) {
  const hasArguments = customerId || email || owner || status || type || from || to;

  customerId = customerId ? customerId : "";
  email = email ? email : "";
  owner = owner ? owner : "";
  status = status ? status : "";
  type = type ? type : "";
  chat = chat ? chat : "";
  order = order ? order : "";
  from = from ? from : "07-01-19";
  to = to ? to : "12-31-20";
  var sortOrder = sort ? sort : "-requested";
  var descending = false;
  if (sortOrder.substr(0, 1) === "-") {
    descending = true;
    sortOrder = sortOrder.substr(1);
  }
  const primaryCompare = getCompareFunction(sortOrder);
  const secondaryCompare = (a, b) => (a.CreatedTime > b.CreatedTime ? -1 : 1);
  const compare = combineCompareFunctions(primaryCompare, secondaryCompare, descending);

  var url = `${AppSettings.RequestReport}`;
  if (hasArguments) {
    url += `?customerId=${customerId}&email=${email}&owner=${owner}&status=${status}&type=${type}&from=${from}&to=${to}`;
  }
  const result = useSecureFetch(url, null, window.location.href);
  // Sort results in descending CreatedTime
  if (result.response) {
    const filtered = filterRequests(result.response, chat, order);
    const sorted = filtered.sort(compare);
    result.response = sorted;
  }
  return result;
}
