import React, { useContext } from "react";
import styled from "styled-components";
import AppContext from "../../../context/AppContext";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoPage from "./InfoPage"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 300px;
  gap: 20px;
  max-width: 1000px;
`;

const Column1 = styled.div`
  max-width: 100%;
`;
const Column2 = styled.div`
  margin-top: 30px;
  max-width: clamp(250px, 20%, 500px);
  padding: 0px 20px;
  font-size: 14px;
  background-color: #fff8d6;
  color: #2e2e2e;
  border: #2e2e2e 1px solid;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
`;
const List = styled.ul`
  font-size: 13px;
  font-weight: 600;
  list-style-position: inside;
  list-style-type: circle;
  margin: 0 0 1rem 1rem;
  padding: 2px 0;
`;

const Item = styled.li`
  padding: 1px 0;
`;

const ReturnButton = styled.button`
  color: white;
  height: 24px;
  margin-left: 8px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;
const Caveat = styled.span`
  font-weight: normal;
  font-style: italic;
  font-size: 80%;
`;
const Icon = styled(FontAwesomeIcon)`
  margin-left: 0px;
  font-size: 80%;
`;
const Nexus = ({children}) => (
  <Popup
    trigger={
      <span>
        {children}<sup><Icon icon={["far", "circle-info"]} /></sup>
      </span>}
    on="hover"
    mouseLeaveDelay={500}
    mouseEnterDelay={100}
    offsetX={0}
    contentStyle={{ width: "20rem", orderRadius: "8px", padding: "8px 16px", fontSize: ".9rem", fontWeight: "100"}}
  >
    {close => <div>Sales tax nexus is the connection between
          a seller and a state that requires the seller to register then collect and remit sales tax
          in the state. Certain business activities, including having a physical presence or reaching
          a certain sales threshold, may establish nexus with the state.</div>}
  </Popup>
);

export default function SalesTax(props) {
  const context = useContext(AppContext);
  const { cartParts, goto } = context;

  return (
    <InfoPage>
      <Wrapper>
      <Column1>
        <h1>Sales Tax</h1>
        <p>Case Parts is required to collect sales tax on all orders shipped
          to states where we have nexus unless we have proper proof of your business' sales and use tax exemption.
        </p>
        <p>If you have an account with Case Parts and are tax-exempt, please email us
          at <a href="mailto:salestax@caseparts.com?subject=How do I submit exemption certificates?">salestax@caseparts.com</a> for
          instructions on uploading your exemption certificates to our system.
        </p>
        <p>If you would like to create an account, please contact us
          at <a href="mailto:accounting@caseparts.com?subject=I would like to create an account">accounting@caseparts.com</a> or
          call us between 7:30 AM and 5:00 PM Pacific Monday through Friday at (800) 421-0271 Prompt 3.
        </p>
        <h2>Important notes</h2>
        <p>You do not need a Case Parts account to place an order with us, but exemption certificates can only be associated with Case Parts accounts.</p>
        <p>We can only accept blanket exemption certificates. We can not apply an exemption certificate to a single order. We do not accept third party sales tax certificates.</p>
        <p>If you are mostly tax-exempt
          but occassionally need to place a taxable order, you can place that order as a guest user.</p>

        {cartParts.length > 0 && <ReturnButton onClick={() => goto("/Checkout")}>Continue Checkout</ReturnButton>}
      </Column1>
      <Column2>
      <h1><Nexus>Nexus States</Nexus></h1>
        <p>Case&nbsp;Parts&nbsp;Company collects sales tax on orders shipped to the following states:</p>
        <List>
          <Item>Alaska (AK) <Caveat>as of 11/1/2022</Caveat></Item>
          <Item>Arkansas (AR)</Item>
          <Item>Arizona	(AZ)</Item>
          <Item>California (CA)</Item>
          <Item>Connecticut (CT) <Caveat>as of 11/1/2022</Caveat></Item>
          <Item>Florida (FL)</Item>
          <Item>Georgia (GA)</Item>
          <Item>Hawaii (HI)</Item>
          <Item>Idaho	(ID)</Item>
          <Item>Illinois (IL)</Item>
          <Item>Indiana (IN)</Item>
          <Item>Iowa (IA)</Item>
          <Item>Kansas (KS)</Item>
          <Item>Kentucky (KY)</Item>
          <Item>Louisiana (LA)</Item>
          <Item>Maryland (MD)</Item>
          <Item>Massachusetts (MA)</Item>
          <Item>Michigan (MI)</Item>
          <Item>Minnesota (MN)</Item>
          <Item>Missouri (MO)</Item>
          <Item>Nebraska (NE)</Item>
          <Item>Nevada (NV)</Item>
          <Item>New Jersey (NJ)</Item>
          <Item>New York (NY)</Item>
          <Item>North Carolina (NC)</Item>
          <Item>Ohio (OH)</Item>
          <Item>Oklahoma (OK) <Caveat>as of 11/1/2022</Caveat></Item>
          <Item>Pennsylvania (PA)</Item>
          <Item>Rhode Island (RI)</Item>
          <Item>South Carolina (SC)</Item>
          <Item>South Dakota (SD)</Item>
          <Item>Tennessee (TN)</Item>
          <Item>Texas (TX) <Caveat>as of 11/1/2022</Caveat></Item>
          <Item>Utah (UT)</Item>
          <Item>Virginia (VA)</Item>
          <Item>Washington (WA)</Item>
          <Item>West Virginia (WV)</Item>
          <Item>Wisconsin (WI)</Item>
        </List>
      </Column2>
      </Wrapper>
    </InfoPage>
  );
}

