// Manages the state data associated with an Expando component and its related items
export default class ExpandoState {
  constructor(defaultState, toggled) {
    this._defaultState = defaultState;
    this._toggled = toggled ? toggled : new Set([]);
    this._activeIds = new Set([]);
  }

  // Methods that change state return a clone to trigger rerender
  Clone() {
    return new ExpandoState(this._defaultState, this._toggled);
  }

  // Toggle the expand/collapse status of the specfied item
  Toggle(id) {
    // Add or delete the item from the list of toggled items
    if (this._toggled.has(id)) {
      this._toggled.delete(id);
    } else {
      this._toggled.add(id);
    }

    if (this._activeIds.size === this._toggled.size && this._toggled !== 0) {
      console.log("All elements toggled, so invert default and clear toggled");
      this._defaultState = !this._defaultState;
      this._toggled = new Set([]);
    }
    return this.Clone();
  }

  // Make sure our set of toggled ids only includes active ids
  LimitToActiveSections(activeIds) {
    this._activeIds = activeIds;
    let currentIds = [...this._toggled];
    let inactive = currentIds.filter(id => !activeIds.has(id));
    if (inactive.length === 0) {
      return null;
    } else {
      inactive.forEach(id => this._toggled.delete(id));
      return this.Clone();
    }
  }

  SetDefault(defaultState) {
    // Whenever we update the default state, clear the list of toggled items
    this._defaultState = defaultState;
    this._toggled = new Set([]);
    return this.Clone();
  }

  IsExpanded(id) {
    // Item is expanded if either Expanded is default state and it's not toggled
    // or Collapsed is default state and it is toggled
    return this._toggled.has(id) ? !this._defaultState : this._defaultState;
  }

  CanCollapse() {
    // Collapsible unless Collapsed is the default state and nothing is toggled
    return this._toggled.size > 0 || this._defaultState;
  }

  CanExpand() {
    // Expandable unless Expanded is the default state and nothing is toggled
    return this._toggled.size > 0 || !this._defaultState;
  }
}
