import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import CheckoutPart from "./CheckoutPart";
import { Link } from "react-router-dom";
import LoginPrompt from "../components/LoginPrompt";
import isNumber from "../../data/isNumber";
import { calculateTotal } from "./OrderCalculations";
import useInventory from "../../requests/hooks/useInventory";

const Wrapper = styled.div`
  margin-left: 25px;
  margin-top: 8px;
  width: 385px;
  padding: 12px;
  background-color: #f5f6f8;
  border: solid 1px #d7d7d7;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
`;
const EditCart = styled.div`
  float: right;
  margin-top: 4px;
  padding: 4px;
  font-size: 14px;
  font-weight: bold;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: black;
  }
`;
const TotalLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: var(--black);
`;
const Total = styled.div`
  float: right;
  font-size: 18px;
  font-weight: bold;
  color: var(--black);
`;
const LightRule = styled.hr`
  margin-top: 12px;
  margin-bottom: 15px;
  width: 100%;
  height: 1px;
  color: var(--very-light-grey);
  background-color: var(--very-light-grey);
  border: none;
`;
const Label = styled.span`
  font-size: 14px;
  font-weight: normal;
`;
const Price = styled.span`
  float: right;
  font-size: 14px;
  font-weight: normal;
  vertical-align: text-bottom;
`;
const Annotation = styled.div`
  margin-top: -4px;
  margin-botton: 18px;
  padding-bottom: 12px;
  font-size: 12px;
  font-style: italic;
  font-weight: lighter;
`;

const Surcharge = ({ label, price, isLoading }) =>
  // eslint-disable-next-line eqeqeq
  price && price != 0 ? (
    <div style={{ marginBottom: "10px" }}>
      <Label>{label === "Sales Tax" ? <Link to='/SalesTax'>{label}</Link> : label}</Label>
      <Price>{isLoading ? <span style={{fontSize: "80%", fontStyle: "italic"}}>calculating...</span> : formatPrice(price)}</Price>
    </div>
  ) : (
    ""
  );

function formatPrice(price) {
  return isNumber(price) ? `$${Number.parseFloat(price).toFixed(2)}` : price;
}

export default function OrderSummary(props) {
  const context = useContext(AppContext);
  const { cartParts, updateNotes, token } = context;
  const shipping = props.shipping ? props.shipping : 0;
  const salesTax = props.salesTax ? props.salesTax : 0;
  const { showShipping, showSalesTax, totalPrice, hideTotal, totalLabel } = calculateTotal(
    cartParts,
    shipping,
    salesTax,
    props.shippingMethod,
    props.checkoutInfo && props.checkoutInfo.ShippingCustomerAccount
  );
  const shownPartIds = cartParts.map(part => part.partId);
  const inventory = useInventory(shownPartIds, token);

  const shippingCustomerAccount =
    props.checkoutInfo &&
    props.checkoutInfo.ShippingCustomerAccount &&
    props.shippingMethod &&
    props.shippingMethod.startsWith("UPS");
  const shippingNote = shippingCustomerAccount
    ? "Total cost excludes shipping which is billed directly to your UPS account"
    : showShipping && shipping === "TBD"
    ? "Total cost will include shipping cost"
    : "";
    const isLoading = props.isLoadingShipping;
  
  return (
    <Wrapper>
      <Link to="/cart">
        <EditCart>Edit your cart</EditCart>
      </Link>
      <Title>{props.isQuote ? "Quote" : "Order"} Summary</Title>
      <LoginPrompt location={props.location} />
      {cartParts.map((part, n) => (
        <CheckoutPart 
          part={part} 
          update={updateNotes} 
          inventory={inventory ? inventory[n] : null}
          key={n} 
          />
      ))}
      <LightRule />
      {hideTotal && "Price will be provided in email"}
      {!hideTotal && showSalesTax && <Surcharge label="Sales Tax" price={salesTax} isLoading={isLoading} />}
      {!hideTotal && showShipping && <Surcharge label="Shipping" price={shipping} isLoading={isLoading} />}
      {shippingNote && <Annotation>{shippingNote}</Annotation>}
      <EstimatedArrival isLoading={isLoading} shipMethod={props.shippingMethod} shippingEstimates={props.shippingEstimates} />
      {!hideTotal && (
        <>
          <Total>{totalPrice}</Total>
          <TotalLabel>{totalLabel}</TotalLabel>
        </>
      )}
    </Wrapper>
  );
}


function EstimatedArrival({ isLoading, shipMethod, shippingEstimates }) {
  const noRatesAvailable = !shippingEstimates || (shippingEstimates && !shippingEstimates.UPSRates)
  const notShipping = !shipMethod || (shipMethod && !shipMethod.includes("UPS"))

  if(notShipping) { 
    return null 
  }

  const partsInStock = shippingEstimates ? shippingEstimates.InStock : null
  const potentialSplit = shippingEstimates ? shippingEstimates.InStockWhse === "ALL" : null
  const map = {
    "UPS Ground" : "UPS Ground",
    "UPS Blue" : "UPS 2nd Day Air",
    "UPS Red": "UPS Next Day Air"
  }

  function getEstimatedArrival(){
    if(noRatesAvailable)
      return "TBD"

    const rate = shippingEstimates
    .UPSRates
    .find(c => c.Description === map[shipMethod])
    
    const arrivalDate = rate ? rate.ArrivalDate : null


    if(potentialSplit)
      return "TBD"

    if(partsInStock)
      return formatDate(arrivalDate)
    
    if(arrivalDate)
      return getDayDifference(arrivalDate)
      

    return "TBD"
  }

  return (
    <Surcharge 
      label={partsInStock ? 'Estimated Arrival Date' : 'Time in Transit'} 
      price={getEstimatedArrival()} 
      isLoading={isLoading} 
      />
  )
}

function getDayDifference(dateString){
  if(!dateString) return
  const date = new Date(dateString)
  const current= new Date()

  // Calculate the time difference in milliseconds
  const timeDiff = date.getTime() - current.getTime()

  // Convert the time difference to days
  const dayDiff = Math.ceil(timeDiff / (24 * 60 * 60 * 1000))

  return  `${dayDiff} Day${dayDiff > 1 ? 's' : ''}`
}

function formatDate(dateString) {
  if(!dateString) return
  const dateParts = dateString.split('-');
  const month = parseInt(dateParts[0], 10) - 1; // JavaScript months are zero-based
  const day = parseInt(dateParts[1], 10);

  const date = new Date(dateParts[2], month, day);
  const monthName = date.toLocaleString('en-US', { month: 'long' });

  return `${monthName} ${day}`;
}