import React from "react";
import Select from "react-select";

const options = [
  { label: "UPS Ground", value: "UPS Ground" },
  { label: "UPS Blue - 2nd Day Air", value: "UPS Blue" },
  { label: "UPS Red - Next Day Air", value: "UPS Red" },
  { label: "Will Call", value: "Will Call" },
  { label: "Contact me with other shipping options", value: "TBD" }
];

// doc: https://react-select.com/styles
const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "23px",
    "min-height": "23px",
    marginTop: "-5px"
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "36px",
    marginTop: "0px"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "36px"
  }),
  container: (provided, state) => ({
    ...provided,
    height: "34px",
    width: "380px",
    marginBottom: "32px"
  })
};

const ShippingMethod = ({ field, form }) => (
  <Select
    required
    isSearchable={false}
    isClearable={false}
    className="text-input"
    placeholder="Select Shipping Method"
    styles={customStyles}
    options={options}
    value={options.find(option => option.value === field.value)}
    defaultValue={{ label: field.value ? field.value : "UPS Ground", value: field.value ? field.value : "UPS Ground" }}
    onChange={option => form.setFieldValue(field.name, option ? option.value : "")}
    onBlur={() => form.setFieldTouched(field.name)}
  />
);

export default ShippingMethod;
