import React from "react";
import { Link } from "react-router-dom";
import Page from "../widgets/Page";
import ContactForm from "../../ui/checkout/ContactForm";
import AppSettings from "../../AppSettings";

export default function ShelfWizard({ title }) {
  return (
    <Page
      title="Wire Shelving"
      icon="Shelf"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <div className="flexContainer">
        <div className="customPartColumn">
          <h2>We offer replacements for virtually all wire shelves</h2>
          <p>
            We offer an extensive inventory of <Link to="/Page/Wire-Shelf-Index">wire shelving</Link>. If one of these
            meets your needs, we'll gladly take your order. But if you're not sure, let us lend a hand with the
            engineering.{" "}
          </p>
          <p>
            Please download and fill-in our{" "}
            <a href={`${AppSettings.PdfPath}/Wire Shelf Order Form.pdf`} target="_blank" rel="noopener noreferrer">
              Wire Shelf Order Form
            </a>{" "}
            and email it to us. Or, if it's more convenient, give us a call or fill in the form on this page with the
            relevant information.
          </p>
        </div>
        <div className="customPartColumn">
          <h2>Let us know your specifications and we'll get you a quote within one business day</h2>
          <ContactForm
            topic="Wire Shelving"
            messageLabel="Shelving Specifications"
            messageHints="Please enter specifications from the Wire&nbsp;Shelf&nbsp;Order&nbsp;Form"
          />
        </div>
      </div>
    </Page>
  );
}
