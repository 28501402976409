import React, { useContext, useState } from 'react'
import AppContext from '../../context/AppContext';
import Popup from "reactjs-popup";

const When = ({ prop, children }) => !prop ? null : <>{children}</>
const WhenDiv = ({ prop, className, children }) => !prop ? null : <div className={className}>{children}</div>

function ImpersonateInfo({ customer }) {
    const format = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    }

    const name = customer.FirstName ? `${format(customer.FirstName)} ${format(customer.LastName)}` : null
    const email = customer.UserName ? customer.UserName.toLowerCase() : ""
    const custID = customer.CustId
    const custName = customer.CustName

    const pre = classname => "impersonate__" + classname

    return (
        <div className={pre("info")}>
            <div>
                <img src="/images/building-gray.png" alt="" />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <When prop={custName}>
                        <strong>{custID}</strong><div style={{ marginTop: "8px" }}>{custName}</div>
                    </When>
                    <When prop={!custName}>
                        No company linked
                    </When>
                </div>
            </div>
            <WhenDiv prop={name}>
                <img src="/images/contact-gray.png" alt="" />{name}
            </WhenDiv>
            <WhenDiv prop={email}>
                <img src="/images/email-gray.png" alt="" />{email}
            </WhenDiv>
        </div>
    )
}

export default function CancelImpersonate() {
    const { claims, impersonate, token } = useContext(AppContext)
    const [error, setError] = useState() 

    const cancelImpersonate = () => {
        impersonate(token)
            .catch(err => setError(err.message))
    }

    const isImpersonating = !!claims.Employee && !!claims.Customer
    return (
        <When prop={isImpersonating} className="impersonate__cancel">
            <Popup
                trigger={
                    <img className="impersonate__cancel-icon" src="/images/maskicon.svg" alt="" />
                }
                on="hover"
                closeOnDocumentClick
                mouseLeaveDelay={500}
                mouseEnterDelay={100}
                contentStyle={{ width: "350px", border: "1px solid rgb(187, 187, 187)", borderRadius: "3px" }}
                arrow={true}
                position="bottom right"
            >
                {close => <div className="impersonate__tooltip">
                    <div className="impersonate__cancel-card-wrapper">
                        <ImpersonateInfo customer={claims.Customer} />
                    </div>
                    <div style={{ marginTop: '10px', width: "100%", marginInline: "auto" }}>
                        <WhenDiv prop={error} className="impersonate__error">
                            {error}
                        </WhenDiv>
                        <button className="impersonate__cancel-btn" onClick={cancelImpersonate}>
                            View As Me
                        </button>
                    </div>
                </div>
                }
            </Popup>
        </When>
    )
}
