import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import WarmerWireForm from "./WarmerWireForm";
import Page from "../widgets/Page";

export default function WireWizard(props) {
  const context = useContext(AppContext);
  const { addOrUpdatePartInCart, removeFromCart, discountLevel, cartParts, findPart } = context;

  const partId = props.match.params.part ? props.match.params.part.toUpperCase() : "";
  // When editing, the timestamp is passed as the partId parameter to uniquely identify the part
  const cartPart = cartParts.find(part => part.uniqueId === partId);
  const part = cartPart ? cartPart : {};

  const accessories = {
    tape: findPart("FT-007"),
    wide8: findPart("SOHC-100-8"),
    narrow8: findPart("SOHC-075-8"),
    wide4: findPart("SOHC-100-4"),
    narrow4: findPart("SOHC-075-4"),
    thermostat: findPart("3ELE-8")
  };

  // Tweak title because we trim 96" covers to 93" to save on shipping cost
  accessories.wide8.title = accessories.wide8.title.replace("96", "93");
  accessories.narrow8.title = accessories.narrow8.title.replace("96", "93");

  return (
    <Page
      title="Electrical Resistance Warmer Wire"
      icon="Wire"
      description="Our electrical resistance wires are individually made to your specifications and are engineered to keep walk-in door frames from freezing shut without the use of a thermostat."
    >
      <div className="flexContainer">
        <div className="wireIntro">
          These electrical resitance wires are individually made to your specifications. They are engineered to keep
          walk-in door frames from freezing shut without the use of a thermostat. They can also be used as anti-sweat
          heaters in various other applications. Each heater is supplied with 18" lead wires. Also, to facilitate
          installation we offer foil tape and snap-on heater covers.
        </div>
        <img className="wireImage" alt="warmer wire" src="/images/warmer-wire.png" />
      </div>
      <WarmerWireForm
        part={part}
        addOrUpdatePartInCart={addOrUpdatePartInCart}
        removeFromCart={removeFromCart}
        cartParts={cartParts}
        history={props.history}
        discountLevel={discountLevel}
        accessories={accessories}
      />
    </Page>
  );
}
