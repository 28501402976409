import useFetch from "./useFetch";
import { useContext } from "react";
import AppContext from "../../context/AppContext";

export default function useSecureFetch(url, options, dependencies, callback, disabled) {
  const context = useContext(AppContext);
  const { token } = context;

  // update options to include necessary headers
  if (!options) options = {};
  if (!options.headers) options.headers = {};
  options.headers["Content-Type"] = "application/json";
  options.headers["Authorization"] = `Bearer ${token}`;
  options.headers["ApiKey"] = process.env.REACT_APP_APIKEY

  // update dependencies to include token
  if (!dependencies) dependencies = [];
  if (!Array.isArray(dependencies)) dependencies = [dependencies];
  dependencies = [...dependencies, token];

  return useFetch(url, options, dependencies, callback, !token || disabled);
}
