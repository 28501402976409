import React from "react";
import Page from "./widgets/Page";

export default function CustomParts(props) {
  const Item = ({ itemName, image, title, description, children }) => (
    <div className="customItem" onClick={() => props.history.push(`/custom/${itemName}`)}>
      <img className="link" alt={title} src={`/images/custom${itemName}.png`} width="275" height="190" />
      {children}
    </div>
  );
  return (
    <Page
      title="Custom Parts"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <div className="flexContainer maxwidth1200">
        <Item itemName="Gasket">
          <h1>Gaskets</h1>
          <p>
            Magnetic and compression gaskets built to your specifications for walk-ins, drawers, and other applications.
          </p>
        </Item>
        <Item itemName="Wire">
          <h1>Warmer Wires</h1>
          <p>
            Electrical resistance warmer wires built to your specifications to ensure that freezers don't freeze shut or
            refrigerators to sweat condensation.
          </p>
        </Item>
        <Item itemName="Heater">
          <h1>Heaters</h1>
          <p>
            We offer an extensive selection of tubular and finned heaters, we also can alter or custom-build for your
            specific application.
          </p>
        </Item>
        <Item itemName="Door">
          <h1>Walk-In Doors</h1>
          <p>
            Replacement door and frame assemblies for virtually all walk-in doors, custom-made for your specific
            application.
          </p>
        </Item>
        <Item itemName="WireShelf">
          <h1>Wire Shelving</h1>
          <p>
            In addition to stocking replacement shelves for most manufacturers, we also can build wire shelves to your
            specific needs.
          </p>
        </Item>
        <Item itemName="Board">
          <h1>Cutting Boards</h1>
          <p>Commercial cutting boards cut to your exact specifications up to 120" x 60"</p>
        </Item>
      </div>
    </Page>
  );
}
