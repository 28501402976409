import React, { useRef } from "react";
import Chevron from "./Chevron";

import "./Accordion.css";

function Accordion(props) {
  const content = useRef(null);

  return (
    <div className="accordion__section">
      <button
        className={`accordion ${props.isActive ? "active" : ""} ${props.isError ? "error" : ""}`}
        onClick={props.toggle}
        type="button"
      >
        <svg height="50" width="40">
          <circle cx="15" cy="25" r="14" stroke={props.isError ? "red" : "#444"} strokeWidth="1" fill="white" />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            stroke={props.isError ? "red" : props.isActive ? "#000" : "#888"}
            fontSize="20px"
            dx="-5px"
            dy="7px"
          >
            {props.step}
          </text>
        </svg>
        <p className={`accordion__title ${props.isError ? " error" : ""}`}>
          {props.title}
          {props.isError ? (
            <small>
              <br />
              has errors or missing info
            </small>
          ) : (
            ""
          )}
        </p>
        <Chevron className={`accordion__icon${props.isActive ? " rotate" : ""}`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{
          maxHeight: props.isActive && content.current ? `${content.current.scrollHeight}px` : "0px"
        }}
        className="accordion__content"
      >
        <div className="accordion__text">{props.children}</div>
      </div>
    </div>
  );
}

export default Accordion;
