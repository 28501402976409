import React, { useContext, useState, useRef } from "react";
import AppContext from "../../context/AppContext";
import ResultWrapper from "../ResultWrapper";
import styled from "styled-components";
import "../requestManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Owner from "../widgets/Owner";
import RequestType from "../widgets/RequestType";
import Timestamp from "../widgets/Timestamp";
import SummaryMetrics from "../widgets/SummaryMetrics";
import useRequestDashboard from "../hooks/useRequestDashboard";

import moment from "moment";
import Popup from "reactjs-popup";
import { Time } from "../RequestFormatting";
import { FindEmployee } from "../data/GetStaffInfo";
//import AppSettings from "../../AppSettings";
import RequestEditor from "../widgets/RequestEditor";
import queryString from "query-string";
import RequestCommands from "../widgets/RequestCommands";

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-bottom: 0px;
  float: right;
  color: var(--CP-blue);
  cursor: pointer;
`;
const VisuallyHidden = styled.textarea`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

export default function RequestDashboard(props) {
  const context = useContext(AppContext);
  const { claims } = context;

  // Verify that this is an employee
  if (claims.UserType !== "Employee") {
    console.log(claims.UserType, "Not authorized");
    window.location = "/";
  }
  const employee = FindEmployee(claims.Employee.UserName);

  let params = queryString.parse(props.location.search);
  const filteredOwner = params.owner ? FindEmployee(params.owner) : null;

  // Set up filters to have lists of requests for each status
  const CreatedTimeAsc = (a, b) => moment(a.CreatedTime) - moment(b.CreatedTime);
  const UpdatedTimeDesc = (a, b) => moment(b.UpdatedTime) - moment(a.UpdatedTime);
  const CreateList = (status, sortOrder) => requests.filter(entry => entry.Status.startsWith(status)).sort(sortOrder);
  const sortByUpdatedTime = true;
  const sort = sortByUpdatedTime ? UpdatedTimeDesc : CreatedTimeAsc;
  const dashStatus = req => req.Status === "New"
    || req.Status === "Assigned"
    || req.Status === "Active"
    || req.Status.startsWith("Waiting")
    || req.Status === "Done";
  var result = useRequestDashboard();
  var requests = result.response
    ? result.response.filter(
      req =>
        dashStatus(req) &&
        (!filteredOwner || req.Status === "New" || filteredOwner === FindEmployee(req.Owner))
    )
    : [];
  const newRequests = CreateList("New", sort);
  const assignedRequests = CreateList("Assigned", sort);
  const activeRequests = CreateList("Active", sort);
  const waitingRequests = CreateList("Waiting", sort);
  const doneRequests = CreateList("Done", UpdatedTimeDesc);
  const myRequests = requests.filter(entry => entry.Status === "New" || FindEmployee(entry.Owner) === employee);
  const myDoneRequests = myRequests.filter(entry => entry.Status === "Done");

  const requestDetails = result.requestHistory;
  // Create sorted lists of cards for each column of the dashboard
  const selectedId = props.match.params.requestId ? props.match.params.requestId.toUpperCase() : "";
  if (selectedId !== result.requestId) {
    result.setRequestId(selectedId);
  }

  // If called with a selected requestId, get the associated request history
  const selectedRequest = selectedId && requests ? requests.find(request => request.RequestId === selectedId) : null;

  const getStatusColor = status => {
    switch (status) {
      case "New":
        return "requestNew";
      case "Assigned":
        return "requestAssigned";
      case "Active":
        return "requestActive";
      case "Done":
        return "requestDone";
      default:
        return "requestWaiting";
    }
  };

  // This is a visually hidden textarea control and a state variable we need
  // to allow us to copy text to the clipboard
  const [clipText, setClipText] = useState("");
  const textAreaRef = useRef(null);
  const HiddenField = () => {
    return <VisuallyHidden ref={textAreaRef} value={clipText} readOnly />;
  };
  function copyToClipboard(text) {
    setClipText(text);
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.select();
        document.execCommand("copy");
        console.log(`Copied: ${text}`);
      } else {
        console.log("textAreaRef is not set up");
      }
    }, 0);
  }

  const CardSummary = props => (
    <div
      className={`requestCard ${props.popup ? "requestCardPopup" : "requestCardNormal"} ${getStatusColor(
        props.entry.Status
      )}`}
    >
      <div>
        <Owner entry={props.entry} employee={employee} popup={props.popup} />
        <RequestType request={props.entry} status={props.status} copyToClipboard={copyToClipboard} />
      </div>
      <div className="requestInfo">
        <WaitReason entry={props.entry} />
        {props.entry.Name && <Line>{props.entry.Name}</Line>}
        {props.entry.Company && <Line>{props.entry.Company}</Line>}
        {props.entry.CustomerId && <Line>{props.entry.CustomerId}</Line>}
        {props.entry.Email && <Line>{props.entry.Email}</Line>}
        {props.entry.Phone && <Line>{props.entry.Phone}</Line>}
      </div>
      <div className="requestTimestamps">
        <Timestamp attribute="CreatedTime" time={props.entry.CreatedTime} />
        {props.entry.UpdatedTime !== props.entry.CreatedTime &&
          props.entry.UpdatedTime !== props.entry.CompletedTime && (
            <Timestamp attribute="UpdatedTime" time={props.entry.UpdatedTime} />
          )}
        <Timestamp attribute="CompletedTime" time={props.entry.CompletedTime} />
      </div>
      <div className="requestNote">{props.entry.Note}</div>
    </div>
  );

  // Each card of the kanban board
  const Card = props => (
    <div
      className="clickable"
      onClick={() => props.history.push(`/dash/${props.entry.RequestId}${window.location.search}`)}
    >
      <CardSummary entry={props.entry} />
    </div>
  );

  // Each column of the kanban board
  const List = props => (
    <div className="requestList">
      <h2>
        {props.title}
        {props.list.length > 0 ? ` (${props.list.length})` : ""}
      </h2>
      {props.list.map((entry, n) => (
        <Card history={props.history} key={entry.RequestId} entry={entry} />
      ))}
    </div>
  );

  // Overall page
  const Page = props => (
    <div className="page">
      <SummaryMetrics requests={requests} />
      <h1>
        {filteredOwner && (
          <span>
            {`${filteredOwner.fullname}'s Requests`}&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="/dash">Show All</a>
          </span>
        )}
        {!filteredOwner && (
          <span>
            ALL Customer Requests&nbsp;&nbsp;&nbsp;&nbsp;
            <a href={`/dash/?owner=${employee.username}`}>
              My Requests
              <span className="smalltext"> ({myRequests.length - newRequests.length - myDoneRequests.length})</span>
            </a>
          </span>
        )}
      </h1>
      {props.children}
      <HiddenField />
    </div>
  );

  const WaitReason = props => {
    const entry = props.entry;
    if (entry.Status.startsWith("Waiting")) {
      return <div className="requestWaitReason">{entry.Status}</div>;
    }
    return "";
  };
  // Include line of descriptive text, if truthy
  const Line = props => !props.children || <h4>{props.children}</h4>;
  // const TimeStamp = props;
  const returnUrl = `/dash${window.location.search}`;
  return (
    <ResultWrapper
      result={result}
      render={() => {
        return (
          <Page>
            <List history={props.history} title="NEW" list={newRequests} />
            <List history={props.history} title="ASSIGNED" list={assignedRequests} />
            <List history={props.history} title="ACTIVE" list={activeRequests} />
            <List history={props.history} title="WAITING" list={waitingRequests} />
            <List history={props.history} title="COMPLETED Today" list={doneRequests} />
            {selectedRequest && (
              <Popup open={true} modal closeOnDocumentClick onClose={() => props.history.push(returnUrl)}>
                {close => (
                  <div className="requestDetail">
                    <div>
                      <CardSummary entry={selectedRequest} status={selectedRequest.Status} popup={true} />
                    </div>
                    <RequestCommands employee={employee} entry={selectedRequest} />
                    <Icon icon={["far", "window-close"]} onClick={close} />
                    <div>
                      <RequestEditor
                        history={props.history}
                        request={selectedRequest}
                        employee={employee}
                        returnUrl={returnUrl}
                      />
                    </div>
                    {requestDetails && (
                      <table className="subtleTable">
                        <thead>
                          <tr>
                            <th>History</th>
                            <th>Status</th>
                            <th>Owner</th>
                            <th>Comment</th>
                            <th>By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requestDetails
                            .sort((a, b) => moment(a.UpdatedTime) - moment(b.UpdatedTime))
                            .map((entry, n) => (
                              <tr key={n}>
                                <td>{Time(entry.UpdatedTime)}</td>
                                <td>{entry.Status}</td>
                                <td>
                                  <a className="requestLink" href={`/dash/?owner=${entry.Owner}`}>
                                    {FindEmployee(entry.Owner).shortname}
                                  </a>
                                </td>
                                <td>{entry.Note}</td>
                                <td>{FindEmployee(entry.UpdatedBy).shortname}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </Popup>
            )}
          </Page>
        );
      }}
    />
  );
}
