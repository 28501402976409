import React from "react";
import { formatFraction } from "./dimensions";

function calcDimensions(width, height) {
  const aspectRatio = width / height;
  const minAspectRatio = 0.5;
  const maxAspectRatio = 2;
  const constrainedHeight =
    aspectRatio < minAspectRatio
      ? width / minAspectRatio
      : aspectRatio > maxAspectRatio
      ? width / maxAspectRatio
      : height;
  const maxDim = width > constrainedHeight ? width : constrainedHeight;
  const index = maxDim < 31 ? 0 : maxDim < 51 ? 1 : maxDim < 71 ? 2 : 3;
  const minorDivisions = [6, 4, 3, 2];
  const minorDivision = minorDivisions[index];
  const border = width * 0.2;

  return {
    realHeight: height,
    vertScaling: height / constrainedHeight,
    width: width,
    height: constrainedHeight,
    xMin: -border,
    yMin: -border,
    xMax: Number(width) + border,
    yMax: Number(constrainedHeight) + border,
    yRealMax: Number(height) + border,
    majorDivisions: 12,
    minorDivisions: minorDivision,
    offset: border
  };
}

function DrawGridLines(xMin, yMin, xMax, yMax, spacing, offset, vertScaling, style) {
  const width = xMax - xMin;
  const height = yMax - yMin;
  const horizontalCount = (Math.ceil(width / 12) * 12) / spacing;
  const verticalCount = (Math.ceil(height / 12) * 12) / spacing;
  const fudge = 12; // ensure we display enough vertical lines

  return (
    <>
      {[...Array(horizontalCount + 1)].map((e, n) => (
        <line
          key={`v${n}`}
          x1={xMin + n * spacing + offset - 12}
          y1={yMin}
          x2={xMin + n * spacing + offset - 12}
          y2={height / vertScaling + fudge}
          style={style}
        />
      ))}
      {[...Array(verticalCount * +fudge)].map((e, n) => (
        <line
          key={`h${n}`}
          x1={xMin}
          y1={(yMin + n * spacing + offset - 12) / vertScaling}
          x2={xMax - xMin}
          y2={(yMin + n * spacing + offset - 12) / vertScaling}
          style={style}
        />
      ))}
    </>
  );
}

function DrawGrid({ xMin, yMin, xMax, yMax, yRealMax, majorDivisions, minorDivisions, offset, vertScaling }) {
  return (
    <>
      <rect width={xMax - xMin} height={yMax - yMin} x={xMin} y={yMin} fill="white" strokeWidth="0" />
      {DrawGridLines(xMin, yMin, xMax, yRealMax, majorDivisions / minorDivisions, offset, vertScaling, {
        stroke: "#d8d8d8",
        strokeWidth: 1,
        vectorEffect: "non-scaling-stroke"
      })}
      {DrawGridLines(xMin, yMin, xMax, yRealMax, majorDivisions, offset, vertScaling, {
        stroke: "#c8c8c8",
        strokeWidth: 2,
        vectorEffect: "non-scaling-stroke"
      })}
    </>
  );
}

const defineGradient = (
  <defs>
    <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(-10)">
      <stop offset="24%" style={{ stopColor: "#000000", stopOpacity: 0.06 }} />
      <stop offset="88%" style={{ stopColor: "#D8DDEB", stopOpacity: 0.8 }} />
    </linearGradient>
  </defs>
);

function DrawDoor({ width, height, xMin, yMin, xMax, yMax }) {
  return (
    <>
      {defineGradient}
      <rect width={width} height={height} x={0} y={0} fill="#D8DDEB" />
      <rect width={width} height={height} x={0} y={0} fill="url(#grad1)" />
      <rect
        width={width}
        height={height}
        x={0}
        y={0}
        fill="none"
        stroke="#383838"
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        width={xMax - xMin}
        height={yMax - yMin}
        x={xMin}
        y={yMin}
        fill="none"
        stroke="#000000"
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
      />
    </>
  );
}

function DrawGasket({ width, height, dartToDart, threeSided, noMagnet }) {
  const solid = {
    stroke: "black",
    strokeWidth: "4",
    vectorEffect: "non-scaling-stroke"
  };
  const dashed = { ...solid, strokeDasharray: "8 2 " };
  const dartToDartNudge = 0.02;
  const maxDim = width > height ? width : height;
  const zero = dartToDart ? maxDim * dartToDartNudge : 0;

  return (
    <>
      <line x1={zero} y1={height - zero} x2={zero} y2={zero} style={noMagnet === "Left" ? dashed : solid} />
      <line x1={zero} y1={zero} x2={width - zero} y2={zero} style={solid} />
      <line
        x1={width - zero}
        y1={zero}
        x2={width - zero}
        y2={height - zero}
        style={noMagnet === "Right" ? dashed : solid}
      />
      {!threeSided && <line x1={width - zero} y1={height - zero} x2={zero} y2={height - zero} style={solid} />}
    </>
  );
}

function DrawMeasurement({ text, position, width, height, xMin, yMin, dartToDart }) {
  const textNudge = 0.7;
  const lineNudge = 0.5;
  const dartToDartNudge = 0.02;
  const maxDim = width > height ? width : height;
  var textX, textY, lineX, lineY, xExtent, yExtent, angle;
  var adjustment = dartToDart ? maxDim * dartToDartNudge : 0;

  switch (position) {
    case "left":
      textX = xMin * textNudge;
      textY = height / 2;
      lineX = xMin * lineNudge;
      lineY = height / 2;
      angle = 90;
      xExtent = 0;
      yExtent = height / 2 - adjustment;
      break;
    case "right":
      textX = width - xMin * textNudge;
      textY = height / 2;
      lineX = width - xMin * lineNudge;
      lineY = height / 2;
      angle = 90;
      xExtent = 0;
      yExtent = height / 2 - adjustment;
      break;
    case "top":
      textX = width / 2;
      textY = yMin * textNudge;
      lineX = width / 2;
      lineY = yMin * lineNudge;
      angle = 0;
      xExtent = width / 2 - adjustment;
      yExtent = 0;
      break;
    case "bottom":
      textX = width / 2;
      textY = height - yMin * textNudge;
      lineX = width / 2;
      lineY = height - yMin * lineNudge;
      angle = 0;
      xExtent = width / 2 - adjustment;
      yExtent = 0;
      break;
    default:
      break;
  }
  const thin = {
    stroke: "#219ADB",
    strokeWidth: "1",
    vectorEffect: "non-scaling-stroke"
  };
  const label = {
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: width / 18,
    fontWeight: "bold",
    textAnchor: "middle"
  };
  return (
    <>
      <line
        x1={lineX - xExtent}
        y1={lineY - yExtent}
        x2={lineX + xExtent}
        y2={lineY + yExtent}
        style={thin}
        markerStart="url(#markerStart)"
        markerEnd="url(#markerArrow)"
      />
      <text
        x={textX}
        y={textY}
        style={label}
        textAnchor="middle"
        dominantBaseline="central"
        transform={`rotate(${angle}, ${textX}, ${textY})`}
      >
        {text}
      </text>
    </>
  );
}

function DrawNote({ position, width, height, xMin, yMin, children }) {
  const nudge = 0.25;
  var textX, textY, angle;

  switch (position) {
    case "left":
      textX = xMin * nudge;
      textY = height / 2;
      angle = 90;
      break;
    case "right":
      textX = width - xMin * nudge;
      textY = height / 2;
      angle = 90;
      break;
    case "top":
      textX = width / 2;
      textY = yMin * nudge;
      angle = 0;
      break;
    case "bottom":
      textX = width / 2;
      textY = height - yMin * nudge;
      angle = 0;
      break;
    default:
      // center
      textX = width / 2;
      textY = height / 2;
      angle = 0;
      break;
  }
  const note = {
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: width * 0.045,
    fontStyle: "italic",
    textAnchor: "middle",
    fill: "#404040"
  };
  const blueNote = { ...note, fill: "#219ADB" };

  return (
    <>
      <text
        x={textX}
        y={textY}
        style={position === "center" ? note : blueNote}
        textAnchor="middle"
        dominantBaseline="central"
        transform={`rotate(${angle}, ${textX}, ${textY})`}
      >
        {children}
      </text>
    </>
  );
}

function DrawAnnotations({ width, height, realHeight, xMin, yMin, dartToDart, threeSided, noMagnet }) {
  return (
    <>
      <defs>
        <marker id="markerArrow" markerWidth="13" markerHeight="13" refX="11" refY="6" orient="auto">
          <path d="M2,2 L2,11 L10,6 L2,2" fill="#219ADB" />
          <path d="M11,0 L11,13 L13,13 L13,0 Z" fill="#219ADB" />
        </marker>
        <marker id="markerStart" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
          <path d="M11,2 L11,11 L3,6 L11,2" fill="#219ADB" />
          <path d="M0,0 L0,13 L2,13 L2,0 Z" fill="#219ADB" />
        </marker>
      </defs>
      <DrawMeasurement
        text={formatFraction(width)}
        position="top"
        width={width}
        height={height}
        xMin={xMin}
        yMin={yMin}
        dartToDart={dartToDart}
      />
      <DrawMeasurement
        text={formatFraction(realHeight)}
        position={noMagnet === "Left" ? "right" : "left"}
        width={width}
        height={height}
        xMin={xMin}
        yMin={yMin}
        dartToDart={dartToDart}
      />
      {threeSided && (
        <DrawNote position="bottom" width={width} height={height} xMin={xMin} yMin={yMin}>
          NO GASKET along bottom edge
        </DrawNote>
      )}
      {noMagnet === "Left" && (
        <DrawNote position="left" width={width} height={height} xMin={xMin} yMin={yMin}>
          NO MAGNET along this side
        </DrawNote>
      )}
      {noMagnet === "Right" && (
        <DrawNote position="right" width={width} height={height} xMin={xMin} yMin={yMin}>
          NO MAGNET along this side
        </DrawNote>
      )}
      {dartToDart && (
        <>
          <DrawNote position="top" width={width} height={height} xMin={xMin} yMin={yMin}>
            dart-to-dart
          </DrawNote>
          <DrawNote
            position={noMagnet === "Left" ? "right" : "left"}
            width={width}
            height={height}
            xMin={xMin}
            yMin={yMin}
          >
            dart-to-dart
          </DrawNote>
        </>
      )}
      {realHeight !== height && (
        <DrawNote position="center" width={width} height={height} xMin={xMin} yMin={yMin}>
          <tspan x={width / 2} dy="0em" textAnchor="middle" style={{ fontWeight: "bold" }}>
            Not to scale:
          </tspan>
          <tspan x={width / 2} dy="1.5em" textAnchor="middle">
            Your gasket will be {realHeight > height ? "taller" : "wider"} than shown
          </tspan>
        </DrawNote>
      )}
    </>
  );
}

export default function GasketPreview({ width, height, dartToDart, sides, noMagnet }) {
  const dim = calcDimensions(width, height);
  const totalWidth = dim.xMax - dim.xMin;
  const totalHeight = dim.yMax - dim.yMin;
  const threeSided = Number(sides) === 3;
  return (
    <div className="gasketPreview">
      <svg viewBox={`${dim.xMin} ${dim.yMin} ${totalWidth} ${totalHeight}`}>
        {DrawGrid(dim)}
        {DrawDoor(dim)}
        {sides &&
          DrawGasket({
            ...dim,
            dartToDart: dartToDart,
            threeSided: threeSided,
            noMagnet: noMagnet
          })}
        {DrawAnnotations({
          ...dim,
          dartToDart: dartToDart,
          threeSided: threeSided,
          noMagnet: noMagnet
        })}
      </svg>
    </div>
  );
}
