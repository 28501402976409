import useStorage from "./useStorage";

export default function useLocalStorage(key, initialValue) {
  const [storedValue, _setValue] = useStorage(window.localStorage, key, initialValue)

  const setValue = (value) => {
    try {
      _setValue(value)
    } catch (e) {
      console.log("ERROR in useLocalStorage.setVaue", key, e)
    }
  };

  return [storedValue, setValue]
}
