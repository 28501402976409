import React, { useContext } from "react";
import AppContext from "../../../context/AppContext";
import InfoPage from "./InfoPage"
import FaqEntry from "./FaqEntry"
//import { Link } from "react-router-dom";

export default function Cpc(props) {
    const { claims } = useContext(AppContext)
    const isEmployee = claims.UserType === "Employee"

    // Redirect non-employees to faq
    if (!isEmployee) {
        window.location = "/faq"
    }

    return <InfoPage className="faq">
        <h1>CPC Employee FAQ (Internal Use Only)</h1>
        <h2>On Being a Slacker</h2>
        <FaqEntry history={props.history} title="What is Slack?">
            <p>
                Slack is a tool we use at Case Parts to work more efficiently as a team. It’s better than email or phone for much of our internal communication and is tightly integrated with our software and business processes.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="What makes Slack better than Email?">
            <p>
                Email is fine for external communications and also for some internal communications such as big announcements. But for most of our internal conversations, Slack is better because:
            </p>
            <ul>
                <li><b>Messages are sent to channels rather than distribution lists.</b> This allows you the freedom to subscribe just to the channels you are interested in and with the notification settings that match your level of interest.</li>
                <li><b>Messages are a real-time conversation.</b> Notifications are sent immediately as messages are posted facilitating quick replies and keeping everyone informed without swamping their inbox.</li>
                <li><b>Messages can be <a href="https://slack.com/help/articles/115000769927-Use-threads-to-organize-discussions-">threaded</a></b> making it easy to reply to comments earlier in the conversation.</li>
                <li><b>You can <a href="https://slack.com/help/articles/202395258-Edit-or-delete-messages">edit or delete</a> your responses</b> if you want.</li>
            </ul>
        </FaqEntry>
        <FaqEntry history={props.history} title="What makes Slack Huddles better than Phone?">
            <p>
                If you have headset on your computer, Slack has a voice feature that has several advantages over phone for talking with your coworkers:
            </p>
            <ul>
                <li><b>It’s integrated with Slack.</b> To talk to one person, initiate the huddle from your DM conversation with them.  To allow everyone in a channel the opportunity to join, initiate the huddle from the channel.</li>
                <li><b>Screen sharing</b>. It’s easy to share your screen with others in a huddle by clicking the <span><img src="/images/info/ScreenShare.png" alt="" /></span> icon.</li>
            </ul>
        </FaqEntry>
        <FaqEntry history={props.history} title="What channels should I subscribe to?">
            <ul>
                <li><a href="https://caseparts.slack.com/archives/C4D188RLL">#general</a>: Information and jokes of interest to all at Case Parts</li>
                <li><a href="https://caseparts.slack.com/archives/CKWR9AAKH">#sales</a>:  The goto place for CSRs to ask questions and share info with other CSRs</li>
                <li><a href="https://caseparts.slack.com/archives/CLELDJ7GB">#customer-chats</a>: Live chats with customers</li>
                <li><a href="https://caseparts.slack.com/archives/C01RQFHS3BK">#customer-text-mpk</a>, <a href="https://caseparts.slack.com/archives/C01QX4LTJ8P">#customer-text-sea</a>, <a href="https://caseparts.slack.com/archives/C01RC3PHPR9">#customer-text-stl</a>: SMS chats with customers associated with the phone numbers for each branch</li>
                <li><a href="https://caseparts.slack.com/archives/CLX4XA600">#customer-dashboard</a>: Events related to web orders</li>
                <li><a href="https://caseparts.slack.com/archives/CQUC95QF9">#software</a>:  The fastest, easiest way to ask questions, report problems, or provide feedback to Danny, Victor, Dom, and Jay</li>
                <li><a href="https://caseparts.slack.com/archives/CQZUXSGTW">#accounts-receivable</a>: instant access to the entire A/R team</li>
            </ul>
        </FaqEntry>
        <FaqEntry history={props.history} title="What are the best Slack notification settings?">
            <p>
                If you’re unfamiliar with Slack notifications, check out <a href="https://www.youtube.com/watch?v=wuyWwzazef8">this video</a> and/or read this <a href="https://slack.com/help/categories/360000047906#adjust-your-notifications">documentation</a>.
            </p><p>
                Basically, you can tailor notifications by right-clicking on any channel and selecting <b>Change notifications</b>.
            </p><img style={{ "float": "right" }} src="/images/info/NotificationSettings.png" alt="" />
            <p>
                For most channels, you’ll want your notifications as shown.
            </p><p>
                For channels like #software and #accounts-receivable that you use infrequently, you can set notifications to <b>Nothing</b>. The channel name will still turn bold in the left panel if there are new messages you haven’t seen, but you won’t get notifications.
            </p><p>
                For channels that you monitor regularly, like the #customer-text-xxx channel for your branch, you might prefer to get a notification on <b>All new messages</b>.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Where can I find more information about Slack?">
            <p>
                The Slack website provides excellent generation information on my topics, including:
            </p>
            <ul>
                <li><a href="https://slack.com/help/articles/115004071768-What-is-Slack-">What is Slack?</a></li>
                <li><a href="https://slack.com/help/articles/360017938993-What-is-a-channel">What is a channel?</a></li>
                <li><a href="https://slack.com/help/articles/212281468-Understand-direct-messages">What are Direct Messages?</a></li>
                <li><a href="https://slack.com/help/categories/200111606-Using-Slack#format-style-messages">How to format and style messages?</a></li>
                <li><a href="https://slack.com/help/articles/216771908-Make-calls-in-Slack#start-a-call">How to start a “huddle” call in Slack?</a></li>
            </ul>
        </FaqEntry>
    </InfoPage>
}