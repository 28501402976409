import AppSettings from "../AppSettings";

async function LoadBlock(path, account, token) {
  let url = encodeURI(AppSettings.BlockRequest(path));
  if (account) {
    url += `?account=${account}`;
  }
  try {
    if (!path) {
      throw Error("Missing block path");
    }
    console.log(`Fetching ${url}...`, token);
    const options = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token
      }
    }
    const response = await fetch(url, options);
    const data = await response.json();
    //console.log(`Fetched ${path}`);

    // Clean-up the html to properly load images
    data.Html = data.Html.replace(/http...develop.caseparts.com/g, AppSettings.ImageService);

    return data.Html;
  } catch (error) {
    const errorMessage = `Fetch block failed for ${url}: ${error}`;
    throw errorMessage;
  }
}

export { LoadBlock };
