import React, { useState, useContext } from "react";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import useRequestStatus from "../hooks/useRequestStatus";
import PutRequest from "../PutRequest";
import ResultWrapper from "../ResultWrapper";
import "../../ui/styling/styles.css";
import "../requestManager.css";
import { Time, Status } from "../RequestFormatting";
import Breadcrumb from "../../ui/navigation/Breadcrumb";
import { FindEmailByRequestId, FindEmailByEmailAddress, FindEmployee, IsAuthorized } from "../data/GetStaffInfo";

export default function RequestStatus({ match }) {
  const context = useContext(AppContext);
  const { account, token } = context;
  const employee = FindEmployee(account);

  // First, we need to authenticate
  if (!IsAuthorized(employee)) {
    window.location = "/";
  }

  const requestId = match.params.requestId;
  const result = useRequestStatus(requestId);
  const Format = (current, requestHistory) => {
    const [status, setStatus] = useState(current.Status);
    const [owner, setOwner] = useState(current.Owner);
    const [note, setNote] = useState("");
    const [error, setError] = useState("");

    const id = current.RequestId;
    const version = requestHistory.length;
    const name = current.Name;
    const email = current.Email;
    const customer = current.CustomerId;
    var who = `${name} (${email})${customer ? ` of ${customer}` : ""}`;
    const responseHandler = response => {
      console.log("RESPONSE: ", response);
      const status = response ? response.status : -1;
      if (status === 409) {
        setError(`Please Try Again!`);
      } else if (status < 0) {
        setError("Unexpected system error");
      } else if (status < 300) {
        console.log("Closing popup");
        //window.location = "/dash";
      } else {
        setError(response.statusText);
      }
    };
    const submitHandler = event => {
      //event.preventDefault();
      PutRequest(responseHandler, id, version, "System", status, owner, note, token);
    };

    // The condition about jay.cincotta@gmail.com is for my convenience testing because my email routes to
    // my default account and searching that by email address takes forever since it matches everything
    const searchGmail =
      email === "jay.cincotta@gmail.com" || !email
        ? FindEmailByRequestId(employee, requestId)
        : FindEmailByEmailAddress(employee, current.Email);

    return (
      <>
        {error && <div className="errorbox">{error}</div>}
        <h2>For {who}</h2>
        <table className="subtleTable">
          <thead>
            <tr>
              <th>Local Time</th>
              <th>Status</th>
              <th>Owner</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {requestHistory.map((entry, n) => (
              <tr key={n}>
                <td>{Time(entry.UpdatedTime)}</td>
                <td>{Status(entry)}</td>
                <td>
                  <Link className="requestLink" to={`/requests/?owner=${entry.Owner}`}>
                    {entry.Owner}
                  </Link>
                </td>
                <td>{entry.Note}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <form onSubmit={submitHandler}>
          <label htmlFor="status">Status:</label>
          <select id="status" defaultValue={status} onChange={e => setStatus(e.target.value)} autoFocus>
            <option value="New">New</option>
            <option value="Assigned">Assigned</option>
            <option value="Active">Active</option>
            <option value="Waiting on Customer">Waiting on Customer</option>
            <option value="Waiting on Vendor">Waiting on Vendor</option>
            <option value="Deleted">Deleted</option>
            <option value="Done">Done</option>
          </select>
          <label htmlFor="owner">Owner:</label>
          <select id="owner" defaultValue={owner} onChange={e => setOwner(e.target.value)}>
            <option value=""></option>
            <option value="bobg">Bob Goldstein</option>
            <option value="chrish">Chris Hall</option>
            <option value="haroldh">Harold Hurtado</option>
            <option value="jakeW">Jake Wagner</option>
            <option value="jayc">Jay Cincotta</option>
            <option value="joAnnar">JoAnna Romeo</option>
            <option value="jonathanh">Jonathan Hurtado</option>
            <option value="jonj">Jon Jilg</option>
            <option value="mackenzieh">Mackenzie Huntsman</option>
            <option value="michaelj">Michael Jilg</option>
            <option value="victorl">Victor Lopez</option>
          </select>
          <label htmlFor="note">Comment:</label>
          <input id="note" type="text" defaultValue="" onChange={e => setNote(e.target.value)} />
          <button type="submit">Update</button>{" "}
        </form>
        <a href={searchGmail} target="gmail">
          Find Related Email
        </a>
      </>
    );
  };

  return (
    <ResultWrapper
      result={result}
      render={() => {
        const history = result.response;
        const current = history[0];

        return (
          <div className="page">
            <h1>
              {String(current.RequestType).toUpperCase()} Request {current.RequestId}
              <Breadcrumb title="Dashboard" path="/dash" />
              <Breadcrumb title="Report" path="/requests" />
              <Breadcrumb title="Return" />
            </h1>
            {Format(current, history)}
          </div>
        );
      }}
    />
  );
}
