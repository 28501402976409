import React from "react";
import Popup from "reactjs-popup";
import "../requestManager.css";
import { FindEmployee, IsAuthorized } from "../data/GetStaffInfo";
import RequestCommands from "./RequestCommands";

export default function Owner({ employee, entry, popup }) {
  // This method is designed to always return a non-null value
  const owner = FindEmployee(entry.Owner);
  const Info = () => (
    <div className="requestOwner">
      <img alt={owner.fullname} height="36" width="36" src={owner.avatar} />
      <div className="ownerName">
        {owner.shortname}
        <br />
        <span className="ownerBranch">{owner.branch}</span>
      </div>
    </div>
  );

  return popup || !IsAuthorized(employee) ? (
    <Info />
  ) : (
    <Popup
      trigger={
        <div>
          <Info />
        </div>
      }
      on="hover"
      closeOnDocumentClick
      mouseLeaveDelay={500}
      mouseEnterDelay={100}
      arrow={false}
      position="right top"
    >
      {close => <RequestCommands employee={employee} entry={entry} close={close} />}
    </Popup>
  );
}
