import React from "react";
import CheckoutNote from "./CheckoutNote";

export default function ShippingTime({ shippingMethod }) {
  const isRed = shippingMethod.startsWith("UPS Red");
  const isBlue = shippingMethod.startsWith("UPS Blue");
  const leadSentence = "Orders placed for in-stock items during business hours are usually shipped the same day ";
  const redText = "and delivered the next business day.";
  const blueText = "and delivered two business days later.";

  if (isRed) {
    return (
      <CheckoutNote icon="info-circle" title="UPS Red Orders">
        {leadSentence} {redText}
      </CheckoutNote>
    );
  }

  if (isBlue) {
    return (
      <CheckoutNote icon="info-circle" title="UPS Blue Orders">
        {leadSentence} {blueText}
      </CheckoutNote>
    );
  }

  // No note needed for other shipping methods
  return "";
}
