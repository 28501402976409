import React from "react"
import InfoPage from "./InfoPage"
import { Link } from "react-router-dom";

export default function Terms() {
    return <InfoPage>
        <h1>Terms of Use</h1>
        <h2>Ownership of the Web site</h2>
        <p>
            All pages within the Case Parts website (the "Web site") and any material made available for download are the property of Celsius Joint Venture dba Case Parts Company (“Case Parts” or “we”). This Web site is protected by United States and international copyright and trademark laws. The Contents of the Web site, including without limitation the files, documents, text, photographs, images, audio, and video, and any materials accessed through or made available for use or download through this Web site ("Content") may not be copied, distributed, modified, reproduced, published or used, in whole or in part, except for purposes of authorized or approved in writing by Case Parts. You may not frame or utilize framing techniques to enclose, or deep linking to, any name, trademarks, service marks, logo, Content or other proprietary information (including images, text, page layout, or form) of Case Parts without our express written consent. Additionally, you may not use manual or automated software, devices, scripts, robots, spiders, other means or processes to access, "scrape", "crawl", retrieve, or index any portion of the Web site or the Content.
        </p>
        <h2>Submissions, Reviews, Feedback and other Postings to the Web site</h2>
        <p>
            If you submit, upload or post any comments, ideas, suggestions, information, files, images or other materials to us or our Web site including email, live chat, and text messages ("Submissions"), you agree that Case Parts shall have a royalty-free, irrevocable, transferable right and license to use the Submissions however Case Parts desires, including without limitation, to copy, modify, delete in its entirety, adapt, publish, translate, create derivative works from and/or sell and/or distribute such Submissions and/or incorporate such Submissions into any form, medium or technology throughout the world. Case Parts is and shall be under no obligation (1) to maintain any Submissions in confidence; (2) to pay to you any compensation for any Submissions; or (3) to respond to any user Submissions. Case Parts does not regularly review Submissions, but does reserve the right (but not the obligation) to monitor and edit or remove any Submissions submitted to the Web site. You are and shall remain solely responsible for the content of any Submissions. Case Parts and its affiliates take no responsibility and assume no liability for any Submissions submitted by you or any third party, and you agree to defend, indemnify and hold Case Parts harmless from and against all third party claims, damages and expenses (including reasonable attorneys fees) against or incurred by Case Parts arising out of any Submissions.
        </p>
        <h2>Pricing</h2>
        <p>
            Prices listed do not include freight, handling fees, taxes, or duties, and are subject to correction or change without notices.  Anonymous users are shown list price and authenticated users with trade accounts are shown discounted prices associated with their account.
        </p>
        <h2>Sales Tax</h2>
        <p>
            You are responsible for all applicable taxes or for providing valid sales tax exemption certificates.
        </p>
        <h2>Payment Terms</h2>
        <p>
            Case Parts accepts Visa, MasterCard, American Express, and Discover. If you have an established Case Parts open account, checks are also accepted and payment terms are net 30 days from the date of shipment or pick-up. If you fail to make payment within 30 days, Case Parts may defer shipments until such payment is made, or may, at its option, cancel all or any part of unshipped orders. Delinquent balances over 30-days will accrue interest at a rate of 1.50% per month. Purchaser agrees to pay the cost of any and all expenses incurred by Case Parts to collect past due sums, including but not limited to legal, collection and court costs.
        </p>
        <h2>Shipping Charges</h2>
        <p>
            Products are shipped F.O.B. shipping point with freight costs and handling fees charged to you. COD shipments are not permitted. Other terms and conditions may apply to special orders including freight collect, export orders, hazardous materials, etc. If product is damaged in transit, you must file a claim with the carrier.
        </p>
        <h2>Force Majeure</h2>
        <p>
            Case Parts shall not be liable for any delay in or impairment of performance resulting in whole or in part from any force majeure event, including but not limited to acts of God, labor disruptions, shortages, inability to procure product, supplies of raw materials, severe weather conditions, or any other circumstances or cause beyond the control of Case Parts in the conduct of its businesses.
        </p>
        <h2>Product Substitution</h2>
        <p>
            Products may be substituted by the manufacturer and may not always exactly match descriptions and/or images.</p>
        <h2>Disclaimer of Warranties</h2>
        <p>
            CASE PARTS OFFERS THE PRODUCTS, THIS WEB SITE, AND ANY AFFILIATED APPLICATIONS AS-IS AND MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING THE SERVICES, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. CASE PARTS DOES NOT WARRANT THAT THIS WEB SITE AND ANY AFFILIATED APPLICATIONS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT CONTENT MADE AVAILABLE ON OR THROUGH THE THIS WEB SITE AND ANY AFFILIATED APPLICATIONS WILL BE ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEB SITE AND ANY AFFILIATED APPLICATIONS USED BY CASE PARTS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. CASE PARTS DOES NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE OF THE CONTENT AVAILABLE THROUGH THIS WEB SITE AND ANY AFFILIATED APPLICATIONS IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE.
        </p>
        <h2>Cancellation</h2>
        <p>
            Any cancellation must be approved by Case Parts, and may be subject to restocking and other charges.
        </p>
        <h2>Product returns</h2>
        <p>
            Email <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a> for instructions on how to return product. Case Parts will not accept any returns without prior approval or consent, which will be given or withheld at Case Parts' discretion. A restocking fee may apply. No returns will be accepted beyond 60 days of purchase. Special orders may not be canceled or returned.
        </p>
        <h2>Support</h2>
        <p>
            For support, please email us at <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a> or call us at 800.421.0271
        </p>
        <h2>Privacy Policy</h2>
        <p>
            If you have any questions regarding privacy, please read our Privacy Policy which can be found at <Link to="/privacy">https://www.caseparts.com/privacy</Link></p>
        <p style={{ "fontSize": "125%", "fontWeight": "bold" }}>
            This document embodies the entire agreement between the parties, and no oral agreement or conversation shall be held to alter the provisions hereof, with the exception of modifications initiated by Case Parts upon providing thirty (30) days written notice of same.
        </p>
    </InfoPage>
}