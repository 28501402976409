import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import Section from "./Section";
import BlockLink from "./BlockLink";
import Expando from "../components/Expando";
import ExpandoState from "../components/ExpandoState";
import GetQueryBySectionTitle from "../../data/GetQueryBySectionTitle";

const Wrapper = styled.div``;

const ExpandoWrapper = styled.div`
  display: block;
  margin-top: 8px;
  padding-bottom: 0px;
`;

const Category = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  mix-blend-mode: multiply;
  background-color: var(--CP-blue);
`;

const CategoryLabel = styled.span`
  margin-top: 8px;
  margin-left: 6px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
`;

export default function SectionGroup({ category }) {
  const context = useContext(AppContext);
  const { sections, block, selectedPart, search, query } = context;
  const [expando, setExpando] = useState(new ExpandoState(false));
  const handleExpandAll = () => setExpando(expando.SetDefault(true));
  const handleCollapseAll = () => setExpando(expando.SetDefault(false));
  const handleToggle = id => {
    setExpando(expando.Toggle(id));
  };
  const handleSearch = title => {
    const query = GetQueryBySectionTitle(title);
    search(query);
  };
  const filteredSections = sections.filter(section => section.category === category);
  const blockId = block ? block.id : -1;
  const GetUrl = path => {
    if (path.startsWith("http")) {
      return path;
    } else if (selectedPart) {
      return `/Part/${selectedPart.partId}/${path}${query ? `?query=${query}` : ""}`;
    } else {
      return `/Page/${path}${query ? `?query=${query}` : ""}`;
    }
  };

  useEffect(() => {
    console.log("Path changed", window.location.pathname);
    if (window.location.pathname === "/") {
      handleCollapseAll();
    } else if (window.location.pathname.startsWith("/Part")) {
      handleExpandAll();
    }
  }, [window.location.pathname]);

  // Be sure to trim out any sections that are no longer part of the current query
  if (filteredSections.length > 0) {
    let activeSections = filteredSections.map(section => section.id);
    let filteredExpando = expando.LimitToActiveSections(new Set(activeSections));
    if (filteredExpando) {
      setExpando(filteredExpando);
    }
  }
  return (
    <Wrapper>
      {/* {filteredSections.length === 0 && <div>No {category} found.</div>} */}
      {filteredSections.length > 0 && (
        <ExpandoWrapper>
          <Expando
            onCollapseAll={handleCollapseAll}
            onExpandAll={handleExpandAll}
            canCollapse={expando.CanCollapse()}
            canExpand={expando.CanExpand()}
          />
          <Category>
            <CategoryLabel>{category}</CategoryLabel>
          </Category>
        </ExpandoWrapper>
      )}
      {filteredSections.map((section, sindex) => (
        <Section
          title={`${section.title}`}
          key={sindex}
          showDetails={expando.IsExpanded(section.id)}
          onToggle={e => {
            e.stopPropagation();
            handleToggle(section.id);
          }}
          onSearch={e => {
            e.stopPropagation();
            handleSearch(section.title);
          }}
          query={GetQueryBySectionTitle(section.title)}
        >
          {section.blocks.map((block, bindex) => (
            <BlockLink
              title={block.title}
              path={GetUrl(block.path)}
              key={`${sindex}-${bindex}`}
              id={block.id}
              selected={block.id === blockId}
              isInfo={block.info}
            />
          ))}
        </Section>
      ))}
    </Wrapper>
  );
}
