import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import queryString from "query-string";
import useOrderReport from "../hooks/useOrderReport";
import ResultWrapper from "../ResultWrapper";
import "../../ui/styling/styles.css";
import "../requestManager.css";
import { Time } from "../RequestFormatting";
import Breadcrumb from "../../ui/navigation/Breadcrumb";
//import AppSettings from "../../AppSettings";
import { FindEmployee, IsAuthorized } from "../data/GetStaffInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function OrdersReport({ location, match }) {
  const context = useContext(AppContext);
  const { account } = context;
  const employee = FindEmployee(account);

  // First, we need to authenticate
  if (!IsAuthorized(employee)) {
    window.location = "/";
  }

  // If called with no arguments, replace with search arguments
  if (!location.search) {
    window.location.replace("/ordersreport?account=&email=&owner=&status=&request=&type=&from=&to=&sort=");
  }

  const args = queryString.parse(location.search);
  const view = match.params.view;
  const query = GetSearchArgs(view, args);
  query.sort = query.sort ? query.sort.toLowerCase() : "-requested";
  const result = useOrderReport(query);

  const FormatUrl = (label, isCurrentView) => {
    var sort;
    if (isCurrentView && query.sort) {
      sort = query.sort.substr(0, 1) === "-" ? query.sort.substr(1) : `-${query.sort}`;
    } else {
      switch (label) {
        case "Request":
          sort = "request";
          break;
        case "OP Key":
          sort = "opkey";
          break;
        case "Status":
          sort = "status";
          break;
        case "Requested":
          sort = "-requested";
          break;
        case "Quoted":
          sort = "-quoted";
          break;
        case "Amount":
          sort = "-amount";
          break;
        case "Account":
          sort = "account";
          break;
        case "Contact":
          sort = "contact";
          break;
        case "Owner":
          sort = "owner";
          break;
        default:
          sort = "";
          break;
      }
    }

    const account = query.account ? query.account : "";
    const email = query.email ? query.email : "";
    const owner = query.owner ? query.owner : "";
    const status = query.status ? query.status : "";
    const type = query.type ? query.type : "";
    const request = query.request ? query.request : "";
    const from = query.from ? query.from : "";
    const to = query.to ? query.to : "";
    const url = `/ordersreport?account=${account}&email=${email}&owner=${owner}&status=${status}&request=${request}&type=${type}&from=${from}&to=${to}&sort=${sort}`;
    return url;
  };

  const ColumnHeading = props => {
    var sort = query.sort;
    var descending = false;
    if (sort.substr(0, 1) === "-") {
      descending = true;
      sort = sort.substr(1);
    }
    const label = props.children;
    const isCurrentView = label.toLowerCase().replace(" ", "") === sort;
    const url = FormatUrl(label, isCurrentView);
    return (
      <Link className="columnHeading" to={url}>
        {isCurrentView && (
          <FontAwesomeIcon
            icon={["fas", descending ? "sort-down" : "sort-up"]}
            style={{ marginRight: "4px", fontSize: "16px" }}
          />
        )}
        {label}
      </Link>
    );
  };

  const formatMoney = amount =>
    !amount
      ? ""
      : Number(amount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");

  const ReportHeading = () => {
    return (
      <>
        <h1>
          {query.title}&nbsp;&nbsp;&nbsp;
          <Breadcrumb className="requestLink" title="Return" />
          &nbsp;&nbsp;&nbsp;
          {query.type !== "order" && (
            <>
              <Link className="requestLink" to={`/ordersreport/orders?from=${query.from}&to=${query.to}`}>
                Only Committed Orders
              </Link>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          {query.type !== "quote" && (
            <>
              <Link className="requestLink" to={`/ordersreport/quotes?from=${query.from}&to=${query.to}`}>
                Only Quotes
              </Link>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          {query.type && (
            <>
              <Link className="requestLink" to={`/ordersreport?from=${query.from}&to=${query.to}`}>
                Orders &amp; Quotes
              </Link>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          <Link className="requestLink" to={window.location.href.replace(/.*ordersreport/, "/requestsreport")}>
            Requests
          </Link>
        </h1>
        {!query.view && (
          <p>
            {query.customer && (
              <span>
                <b>Customer</b>: {query.customer}&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {query.email && (
              <span>
                <b>Email</b>: {query.email}&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {query.request && (
              <span>
                <b>Request Type</b>: {query.request === "Contact" ? "Email" : query.request}&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {query.status && (
              <span>
                <b>Status</b>: {query.status}&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {query.owner && (
              <span>
                <b>Owner</b>: {FindEmployee(query.owner).fullname}&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {query.from && (
              <span>
                <b>From</b>: {query.from}&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {query.to && (
              <span>
                <b>To</b>: {query.to}&nbsp;&nbsp;&nbsp;
              </span>
            )}
          </p>
        )}
      </>
    );
  };
  function GetSearchArgs(view, args) {
    if (view === "orders" || args.type === "order") {
      args.title = "Customer Orders";
      args.type = "order";
    } else if (view === "quotes" || args.type === "quote") {
      args.title = "Customer Quotes";
      args.type = "quote";
    } else {
      args.title = "Customer Orders & Quotes";
      args.type = null;
    }
    return args;
  }

  return (
    <ResultWrapper
      result={result}
      render={() => {
        const requestList = result.response;

        return (
          <div className="page">
            <ReportHeading />
            <table className="subtleTable">
              <thead>
                <tr>
                  <th>
                    <ColumnHeading>Request</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>OP Key</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Status</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Requested</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Quoted</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Amount</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Account</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Contact</ColumnHeading>
                  </th>
                  <th>
                    <ColumnHeading>Owner</ColumnHeading>
                  </th>
                </tr>
              </thead>
              <tbody>
                {requestList.map((entry, n) => (
                  <tr key={n}>
                    <td>
                      <b>{entry.RequestType === "Contact" ? "Email" : entry.RequestType}</b>
                      <br />
                      <Link className="requestId" to={`/request/${entry.RequestId}`}>
                        {entry.RequestId}
                      </Link>
                    </td>
                    <td>{entry.OpKey}</td>
                    <td>{entry.Status}</td>
                    <td>{Time(entry.RequestCreated)}</td>
                    <td>{Time(entry.QuoteCreated)}</td>
                    <td className="amount">{formatMoney(entry.Total)}</td>
                    <td>
                      <Link className="requestLink" to={`/ordersreport/?account=${entry.CustomerId}`}>
                        {entry.CustomerId}
                      </Link>
                      <br />
                      {entry.Company}
                    </td>
                    <td>
                      {entry.Name}
                      <br />
                      {!!entry.Email && (
                        <Link className="requestLink" to={`/ordersreport/?email=${entry.Email}`}>
                          {entry.Email}
                        </Link>
                      )}
                    </td>
                    <td>
                      <Link
                        className="requestLink"
                        to={`/ordersreport/?owner=${FindEmployee(entry.Owner).username}&type=${
                          query.type ? query.type : ""
                        }&from=${query.from ? query.from : ""}&to=${query.to ? query.to : ""}`}
                      >
                        {FindEmployee(entry.Owner).shortname}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    />
  );
}
