import React, { useContext } from "react"
import AppContext from "../../../context/AppContext"
import InfoPage from "./InfoPage"
import FaqEntry from "./FaqEntry"
//import { Link } from "react-router-dom";

export default function Csr(props) {
    const { claims } = useContext(AppContext)
    const isEmployee = claims.UserType === "Employee"

    // Redirect non-employees to faq
    if (!isEmployee) {
        window.location = "/faq"
    }

    return <InfoPage className="faq">
        <h1>Customer Service FAQ (Internal Use Only)</h1>
        <h2>Organizing Your Time &amp; Space</h2>
        <FaqEntry history={props.history} title="How should I organize the windows on my computer">
            <p>We CSRs have a lot of screens to navigate such as Sage, Slack, and Gmail. Being able to minimize a window and size it to your preference helps to see any new notifications that may need immediate attention. Having two monitors already helps with this issue but having one full page taken up by say Gmail will hide Slack as well as any desktop shortcuts we quickly make use of. Shaping your windows will allow for quick access to all of the programs and tools we use.</p>
        </FaqEntry>
        <FaqEntry history={props.history} title="How do we best multitask between phones, chats, email, and research?">
            <p>As CSR our number one priority should be any real time event that comes across our workflow. Walk in customers and phone calls should receive priority over email. At no time should we be turning our phones off to read or answer emails due to the fact that communication usually isn’t instantaneous in those circumstances. Having your phone on, multitasking when putting a customer on hold or calling a factory and also being put on hold gives you plenty of time to catch up on emails. Help out fellow CSRs by having your phone on and available to handle an incoming call. If a special circumstance arises let your supervisor or a fellow CSR aware. No single CSR should be handling back to back to back calls while another CSR has their phone off reading an email. Teamwork will provide all of us the opportunity to handle all of our different responsibilities during our daily workflow.</p>
        </FaqEntry>
        <FaqEntry history={props.history} title="How do I turn off my computer on the weekdays, weekends, and when Danny wants to perform security updates?">
            <p>Best practice is to leave your workspace computer on sleep mode in the event that Danny needs to perform updates as well as any unforeseen updates needed within our server structure. This also helps with your morning start up by reducing the time it takes for our systems to boot.</p>
        </FaqEntry>

        <h2>Workflows &amp; Processes</h2>
        <FaqEntry history={props.history} title="What's the best way to handle a colleague's 'Contact Customer' order?">
            <p>Best practice would be to first see all information is present in the quote such as pricing, part number, and lead time information. If you contact the customer by phone please provide brief notes on what was said such as part availability and whom you spoke to. If you reach out to the customer via email CC the CSR that started the quote so they are aware of contact being made as well as any notes provided to the customer.</p>
        </FaqEntry>
        <FaqEntry history={props.history} title="What is the best way to respond to an email sent to customerservice@caseparts.com?">
            <p>When responding to a customer service email we are to forward the email from the customer service flow to ourselves, then move to h<b>andled by (MPK/STL/SEA)</b>, and then reach out to the customer letting them know we have received their request as soon as possible.  Please be sure that the replay is going to the customer and not back to the customer service email. </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="What is the best way to respond to an online research request?">
            <p>We must respond back to the customer by at least letting them know we are working on their request in a timely manner to avoid the customer sending multiple emails or having to call in asking why their request hasn’t been answered. Please try to keep the customer aware of any special situations with factories that take longer to respond to us. The customer should be given reasonable expectations depending on what they are asking for. </p>
        </FaqEntry>

        <h2>Return Procedures</h2>
        <p><i>!! Note the order, every time. That way anyone else who looks in the order is aware of what is happening. !!</i></p>
        <FaqEntry history={props.history} title="Returning Non-Defective Stock parts to Stock">
            <p>
                Give RMA to customer and note why part is coming back.
            </p><p>
                MAKE SURE ITS NOT A <b>"DEFAULT"</b> PART, if it is and we do not really stock, see below for "Returning Non-Defective Parts to Factory"
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Returning Non-Defective SPO Parts to Factory">
            <p>Let returns department know the reason and we will work on the RMA.</p>
            <p>
                If a good amount of time has passed, find out why. (This doesn't mean grill them for it. It could just be they forgot) Not necessary but always appreciated when your emails say (customer is aware of restock).
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Returning Defective Parts to the Factory (stock or not)">
            <p>
                Get the model/serial - make sure it was the correct part being used for the application. (Except for Generic vendors- Backer Heaters, Miljoco, Weiss .... )
            </p>
            <p>
                Find out why the part is defective.
            </p>
            <p>
                Does customer want a replacement? If you send one out, please note if you do and whether you billed them or not. (This is so we know if we need to credit them or not.) And if you do send another one out, make sure it was the correct one first!
            </p>
            <p>
                If it's a call tag situation- find out where and how the customer wants the call tag.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Some Factory Specifics ">
            <p><b>Parts Town</b> has a <a href="https://www.partstown.com/images/partstownProductCatalog/source/Defective-Part-Return-Form-2022.pdf">defective parts form</a> - You will always need to get this filled out before we start anywhere. You get that filled out, send to returns dept, Shaina will see if PT needs the part back or not. More than half of the time they do not, this is why we wait to give customer RMA#.
            </p>
            <p><b>Kason</b> has to inspect their defective parts. If the part is not defective, they will let me know and ask if the customer needs it back. If you can get this ahead of time, while you have the custome'r on the line, that would be great. See if they deem this to be working, do you want it back? Do you have a UPS account# to use IF this is deemed not defective?
            </p>
            <p><b>True</b> has given us approval to send non-defective parts back w/a 25% restocking. If it is defective, see above!
            </p>
        </FaqEntry>
   </InfoPage>
}