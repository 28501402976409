// base on https://developer.cybersource.com/docs/cybs/en-us/state-codes/reference/all/na/state-codes/state-codes.html
const statesAndTerritories = [
    {
      code: "AL",
      name: "Alabama",
      category: "state",
      zipCodes: [{ minZip: 35000, maxZip: 36999 }]
    },
    {
      code: "AK",
      name: "Alaska",
      category: "state",
      zipCodes: [{ minZip: 99500, maxZip: 99999 }]
    },
    {
      code: "AS",
      name: "American Samoa",
      category: "territory",
      zipCodes: [{ minZip: 96799, maxZip: 96799 }]
    },
    {
      code: "AZ",
      name: "Arizona",
      category: "state",
      zipCodes: [{ minZip: 85000, maxZip: 86999 }]
    },
    {
      code: "AR",
      name: "Arkansas",
      category: "state",
      zipCodes: [{ minZip: 71600, maxZip: 72999 }]
    },
    {
      code: "CA",
      name: "California",
      category: "state",
      zipCodes: [{ minZip: 90000, maxZip: 96699 }]
    },
    {
      code: "CO",
      name: "Colorado",
      category: "state",
      zipCodes: [{ minZip: 80000, maxZip: 81999 }]
    },
    {
      code: "CT",
      name: "Connecticut",
      category: "state",
      zipCodes: [{ minZip: 6000, maxZip: 6999 }]
    },
    {
      code: "DE",
      name: "Delaware",
      category: "state",
      zipCodes: [{ minZip: 19700, maxZip: 19999 }]
    },
    {
      code: "DC",
      name: "District of Columbia",
      category: "district",
      zipCodes: [{ minZip: 20000, maxZip: 20599 }]
    },
    {
      code: "FM",
      name: "Federated State of Micronesia",
      category: "territory",
      zipCodes: [{ minZip: 96941, maxZip: 96944 }]
    },
    {
      code: "FL",
      name: "Florida",
      category: "state",
      zipCodes: [{ minZip: 32000, maxZip: 34999 }]
    },
    {
      code: "GA",
      name: "Georgia",
      category: "state",
      zipCodes: [{ minZip: 30000, maxZip: 31999 }]
    },
    {
      code: "GU",
      name: "Guam",
      category: "territory",
      zipCodes: [{ minZip: 96900, maxZip: 96938 }]
    },
    {
      code: "HI",
      name: "Hawaii",
      category: "state",
      zipCodes: [
        { minZip: 96700, maxZip: 96798 },
        { minZip: 96800, maxZip: 96899 }
      ]
    },
    {
      code: "ID",
      name: "Idaho",
      category: "state",
      zipCodes: [{ minZip: 83200, maxZip: 83999 }]
    },
    {
      code: "IL",
      name: "Illinois",
      category: "state",
      zipCodes: [{ minZip: 60000, maxZip: 62999 }]
    },
    {
      code: "IN",
      name: "Indiana",
      category: "state",
      zipCodes: [{ minZip: 46000, maxZip: 47999 }]
    },
    {
      code: "IA",
      name: "Iowa",
      category: "state",
      zipCodes: [{ minZip: 50000, maxZip: 52999 }]
    },
    {
      code: "KS",
      name: "Kansas",
      category: "state",
      zipCodes: [{ minZip: 66000, maxZip: 67999 }]
    },
    {
      code: "KY",
      name: "Kentucky",
      category: "state",
      zipCodes: [{ minZip: 40000, maxZip: 42999 }]
    },
    {
      code: "LA",
      name: "Louisiana",
      category: "state",
      zipCodes: [{ minZip: 70000, maxZip: 71599 }]
    },
    {
      code: "ME",
      name: "Maine",
      category: "state",
      zipCodes: [{ minZip: 3900, maxZip: 4999 }]
    },
    {
      code: "MH",
      name: "Marshall Islands",
      category: "territory",
      zipCodes: [
        { minZip: 96960, maxZip: 96960 },
        { minZip: 96970, maxZip: 96970 }
      ]
    },
    {
      code: "MD",
      name: "Maryland",
      category: "state",
      zipCodes: [{ minZip: 20600, maxZip: 21999 }]
    },
    {
      code: "MA",
      name: "Massachusetts",
      category: "state",
      zipCodes: [{ minZip: 1000, maxZip: 2799 }]
    },
    {
      code: "MI",
      name: "Michigan",
      category: "state",
      zipCodes: [{ minZip: 48000, maxZip: 49999 }]
    },
    {
      code: "MN",
      name: "Minnesota",
      category: "state",
      zipCodes: [{ minZip: 55000, maxZip: 56999 }]
    },
    {
      code: "MS",
      name: "Mississippi",
      category: "state",
      zipCodes: [{ minZip: 38600, maxZip: 39999 }]
    },
    {
      code: "MO",
      name: "Missouri",
      category: "state",
      zipCodes: [{ minZip: 63000, maxZip: 65999 }]
    },
    {
      code: "MT",
      name: "Montana",
      category: "state",
      zipCodes: [{ minZip: 59000, maxZip: 59999 }]
    },
    {
      code: "NE",
      name: "Nebraska",
      category: "state",
      zipCodes: [{ minZip: 68000, maxZip: 69999 }]
    },
    {
      code: "NV",
      name: "Nevada",
      category: "state",
      zipCodes: [{ minZip: 88900, maxZip: 89999 }]
    },
    {
      code: "NH",
      name: "New Hampshire",
      category: "state",
      zipCodes: [{ minZip: 3000, maxZip: 3899 }]
    },
    {
      code: "NJ",
      name: "New Jersey",
      category: "state",
      zipCodes: [{ minZip: 7000, maxZip: 8999 }]
    },
    {
      code: "NM",
      name: "New Mexico",
      category: "state",
      zipCodes: [{ minZip: 87000, maxZip: 88499 }]
    },
    {
      code: "NY",
      name: "New York",
      category: "state",
      zipCodes: [{ minZip: 10000, maxZip: 14999 }]
    },
    {
      code: "NC",
      name: "North Carolina",
      category: "state",
      zipCodes: [{ minZip: 27000, maxZip: 28999 }]
    },
    {
      code: "ND",
      name: "North Dakota",
      category: "state",
      zipCodes: [{ minZip: 58000, maxZip: 58999 }]
    },
    {
      code: "MP",
      name: "Northern Mariana Islands",
      category: "territory",
      zipCodes: [{ minZip: 96950, maxZip: 96952 }]
    },
    {
      code: "OH",
      name: "Ohio",
      category: "state",
      zipCodes: [{ minZip: 43000, maxZip: 45999 }]
    },
    {
      code: "OK",
      name: "Oklahoma",
      category: "state",
      zipCodes: [{ minZip: 73000, maxZip: 74999 }]
    },
    {
      code: "OR",
      name: "Oregon",
      category: "state",
      zipCodes: [{ minZip: 97000, maxZip: 97999 }]
    },
    {
      code: "PW",
      name: "Palau",
      category: "territory",
      zipCodes: [{ minZip: 96939, maxZip: 96940 }]
    },
    {
      code: "PA",
      name: "Pennsylvania",
      category: "state",
      zipCodes: [{ minZip: 15000, maxZip: 19699 }]
    },
    {
      code: "PR",
      name: "Puerto Rico",
      category: "territory",
      zipCodes: [
        { minZip: 600, maxZip: 799 },
        { minZip: 900, maxZip: 999 }
      ]
    },
    {
      code: "RI",
      name: "Rhode Island",
      category: "state",
      zipCodes: [{ minZip: 2800, maxZip: 2999 }]
    },
    {
      code: "SC",
      name: "South Carolina",
      category: "state",
      zipCodes: [{ minZip: 29000, maxZip: 29999 }]
    },
    {
      code: "SD",
      name: "South Dakota",
      category: "state",
      zipCodes: [{ minZip: 57000, maxZip: 57999 }]
    },
    {
      code: "TN",
      name: "Tennessee",
      category: "state",
      zipCodes: [{ minZip: 37000, maxZip: 38599 }]
    },
    {
      code: "TX",
      name: "Texas",
      category: "state",
      zipCodes: [
        { minZip: 75000, maxZip: 79999 },
        { minZip: 88500, maxZip: 88599 }
      ]
    },
    {
      code: "UT",
      name: "Utah",
      category: "state",
      zipCodes: [{ minZip: 84000, maxZip: 84999 }]
    },
    {
      code: "VT",
      name: "Vermont",
      category: "state",
      zipCodes: [{ minZip: 5000, maxZip: 5999 }]
    },
    {
      code: "VI",
      name: "Virgin Islands",
      category: "territory",
      zipCodes: [{ minZip: 800, maxZip: 899 }]
    },
    {
      code: "VA",
      name: "Virginia",
      category: "state",
      zipCodes: [{ minZip: 22000, maxZip: 24699 }]
    },
    {
      code: "WA",
      name: "Washington",
      category: "state",
      zipCodes: [{ minZip: 98000, maxZip: 99499 }]
    },
    {
      code: "WV",
      name: "West Virginia",
      category: "state",
      zipCodes: [{ minZip: 24700, maxZip: 26999 }]
    },
    {
      code: "WI",
      name: "Wisconsin",
      category: "state",
      zipCodes: [{ minZip: 53000, maxZip: 54999 }]
    },
    {
      code: "WY",
      name: "Wyoming",
      category: "state",
      zipCodes: [{ minZip: 82000, maxZip: 83199 }]
    }
  ];
  
  function listByZipCode() {
    return statesAndTerritories
      .map((a) =>
        a.zipCodes.map((b) => ({
          code: a.code,
          minZip: b.minZip,
          maxZip: b.maxZip
        }))
      )
      .flat()
      .sort((a, b) => a.minZip - b.minZip);
  }
  
  function findByZipCode(zip) {
    return statesAndTerritories.find((a) =>
      a.zipCodes.find((b) => zip >= b.minZip && zip <= b.maxZip)
    );
  }
  
  function listByStateCode() {
    return statesAndTerritories.sort((a, b) => a.code - b.code);
  }
  
  function listByStateName() {
    return statesAndTerritories.sort((a, b) => a.name - b.name);
  }
  
  export { listByStateCode, listByStateName, findByZipCode, listByZipCode };
  