// This is needed to expose the dynamic react-styles to prerender.io
// https://github.com/prerender/prerender/issues/574
// TODO: Verify if we really need to use setTimer before calling this method.
export const extractCSStoHTML = () => {
  if (
    typeof navigator != "undefined" &&
    (navigator.userAgent.includes("Prerender") || navigator.userAgent.includes("prerender"))
  ) {
    var cssStyles = "";
    for (let i = 0; i < document.styleSheets.length - 1; i++) {
      let style = null;
      let styleSheet = document.styleSheets[i];
      if (styleSheet.href === null && styleSheet.ownerNode.textContent === "") {
        style = styleSheet.rules;
      }
      for (let item in style) {
        if (style[item].cssText !== undefined) {
          cssStyles += style[item].cssText;
        }
      }
    }
    var head = document.head || document.getElementsByTagName("head")[0];
    var style = document.getElementById("styles-for-prerender");
    if (style) {
      style.setAttribute("iteration", parseInt(style.getAttribute("iteration")) + 1);
      while (style.firstChild) {
        style.removeChild(style.firstChild);
      }
    } else {
      style = document.createElement("style");
      style.setAttribute("iteration", "1");
      head.appendChild(style);
      style.id = "styles-for-prerender";
      style.type = "text/css";
    }
    style.appendChild(document.createTextNode(cssStyles));
    // Mark page as ready now
    window.prerenderReady = true;
  }
};
