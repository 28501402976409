import React from "react";
import styled from "styled-components";
import ContactForm from "../../checkout/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import InfoPage from "./InfoPage"

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 0px;
`;
const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-bottom: 8px;
`;
const Column = styled.div`
  float: left;
`;
const Annotation = styled.div`
  margin-top: 12px;
  margin-left: 26px;
  width: 380px;
  font-size: 14px;
  font-style: italic;
  background-color: var(--highlight-yellow);
  padding: 16px;
`;
const AnnotationTitle = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
`;
const Offices = styled.div`
  padding-top: 8px;
  color: black;
`;
const Office = styled.div`
  width: 250px;
  margin: 10px 20px 20px 20px;
`;
const OfficeName = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
  margin: 0px;
`;
const OfficePhone = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
  margin: 4px 0px;
`;
const PhoneIcon = styled(FontAwesomeIcon)`
  color: var(--CP-blue);
  margin-right: 4px;
`;
const OfficeDetails = styled.div`
  opacity: 0.75;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.01px;
  text-align: left;
  color: black;
`;
const Link = styled.a`
  text-decoration: none;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default function Contact(props) {
  return (
    <InfoPage>
      <Wrapper>
        <Helmet>
          <title>Contact Us - Case Parts Commercial Refrigeration Parts Specialists</title>
          <meta
            name="description"
            content="Case Parts Commercial Refrigeration Parts Specialists including Latches, Hinges, Gaskets, Heaters, Pans, Walk-In Equipment, Electrical, Thermometers, Alarms, Shelving"
          />
        </Helmet>
        <Title>Contact Us</Title>
        <Column>
          <ContactForm />
        </Column>
        <Column>
          <Annotation>
            <AnnotationTitle>How can we help?</AnnotationTitle>
            Ask us anything or tell us how we could improve.
            <br />
            Use this form or feel free to give us a call or live chat.
            <Offices>
              <Office>
                <OfficeName>Los Angeles</OfficeName>
                <OfficePhone>
                  <Link href="tel:+18004210271">
                    <PhoneIcon icon={["far", "phone"]} />
                    (800) 421-0271
                  </Link>
                </OfficePhone>
                <OfficeDetails>Monday - Friday 7:30AM - 5:00PM PT</OfficeDetails>
              </Office>
              <Office>
                <OfficeName>St. Louis</OfficeName>
                <OfficePhone>
                  <Link href="tel:+18004239337">
                    <PhoneIcon icon={["far", "phone"]} />
                    (800) 423-9337
                  </Link>
                </OfficePhone>
                <OfficeDetails>Monday - Friday 7:00AM - 4:30PM CT</OfficeDetails>
              </Office>
              <Office>
                <OfficeName>Seattle</OfficeName>
                <OfficePhone>
                  <Link href="tel:+18007153416">
                    <PhoneIcon icon={["far", "phone"]} />
                    (800) 715-3416
                  </Link>
                </OfficePhone>
                <OfficeDetails>Monday - Friday 7:30AM - 5:00PM PT</OfficeDetails>
              </Office>
            </Offices>
          </Annotation>
        </Column>
      </Wrapper>
    </InfoPage>
  );
}
