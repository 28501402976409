//import { WriteStream } from "fs";
import AppSettings from "./AppSettings";

var stackTrace = require("stacktrace-js");
var clientPlatform = require("platform");
var existingOnError = window.onerror;
var sequenceNumber = 0;
var sessionId;
var agentSessionId;
var messageStorage = [];
var storageAvailable = storageSupported();
var storageFull = false;
var corsOrigin = AppSettings.Loupe;
var globalKeyList = [];
var authHeader;

export let propagateError = false;
export const logMessageSeverity = {
  none: 0,
  critical: 1,
  error: 2,
  warning: 4,
  information: 8,
  verbose: 16
};

var maxRequestSize = 204800;
var messageInterval = 10;

setUpOnError(window);
setUpClientSessionId();
setUpSequenceNumber();
addSendMessageCommandToEventQueue();

// JayC: These are extensions I added for convenience
export function Verbose(message, context) {
  Write(logMessageSeverity.verbose, message, context);
}
export function Information(message, context) {
  Write(logMessageSeverity.information, message, context);
}
export function Warning(message, context) {
  Write(logMessageSeverity.warning, message, context);
}
export function Error(message, context) {
  Write(logMessageSeverity.error, message, context);
}

function Write(severity, caption, context) {
  var severityText;
  switch (severity) {
    case logMessageSeverity.information:
      severityText = "INFO: ";
      break;
    case logMessageSeverity.warning:
      severityText = "WARNING:";
      break;
    case logMessageSeverity.error:
      severityText = "ERROR: ";
      break;
    default:
      severityText = "";
      break;
  }
  if (context) {
    console.log(`${severityText}${caption}`, context);
  } else {
    console.log(`${severityText}${caption}`);
  }
  const description = context && typeof context === "object" ? JSON.stringify(context, null, 4) : context;
  write(severity, "PartFinder", caption, description);
}
// JayC: End of extensions

export function verbose(category, caption, description, parameters, exception, details, methodSourceInfo) {
  write(logMessageSeverity.verbose, category, caption, description, parameters, exception, details, methodSourceInfo);
}

export function information(category, caption, description, parameters, exception, details, methodSourceInfo) {
  write(
    logMessageSeverity.information,
    category,
    caption,
    description,
    parameters,
    exception,
    details,
    methodSourceInfo
  );
}

export function warning(category, caption, description, parameters, exception, details, methodSourceInfo) {
  write(logMessageSeverity.warning, category, caption, description, parameters, exception, details, methodSourceInfo);
}

export function error(category, caption, description, parameters, exception, details, methodSourceInfo) {
  write(logMessageSeverity.error, category, caption, description, parameters, exception, details, methodSourceInfo);
}

export function critical(category, caption, description, parameters, exception, details, methodSourceInfo) {
  write(logMessageSeverity.critical, category, caption, description, parameters, exception, details, methodSourceInfo);
}

export function MethodSourceInfo(file, method, line, column) {
  this.file = file || null;
  this.method = method || null;
  this.line = line || null;
  this.column = column || null;
}

function addSendMessageCommandToEventQueue() {
  // check for unsent messages on start up
  if ((storageAvailable && localStorage.length) || messageStorage.length) {
    setTimeout(logMessageToServer, messageInterval);
  }
}

export function setSessionId(value) {
  sessionId = value;
}

export function setCORSOrigin(value) {
  consoleLog(`setCORSOrigin(${value})`);
  corsOrigin = value;
}

export function setAuthorizationHeader(header) {
  if (header) {
    if (header.name && header.value) {
      authHeader = header;
    } else {
      consoleLog("setAuthorizationHeader failed. The header provided appears invalid as it doesn't have name & value");
    }
  } else {
    consoleLog("setAuthorizationHeader failed. No header object provided");
  }
}

function sanitiseArgument(parameter) {
  if (typeof parameter == "undefined") {
    return null;
  }

  return parameter;
}

function buildMessageSourceInfo(data) {
  return new MethodSourceInfo(data.file || null, data.method || null, data.line || null, data.column || null);
}

export function write(severity, category, caption, description, parameters, exception, details, methodSourceInfo) {
  exception = sanitiseArgument(exception);
  details = sanitiseArgument(details);

  if (details && typeof details !== "string") {
    details = JSON.stringify(details);
  }

  methodSourceInfo = sanitiseArgument(methodSourceInfo);

  if (methodSourceInfo && !(methodSourceInfo instanceof MethodSourceInfo)) {
    methodSourceInfo = buildMessageSourceInfo(methodSourceInfo);
  }

  createMessage(severity, category, caption, description, parameters, exception, details, methodSourceInfo);

  addSendMessageCommandToEventQueue();
}

function setUpOnError(window) {
  if (typeof window.onerror === "undefined") {
    consoleLog("Gibraltar Loupe JavaScript Logger: No onerror event; errors cannot be logged to Loupe");

    return;
  }

  window.onerror = function(msg, url, line, column, error) {
    if (existingOnError) {
      existingOnError.apply(this, arguments);
    }

    setTimeout(logError, 10, msg, url, line, column, error);

    // if we want to propagate the error the browser needs
    // us to return false but logically we want to state we
    // want to propagate i.e. true, so we reverse the bool
    // so users can set as they expect not how browser expects
    return !this.propagateOnError;
  };
}

function getPlatform() {
  var platformDetails = clientPlatform;

  platformDetails.size = {
    width: window.innerWidth || document.body.clientWidth,
    height: window.innerHeight || document.body.clientHeight
  };

  return platformDetails;
}

function getStackTrace(error, errorMessage) {
  if (typeof error === "undefined" || error === null || !error.stack) {
    return createStackFromMessage(errorMessage);
  }

  return createStackFromError(error);
}

function createStackFromMessage(errorMessage) {
  if (stackTrace) {
    try {
      var stack = stackTrace({ e: errorMessage }).reverse();

      return stripLoupeStackFrames(stack);
    } catch (e) {
      // deliberately swallow; some browsers don't expose the stack property on the exception
    }
  }

  return [];
}

function createStackFromError(error) {
  // remove trailing new line

  if (error.stack.substring(error.stack.length - 1) === "\n") {
    error.stack = error.stack.substring(0, error.stack.length - 1);
  }

  return error.stack.split("\n");
}

function stripLoupeStackFrames(stack) {
  // if we error is from a simple throw statement and not an error then

  // stackTrace.js will have added methods from here so we need to remove

  // them otherwise will be reported in Loupe

  if (stack) {
    var userFramesStartPosition = userFramesStartAt(stack);

    if (userFramesStartPosition > 0) {
      // strip all loupe related frames from stack

      stack = stack.slice(userFramesStartPosition);
    }
  }

  return stack;
}

function userFramesStartAt(stack) {
  var loupeMethods = ["logError", "getStackTrace", "createStackFromMessage", "createStackTrace"];

  var position = 0;

  if (stack[0].indexOf("Cannot access caller") > -1) {
    position++;
  }

  for (; position < loupeMethods.length; position++) {
    if (stack.length < position) {
      break;
    }

    if (stack[position].indexOf(loupeMethods[position]) === -1) {
      break;
    }
  }

  return position;
}

function logError(msg, url, line, column, error) {
  var errorName = "";

  if (error) {
    errorName = error.name || "Exception";
  }

  var exception = {
    message: msg,
    url: url,
    stackTrace: getStackTrace(error, msg),
    cause: errorName,
    line: line,
    column: column
  };

  createMessage(logMessageSeverity.error, "JavaScript", errorName, "", null, exception, null, null);

  return logMessageToServer();
}

function storageSupported() {
  var testValue = "_loupe_storage_test_";

  try {
    localStorage.setItem(testValue, testValue);
    localStorage.removeItem(testValue);

    return true;
  } catch (e) {
    return false;
  }
}

export function clientSessionHeader() {
  return {
    headerName: "loupe-agent-sessionId",
    headerValue: agentSessionId
  };
}

function checkForStorageQuotaReached(e) {
  if (e.name === "QUOTA_EXCEEDED_ERR" || e.name === "NS_ERROR_DOM_QUOTA_REACHED" || e.name === "QuotaExceededError") {
    storageFull = true;
    return true;
  }
  return false;
}

function setUpClientSessionId() {
  var currentClientSessionId = getClientSessionHeader();

  if (currentClientSessionId) {
    agentSessionId = currentClientSessionId;
  } else {
    agentSessionId = generateUUID();

    storeClientSessionId(agentSessionId);
  }
}

function storeClientSessionId(sessionIdToStore) {
  if (storageAvailable && !storageFull) {
    try {
      sessionStorage.setItem("LoupeAgentSessionId", sessionIdToStore);
    } catch (e) {
      if (checkForStorageQuotaReached(e)) {
        return;
      }
      consoleLog("Unable to store clientSessionId in session storage. " + e.message);
    }
  }
}

function getClientSessionHeader() {
  try {
    var clientSessionId = sessionStorage.getItem("LoupeAgentSessionId");

    if (clientSessionId) {
      return clientSessionId;
    }
  } catch (e) {
    consoleLog("Unable to retrieve clientSessionId number from session storage. " + e.message);
  }

  return null;
}

function setUpSequenceNumber() {
  var sequence = getSequenceNumber();

  if (sequence === -1 && storageAvailable) {
    // unable to get a sequence number
    sequenceNumber = 0;
  } else {
    sequenceNumber = sequence;
  }
}

function getNextSequenceNumber() {
  var storedSequenceNumber;

  if (storageAvailable) {
    // try and get sequence number from session storage
    storedSequenceNumber = getSequenceNumber();

    if (storedSequenceNumber < sequenceNumber) {
      // seems we must have had a problem storing a number
      // previously, so replace value we just read with
      // the one we are holding in memory
      storedSequenceNumber = sequenceNumber;
    }

    // if we've got the sequence number increment it and store it
    if (storedSequenceNumber !== -1) {
      storedSequenceNumber++;

      if (setSequenceNumber(storedSequenceNumber)) {
        sequenceNumber = storedSequenceNumber;
        return sequenceNumber;
      }
    }
  }

  sequenceNumber++;
  return sequenceNumber;
}

function getSequenceNumber() {
  if (storageAvailable) {
    try {
      var currentNumber = sessionStorage.getItem("LoupeSequenceNumber");

      if (currentNumber) {
        return parseInt(currentNumber);
      } else {
        return 0;
      }
    } catch (e) {
      consoleLog("Unable to retrieve sequence number from session storage. " + e.message);
    }
  }

  // we return -1 to indicate cannot get sequence number
  // or that sessionStorage isn't available
  return -1;
}

function setSequenceNumber(sequenceNumber) {
  try {
    sessionStorage.setItem("LoupeSequenceNumber", sequenceNumber);
    return true;
  } catch (e) {
    if (checkForStorageQuotaReached(e)) {
      return;
    }

    consoleLog("Unable to store sequence number: " + e.message);
    return false;
  }
}

function createMessage(severity, category, caption, description, parameters, exception, details, methodSourceInfo) {
  var messageSequenceNumber = getNextSequenceNumber();
  var timeStamp = createTimeStamp();
  if (exception) {
    exception = createExceptionFromError(exception);
  }

  var message = {
    severity: severity,
    category: category,
    caption: caption,
    description: description,
    parameters: parameters,
    details: details,
    exception: exception,
    methodSourceInfo: methodSourceInfo,
    timeStamp: timeStamp,
    sequence: messageSequenceNumber,
    agentSessionId: agentSessionId,
    sessionId: sessionId
  };

  storeMessage(message);
}

function storeMessage(message) {
  if (storageAvailable && !storageFull) {
    try {
      localStorage.setItem("Loupe-message-" + generateUUID(), JSON.stringify(message));
    } catch (e) {
      checkForStorageQuotaReached(e);
      consoleLog("Error occured trying to add item to localStorage: " + e.message);
      messageStorage.push(message);
    }
  } else {
    if (messageStorage.length === 5000) {
      messageStorage.shift();
    }

    messageStorage.push(JSON.stringify(message));
  }
}

function createExceptionFromError(error, cause) {
  // if error has simply been passed through as a string
  // log the best we could

  if (typeof error == "string") {
    return {
      message: error,
      url: window.location.href,
      stackTrace: [],
      cause: cause || "",
      line: null,
      column: null
    };
  }

  // if the object has an Url property
  // its one of our exception objects so just
  // return it
  if ("url" in error) {
    return error;
  }

  return {
    message: error.message,
    url: window.location.href,
    stackTrace: error.stackTrace,
    cause: cause || "",
    line: error.lineNumber || null,
    column: error.columnNumber || null
  };
}

function createTimeStamp() {
  var now = new Date(),
    tzo = -now.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function(num) {
      var norm = Math.abs(Math.floor(num));

      return (norm < 10 ? "0" : "") + norm;
    };

  return (
    now.getFullYear() +
    "-" +
    pad(now.getMonth() + 1) +
    "-" +
    pad(now.getDate()) +
    "T" +
    pad(now.getHours()) +
    ":" +
    pad(now.getMinutes()) +
    ":" +
    pad(now.getSeconds()) +
    "." +
    pad(now.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
}

function generateUUID() {
  var d = Date.now();

  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}

function truncateDetails(storedData) {
  // we know what the normal size of our requests are (about 5k)
  // so the remaining size is most likely to be in the details
  // section which we will truncate
  // alter details and put back on original message
  if (storedData.message.details) {
    var messageSizeWithoutDetails = storedData.size - storedData.message.details.length;

    if (messageSizeWithoutDetails < maxRequestSize) {
      var details = { message: "User supplied details truncated as log message exceeded maximum size." };
      storedData.message.details = JSON.stringify(details);
      var messageSize = JSON.stringify(storedData);
      storedData.size = messageSize.length;
    }
  }

  return storedData;
}

function dropMessage(storedData) {
  removeMessagesFromStorage([storedData.key]);
  var droppedCaption = storedData.message.caption;
  var droppedDescription = storedData.message.description;

  // check that if we try to include the caption & description it won't exceed the max request size
  if (droppedCaption.length + droppedDescription.length < maxRequestSize - 400) {
    createMessage(
      logMessageSeverity.error,
      "Loupe",
      "Dropped message",
      "Message was dropped as its size exceeded our max request size. Caption was {0} and description {1}",
      [droppedCaption, droppedDescription]
    );
  } else {
    if (droppedCaption.length < maxRequestSize - 400) {
      createMessage(
        logMessageSeverity.error,
        "Loupe",
        "Dropped message",
        "Message was dropped as its size exceeded our max request size. Caption was {0}",
        [droppedCaption]
      );
    } else {
      createMessage(
        logMessageSeverity.error,
        "Loupe",
        "Dropped message",
        "Message was dropped as its size exceeded our max request size.\nUnable to log caption or description as they exceed max request size"
      );
    }
  }
}

function overSizeMessage(storedData) {
  var messageTooLarge = false;

  if (storedData.size > maxRequestSize) {
    // we know what the normal size of our requests are (about 5k)
    // so the remaining size is most likely to be in the details
    // section which we will try truncate
    storedData = truncateDetails(storedData);

    // if message is still too large we have no option but to drop that message
    if (storedData.size > maxRequestSize) {
      dropMessage(storedData);
      messageTooLarge = true;
    }
  }
  return messageTooLarge;
}

function messageSort(a, b) {
  var firstDate = new Date(a.message.timeStamp);
  var secondDate = new Date(b.message.timeStamp);

  if (firstDate > secondDate) {
    return -1;
  }

  if (firstDate < secondDate) {
    return 1;
  }

  // if the dates are the same then we use the sequence
  // number
  return a.message.sequence - b.message.sequence;
}

function getMessagesToSend() {
  var messages = [];
  var keys = [];
  var moreMessagesInStorage = false;
  var messagesFromStorage = [];

  if (messageStorage.length) {
    //     messagesFromStorage.push({
    //         key: null,
    //         message: JSON.parse(messageStorage[j]),
    //         size: messageStorage[j].length
    //     });

    messages = messageStorage.slice();
    messageStorage.length = 0;
  }

  if (storageAvailable) {
    // because local storage isn't structured we cannot simply read
    // the first 10 messages as we have no idea if they are the ones
    // we should send.  So we have to read all of the messages in
    // before we can sort them to ensure we get the right ones and
    // then select the top 10 messages
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).indexOf("Loupe-message-") > -1) {
        if (globalKeyList.indexOf(localStorage.key(i)) === -1) {
          var message = localStorage.getItem(localStorage.key(i));
          messagesFromStorage.push({
            key: localStorage.key(i),
            message: JSON.parse(message),
            size: message.length
          });
        }
      }
    }
  }

  if (messagesFromStorage.length && messagesFromStorage.length > 1) {
    messagesFromStorage.sort(messageSort);
  }

  if (messagesFromStorage.length > 10) {
    moreMessagesInStorage = true;
    messagesFromStorage = messagesFromStorage.splice(0, 10);
  }

  // if we aren't using our standard message interval then we know
  // there is a problem sending messages so we only want to send
  // 1 message

  if (messageInterval !== 10) {
    messagesFromStorage = messagesFromStorage.splice(0, 1);
  }

  var cumulativeSize = 0;

  for (var index = 0; index < messagesFromStorage.length; index++) {
    if (overSizeMessage(messagesFromStorage[index])) {
      continue;
    }

    cumulativeSize += messagesFromStorage[index].size;
    if (cumulativeSize > maxRequestSize) {
      break;
    }

    messages.push(messagesFromStorage[index].message);

    // if its a message from memory we won't have a key
    // so only add to the keys array when we have an
    // actual key
    if (messagesFromStorage[index].key) {
      keys.push(messagesFromStorage[index].key);
    }
  }

  // if we have keys then add them to the global key list
  // to ensure we don't pick up these keys again
  if (keys.length) {
    Array.prototype.push.apply(globalKeyList, keys);
  }

  return [messages, keys, moreMessagesInStorage];
}

function removeKeysFromGlobalList(keys) {
  // remove these keys from our global key list
  if (globalKeyList.length && keys) {
    var position = globalKeyList.indexOf(keys[0]);
    globalKeyList.splice(position, keys.length);
  }
}

function removeMessagesFromStorage(keys) {
  if (!keys) {
    return;
  }

  for (var i = 0; i < keys.length; i++) {
    try {
      localStorage.removeItem(keys[i]);
    } catch (e) {
      consoleLog("Unable to remove message from localStorage: " + e.message);
    }
  }
}

export function resetMessageInterval(interval) {
  var newInterval = interval || 10;

  if (newInterval < 10) {
    newInterval = 10;
  }

  if (newInterval < messageInterval) {
    messageInterval = newInterval;
  }
}

function setMessageInterval(callFailed) {
  // on a successful call with standard interval
  // do nothing
  if (!callFailed && messageInterval === 10) {
    return;
  }

  // below 10 seconds we alter the interval
  // by factor of 10
  if (messageInterval < 10000) {
    if (callFailed) {
      messageInterval = messageInterval * 10;
    } else {
      messageInterval = messageInterval / 10;

      // check we aren't below standard internal
      if (messageInterval < 10) {
        messageInterval = 10;
      }
    }

    return;
  }

  // at 10 seconds we for failure to 30 seconds

  if (messageInterval === 10000) {
    if (callFailed) {
      messageInterval = 30000;
    } else {
      messageInterval = 1000;
    }

    return;
  }

  // if at 30 secs & call succeeded we need to step
  // down to 10 secs
  if (!callFailed && messageInterval === 30000) {
    messageInterval = 10000;

    return;
  }

  // at higher levels we alter the message interval
  // by a factor of 2
  if (callFailed) {
    // the max interval we use is 16 min, if we've
    // reached that then don't increase any further
    if (messageInterval < 960000) {
      messageInterval = messageInterval * 2;
    }
  } else {
    messageInterval = messageInterval / 2;
  }
}

function debounce(func, wait, immediate) {
  var timeout;

  return function() {
    var context = this,
      args = arguments;

    var later = function() {
      timeout = null;

      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function logMessageToServer() {
  var messageDetails = getMessagesToSend();
  var messages = messageDetails[0];
  var keys = messageDetails[1];
  var messagesStillInStorage = messageDetails[2];

  // no messages so exit
  if (!messages.length) {
    return;
  }

  var logMessage = {
    session: {
      client: getPlatform(),
      currentAgentSessionId: agentSessionId
    },

    logMessages: messages
  };

  var updateMessageInterval = debounce(setMessageInterval, 500);
  sendMessageToServer(logMessage, keys, messagesStillInStorage, updateMessageInterval);
}

function afterRequest(callFailed, moreMessages, updateMessageInterval) {
  updateMessageInterval(callFailed);

  if (storageFull && !callFailed) {
    storageFull = false;
  }

  if (moreMessages) {
    addSendMessageCommandToEventQueue();
  }
}

function requestSucceeded(keys, moreMessages, updateMessageInterval) {
  removeMessagesFromStorage(keys);

  afterRequest(false, moreMessages, updateMessageInterval);
}

function requestFailed(xhr, keys, moreMessages, updateMessageInterval) {
  if (xhr.status === 0 || xhr.status === 401) {
    removeKeysFromGlobalList(keys);
  } else {
    removeMessagesFromStorage(keys);
  }
  var origin = corsOrigin || window.location.origin;
  consoleLog(`Loupe JavaScript Logger: Failed to log to ${origin}`);
  consoleLog("  Status: " + xhr.status + ": " + xhr.statusText);
  afterRequest(true, moreMessages, updateMessageInterval);
}

function sendMessageToServer(logMessage, keys, moreMessages, updateMessageInterval) {
  try {
    var origin = corsOrigin || window.location.origin;
    origin = stripTrailingSlash(origin);
    var xhr = createCORSRequest(origin);

    if (!xhr) {
      consoleLog("Loupe JavaScript Logger: No XMLHttpRequest; error cannot be logged to Loupe");
      return false;
    }

    consoleLog(logMessage);

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status <= 204) {
          requestSucceeded(keys, moreMessages, updateMessageInterval);
        } else {
          requestFailed(xhr, keys, moreMessages, updateMessageInterval);
        }
      }
    };

    xhr.send(JSON.stringify(logMessage));
  } catch (e) {
    consoleLog("Loupe JavaScript Logger: Exception while attempting to log");

    return false;
  }
}

function stripTrailingSlash(origin) {
  return origin.replace(/\/$/, "");
}

function createCORSRequest(url) {
  if (typeof XMLHttpRequest === "undefined") {
    return null;
  }

  var xhr = new XMLHttpRequest();

  if ("withCredentials" in xhr) {
    // Check if the XMLHttpRequest object has a "withCredentials" property.
    // "withCredentials" only exists on XMLHTTPRequest2 objects.
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");

    // if we have an auth header then add it to the request
    if (authHeader) {
      xhr.setRequestHeader(authHeader.name, authHeader.value);
    }
  } else {
    // Otherwise, CORS is not supported by the browser.
    xhr = null;
  }

  return xhr;
}

function consoleLog(msg) {
  if (!AppSettings.VerboseLoupeLogging) return;

  var console = window.console;

  if (console && typeof console.log === "function") {
    console.log(msg);
  }
}
