import AppSettings from "../../AppSettings";
import { FindEmployee } from "../../../src/requests/data/GetStaffInfo";
import * as loupe from "../../Loupe.JavaScript.Agent";

const arId = "<!subteam^SR0CZN8M6|AR>";
const mgrId = "<!subteam^SR4GC822X|NEW>";

function handleErrors(response) {
  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw {
      status: response.status,
      type: response.type,
      url: response.url,
      statusText: response.statusText
    };
  }
  return response;
}

async function SendNotification(payload) {
  const endpoint = AppSettings.SlackWebHook;
  await fetch(endpoint, {
    method: "post",
    body: JSON.stringify(payload)
  })
    .then(handleErrors)
    .then(() => console.log(`Sent Slack Notification: ${payload.text}`))
    .catch(error => loupe.Error("Failed to send Slack notification", error));
}

function formatSubject(subject, notifyAR) {
  // This is some Slack magic to mention the Dashboard Managers group
  // see: https://stackoverflow.com/questions/41548739/how-to-alert-a-custom-group-in-slack-using-incoming-webhook
  const dashers = notifyAR ? `${arId} ${mgrId}: ` : `${mgrId}: `;

  const index = subject.indexOf("ref:");
  if (index < 0) {
    return `${dashers}*${subject}*`;
  }
  const head = subject.substr(0, index);
  return `${dashers}*${head}*`;
}

async function sendOrderNotification(subject, customer, requestId, partList, shippingCaption) {
  await SendNotification({
    text: subject,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${formatSubject(subject)}\n<${AppSettings.RequestStatusPage(
            requestId
          )}|View Request: ${requestId}>\n\n${partList}`
        },
        fields: [
          {
            type: "mrkdwn",
            text: `*Name:*\n${customer.name}`
          },
          {
            type: "mrkdwn",
            text: `*Company:*\n${customer.company}`
          },
          {
            type: "mrkdwn",
            text: `*PO/Job Name:*\n${customer.po ? customer.po : "--- Contact about payment ---"}`
          },
          {
            type: "mrkdwn",
            text: `*Shipping Method:*\n${shippingCaption}`
          }
        ]
      },
      {
        type: "divider"
      }
    ]
  });
}

async function sendResearchNotification(subject, request, requestId) {
  await SendNotification({
    text: subject,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${formatSubject(subject)}\n<${AppSettings.RequestStatusPage(requestId)}|View Request: ${requestId}>`
        },
        fields: [
          {
            type: "mrkdwn",
            text: `*Name:*\n${request.name}`
          },
          {
            type: "mrkdwn",
            text: `*Company:*\n${request.company}`
          },
          {
            type: "mrkdwn",
            text: `*Make:*\n${request.make}`
          },
          {
            type: "mrkdwn",
            text: `*Model:*\n${request.model}`
          },
          {
            type: "mrkdwn",
            text: `*Serial Number:*\n${request.serial}`
          },
          {
            type: "mrkdwn",
            text: `*Part Description:*\n${request.description}`
          }
        ]
      },
      {
        type: "divider"
      }
    ]
  });
}

async function sendEmailNotification(subject, customer, requestId) {
  await SendNotification({
    text: subject,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${formatSubject(subject)}\n<${AppSettings.RequestStatusPage(
            requestId
          )}|View Request: ${requestId}>\n\n${customer.notes}`
        },
        fields: [
          {
            type: "mrkdwn",
            text: `*Name:*\n${customer.name}`
          },
          {
            type: "mrkdwn",
            text: `*Email:*\n${customer.email}`
          }
        ]
      },
      {
        type: "divider"
      }
    ]
  });
}

async function sendAccountNotification(subject, customer, requestId) {
  await SendNotification({
    text: subject,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${formatSubject(
            subject,
            true // this second argument indicates that A/R should be notified
          )}\n_Waiting on Customer: Needs follow-up in a couple days_\n<${AppSettings.RequestStatusPage(
            requestId
          )}|View Request: ${requestId}>`
        },
        fields: [
          {
            type: "mrkdwn",
            text: `*Email:*\n${customer.email}`
          },
          {
            type: "mrkdwn",
            text: `*Phone:*\n${customer.phone}`
          }
        ]
      },
      {
        type: "divider"
      }
    ]
  });
}

async function sendUpdateNotification(request, action, updatedBy, owner, note) {
  // Smart mention logic added to provide CSRs better notification behavior
  const oldOwner = FindEmployee(request.Owner);
  const newOwner = FindEmployee(owner);
  const updater = FindEmployee(updatedBy);
  const notifyNew = newOwner !== updater ? newOwner : null;
  const notifyOld = oldOwner !== updater && oldOwner !== notifyNew ? oldOwner : null;
  const assignNotify = (newOwner, oldOwner) => {
    const n = newOwner && newOwner.slackId ? newOwner.slackId : null;
    const o = oldOwner && oldOwner.slackId ? oldOwner.slackId : null;
    if (n && o) return `<@${n}>, <@${o}>: `;
    if (n) return `<@${n}>: `;
    if (o) return `<@${o}>: `;
    return "";
  };
  var notify = assignNotify(notifyNew, notifyOld);
  if (request.RequestType === "Account") {
    notify = notify ? `${arId} ${notify}` : `${arId}: `;
  }

  owner = newOwner.fullname;
  updatedBy = updater.fullname;

  var tail;
  switch (action) {
    case "Claim":
      tail = `claimed by ${updatedBy}`;
      break;
    case "Assign":
      tail = `assigned to ${owner} by ${updatedBy}`;
      break;
    case "Complete":
      tail = `completed by ${updatedBy}`;
      break;
    case "Wait on Customer":
      tail = `set to Waiting on Customer by ${updatedBy}`;
      break;
    case "Wait on Factory":
      tail = `set to Waiting on Factory by ${updatedBy}`;
      break;
    default:
      tail = "";
      break;
  }
  var id = request.RequestId;
  var type = request.RequestType;
  var status = request.Status;
  var text = `${status} ${type} Request ${id}`;
  var formattedText = `${notify}${status} ${type} Request <${AppSettings.RequestStatusPage(id)}|${id}>`;
  if (request.CustomerId) {
    tail = `from ${request.CustomerId} ${tail}`;
  }
  if (note) tail += `\n_${note}_`;

  await SendNotification({
    text: `${text} ${tail}`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${formattedText} ${tail}`
        }
      },
      {
        type: "divider"
      }
    ]
  });
}
export {
  sendOrderNotification,
  sendResearchNotification,
  sendEmailNotification,
  sendAccountNotification,
  sendUpdateNotification
};
