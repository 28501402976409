import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import "../styling/styles.css";
import CheckoutForm from "../checkout/CheckoutForm";
import OrderSummary from "../checkout/OrderSummary";
import Breadcrumb from "../navigation/Breadcrumb";
import { Helmet } from "react-helmet";
import useCheckoutInfo from "../../requests/hooks/useCheckoutInfo";
import useVerifyOrder from "../../requests/hooks/useVerifyOrder";
import MakeModelSerial from "../checkout/MakeModelSerial";
import WillCallNote from "../checkout/WillCallNote";
import Prop65 from "../checkout/Prop65";
import ShippingTime from "../checkout/ShippingTime";
import NonRefundable from "../checkout/NonRefundable";
import CheckoutTitle from "../checkout/CheckoutTitle";
import Notice from "../components/Notice"
import InventoryDisclaimer from "../checkout/InventoryDisclaimer";

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 180px;
  margin-right: 20px;
`;
const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-bottom: 4px;
`;
const BodyText = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: var(--black);
  max-width: 600px;
`;
const Column = styled.div`
  float: left;
`;

// We need this hack to initialize a few state variables properly
const checkoutStorageKey = "cpc-checkout";

export default function Checkout(props) {
  const context = useContext(AppContext);
  const { cartParts, goto, clearCart, account, discountLevel, contactInfo } = context;

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      const sum = (total, part) => total + part.price * part.quantity;
      const subtotal = cartParts.reduce(sum, 0);
      const props = {
        value: subtotal,
        currency: "USD",
      }
      console.log("BEGIN CHECKOUT", props)
      window.gtag('event', 'begin_checkout', props)
    } else {
      console.log('gtag function not available');
    }
  }, [])
  const checkForValue = (name, defaultValue) => {
    try {
      const item = window.localStorage.getItem(checkoutStorageKey);
      return item ? JSON.parse(item)[name] : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  };
  const customerAccount = discountLevel === "Employee" || (account && account.includes("@")) ? null : account;
  const result = useCheckoutInfo(customerAccount);
  const checkoutInfo = result.response;
  const [custKey, setCustKey] = useState(0);
  const [address, setAddress] = useState(checkForValue("address", contactInfo.address));
  const [zipcode, setZipcode] = useState(checkForValue("zipcode", contactInfo.zipcode));
  const [shippingMethod, setShippingMethod] = useState("UPS Ground", "");
  const [tbd, setTbd] = useState(false);
  const [addressIndex, setAddressIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);
  const [isQuote, setIsQuote] = useState(checkForValue("isQuote", false));
  const [salesTax, setSalesTax] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const addressKey = addressIndex > 0 && checkoutInfo ? checkoutInfo.ShippingAddresses[addressIndex - 1].Key : 0;
  const verifyOrder = useVerifyOrder(
    custKey,
    cartParts,
    addressKey,
    address,
    zipcode,
    shippingMethod,
    json => {
      setSalesTax(json.SalesTax);
      if (json.HighConfidence) {
        console.log("High Confidence shipping & tax", json.ShippingCost, json.SalesTax);
        setShippingCost(json.ShippingCost);
      } else {
        console.log("Low Confidence shipping & tax", json.ShippingCost, json.SalesTax);
        setShippingCost(0);
      }
    },
    customerAccount && !custKey // Don't call while we're still waiting for customerInfo
  );
  const invalidAddress = verifyOrder && verifyOrder.error && verifyOrder.error.status === 422 && verifyOrder.error.message === 'Invalid Address';
  const cantEstimateShipping = (verifyOrder && verifyOrder.error && verifyOrder.error.status >= 300) || shippingCost === 0;

  console.log("VerifyOrder", verifyOrder)
  if (checkoutInfo && checkoutInfo.CustKey && checkoutInfo.CustKey !== custKey) {
    console.log("Set CustKey", checkoutInfo.CustKey);
    setCustKey(checkoutInfo.CustKey);
  }

  if (cartParts.length === 0) {
    props.history.replace("/");
    return (
      <Wrapper>
        <Title>Your cart is empty</Title>
      </Wrapper>
    );
  } else if (result.error) {
    return (
      <Wrapper>
        <Title>Checkout is currently unavailable</Title>

        <BodyText>
          Our apologies, our checkout system encountered an error. Your shopping cart has been retained. We are working
          on the issue and will make the necessary corrections as quickly as possible. Please try again later, or call
          any of our branches.
        </BodyText>

        <Breadcrumb title="Continue shopping" />
      </Wrapper>
    );
  } else if (customerAccount && !checkoutInfo) {
    return (
      <Wrapper>
        Loading your account information...
      </Wrapper>
    );
  } else {
    return (
      <>
        {new Date() > new Date("10/1/2022") && new Date() < new Date("11/12/2022") &&
          <Notice id='SalesTax'><p>As of November 1<sup>st</sup>, Case Parts is also collecting sales tax on orders shipped to:<br />
            <b>Alaska</b>, <b>Connecticut</b>, <b>Oklahoma</b>, and <b>Texas</b>
            <br />unless a current exemption certificate is on file.<br />
            This in addition to the list of states where we already collect sales tax.</p>
            <Link to='/SalesTax'>Learn more about placing tax-exempt orders</Link></Notice>
        }
      <Wrapper>
        <Helmet>
          <title>Review your order - Case Parts Commercial Refrigeration Parts Specialists</title>
          <meta
            name="description"
            content="Case Parts Commercial Refrigeration Parts Specialists including Latches, Hinges, Gaskets, Heaters, Pans, Walk-In Equipment, Electrical, Thermometers, Alarms, Shelving"
          />
        </Helmet>
        <CheckoutTitle isQuote={isQuote} setIsQuote={setIsQuote} />
        <Column>
          <CheckoutForm
            parts={cartParts}
            goto={goto}
            clearCart={clearCart}
            account={customerAccount}
            checkoutInfo={checkoutInfo}
            address={address}
            setAddress={setAddress}
            zipcode={zipcode}
            setZipcode={setZipcode}
            shippingMethod={shippingMethod}
            setShippingMethod={setShippingMethod}
            custKey={custKey}
            shippingCost={shippingCost}
            isLoadingShipping={verifyOrder.isLoading}
            salesTax={salesTax}
            tbd={tbd}
            setTbd={setTbd}
            addressIndex={addressIndex}
            setAddressIndex={setAddressIndex}
            cardIndex={cardIndex}
            setCardIndex={setCardIndex}
            isLoading={result.isLoading}
            isQuote={isQuote}
            invalidAddress={invalidAddress}
          />
        </Column>
        <Column>
          <OrderSummary
            shipping={tbd || cantEstimateShipping ? "TBD" : shippingCost}
            salesTax={salesTax}
            isQuote={isQuote}
            invalidAddress={invalidAddress}
            shippingMethod={shippingMethod}
            checkoutInfo={checkoutInfo}
            shippingEstimates={verifyOrder.response}
            isLoadingShipping={verifyOrder.isLoading}
            location={props.location}
          />
          {shippingMethod.startsWith("Will Call - ") && <WillCallNote branch={shippingMethod.substring(12)} />}
          <NonRefundable parts={cartParts} />
          <InventoryDisclaimer shippingEstimates={verifyOrder.response} isLoading={verifyOrder} shipMethod={shippingMethod} />
          <ShippingTime shippingMethod={shippingMethod} />
          <Prop65 zipcode={zipcode} />
          <MakeModelSerial />
        </Column>
      </Wrapper>
      </>
    );
  }
}
