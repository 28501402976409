import React, { useState, useContext } from "react";
import styled from "styled-components";
import "../styling/styles.css";
import Creatable from "react-select/lib/Creatable";
import AppContext from "../../context/AppContext";

export default function SearchBox(props) {
  const { search, getKeywordOptions, query } = useContext(AppContext);
  const [token, setToken] = useState("");

  // Initial value of search should be current query
  // Be careful to remove excess whitespace
  const cleanQuery = query ? query : "";
  const defaultValue = cleanQuery
    .split(" ")
    .filter(t => t.trim() !== "")
    .map(t => ({ label: t.toUpperCase(), value: t }));

  // run search upon new keyword token
  const onChange = inputValue => {
    const text = inputValue
      .map(x => x.value)
      .join(" ")
      .trim();
    if (text !== cleanQuery) {
      search(text);
    }
    setToken("");
  };

  const onKeyDown = e => {
    if (e.keyCode === 32 || e.keyCode === 188 || e.keyCode === 13) {
      // space and comma add current token to search
      e.preventDefault();
      if (!query.includes(token)) {
        search(`${query} ${token}`.trim());
        setToken("");
      }
    } else if (e.keyCode === 8) {
      // handle backspace explicitly
      e.preventDefault();
      if (token !== "") {
        setToken(token.substring(0, token.length - 1));
      } else if (query) {
        const tokens = query.split(" ");
        const newQuery = tokens
          .slice(0, tokens.length - 1)
          .join(" ")
          .trim();
        search(newQuery);
      }
    } else if (isAllowedKey(e.key)) {
      setToken(`${token}${e.key}`);
    } else if (!isUnfilteredKey(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const onBlur = () => setToken("");

  const isAllowedKey = key => {
    var input = `${key}`;
    if (/[a-zA-Z0-9-_]/.test(input) && input.length === 1) return true;
    return false;
  };

  const isUnfilteredKey = key => {
    var input = `${key}`;
    const unfilteredKeys = ["Enter", "Tab", "ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"];
    const isUnfiltered = unfilteredKeys.includes(input);
    return isUnfiltered;
  };

  const CreateWrapper = styled.div`
    // background: var(--highlight-yellow);
    // padding: 8px;
  `;
  const CreateLabel = styled.span`
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
  `;
  const CreateText = styled.span`
    // font-size: 16px;
    // font-style: regular;
    // font-weight: 600;
  `;

  const selectRef = React.createRef();

  return (
    // After hours of researching clipboard operations, I finally found this technique:
    // https://stackoverflow.com/a/53320587/26553
    <div
      onPaste={e => {
        if (e.clipboardData) {
          const text = e.clipboardData.getData("text/plain");
          search(text);
          setToken("");
          selectRef.current.blur();
        }
      }}
    >
      <Creatable
        {...props}
        ref={selectRef}
        isMulti
        autoFocus
        options={getKeywordOptions(token)}
        placeholder={"Enter keywords or Part #"}
        noOptionsMessage={() => {
          return query ? null : (
            <span>
              Search by part number or term and matching keywords will appear.
              <ul>
                <li>Press ENTER to view all parts matching your search term.</li>
                <li>Or use the UP/DOWN &amp; TAB keys to select a keyword from the list.</li>
              </ul>
              Enter multiple search terms to further refine search results.
            </span>
          );
        }}
        formatCreateLabel={userInput => (
          <CreateWrapper>
            <CreateLabel>Search for: </CreateLabel>
            <CreateText>
              "<b>{userInput}</b>"
            </CreateText>
          </CreateWrapper>
        )}
        inputValue={token}
        onKeyDown={onKeyDown}
        value={defaultValue}
        onChange={onChange}
        styles={props.styles}
        onBlur={onBlur}
      />
    </div>
  );
}
