import React from "react";
import styled from "styled-components";
import "../styling/styles.css";

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
`;
const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-bottom: 8px;
`;
const Subtitle = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  padding-top: 8px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;
const BodyText = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
  margin-bottom: 8px;
  max-width: 480px;
  padding: 0px 8px 8px 8px;
  padding-left: 16px;
`;
const Column = styled.div`
  float: left;
  width: 500px;
  margin: 0px 12px;
`;
const CloseButton = styled.div`
  float: right;
  margin: 8px;
  font-size: 14px;
  color: var(--CP-blue);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--black);
    background-color: var(--highlight-orange);
  }
`;
export default function Legal(props) {
  return (
    <Wrapper>
      <CloseButton onClick={() => props.close()}>Close</CloseButton>
      <Title>Important Read Carefully</Title>
      <Column>
        <Subtitle>Copyright Information</Subtitle>
        <BodyText>
          I understand the contents of this catalog are protected by copyright owned by Celsius Joint Venture, and that
          permission to duplicate any part of this catalog is prohibited without prior written authorization from
          Celsius Joint Venture at 877 Monterey Pass Road, Monterey Park California 91754.
        </BodyText>
        <Subtitle>Manufacturer's Part Numbers</Subtitle>
        <BodyText>
          Any trademark or service mark shown or displayed herein are used for purposes of identification only. The use
          of any trademark or service mark herein is not intended to represent, imply or otherwise suggest a sponsorship
          or endorsement of the products listed herein by their respective trademark or service mark holders. All
          trademarks, service marks and brands appearing herein are the property of their respective owners.
        </BodyText>
        <Subtitle>Warning</Subtitle>
        <BodyText>
          Some items distributed by Case Parts Company may contain chemicals known to the State of California to cause
          cancer or reproductive harm.
        </BodyText>
      </Column>
    </Wrapper>
  );
}
