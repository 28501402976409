import AppSettings from "../../AppSettings"

export const imageCategories = {
  SPIN: "360",
  SPIN_HERO: "360-hero",
  SUPPLEMENTAL: "2D/",
  GLASTENDER: "GT",
}

export const MapSrc = (resourcePath, partNumber) => {
  if(resourcePath.startsWith(imageCategories.SPIN_HERO))
    return resourcePath.replace("360-hero/", AppSettings.SpinStill(partNumber))
  
  if(resourcePath.startsWith(imageCategories.SPIN))
    return resourcePath.replace("360/", AppSettings.SpinStill(partNumber))

  if(resourcePath.startsWith(imageCategories.SUPPLEMENTAL))
    return resourcePath.replace("2D/", AppSettings.Supplemental(partNumber))

  return AppSettings.PartImage(resourcePath)
}

export const isSpin = (resourcePath) => {
  return resourcePath && resourcePath.startsWith(imageCategories.SPIN)
}

export const isSpinHero = (resourcePath) => {
  return resourcePath && resourcePath.startsWith(imageCategories.SPIN_HERO)
}

export const isSupplemental = (resourcePath) => {
  return resourcePath && resourcePath.startsWith(imageCategories.SUPPLEMENTAL)
}

