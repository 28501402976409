import React from "react";
import styled from "styled-components";
import { Field, ErrorMessage } from "formik";
import Popup from "reactjs-popup";
import images from "react-payment-inputs/images";
import { listByStateCode } from '../../data/states';
import provinces from './DataProvinces'
import Select from "react-select";
import { useRef } from "react";
import getStateCode from "../../requests/hooks/getStateCode";

const Extra = styled.div`
  float: right;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  margin-right: 0px;
`;
const Link = styled.a`
  margin-left: 20px;
`;
const KnownCreditCard = styled.div`
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 380px;
  border: solid 1px #c6c6c6;
  background-color: #e0e0e0;
  }
`;
const ClickableCreditCard = styled(KnownCreditCard)`
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;
const Caption = styled.span`
  padding-left: 8px;
  display: inline-block;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
`;
const Label = styled.span`
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  font-style: italic;
`;
const Value = styled.span`
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 14px;
  font-weight: bold;
`;
const PopupWrapper = styled.div`
  margin: 0px;
  padding: 16px 16px 0px 16px;
  max-height: 80vh;
  overflow-y: scroll;
`;
const CardImages = styled.img`
  margin-left: 106px;
  margin-bottom: -4px;
`;
const CheckboxWrapper = styled.div`
  margin-top: -10px;
  margin-left: 32px;
  color: #4a4a4a;
  font-size: 13px;
`;
const hideCCEditor = { visibility: 'hidden', height: '0px', width: '0px' };
const showCCEditor = { visibility: 'visible', height: '100%', width: '100%' };

const FormField = ({ name, required, caption, type, component, placeholder, errors, touched }) => (
  <>
    <label htmlFor={name}>
      {caption}
      {required && <strong>*</strong>}
    </label>
    <ErrorMessage name={name} className="error" component="div" />
    <Field
      component={component || "input"}
      type={type || "text"}
      name={name}
      placeholder={placeholder}
      className={errors[name] && touched[name] ? "text-input error" : "text-input"}
    />
  </>
);

const PostalCodeField = ({ setFieldValue, handleChange, handleBlur, values, errors, touched }) => {
  const postalRef = useRef();
  const fieldName = "billingZipcode";
  const invalidCharacters = /[^A-Za-z0-9 ?]/;

  // Format for Canadian Postal Codes. A1A 1A1
  const handleKeyUp = (e) => {
    let post = postalRef.current.value.toUpperCase();
    const isCanada = ["CA", "CAN"].indexOf(values.billingCountry) >= 0;

    // if backspacing into the space character then auto delete the space
    if (e.keyCode === 8 && isCanada && post.length === 4) {
      post = post.substring(0, 3);
    }

    // if typing - add a space after third character
    if (e.keyCode !== 8 && isCanada && post.length === 3) {
      post = post + ' ';
    }
    // ELSE if typing into the 4th character insert a space into position 3 (A1A 1A1)
    else if (e.keyCode !== 8 && isCanada && post.length === 4) {
      post = [post.slice(0, 3), ' ', post.slice(3)].join('');
    }

    // guard against invalid characters / multiple spaces
    post = post.replace(/  +/g, ' ').replace(invalidCharacters, '');


    setFieldValue(fieldName, post);
    postalRef.current.value = post;
  }

  return (<>
    <label htmlFor={fieldName}>
      {["CA", "CAN"].indexOf(values.billingCountry) >= 0 ? "Billing Postal Code" : "Billing Zipcode"}
      <strong>*</strong>
    </label>
    <ErrorMessage name={fieldName} className="error" component="div" />
    <input
      ref={postalRef}
      type="text"
      id={fieldName}
      name={fieldName}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      value={values.billingZipcode}
      placeholder={["CA", "CAN"].indexOf(values.billingCountry) >= 0 ? "Enter postal code" : "Enter zipcode"}
      className={errors["billingZipcode"] && touched["billingZipcode"] ? "text-input error" : "text-input"}
    />
  </>
  )
};

function State({ values, touched, setFieldValue }) {
  const stateCode = getStateCode(values.billingZipcode);
  const state = getStateCode(values.billingZipcode, true);
  if (values.billingState !== stateCode) {
    setFieldValue("billingState", stateCode)
  }
  var className = "readonlytext";
  if (touched.billingZipcode && !stateCode) {
    className += " error";
  }
  return (
    <>
      <label>
        Billing State<strong>*</strong> <i style={{ fontWeight: "normal", fontSize: "75%" }}>(implied by zipcode)</i>
      </label>
      <div className={className}>{state ? state : "Enter a valid US zipcode below"}</div>
    </>
  );
}

function StateSelectField({ field, form }) {
  const stateOptions = listByStateCode().map((c, i) => { return { label: c.name, value: c.code } });
  const provinceOptions = provinces.map((c, i) => { return { label: c, value: c } });

  const isCanadian = form.values.billingCountry === "CA" || form.values.billingCountry === "CAN";
  const options = isCanadian ? provinceOptions : stateOptions;

  // doc: https://react-select.com/styles
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: "23px",
      "min-height": "23px",
      marginTop: "-5px"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
      marginTop: "0px"
    }),
    control: (provided, state) => ({
      ...provided,
      height: "36px",
    }),
    container: (provided, state) => ({
      ...provided,
      height: "34px",
      width: "400px",
    })
  };

  if (form.errors[field.name] && form.touched[field.name]) {
    customStyles.control = (provided, state) => ({
      ...provided,
      border: "1px solid red",
      height: "36px",
      "&:hover": {
        borderColor: "red"
      }
    });
  } else {
    customStyles.control = (provided, state) => ({
      ...provided,
      height: "36px"
    });
  }

  customStyles.container = (provided, state) => ({
    ...provided,
    height: "34px",
    width: "400px",
    marginBottom: "20px"
  });

  const currentOption = options.find(option => option.value === field.value);
  const value = currentOption ? currentOption : null;

  return (
    <Select
      required
      isSearchable={true}
      isClearable={false}
      className="text-input"
      styles={customStyles}
      options={options}
      value={value}
      onChange={option => form.setFieldValue(field.name, option ? option.value : "")}
      onBlur={() => form.setFieldTouched(field.name)}
    />
  );
}

function CountrySelectField({ field, form }) {
  const options = [
    { label: "USA", value: "US" },
    { label: "CAN", value: "CA" }
  ]

  // doc: https://react-select.com/styles
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: "23px",
      "min-height": "23px",
      marginTop: "-5px"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
      marginTop: "0px"
    }),
    control: (provided, state) => ({
      ...provided,
      height: "36px"
    }),
    container: (provided, state) => ({
      ...provided,
      height: "34px",
      width: "380px",
      marginBottom: "20px"
    })
  };

  const handleCountryChange = (option) => {
    form.setFieldValue(field.name, option ? option.value : "");
    form.setFieldValue("billingState", "");
  }

  return (
    <Select
      required
      isSearchable={false}
      isClearable={false}
      className="text-input"
      styles={customStyles}
      options={options}
      value={options.find(option => option.value === field.value)}
      defaultValue={{ label: field.value ? field.value : "USA", value: field.value ? field.value : "US" }}
      onChange={handleCountryChange}
      onBlur={() => form.setFieldTouched(field.name)}
    />
  );
}

function Card({ Key, Name, Expiration, LastFourDigits, onClick }) {
  return (
    <KnownCreditCard key={Key} onClick={onClick}>
      <Caption>{Name}</Caption>
      <Label>last four:</Label>&nbsp;
      <Value>{LastFourDigits}</Value>&nbsp;&nbsp; &nbsp;&nbsp;
      <Label>exp:</Label>&nbsp;
      <Value>{Expiration}</Value>
    </KnownCreditCard>
  );
}

function ClickableCard({ Key, Name, Expiration, LastFourDigits, onClick }) {
  return (
    <ClickableCreditCard key={Key} onClick={onClick}>
      <Caption>{Name}</Caption>
      <Label>last four:</Label>&nbsp;
      <Value>{LastFourDigits}</Value>&nbsp;&nbsp; &nbsp;&nbsp;
      <Label>exp:</Label>&nbsp;
      <Value>{Expiration}</Value>
    </ClickableCreditCard>
  );
}

function getCard(cardIndex, creditCards) {
  if (cardIndex > 0 && creditCards && cardIndex <= creditCards.length) {
    const card = creditCards[cardIndex - 1];
    return <Card {...card} />;
  }
  return <KnownCreditCard>Loading...</KnownCreditCard>;
}



const CreditCard = ({
  values,
  errors,
  touched,
  creditCards,
  cardIndex,
  setCardIndex,
  setFieldValue,
  requestRefresh,
  meta,
  getCardImageProps,
  getCardNumberProps,
  getExpiryDateProps,
  account,
  validate,
  microformError,
  cardTouched,
  securityCodeTouched,
  handleChange,
  handleBlur,
  visible
}) => {
  const extra = (
    <Extra>
      {cardIndex > 0 && (
        <Link
          onClick={() => {
            setCardIndex(0);
            requestRefresh();
          }}
        >
          Enter credit card
        </Link>
      )}
      {creditCards && creditCards.length > 0 && (
        <>
          <Popup
            trigger={<Link>Select from list</Link>}
            modal
            closeOnDocumentClick={true}
            closeOnEscape
            contentStyle={{ width: "440px", backgroundColor: "#ffffff" }}
          >
            {close => (
              <PopupWrapper>
                <h2>Select desired credit card</h2>
                {creditCards.map((address, index) => (
                  <ClickableCard
                    key={index}
                    {...address}
                    onClick={() => {
                      setCardIndex(index + 1);
                      requestRefresh();
                      close();
                    }}
                  />
                ))}
              </PopupWrapper>
            )}
          </Popup>
        </>
      )}
    </Extra>
  );

  const isCanadian = values.billingCountry === "CA" || values.billingCountry === "CAN";

  return (
    <>
      {cardIndex > 0 && (
        <>
          {extra}
          <label htmlFor="cardNumber">Credit Card</label>
          {getCard(cardIndex, creditCards)}
        </>
      )}
      {cardIndex === 0 && (
        <div style={visible ? { ...showCCEditor } : { ...hideCCEditor }}>
          <div>
            {extra}
            <label>
              Credit Card<strong>*</strong>
              <CardImages src="/images/credit-cards.jpg" />
            </label>
            {/* <ErrorMessage name="cardNumber" className="error" component="div" /> */}
            {errors['cardNumber'] ? <p className="error">{errors['cardNumber']}</p> : null}
            <svg {...getCardImageProps({ images })} />
            {/* <Field name="cardNumber">
              {({ field }) => (
                <input
                  className={`cardNumber text-input ${errors.cardNumber && touched.cardNumber ? "error" : ""}`}
                  {...getCardNumberProps({
                    onBlur: field.onBlur,
                    onChange: field.onChange,
                    defaultValue: values.cardNumber
                  })}
                />
              )}
            </Field> */}


            <div className="microform">
              <div
                className={`card-input ${(microformError) && "card-error"}`}
                id="number-container"></div>
              <div
                className={`card-input ${(microformError) && "card-error"}`}
                id="securityCode-container"></div>
            </div>

            <label>Expiration Date<strong>*</strong></label>
            <ErrorMessage name="expiryDate" className="error" component="div" />
            <Field name="expiryDate">
              {({ field }) => (
                <input
                  className={`expiryDate text-input ${errors.expiryDate && touched.expiryDate ? "error" : ""}`}
                  {...getExpiryDateProps({
                    onBlur: field.onBlur,
                    onChange: field.onChange,
                    defaultValue: values.expiryDate
                  })}
                />
              )}
            </Field>


            {/* This block suppressed until we implement this-order-only cards for customers
                https://trello.com/c/n3M6hSUq/196-this-order-only-credit-cards-fro-customers-linked-to-a-sage-account
             */}
            {false && account && (
              <Field name="rememberCard">
                {({ field }) => (
                  <CheckboxWrapper>
                    <input
                      {...field}
                      className="checkbox"
                      type="checkbox"
                      checked={field.value}
                      onChange={e => {
                        console.log("CLICK", field.value, e.target);
                        setFieldValue(field.name, !field.value);
                      }}
                    />
                    <span>Remember this card?</span>
                  </CheckboxWrapper>
                )}
              </Field>
            )}
          </div>
          <div style={{ marginTop: "8px" }}>
            <FormField
              name="nameOnCard"
              caption="Name on Card"
              placeholder="Enter name on credit card"
              required
              errors={errors}
              touched={touched}
            />
            <FormField
              name="billingAddress"
              caption="Billing Street"
              placeholder="Enter street address"
              required
              errors={errors}
              touched={touched}
            />
            <FormField
              name="billingCity"
              caption="Billing City"
              placeholder="Enter city"
              required
              errors={errors}
              touched={touched}
            />
            <FormField
              name="billingCountry"
              caption="Billing Country"
              component={CountrySelectField}
              required
              errors={errors}
              touched={touched}
            />
            {isCanadian &&
              <FormField
                name="billingState"
                caption="Billing Province"
                component={StateSelectField}
                required
                errors={errors}
                touched={touched}
              />
            }
            {!isCanadian &&
              <State values={values} touched={touched} setFieldValue={setFieldValue} />
            }
            <PostalCodeField
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreditCard;
