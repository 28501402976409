import AppSettings from "../../AppSettings";
import { sendResearchNotification } from "./SlackNotification";
import { PostResearchRequest } from "../../requests/PostRequest";

async function SendEmail(email) {
  const endpoint = AppSettings.EmailRequest;
  console.log(`Sending email: ${email.Subject} to ${email.To} from ${email.From} using ${endpoint}`);
  await fetch(endpoint, {
    method: "post",
    headers: {
      // You'd think we'd by sending Content-Type application/json, but doing so causes a preflight check
      // https://dev.to/effingkay/cors-preflighted-requests--options-method-3024
      // Which wouldn't be so bad, but the associated OPTIONS request was generating an error on chrome
      // https://stackoverflow.com/questions/45468033/err-spdy-protocol-error-ajax-net-web-api
      // We think this has something to do with Chrome upgrading the request to HTTP/2, but we punted.
      // FYI, disabling HTTP2 in Chrome allowed us to POST with application/json with no error
      // https://stackoverflow.com/questions/42435786/how-to-disable-http-2-in-chrome-or-chromium
      "Content-Type": "text/plain"
    },
    body: JSON.stringify(email)
  });
  console.log(`Sent email: ${email.Subject}`);
}

function formatRequest(request, account) {
  const formatRow = (name, value) => `<tr><td><i>${name}</i></td><td><b>${value}</b></td></tr>`;

  return `<h3>Part Research Request</h3>
  <table>
  ${account ? formatRow("CustID", account) : ""}
	${formatRow("Company", request.company)}
	${formatRow("Name", request.name)}
	${formatRow("Email", request.email)}
	${formatRow("Phone", request.phone)}
	${formatRow("Prefers", request.preference)}
	${formatRow("Manufacturer", request.make)}
	${formatRow("Model", request.model)}
	${formatRow("Serial Number", request.serial)}
	${formatRow("Part Description", request.description)}
	</table>`;
}

async function sendRequestEmail(subject, message) {
  await SendEmail({
    To: AppSettings.Email.QuoteTo,
    From: AppSettings.Email.QuoteFrom,
    Subject: `${AppSettings.IsProd ? "" : "[TEST] "}${subject}`,
    Message: `${message}<p><i>Submitted from: ${AppSettings.AppVersion}</i></p>`,
    IsHtml: true,
    Cc: "",
    Bcc: AppSettings.Email.QuoteBcc
  });
}

async function sendAcknowledmentEmail(to, subject, message) {
  await SendEmail({
    To: `${to}`,
    From: AppSettings.Email.ReceiptFrom,
    Subject: `${AppSettings.IsProd ? "" : "[TEST] "}${subject}`,
    Message: `${message}`,
    IsHtml: true,
    Cc: "",
    Bcc: AppSettings.Email.ReceiptBcc
  });
}

const append = (text, add) => (text ? `${text} | ${add}` : add);

function formatNote({ location, make, model, serial, description }) {
  var text = location;
  if (make) text = append(text, `Make: ${make}`);
  if (model) text = append(text, `Model: ${model}`);
  if (serial) text = append(text, `S/N: ${serial}`);
  if (description) text = append(text, `${description}`);
  return text;
}
export default async function SubmitResearchRequest(request, onSubmit, account, resetForm, token) {
  const requestId = await PostResearchRequest(
    account,
    request.company,
    request.name,
    request.email,
    request.phone,
    formatNote(request),
    token
  );
  const statusUrl = AppSettings.RequestStatusPage(requestId);
  try {
    const subject = account
      ? `${request.location} Research Request for ${account} by ${request.email} ref:${requestId} `
      : `${request.location} Research Request for ${request.email} ref:${requestId} `;
    const quote = `${formatRequest(request, account)}`;
    await sendRequestEmail(
      subject,
      `<p>Please research parts ${account ? `for custId ${account}` : ""} and contact the customer:</p>
  ${quote}
  <p><a href=${statusUrl}>View Request ${requestId}</a></p>`
    );
    await sendAcknowledmentEmail(
      request.email,
      `We're researching the parts you need  ref:${requestId}`,
      `<p>Dear ${request.name},</p>
	<p>Thank you for trusting Case Parts with your business.  One of our team members
	will be in touch shortly regarding the parts you need.</p>
  ${quote}
  <p>You can reply to this email with any updates or call us referencing request: ${requestId}</p>
	<p>Thanks,<br/>The Case Parts Team</p>`
    );
    await sendResearchNotification(subject, request, requestId);
    onSubmit();
    resetForm();
  } catch (error) {
    onSubmit(error);
  }
}
