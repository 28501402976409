import React from "react";
import CheckoutNote from "./CheckoutNote";
import warehouses from "../../warehouses.json";

export default function WillCallNote({ branch }) {
  console.log("WC", branch);
  const warehouse = warehouses.find(w => branch === w.branch);
  if (!warehouse) return "";

  return (
    <CheckoutNote icon="warehouse-alt" title={`Will Call Pick-up: ${warehouse.office}`}>
      <p>
        <b>{warehouse.willcallMessage}</b>
      </p>
      <p>
        <b>
          <a href={warehouse.map} target="_blank" rel="noopener noreferrer">
            {warehouse.address1} {warehouse.address2}
          </a>
          <br />
          Monday - Friday {warehouse.willcallHours}
          <br />
          {warehouse.localPhone}
        </b>
      </p>
    </CheckoutNote>
  );
}
