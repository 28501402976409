import React from "react";
import styled from "styled-components";
import Attribute from "./Attribute";
import "../../styling/styles.css";

const Details = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0px;
  padding-bottom: 16px;
`;
const Heading = styled.div`
  width: 50px;
  padding-top: 16px;
  padding-left: 17px;
  padding-right: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;
const Attributes = styled.div`
  width: 750px;
  padding-top: 16px;
  padding-left: 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: normal;
`;

export default function PartDetails(props) {
  return (
    <Details>
      <Heading>Part Details</Heading>
      <Attributes>
        {props.attributes &&
          props.attributes.map(attr => <Attribute key={attr.name} name={attr.name} value={attr.value} />)}
      </Attributes>
    </Details>
  );
}
