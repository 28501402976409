import React from "react";
import "./requestManager.css";

export default function RequestWrapper({ result, render: View }) {
  if (!result) {
    return null;
  }

  if (result.error) {
    console.log("Error", result.error);
    return (
      <div className="requestSystemMessage">
        <h1>{`Error Encountered`}</h1>
        <p>{JSON.stringify(result.error)}</p>
      </div>
    );
  }
  if (result.isLoading || !result.response) {
    return (
      <div className="requestSystemMessage">
        <h1>{`Loading...`}</h1>
      </div>
    );
  }

  return <View />;
}
