import AppSettings from "../../AppSettings";
import * as loupe from "../../Loupe.JavaScript.Agent";
import isNumber from "../../data/isNumber";
import getStateCode from "../../requests/hooks/getStateCode";
import { customPartType } from "../../customParts/helpers";
import FormatCheckoutEmails from "./FormatCheckoutEmails"; // can delete
import { PostCheckoutRequest } from "../../requests/PostRequest"; // can delete
import PutRequest from "../../requests/PutRequest"; // keep but remove import
import { sendOrderNotification } from "./SlackNotification"; // keep but remove import

function shippingCaption(values) {
  return values.shipping === "Will Call"
    ? `Will Call - ${values.willCallLocation}`
    : values.shipping + (values.approveShipping ? " - APPROVAL REQUIRED" : "");
}

// function handleErrors(response) {
//   if (!response.ok) {
//     console.log(`handleErrors: ${response.status} ${response.statusText}`, response);
//     // response.text().then(text => {
//     //   const result = { ok: response.ok, status: response.status, statusText: response.statusText, text: text };
//     //   throw result;
//     // });
//     const result = {
//       ok: response.ok,
//       status: response.status,
//       statusText: response.statusText,
//       text: "Error encountered"
//     };
//     throw result;
//   }
//   return response;
// }

// async function SendEmail(email) {
//   const endpoint = AppSettings.EmailRequest;
//   console.log(`Sending email: ${email.Subject} to ${email.To} from ${email.From} using ${endpoint}`);
//   await fetch(endpoint, {
//     method: "post",
//     headers: {
//       // You'd think we'd by sending Content-Type application/json, but doing so causes a preflight check
//       // https://dev.to/effingkay/cors-preflighted-requests--options-method-3024
//       // Which wouldn't be so bad, but the associated OPTIONS request was generating an error on chrome
//       // https://stackoverflow.com/questions/45468033/err-spdy-protocol-error-ajax-net-web-api
//       // We think this has something to do with Chrome upgrading the request to HTTP/2, but we punted.
//       // FYI, disabling HTTP2 in Chrome allowed us to POST with application/json with no error
//       // https://stackoverflow.com/questions/42435786/how-to-disable-http-2-in-chrome-or-chromium
//       "Content-Type": "text/plain"
//     },
//     body: JSON.stringify(email)
//   })
//     .then(handleErrors)
//     .then(() => console.log(`Sent email: ${email.Subject}`))
//     .catch(error => console.log(error));
// }

// const formatPartsAsNote = parts => {
//   const note = parts.map(part => `(${part.quantity}) ${part.partId}: ${part.title}`).join(" | ");
//   return note;
// };

// async function sendRequestEmail(subject, message) {
//   await SendEmail({
//     To: AppSettings.Email.QuoteTo,
//     From: AppSettings.Email.QuoteFrom,
//     Subject: `${AppSettings.IsProd ? "" : "[TEST] "}${subject}`,
//     Message: `${message}<p><i>Submitted from: ${AppSettings.AppVersion}</i></p>`,
//     IsHtml: true,
//     Cc: "",
//     Bcc: AppSettings.Email.QuoteBcc
//   });
// }

// async function sendAcknowledmentEmail(to, subject, message) {
//   await SendEmail({
//     To: `${to}`,
//     From: AppSettings.Email.ReceiptFrom,
//     Subject: `${AppSettings.IsProd ? "" : "[TEST] "}${subject}`,
//     Message: `${message}`,
//     IsHtml: true,
//     Cc: "",
//     Bcc: AppSettings.Email.ReceiptBcc
//   });
// }

async function CreateQuote(
  checkoutType,
  // requestId,
  parts,
  values,
  custKey,
  shippingCost,
  salesTax,
  checkoutInfo,
  isQuote,
  token,
  cardToken
) {
  const addressIndex = values.addressIndex;
  const cardIndex = values.cardIndex;
  // console.log("CreateQuote:", requestId, parts, values, custKey, addressIndex, cardIndex);
  console.log("CreateQuote:", checkoutInfo, parts, values, custKey, addressIndex, cardIndex);
  const items = parts.map(part => {
    const partType = customPartType(part);
    const item = {
      Quantity: isNumber(part.quantity) ? Number(part.quantity) : 1,
      ItemId: part.partId,
      Price: isNumber(part.price) ? Number(part.price) : 0,
      CustomPart: partType,
      GasketSpecs: partType === "Gasket" ? part.specs : {},
      WireSpecs: partType === "Wire" ? part.specs : {},
      Make: part.notes && part.notes.make ? part.notes.make : "",
      Model: part.notes && part.notes.model ? part.notes.model : "",
      Serial: part.notes && part.notes.serial ? part.notes.serial : ""
    };
    return item;
  });
  const endpoint = AppSettings.ApiCreateQuote;
  const denullify = val => (val ? val : "");
  const paymentMethod = isQuote ? "Quote" : values.paymentMethod;
  const quote = {
    CheckoutType: checkoutType,
    // RequestId: requestId,
    ShippingMethod: shippingCaption(values),
    ApproveShipping: !!values.approveShipping,
    PaymentMethod: paymentMethod,
    PO: denullify(values.po),
    Notes: denullify(values.notes),
    Customer: {
      CustKey: custKey ? custKey : 0,
      Name: denullify(values.name),
      Company: denullify(values.company),
      Email: denullify(values.email),
      Phone: denullify(values.phone),
      Preference: denullify(values.preference)
    },
    Items: items,
    ShippingAddress: {
      Key: addressIndex ? checkoutInfo.ShippingAddresses[addressIndex - 1].Key : 0,
      Recipient: !addressIndex ? denullify(values.recipient) : "",
      Street: !addressIndex ? denullify(values.address) : "",
      Addr2: !addressIndex ? denullify(values.suite) : "",
      City: !addressIndex ? denullify(values.city) : "",
      State: !addressIndex ? getStateCode(values.zipcode) : "",
      Zipcode: !addressIndex ? denullify(values.zipcode) : ""
    },
    CreditCard: {
      Key: cardIndex ? checkoutInfo.PaymentMethods.CreditCards[cardIndex - 1].Key : 0,
      Name: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.nameOnCard) : "",
      TransientToken: !cardIndex && values.paymentMethod === "Credit Card" ? cardToken : null,
      // CardNumber: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.cardNumber) : "",
      // CardType: cardType,
      Expiration: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.expiryDate) : "",
      Street: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.billingAddress) : "",
      Zipcode: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.billingZipcode) : "",
      City: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.billingCity) : "",
      State: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.billingState) : "",
      Country: !cardIndex && values.paymentMethod === "Credit Card" ? denullify(values.billingCountry) : "",
      RememberCard: !!values.rememberCard
    },
    ShippingCost: isNumber(shippingCost) ? Number(shippingCost) : 0,
    SalesTax: isNumber(salesTax) ? Number(salesTax) : 0
  };
  const quoteJson = JSON.stringify(quote, null, 2);

  // loupe.Information(`Calling CreateQuote ${requestId}...`, quoteJson);
  loupe.Information(`Calling CreateQuote...`, quoteJson);
  console.log("CreateQuote", quote);
  return await fetch(endpoint, {
      method: "post",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: quoteJson
    })
    .then(response => {
      if(!response.ok) {
        return response.text().then(errorText => {
          throw new Error(errorText);
        });
      }
      return response.text();
    })
    .then(requestId => {
      if (typeof window.gtag === 'function') {
        const sum = (total, part) => total + part.price * part.quantity;
        const subtotal = parts.reduce(sum, 0);
        const props = {
          transaction_id: requestId,
          value: subtotal,
          tax: isNumber(salesTax) ? Number(salesTax) : 0,
          shipping: isNumber(shippingCost) ? Number(shippingCost) : 0,
          currency: "USD",
          coupon: isQuote ? "QUOTE" : ""
        }
        console.log("PURCHASE EVENT", props)
        window.gtag('event', 'purchase', props)
      } else {
        console.log('gtag function not available');
      }
      return requestId
    })
    .catch(err => {
        // loupe.Error(`CreateQuote failed on requestID ${requestId}: ${errMessage}`);
        const errMessage = err.message
        loupe.Error(`CreateQuote failed: ${errMessage}`);
        throw new Error(errMessage);
    })
}

export default async function SubmitOrder(
  parts,
  values,
  account,
  custKey,
  shippingCost,
  salesTax,
  checkoutInfo,
  isQuote,
  //cardType,
  token,
  cardToken
) {
  console.log("SubmitOrder", parts, shippingCost, salesTax);

  // The first big thing we need to do is create a RequestId for this checkout
  // const shortPartList = formatPartsAsNote(parts);
  // const requestId = await PostCheckoutRequest(
  //   account,
  //   values.company,
  //   values.name,
  //   values.email,
  //   values.phone,
  //   shortPartList,
  //   token
  // );

  // The CSR needs to follow-up with customer if any of these conditions exist:
  // 1. The customer asked to discuss shipping options
  // 2. The customer entered a comment in the Notes field
  const isReadyToCommit = values.shipping !== "TBD" && !values.notes && !values.approveShipping;

  const checkoutType = isQuote
    ? isReadyToCommit
      ? "QuoteSent"
      : "QuoteNeeded"
    : isReadyToCommit
      ? "OrderReady"
      : "OrderPending";

  // Now, we can attempt to create the quote
  const requestId = await CreateQuote(
    checkoutType,
    // requestId,
    parts,
    values,
    custKey,
    shippingCost,
    salesTax,
    checkoutInfo,
    isQuote,
    //cardType,
    token,
    cardToken
  )
  // .catch(err => {
    // // If there is an error, delete the request
    // PutRequest(
    //   null,
    //   requestId,
    //   -1,
    //   "System",
    //   "Deleted",
    //   "System",
    //   "Request cancelled due to error calling CreateQuote",
    //   token
    // );
  //   console.log("Error in SubmitOrder", err)
  //   throw err;
  // });

  // const { custSubject, custMessage, csrSubject, csrMessage } = FormatCheckoutEmails(
  //   requestId,
  //   account,
  //   values,
  //   checkoutInfo,
  //   parts,
  //   isQuote,
  //   isReadyToCommit,
  //   checkoutType,
  //   shippingCost,
  //   shippingCaption(values),
  //   salesTax
  // );
  // // Having successfully created the OP quote on the server and formatted appropriate
  // // email messages for the customer and the CSR.  Send the emails
  // await sendRequestEmail(csrSubject, csrMessage);
  // await sendAcknowledmentEmail(values.email, custSubject, custMessage);

  // // Post Slack notification and return
  // var multiLinePartList = shortPartList.replace(/ \| /g, "\n");
  // if (values.notes) multiLinePartList += "\n" + values.notes;
  // await sendOrderNotification(csrSubject, values, requestId, multiLinePartList, shippingCaption(values));

  // Finally, return the requestId and checkout type to caller
  const message = isQuote
    ? checkoutType.toLowerCase()
    : values.willCallLocation && values.shipping.startsWith("Will Call")
      ? `willcall&warehouse=${values.willCallLocation}`
      : isReadyToCommit
        ? "order"
        : "pending";
  return { requestId, message };
}
