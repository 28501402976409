import React from "react";
import styled from "styled-components";
import "../styling/styles.css";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding-left: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: -0.23px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: ${props => (props.selected ? "white" : "#4a4a4a")};
  background: ${props => (props.selected ? "#f06721" : "white")};
`;
const HoverWrapper = styled(Wrapper)`
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: var(--black);
  }
`;
const Icon = styled.img`
  margin-right: 6px;
  width: 11px;
  height: 14px;
`;

export default function BlockLink(props) {
  const contents = (
    <HoverWrapper selected={props.selected}>
      <Icon src={props.isInfo ? "/images/icon-info2-small.svg" : "/images/icon-page.svg"} />
      {props.title}
    </HoverWrapper>
  );
  const wrapper = props.path.includes("http") ? (
    <a href={props.path} target="_blank" rel="noopener noreferrer">
      {contents}
    </a>
  ) : (
    <Link to={props.path}>{contents}</Link>
  );
  return props.selected ? (
    <Wrapper selected={props.selected}>
      <Icon src={props.isInfo ? "/images/icon-info2-small.svg" : "/images/icon-page.svg"} />
      {props.title}
    </Wrapper>
  ) : (
    wrapper
  );
}
