import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import SectionGroup from "./SectionGroup";
import AppSettings from "../../AppSettings";

const Wrapper = styled.div`
  grid-area: bottomLeft;
  overflow-y: auto;
  margin: 0px;
  padding: 6px;
  width: 300px;
  background-color: var(--light-blue);
`;
const HeadingArea = styled.div`
  margin: 8px 6px 20px 6px;
`;
const Icon = styled.img`
  margin-right: 8px;
  width: 50px;
  height: 49px;
  float: left;
`;
const Title = styled.div`
  height: 25px;
  font-size: 22px;
  font-weight: 600;
  color: var(--black);
`;
const Subtitle = styled.div`
  margin-top: 4px;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
`;
const SubtitleLink = styled(Subtitle)`
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
  }
`;
const NoPagesMessage = styled.div`
  margin: 8px 6px 20px 6px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
`;
const ContactUsButton = styled.a`
  background-color: var(--CP-blue);
  color: white;
  border-radius: 4px;
  margin-top: 16px;
  width: 100%;
  padding: 8px;
  display:block;
  box-sizing: border-box;
  text-align: center;
`
const NoMatch = () => (
  <NoPagesMessage>
    <h1>
      Not finding what you need?
      <br />
      Let us help!
    </h1>
    <p>We are experts at researching and sourcing refrigeration parts.</p>
    <p>Every year, we deliver thousands of orders for parts not included in our extensive catalog.</p>
    <ContactUsButton href={AppSettings.OrderLinkClient + '/contactus'} >Contact Us</ContactUsButton>
  </NoPagesMessage>
);

export default function SectionList(props) {
  const context = useContext(AppContext);
  let { query, search, selectedPart, sections } = context;
  if (!query) {
    query = "";
  }
  return (
    <Wrapper>
      <HeadingArea>
        {selectedPart && (
          <>
            <Icon src="/images/icon-search.png" />
            <Title>{selectedPart.partId}</Title>
            <SubtitleLink onClick={() => search("")}>Show All Pages</SubtitleLink>
          </>
        )}
        {query && !selectedPart && (
          <>
            <Icon src="/images/icon-search.png" />
            <Title>{query.length < 16 ? query : `${query.substr(0, 15)}...`}</Title>
            <SubtitleLink onClick={() => search("")}>Clear Search Filter</SubtitleLink>
          </>
        )}
        {!query && !selectedPart && (
          <>
            <Icon src="/images/icon-catalog.png" />
            <Title>Catalog Content</Title>
            <Subtitle>Browse catalog pages</Subtitle>
          </>
        )}
      </HeadingArea>
      {sections.length === 0 && <NoMatch />}
      <SectionGroup category="Part Types" />
      <SectionGroup category="Manufacturers" />
    </Wrapper>
  );
}
