import { useState, useEffect, useRef } from "react";
import AppSettings from "../../AppSettings";

export default function useMicroFlex(apiToken) {
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const [autoCompleteData, setAutoCompleteData] = useState(null);
    const formRef = useRef();

    //set up script
    useEffect(() => {
        if (!window.Flex) {
            const script = document.createElement("script");
            script.src = "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js";
            script.async = true;
            script.onload = () => {
                console.log("loaded microflex script");
            };
            document.body.appendChild(script);
        }

        // Rerun setupMicroform every 15 minutes.  This will clear protected fields if form left open
        const timer = () => setInterval(() => setupMicroform(), 15 * 60 * 1000);
        const timerId = timer();
        console.log("Microflex reset timer STARTED", timerId);
        return () => {
            clearInterval(timerId);
            console.log("Microflex reset timer CLEARED", timerId);
        }
    }, []);

    //set up microform
    useEffect(() => {
        if (!window.Flex) {
            return;
        }
        setupMicroform();
    }, [window.Flex]);

    // This is the actual setup for the microflex fields
    async function setupMicroform() {
      let port = '';
      if (window.location.hostname.includes("localhost"))
          port = window.location.port;
      const apiURL = `${AppSettings.Cybersource}publickey/${port}`;
      console.log("Requesting microflex public key from", apiURL);
      const jwt = await fetch(apiURL, {
          method: "GET",
          headers: {
              Authorization: "Bearer " + apiToken
          }
      })
          .then(resp => resp.text())
          .then(data => data)
          .catch(err => console.log("Caught exception while loading microflex public key", err));

      if (!jwt) {
          console.log("ERROR! Failed to load microflex public key");
          return;
      }

      let styles = {
          'input': {
              'font-size': '16px',
              'font-family': 'helvetica, tahoma, calibri, sans-serif',
              'color': '#555',
              'line-height': '19px'
          },
          ':focus': { 'color': 'blue' },
          ':disabled': { 'cursor': 'not-allowed' },
          'valid': { 'color': '#3C763D' },
          'invalid': { 'color': '#A94442' }
      };

      let flex = new window.Flex(jwt);
      let microform = flex.microform({ styles: styles });
      formRef.current = microform;
      let number = microform.createField('number', { placeholder: 'Card Number' });
      let securityCode = microform.createField('securityCode', { placeholder: 'CVV' });
      //{ placeholder: '•••'}

    // Events: https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ/Events.html
    // Don't validate on autocomplete - can cause token generation limit exceeded error
    // Client needs to validate manually on form submit      
      number.on("autocomplete", function(data){
        setAutoCompleteData(data);
        setToken(null);
      });
  
      securityCode.on("autocomplete", function(data){
        setAutoCompleteData(data);
        setToken(null);
      });

      number.on("change", (e) => {
        setToken(null);
        setError(null);
      });
  
      securityCode.on("change", (e) => {
        setToken(null);
        setError(null);
      })

      number.load('#number-container');
      securityCode.load('#securityCode-container');
    }

    // Promise tokenizes & validates the card # and security code
    function validate() {
      return new Promise((resolve, reject) => {
        if (!formRef.current) {
          return reject(new Error("Form reference is not available."));
        }
    
        console.log("Creating Token");
    
        formRef.current.createToken({}, function (error, token) {
          if (error) {
            console.log("ERROR generating transient token", { error: error});
          }
          
          if(token){
            console.log("Loaded microflex transient token", { token: token });
          }          
  
          let errorMessage = "";
          if(error && error.status === 429){ // Tokenization limit exceeded
            errorMessage = "Credit card could not be validated. Please refresh and try again.";
          }
          else if(error && error.status === 400){ // Server-side validation rejection
            errorMessage = "Invalid credit card. Please double-check details.";
          }
          else if(error && error.message){
            errorMessage = error.message
          }
  
          setError(errorMessage); 
          setToken(token);
  
          if(error){
            reject(new Error(errorMessage)); // Reject the promise with the error
          } else {
            resolve(token); // Resolve the promise with the token
          }
        });
      });
    }

    return {
      validateCard: validate, 
      cardToken: token, 
      cardError: error, 
      autoCompleteData: autoCompleteData
    }
    // return [validate, token, error]

}