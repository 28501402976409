import React from "react"
import InfoPage from "./InfoPage"
import FaqEntry from "./FaqEntry"
import { Link } from "react-router-dom"
import AppSettings from "../../../AppSettings"

export default function Faq(props) {
    return <InfoPage {...props} className="faq">
        <h1>Frequently Asked Questions</h1>
        <h2>Website</h2>
        <FaqEntry history={props.history} title="How do I sign in into my account?">
            <p>
                Click <Link to="/login?returnUrl=faq">Sign in</Link> on the main menu of www.caseparts.com and enter your email address and password. If you find yourself looking at recent orders on the my.caseparts.com page, click the Catalog link to return to www.caseparts.com.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="What to do If I forgot my password?">
            <p>
                If you forget your password, go to the <Link to="/login?returnUrl=faq">Sign in</Link> screen and click the <a href={AppSettings.ForgotPasswordPage}>Forgot Password</a> link. Then enter your email address and click the Recover button.  Check your inbox for an email from operations@caseparts.com and click the Reset Password link in that email.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="How to link my email to my account?">
            <p>
                If you already have a Case Parts account (see above), you can link and register your email to your Case Parts account in the <a href={AppSettings.RegisterUserPage}>Registration</a> page. Note that you will need to know your AccountID and the order number for one of your orders to link your email with your account.
            </p>
        </FaqEntry>
        <h2>Accounting</h2>
        <FaqEntry  history={props.history} title="How do I open an account?">
            <p>
                You do not need a commercial account with Case Parts to purchase from us, but if you are in the trade, this offers advantages including order and invoice history, support for sales tax exemption certificates, and trade discounts.
            </p>
            <p>
                To apply for a commercial account, please complete<a href={AppSettings.RegistrationPage}> this form</a>.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="How to get my invoice?">
            <p>
                If you have a Case Parts account, you can access your invoices online.  Otherwise, any of our friendly Customer Service representatives can email you copies of your invoices. Or, you can contact our Accounting department at <a href="mailto:accounting@caseparts.com">accounting@caseparts.com</a> or call 800-421-0271 and press 3 at the prompt.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="How to update my billing address?">
            <p>
                To update your billing address, please contact our Accounting department at <a href="mailto:accounting@caseparts.com">accounting@caseparts.com</a> or call 800-421-0271 and press 3 at the prompt.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="How can I specify/update shipping addresses for my company?">
            <p>
                To specify/update shipping addresses for your company, please contact our Accounting department at <a href="mailto:accounting@caseparts.com">accounting@caseparts.com</a> or call 800-421-0271 and press 3 at the prompt.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Can I permit multiple employees to access my account?">
            <p>
                Yes, multiple email addresses can be associated with your Case Parts account. If you're the account owner, invite others using the <a href={AppSettings.OrderLinkClient}>User Manager</a> found in the Account page.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Can I limit which users can view invoices?">
            <p>
                Yes, by default, all employees linked to your account have access to your invoice and order history.  If you would like to restrict invoice access, please contact our Accounting department at <a href="mailto:accounting@caseparts.com">accounting@caseparts.com</a> or call 800-421-0271 and press 3 at the prompt.
            </p>
        </FaqEntry>
        <h2>Warranty &amp; Returns</h2>
        <FaqEntry  history={props.history} title="What is your return policy?">
            <p>
                Full details on our return policy are provided at <Link to="/returns">www.caseparts.com/returns</Link>
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="How to request a return?">
            <p>
                To return merchandise, please request a Return Merchandise Authorization (RMA) number from our Customer Service Department by emailing <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a> providing your order number, the part numbers, and the quantities you wish to return.
            </p>
            <p>
                Please note that returns are subject to our <Link to="/returns">return policy</Link>.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="When will I receive my refund for a return?">
            <p>
                Refunds on returns are applied to your account within 30 days of receiving returned parts.
            </p>
        </FaqEntry>
        <h2>Shipping</h2>
        <FaqEntry  history={props.history} title="How to find tracking numbers for my orders?">
            <p>
                If you have a Case Parts account, tracking numbers are available as part of the details provided for each order in your order history.  Alternately, you can obtain a tracking number by reaching out to <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a> including your order number.
            </p>
        </FaqEntry>
        <h2>Credit Cards</h2>
        <FaqEntry  history={props.history} title="Why isn't my credit card being accepted?">
            <p>
                Please verify that the name, billing address, and postal code you entered match the values on file with the bank that issued your credit card.  Also double-check that you entered the correct card number and Card Verification Value (CVV).  Please look for any red text on the form that might provide hints as to which value is in error.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="What credit cards do you accept?">
            <p>
                Case Parts accepts Visa, MasterCard, American Express, and Discover cards.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Why did you charge us more than what the invoice says?">
            <p>
                You have not been overcharged.  Your credit card is not charged until your order has been shipped or picked up at Will Call.  What you may be seeing is the initial funds reservation that appears before we finalize your order. You will not be charged this amount.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Why am I seeing a $1 charge on my credit card?">
            <p>
                Case Parts does not charge your credit card until we ship your order, but the process of validating some credit cards puts a temporary $1 hold on the card.  This hold is released automatically and you will not be charged.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Is my credit card data safe? What if Cybersource gets hacked?">
            <p>
                Case Parts takes credit card security very seriously.  We have partnered with Cybersource, a recognized leader in credit card security and a wholly-owned subsidiary of Visa.  In 2021, Cybersource processed 32 billion transactions from over 480,000 customers equating to $691B in payments.
                </p><p>
                Cybersource is a PCI Level 1 Service Provider.   In basic terms, hacking into Cybersource is like breaking into Fort Knox.  All credit data credit card data for Case Parts customers is encrypted and securely stored with Cybersource providing the highest level of security available.
            </p>
        </FaqEntry>
        <h2>Sales Tax</h2>
        <FaqEntry  history={props.history} title="Why am I being charged sales tax on my order?">
            <p>
                A <a href="https://www.avalara.com/us/en/learn/sales-tax/south-dakota-wayfair.html">Supreme Court ruling in 2018</a> overruled a longstanding physical presence rule, allowing states to require remote sellers to collect and remit sales tax.  As a result, Case Parts is required to collect sales tax for sales to <Link to="/salestax">most states</Link> unless you have an account with us and have a valid sales tax exemption certificate on file.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Must I have an account with Case Parts to place a tax-exempt order?">
            <p>
                Yes, although you do not need a Case Parts account to place an order with us, exemption certificates can only be associated with Case Parts accounts.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="How do I update my reseller permits?">
            <p>
                If you need to add or update your reseller permits, please contact our Accounting department at <a href="accounting@caseparts.com">accounting@caseparts.com</a> or call 800-421-0271 and press 3 at the prompt.
            </p>
        </FaqEntry>
        <FaqEntry  history={props.history} title="Can I use my Exemption Certificate on a one-time basis?">
            <p>
                We can only accept blanket exemption certificates. We can not apply an exemption certificate to a single order. If you are mostly tax-exempt but occasionally need to place a taxable order, you can place that order as a guest user.
            </p>
        </FaqEntry>
    </InfoPage>
}