import React, { useContext } from 'react'
import AppContext from '../../context/AppContext'
import './NationalAccountIdStyles.css'

const WhenDiv = ({ prop, className, style, children }) => !prop ? null : <div className={className} style={style}>{children}</div>

export default function NationalAccountId({ size, account }) {
  const { claims } = useContext(AppContext)
  if(!claims.Customer.HQ){
    return null
  }

  const containerStyles = {
    width: size,
    minWidth: size,
    height: size,
    minHeight: size
  }

  return (
    <React.Fragment>
      <WhenDiv prop={account.CustId === account.HQ} className='nat-acct__hq' style={containerStyles}>
        <img src="/images/headquarters.svg" alt='' />
      </WhenDiv>

      <WhenDiv prop={account.CustId !== account.HQ} className='nat-acct__branch' style={containerStyles}>
        <img src="/images/branch.svg" alt='' />
      </WhenDiv>
    </React.Fragment>
  )
}
