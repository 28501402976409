import AppSettings from "../AppSettings";

const OrderForms = {
  "99991": {
    Key: 99991,
    Title: "Walk-In Door Order Form",
    BlockSeq: 0,
    Path: `${AppSettings.PdfPath}/Walk-In Door Order Form.pdf`,
    Section: "Walk-In Equipment",
    SectionKey: 5,
    SectionSeq: 4,
    Category: "Part Types",
    Info: true
  },
  "99992": {
    Key: 99992,
    Title: "Wire Shelf Order Form",
    BlockSeq: -1,
    Path: `${AppSettings.PdfPath}/Wire Shelf Order Form.pdf`,
    Section: "Shelving-Miscellaneous",
    SectionKey: 8,
    SectionSeq: 7,
    Category: "Part Types",
    Info: true
  },
  "99993": {
    Key: 99993,
    Title: "Custom Coil Order Form",
    BlockSeq: 0,
    Path: `${AppSettings.PdfPath}/Custom Coil Order Form.pdf`,
    Section: "Shelving-Miscellaneous",
    SectionKey: 8,
    SectionSeq: 7,
    Category: "Part Types",
    Info: true
  }
};

export default OrderForms;
