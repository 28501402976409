import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import styled from "styled-components";

const Wrapper = styled.div`
  float: left;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--black);
`;

export default function LoginPrompt(props) {
  const context = useContext(AppContext);
  const { claims } = context;

  return claims.UserType !== "Guest" ? (
    ""
  ) : (
    <Wrapper>
      <i>Existing customer?</i>
      <br />
      <Link to={`/Login?returnUrl=${props.location.pathname}`}>Sign in</Link> to your account
    </Wrapper>
  );
}
