import React from "react";
import StaffInfo from "./StaffInfo.json";

export function FindEmployee(employeeName) {
  const name = employeeName ? employeeName.toLowerCase() : "";
  const employee = StaffInfo.find(ee => 
      ee.shortname.toLowerCase() === name
     || ee.username === name
     || ee.email === name);
  if (!employee) {
    return {
      username: "",
      fullname: "Unassigned",
      avatar: "/images/question.png",
      branch: "",
      role: "",
      shortname: "",
      email: "",
      ext: "",
      gmailInbox: ""
    };
  }
  if (!employee.avatar) {
    employee.avatar = "/images/question.png";
  }
  return employee;
}

export function IsAuthorized(employee) {
  return !!(employee && employee.authorized && employee.gmailInbox);
}

export function FindEmailByRequestId(employee, requestId) {
  const inbox = employee ? employee.gmailInbox : "";
  return inbox ? `${inbox}/Ref%3A${requestId}` : "";
}

export function FindEmailByCustomerId(employee, customerId) {
  const inbox = employee ? employee.gmailInbox : "";
  return inbox ? `${inbox}/${customerId}` : "";
}

export function FindEmailByEmailAddress(employee, email) {
  const inbox = employee ? employee.gmailInbox : "";
  return inbox ? `${inbox}/${email.replace("@", "%40")}` : "";
}

export function ChatFilter(employee, list) {
  return employee && !employee.showChat ? list.filter(request => request.RequestType !== "Chat") : list;
}

function SortCompare(a, b) {
  const branchCompare = a.branch.localeCompare(b.branch);
  return branchCompare ? branchCompare : a.fullname.localeCompare(b.fullname);
}

function Format(list, filter, format) {
  return list.filter(filter).map(ee => (
    <option value={ee.username} key={ee.username}>
      {format(ee)}
    </option>
  ));
}

export function OwnerOptionList() {
  const sorted = StaffInfo.sort(SortCompare);
  var result = [];
  result.push(
    <option disabled key="mpk">
      MPK Customer Service
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.branch === "MPK" && ee.role === "Customer Service",
      ee => ee.fullname
    )
  );
  result.push(
    <option disabled key="sea">
      SEA Customer Service
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.branch === "SEA" && ee.role === "Customer Service",
      ee => ee.fullname
    )
  );
  result.push(
    <option disabled key="stl">
      STL Customer Service
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.branch === "STL" && ee.role === "Customer Service",
      ee => ee.fullname
    )
  );
  result.push(
    <option disabled key="mgr">
      Branch Managers
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.role === "Manager",
      ee => `${ee.fullname}, ${ee.branch}`
    )
  );
  result.push(
    <option disabled key="other">
      Other
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.role !== "Customer Service" && ee.role !== "Manager",
      ee => `${ee.fullname}, ${ee.role}`
    )
  );
  return result;
}

export function CsrList() {
  const sorted = StaffInfo.sort(SortCompare);
  var result = [];
  result.push(
    <option key="all" value=" ">
      Any / All
    </option>
  );
  result.push(
    <option disabled key="mpk" value="mpk">
      MPK
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.branch === "MPK" && (ee.role === "Customer Service" || ee.role === "Manager"),
      ee => ee.shortname
    )
  );
  result.push(
    <option disabled key="sea" value="sea">
      SEA
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.branch === "SEA" && (ee.role === "Customer Service" || ee.role === "Manager"),
      ee => ee.shortname
    )
  );
  result.push(
    <option disabled key="stl" value="stl">
      STL
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.branch === "STL" && (ee.role === "Customer Service" || ee.role === "Manager"),
      ee => ee.shortname
    )
  );
  result.push(
    <option disabled key="other">
      Everyone Else
    </option>
  );
  result.push(
    ...Format(
      sorted,
      ee => ee.role !== "Customer Service" && ee.role !== "Manager" && ee.branch && ee.branch !== "System",
      ee => `${ee.shortname}`
    )
  );
  return result;
}
