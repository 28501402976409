import WarmerWires from "./WarmerWires.json";

const sortedWires = WarmerWires.sort((a, b) => a.ohms - b.ohms);
const allWires = uniquify(sortedWires);
const mpkWires = uniquify(sortedWires, "MPK");
const stlWires = uniquify(sortedWires, "STL");
const minSafeWPF = 2;
const maxSafeWPF = 12;
const bestSingleWPF = 6;
const bestDoubleWPF = 3;

// Given a sorted list, return a pruned list of unique ohms values, optionally filtered by warehouse
function uniquify(list, whse) {
  var result = [];
  list.reduce((prev, curr) => {
    var skip = false;
    if (whse && curr.whse !== whse) {
      skip = true;
    }
    if (result.length > 0 && result[result.length - 1].ohms >= curr.ohms) {
      skip = true;
    }

    if (!skip) {
      result.push(curr);
    }

    return curr;
  }, null);
  //console.log("uniquify", whse, result);
  return result;
}

// Determine total inches required
function calcTotalInches(isSinglePass, width, height) {
  return isSinglePass ? 2 * height + 2 * width : 4 * height + 2 * width;
}

function FeetRounded(isSinglePass, width, height) {
  const inches = calcTotalInches(isSinglePass, width, height);
  return Math.ceil(inches / 12);
}

function GetWirePrice(isSinglePass, width, height, discountLevel) {
  const standardPricing = [
    // Pricing change effective 6/1/2022
    // [20, 13, 12], // extra small
    // [25, 15, 14], // small
    // [29, 17, 16], // medium
    // [33, 21, 20], // large
    // [36, 24, 22] // extra large
    [30, 22, 20], // extra small
    [35, 24, 22], // small
    [39, 25, 23], // medium
    [43, 29, 28], // large
    [46, 33, 30], // extra large
  ];
  // Pricing change effective 6/1/2022
  // const perFootPricing = [0.69, 0.45, 0.43];
  const perFootPricing = [0.94, 0.7, 0.6];
  const feet = calcTotalInches(isSinglePass, width, height) / 12;
  const priceLevelIndex = discountLevel === "Wholesale" ? 2 : discountLevel === "Dealer" ? 1 : 0;
  const sizeIndex = feet < 11 ? 0 : feet < 21 ? 1 : feet < 31 ? 2 : feet < 41 ? 3 : feet < 51 ? 4 : -1;
  const price = sizeIndex < 0 ? perFootPricing[priceLevelIndex] * feet : standardPricing[sizeIndex][priceLevelIndex];
  return price.toFixed(2);
}

function WattsPerFoot(ohmsPerFoot, totalInches, voltage) {
  if (!ohmsPerFoot || !totalInches || !voltage) {
    return 0;
  }
  const feet = totalInches / 12;
  return (voltage * voltage) / (feet * ohmsPerFoot) / feet;
}

function BestWPF(isSinglePass) {
  return isSinglePass ? bestSingleWPF : bestDoubleWPF;
}

function FindWiresByLength(bestWPF, totalInches, voltage, list) {
  if (bestWPF < minSafeWPF) {
    throw new Error(`Warmer wires should be designed for at least ${minSafeWPF} watts per foot`);
  }

  if (bestWPF > maxSafeWPF) {
    throw new Error(`Warmer wires should be designed for less than ${maxSafeWPF} watts per foot`);
  }

  // Default to using all available wires
  if (!list) list = allWires;

  var bestIndex = -1;
  var minDelta = maxSafeWPF;
  var wires = [];
  var i;
  for (i = 0; i < list.length; i++) {
    const wire = list[i];
    const wpf = WattsPerFoot(wire.ohms, totalInches, voltage);
    if (wpf <= maxSafeWPF && wpf >= minSafeWPF) {
      const delta = Math.abs(bestWPF - wpf);
      const feet = totalInches / 12;
      const watts = wpf * feet;
      const amps = watts / voltage;
      const w = {
        wpf: wpf.toFixed(2),
        delta: delta.toFixed(2),
        watts: watts.toFixed(2),
        amps: amps.toFixed(2),
        ...wire,
      };
      if (delta < minDelta) {
        minDelta = delta;
        bestIndex = wires.length;
      }
      wires.push(w);
    }
  }
  return { bestIndex, wires };
}

function FindWiresBySpecs(isSinglePass, width, height, voltage, list) {
  const bestWPF = BestWPF(isSinglePass);
  const totalInches = calcTotalInches(isSinglePass, width, height);
  return FindWiresByLength(bestWPF, totalInches, voltage, list);
}

export { FindWiresBySpecs, FeetRounded, GetWirePrice, calcTotalInches, allWires, mpkWires, stlWires };
