import React from "react";
import Select from "react-select";
import { Field, ErrorMessage } from "formik";

const FormField = ({ name, required, caption, type, component, placeholder, errors, touched, tabIndex }) => (
  <>
    <label htmlFor={name}>
      {caption}
      {required && <strong>*</strong>}
    </label>
    <ErrorMessage name={name} className="error" component="div" />
    <Field
      component={component || "input"}
      type={type || "text"}
      name={name}
      placeholder={placeholder}
      className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      tabIndex={tabIndex}
    />
  </>
);

var options = [];

// doc: https://react-select.com/styles
const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "23px",
    "min-height": "23px",
    marginTop: "-5px"
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "36px",
    marginTop: "0px"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "36px",
    width: "400px"
  }),
  container: (provided, state) => ({
    ...provided,
    height: "34px",
    marginBottom: "32px"
  })
};

const getOption = val => ({ label: val, value: val });
const PaymentChoices = ({ field, form }) => (
  <Select
    required
    isSearchable={false}
    isClearable={false}
    className="text-input"
    placeholder="Select Payment Method"
    styles={customStyles}
    options={options}
    defaultValue={field.value ? getOption(field.value) : options[0]}
    onChange={option => {
      console.log("Set", field.name, option);
      form.setFieldValue(field.name, option ? option.value : "");
    }}
    onBlur={() => form.setFieldTouched(field.name)}
  />
);

const PaymentMethod = ({ values, errors, touched, paymentMethods, setFieldValue, isLoading, tabIndex }) => {
  options = [];

  if (paymentMethods && paymentMethods.OnAccount) {
    options.push(getOption("On Account"));
  }

  options.push(getOption("Credit Card"));

  // Update default payment method when we receive data from the server
  if (!isLoading && !values.paymentMethod) {
    console.log("Set paymentMethod", options[0].value);
    setFieldValue("paymentMethod", options[0].value);
  }

  // No need to display a combobox if there's only one choice
  if (options.length < 2) {
    return "";
  }

  return (
    <FormField
      name="paymentMethod"
      caption="Payment Method"
      component={PaymentChoices}
      errors={errors}
      touched={touched}
      tabIndex={tabIndex}
    />
  );
};

export default PaymentMethod;
