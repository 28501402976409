import React, { useState } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import useRequestStatus from "../hooks/useRequestStatus";
import "../../ui/styling/styles.css";
import "../requestManager.css";
import { Time, Status } from "../RequestFormatting";
import { FindEmployee } from "../data/GetStaffInfo";
import MiniOwner from "./MiniOwner";

export default function RequestHistoryPopup({ requestId, trigger }) {
  const [isOpen, setIsOpen] = useState(false);
  const result = useRequestStatus(requestId, !isOpen);
  const history = result.response;

  const History = ({ close }) => (
    <div>
      <table className="subtleTable">
        <thead>
          <tr>
            <th>Local Time</th>
            <th>Status</th>
            <th>Owner</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {history &&
            history.map((entry, n) => (
              <tr key={n}>
                <td>{Time(entry.UpdatedTime)}</td>
                <td>{Status(entry)}</td>
                <td className="clickable" style={{ minWidth: "80px" }}>
                  <Link to={`/requests/?owner=${FindEmployee(entry.Owner).username}`}>
                    <MiniOwner entry={entry} />
                  </Link>
                </td>
                <td>{entry.Note}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <Popup
      className="requestType"
      trigger={<div>{trigger}</div>}
      on="hover"
      position="right top"
      mouseLeaveDelay={500}
      mouseEnterDelay={100}
      contentStyle={{ width: "800px" }}
      keepTooltipInside={true}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <History />
    </Popup>
  );
}
