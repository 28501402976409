import React from "react";
//import Moment from "react-moment";
import moment from "moment";

const Time = utc => {
  const timestamp = moment(utc);
  const time = timestamp.format("LT").replace(" ", "\u00A0");

  if (timestamp.isSame(new Date(), "day")) {
    return time;
  }

  const date = timestamp.format("MMM D").replace(" ", "\u00A0");
  return `${date} ${time}`;
};

const Status = x => {
  switch (x.Status) {
    case "New":
      return (
        <span>
          <b>Submitted</b>
        </span>
      );
    case "Assigned":
      return (
        <span>
          <b>Assigned</b> to {x.Owner} by {x.UpdatedBy}
        </span>
      );
    case "Done":
      return (
        <span>
          <b>Completed</b> by {x.UpdatedBy}
        </span>
      );
    default:
      return (
        <span>
          <b>{x.Status}</b> by {x.UpdatedBy}
        </span>
      );
  }
};
/*
const Holidays = ["1/1", "5/27", "7/4", "9/2", "11/28", "12/25"];
const HalfDays = ["7/5", "11/29", "12/24", "12/31"];
const StartOfDay = 7.5;
const EndOfDay = 17.0;
const EndOfHalfDay = 14.0;
const PSTOffset = -8;
const PDTOffset = -7;

const ElapsedTime = (from, to) => {
  const date1 = new Date(from);
  const date2 = new Date(to);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
*/
export { Time, Status };
