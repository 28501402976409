import React, { useState, useEffect } from "react";

// Replace the innerHtml of the child element with the contents of an external html file
export default function InjectHtml({ url, children }) {
    const [html, setHtml] = useState("");

    // Just fetch when the component is first loaded
    useEffect(() => {
        fetch(url)
            .then(response => {
                // silently ignore load errors
                if (response.ok) {
                    return response.text();
                }
            })
            .then(text => {
                // updating state will trigger a rerender
                if (!!text) {
                    setHtml(text);
                }
            })
            .catch(() => console.log("Could not read " + url))
    });

    function isValid(html) {
        // Empty string is invalid
        if (!html) return false;

        // This catches an invalid relative url.
        // In that case, we get the default index.html file.
        // We check for that by looking for the </html> tag.
        if (html.includes("</html>")) {
            console.log("Invalid url: " + url);
            return false;
        }

        return true;
    }

    // we set the innerHTML of a clone of the child component so that all styling is 
    // external to this function.  Unless we get html, we just return an empty string.
    // An empty html file or an invalid path are ignored.
    return isValid(html) ? React.cloneElement(children, { dangerouslySetInnerHTML: { __html: html } }) : ""
}
