import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const requestColors = {
  Checkout: "#3d9665",
  Research: "#1380b1",
  Chat: "#7b77ee",
  OrderLink: "#63bf8d",
  Contact: "#de5449",
  Email: "#de5449",
  Account: "#f5a056"
};

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 4px;
  float: left;
  color: white;
  background: ${props => props.background}
  padding: 4px;
`;
const Label = styled.span`
  font-size: 11px;
  color: var(--greyish-brown);
  display: block;
  font-variant: small-caps;
`;
const Id = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: var(--black);
  letter-spacing: 1px;
`;
const Wrapper = styled.div`
  display: inline-block;
`;

const getIcon = requestType => {
  switch (requestType) {
    case "Contact":
      return "envelope";
    case "Research":
      return "books";
    case "Account":
      return "building";
    case "Chat":
      return "comments";
    case "OrderLink":
      return "cart-circle-arrow-down";
    case "Checkout":
      return "shopping-cart";
    default:
      return "question";
  }
};

export default function MiniRequestType({ request }) {
  const requestType =
    request.RequestType === "Chat" && !request.ChannelId
      ? "Offline\u00A0Chat"
      : request.RequestType === "Contact"
      ? "Email"
      : request.RequestType;
  const icon = getIcon(request.RequestType);

  return (
    <Wrapper>
      <Icon icon={["far", icon]} background={requestColors[request.RequestType]} />
      <div>
        <Label>{requestType}</Label>
        <Id>{request.RequestId}</Id>
      </div>
    </Wrapper>
  );
}
