import React from "react";
import { Formik, Field } from "formik";
import "../../ui/styling/formik.css";
import PutRequest from "../PutRequest";
import { FindEmployee, OwnerOptionList, IsAuthorized } from "../data/GetStaffInfo";
import { sendUpdateNotification } from "../../ui/checkout/SlackNotification";
import { useContext } from "react";
import AppContext from "../../context/AppContext";

export default function RequestEditor({ history, request, employee, returnUrl }) {
  const context = useContext(AppContext);
  const { token } = context;
  const defaultAction =
    request.Status === "Active" && (request.Owner === employee.shortname || request.Owner === employee.username)
      ? "Complete"
      : "Claim";
  const currentOwner = FindEmployee(request.Owner);
  const initialValues = {
    action: defaultAction,
    owner: currentOwner.username ? currentOwner.username : employee.username,
    note: ""
  };
  const handleValidation = values => {
    // do we still need this?
  };

  const handleSubmit = (values, actions) => {
    var status;
    var owner = "";
    var note = values.note.trim();
    var defaultNote = "";

    switch (values.action) {
      case "Claim":
        status = "Active";
        owner = employee.username;
        defaultNote = `Claimed by ${employee.shortname}`;
        break;
      case "Assign":
        status = "Assigned";
        owner = values.owner;
        defaultNote = `Assigned by ${employee.shortname}`;
        break;
      case "Complete":
        status = "Done";
        defaultNote = `Completed by ${employee.shortname}`;
        break;
      case "Wait on Customer":
        status = "Waiting on Customer";
        break;
      case "Wait on Factory":
        status = "Waiting on Factory";
        break;
      default:
        status = "";
        break;
    }

    const responseHandler = async response => {
      console.log("RESPONSE: ", response);
      const status = response ? response.status : -1;
      if (status === 409) {
        actions.setFieldError("action", "This card has been updated. Please refresh.");
        actions.setFieldError("refresh", true);
      } else if (status < 0) {
        actions.setFieldError("action", "Sorry an unexpected error occurred. Please try again.");
      } else if (status < 300) {
        await sendUpdateNotification(request, values.action, employee.shortname, owner, note);
        actions.setSubmitting(false);
        //window.location = returnUrl; // On success, return to dashboard
        console.log("pushing", returnUrl);
        history.push(returnUrl);
      } else {
        actions.setFieldError("action", `${response.statusText}. Please try again.`);
      }
    };

    if (!IsAuthorized(employee)) {
      actions.setFieldError("action", "You are not authorized to update requests");
    } else if (
      request.Status === status &&
      (!owner || FindEmployee(request.Owner) === FindEmployee(owner)) &&
      (!note || note === request.Note)
    ) {
      actions.setFieldError("action", "Update ignored, because nothing has been changed.");
    } else if (!note && !defaultNote) {
      actions.setFieldError("action", "Comment must briefly describe what we're waiting for");
    } else {
      PutRequest(
        responseHandler,
        request.RequestId,
        request.Version,
        employee.shortname,
        status,
        owner ? owner : request.Owner,
        note || defaultNote,
        token
      );
    }
  };

  return (
    <div className="requestEditor">
      <Formik
        initialValues={initialValues}
        validate={handleValidation}
        onSubmit={handleSubmit}
        render={props => (
          <form onSubmit={props.handleSubmit}>
            <div className="requestErrorMessage">
              {props.errors.action}
              {props.errors.refresh && (
                <button className="refreshButton" onClick={() => window.location.reload()}>
                  Refresh
                </button>
              )}
            </div>
            <div className="requestEditorColumn">
              <label htmlFor="status">Action:</label>
              <Field id="reqActionList" component="select" name="action">
                <option value="Claim">Claim</option>
                <option value="Complete">Complete</option>
                <option value="Assign">Assign</option>
                <option value="Wait on Customer">Wait on Customer</option>
                <option value="Wait on Factory">Wait on Factory</option>
              </Field>
            </div>
            {props.values.action === "Assign" && (
              <div className="requestEditorColumn">
                <label htmlFor="owner">Owner:</label>
                <Field id="reqOwnerList" component="select" name="owner">
                  <OwnerOptionList />
                </Field>
              </div>
            )}
            <br />
            <div style={{ clear: "both" }}>
              <label htmlFor="note">Comment:</label>
              <Field type="text" name="note" />
              {!props.errors.refresh && <button type="submit">Update</button>}
            </div>
          </form>
        )}
      />
    </div>
  );
}
