import useSecureFetch from "./useSecureFetch";
import AppSettings from "../../AppSettings";


export default function useRequestStatus(requestId, disabled) {
  if (!requestId) {
    return null;
  }  

  const url = `${AppSettings.RequestStatus(requestId)}`;
  //const url = `${AppSettings.RequestHistory}?customerId=TWCSE50321`;
  //const url = `${AppSettings.RequestReport}`;
  //const url = `${AppSettings.RequestMetrics}?from=05-03-19&to=09-14-19`;
  //const url = `${AppSettings.CatalogRequest}${requestId}`;
  const result = useSecureFetch(url, null, disabled, null, disabled);
  return result;
}
