import React from "react";
import styled from "styled-components";
import "../styling/styles.css";
import Breadcrumb from "../navigation/Breadcrumb";
import queryString from "query-string";
import warehouses from "../../warehouses.json";
import WillCallNote from "../checkout/WillCallNote";

const Wrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 40px;
  width: 520px;
  margin-left: 200px;
  text-align: center;
`;
const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-bottom: 8px;
`;
const Image = styled.div``;
const BodyText = styled.p`
  font-size: 14px;
  color: var(--greyish-brown);
  text-align: center;
`;
const Link = styled.a`
  text-decoration: none;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
const Message = styled.div`
  color: var(--greyish-brown);
  margin-bottom: 8px;
  max-width: 480px;
  padding: 16px;
`;
const Bold = styled.span`
  color: black;
  font-style: oblique;
  font-weight: bold;
  font-size: 18px;
`;
const RequestId = styled.div`
  color: black;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 6px;
`;

const OrderChanges = (requestId, isQuote) => (
  <Message>
    <Bold>If you need to change or cancel your {isQuote ? "quote" : "order"}...</Bold>
    <br />
    <br />
    please contact us immediately at <br />
    <Link href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</Link> or&nbsp;
    <Link href="tel:+18004210271">(800) 421-0271</Link>.
    <br />
    We are also available via <Link onClick={() => window._chatlio.show({ expanded: true })}>chat</Link>.
    {requestId && (
      <div><br/>
        <div>For fastest service, please reference your RequestID:</div>
        <RequestId>{requestId}</RequestId>
      </div>
    )}
  </Message>
);

const ReadyOrder = (email, requestId) => (
  <>
    <Title>Thank you for your order!</Title>
    <BodyText>
      We've sent a confirmation email to <b>{email}</b> summarizing your order to the email address you provided. You
      will receive a second email once your order ships.
    </BodyText>
    {OrderChanges(requestId)}
  </>
);
const WillCallOrder = (branch, email, requestId) => {
  const warehouse = warehouses.find(w => branch === w.branch);
  if (!warehouses) return;
  const { office } = warehouse;

  return (
    <>
      <Title>Thank you for your Will Call order!</Title>
      <BodyText>
        We've sent a confirmation email to <b>{email}</b> summarizing your order
        <br /> which is awaiting pick-up at our {office} branch.
      </BodyText>
      <div style={{ marginLeft: "24px" }}>
        <WillCallNote branch={branch} />
      </div>
      {OrderChanges(requestId)}
    </>
  );
};

const PendingOrder = (email, requestId) => (
  <>
    <Title>We'll be in touch soon to finalize your order!</Title>
    <BodyText>
      We've summarized your pending order in a confirmation email sent to <b>{email}</b>. A member of our Case Parts
      customer service team will follow up with you shortly to finalize your order.
    </BodyText>
    {OrderChanges(requestId)}
  </>
);
const QuoteNeeded = (email, requestId) => (
  <>
    <Title>We're working on your quote!</Title>
    <BodyText>
      Your quote is being processed by a member of our Case Parts customer service team who will follow-up with you
      shortly at <b>{email}</b>.
    </BodyText>
    {OrderChanges(requestId, true)}
  </>
);
const QuoteSent = (email, requestId) => (
  <>
    <Title>Check your inbox!</Title>
    <BodyText>
      Your quote has been sent to <b>{email}</b>.
      <br />
      Please let us know if you wish to move forward with placing an order.
    </BodyText>
  </>
);
const ResearchRequest = requestId => (
  <>
    <Title>We're on it!</Title>
    <BodyText>Our part experts will be in touch shortly regarding the parts you need.</BodyText>{" "}
  </>
);
const ContactRequest = requestId => (
  <>
    <Title>Thanks for reaching out!</Title>
    <BodyText>Our customer service team will contact you shortly.</BodyText>{" "}
  </>
);

export default function Thankyou({ location }) {
  let { message, requestId, warehouse, email } = queryString.parse(location.search);

  return (
    <Wrapper>
      <Image>
        <img src="/images/checkmark.png" alt="" />
      </Image>
      {message === "willcall" && WillCallOrder(warehouse, email, requestId)}
      {message === "order" && ReadyOrder(email, requestId)}
      {message === "pending" && PendingOrder(email, requestId)}
      {message === "quoteneeded" && QuoteNeeded(email, requestId)}
      {message === "quotesent" && QuoteSent(email, requestId)}
      {message === "research" && ResearchRequest(requestId)}
      {message === "contact" && ContactRequest(requestId)}

      <br />
      <Breadcrumb title="Continue shopping" path="/" />
    </Wrapper>
  );
}
