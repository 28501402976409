import React from "react";
import { CsrList } from "../data/GetStaffInfo";

export default function SelectCsr({ current, onSelect }) {
  return (
    <select defaultValue={current} onChange={e => onSelect(e.target.value)}>
      <CsrList />
    </select>
  );
}
