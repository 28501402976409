import React from "react";
import styled from "styled-components";
import AppSettings from "../../../AppSettings";

const Discontinued = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 12px;
  font-style: italic;
  color: red;
`;

/// Inventory returns green text giving availability info about a part
export default function Inventory({ part, inventory, quantity, discontinued }) {
  // If we don't have inventory data, we can't format an availability message
  const NoMessage = "";
  if (!inventory) return NoMessage;

  const When = ({prop, children}) => {
    if(!prop) { return null}
    return <React.Fragment>{children}</React.Fragment>
  }
  // We include the desired quantity in the returned string when quantity > 1

  const Message = ({ children, notInStock, customPart }) => (
    customPart ?
    <div style={{ fontStyle: "italic", color: "green", fontSize: "12px", marginTop: "8px" }}>
      {children}
    </div>
    :
    notInStock 
    ? 
    <a href={AppSettings.AccountPage + "/contactus"} style={{ fontStyle: "italic", fontSize: "12px", marginTop: "8px", display: 'inline-block' }}>{children}</a> 
    : 
    <div style={{ fontStyle: "italic", color: "green", fontSize: "12px", marginTop: "8px" }}>
      {quantity > 1 ? `${quantity}+` : ""} In-Stock {children}
    </div>
  );

  // Unreserved quantity excludes parts committed to other orders
  // Available quantity includes parts committed to other orders
  // If one or more warehouses have sufficient unreserved inventory >= quantity requested,
  // we list that warehouse as able to fulfill the order.
  // If no warehouse can fulfill the order from unreserved inventory at that warehouse,
  // We list the part as having LIMITED AVAILABILITY if the total available quantity >= quantity requested
  const unreserved = whse => inventory.Inventory ? inventory.Inventory[whse].Unreserved : inventory[whse];
  const available = whse => inventory.Inventory ? inventory.Inventory[whse].Available : inventory[whse];
  // Note that the functions above are backward compatible with an earlier version of the endpoint that
  // that did not provide detailed Inventory data

    // Deal with discontinued parts
    if (discontinued) {
      const total = unreserved("MPK") + unreserved("STL") + unreserved("SEA");
      const message = total >= quantity
      ? "This part is discontinued, supplies are limited"
      : total > 0
      ? `This part is discontinued and we only have ${total} in stock`
      : "Call us. This part is discontinued but we might have compatible alternatives."
  
      if(total <= 0)
        return <Message notInStock={true}>Contact us. This part is discontinued but we might have compatible alternatives.</Message>
      
      return <Discontinued>{message}</Discontinued>
    }
  
  // Count up the number of warehouses with sufficient unreserved quantity
  const mpk = unreserved("MPK") >= quantity ? 1 : 0;
  const stl = unreserved("STL") >= quantity ? 1 : 0;
  const sea = unreserved("SEA") >= quantity ? 1 : 0;
  const whseCount = mpk + stl + sea;

  const leadTimes = {
    "Gasket": "2",
    "Wire": "1",
    "Heater": "1"
  }

  const isCustom = part && part.custom !== undefined && (part.custom === "Gasket" || part.custom === "Wire");

  if (whseCount === 3) return <Message>at all three of our warehouses</Message>;
  if (whseCount === 1) return <Message>at our {mpk ? "Los Angeles" : stl ? "St. Louis" : "Seattle"} warehouse</Message>;
  if (mpk && stl) return <Message>at our Los Angeles and St. Louis warehouses</Message>;
  if (mpk && sea) return <Message>at our Los Angeles and Seattle warehouses</Message>;
  if (stl && sea) return <Message>at our St. Louis and Seattle warehouses</Message>;
  if (stl && sea) return <Message>at our St. Louis and Seattle warehouses</Message>;
  if (available("MPK") + available("STL") + available("SEA") >= quantity) return <Message>with LIMITED AVAILABILITY</Message>;
  if (available("MPK") + available("STL") + available("SEA") === 0 && !isCustom) return <Message notInStock={true}>Contact us for availability</Message>
  if (isCustom) return <Message customPart={true}>Typically {leadTimes[part.custom]} business day{leadTimes[part.custom] === "1" ? '' : 's'} or less</Message>
  return NoMessage;
}
