import React, { useContext } from "react";
import { withRouter } from "react-router";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import TopSection from "./TopSection";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import Loading from "./Loading";
import "../styling/styles.css";

const SplitWrapper = styled.div`
  display: grid;
  grid-template-columns: 312px auto;
  grid-template-rows: 62px auto;
  grid-template-areas: "top top" "bottomLeft bottomRight";
  height: 100vh;
  overflow-y: hidden;
`;

const BottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-template-rows: 62px auto;
  grid-template-areas: "top top" "bottom bottom";
  height: 100vh;
  overflow-y: hidden;
`;

const BottomSection = styled.div`
  grid-area: bottom;
  overflow-y: auto;
  background-color: var(--paper-grey);
`;

function MainLayout(props) {
  const context = useContext(AppContext);
  const { initialized, claims } = context;
  const ready = initialized && claims.UserType;
  const pageName = props.location.pathname.toLowerCase();
  const showLeftSection =
    !pageName ||
    pageName === "/" ||
    pageName.startsWith("/404") ||
    pageName.startsWith("/search") ||
    pageName.startsWith("/page") ||
    pageName.startsWith("/part");

  //This does fire every time we navigate the app.
  // But it does not re-initialize the state.
  return (
    <>
      {!ready && (
        <>
          <TopSection location={props.location}/>
          <Loading />
        </>
      )}
      {ready && showLeftSection && (
        <SplitWrapper>
          <TopSection  location={props.location} />
          <LeftSection />
          <RightSection>{props.children}</RightSection>
        </SplitWrapper>
      )}
      {ready && !showLeftSection && (
        <BottomWrapper>
          <TopSection location={props.location} />
          <BottomSection location={props.location}>{props.children}</BottomSection>
        </BottomWrapper>
      )}
    </>
  );
}

export default withRouter(MainLayout);
