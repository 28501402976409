import React from "react";
import styled from "styled-components";
import "../styling/styles.css";

const Wrapper = styled.div`
  grid-area: bottomRight;
  overflow-y: auto;
  background-color: var(--paper-grey);
  margin-bottom: 20px;
`;

export default function RightSection(props) {
  return <Wrapper>{props.children}</Wrapper>;
}
