import AppSettings from "../AppSettings";
import gaskets from "./gasket/GasketInfo.json";
import { parseFraction } from "./gasket/dimensions";

function isGasket(part) {
  if (!part || !part.attributes) return false;
  return part.attributes.some(attr => attr.name === "Unit" && attr.value === "Pre-Molded");
}

function getGasket(partId) {
  partId = partId ? partId.toUpperCase(partId) : "";
  const gasket = gaskets.find(part => part.partId === partId);
  return gasket;
}

function customPartType(part) {
  if (part.custom) return part.custom;

  if (isGasket(part)) return "Gasket";
  return "";
}

function isCustomPart(part) {
  return !!customPartType(part);
}

function hasCustomParts(parts) {
  return parts.filter(part => isCustomPart(part)).length > 0;
}

function profileUrl(gasket) {
  return `${AppSettings.ImageService}/catalog/graphics%2fDrawings%5c${gasket.profile}.png`;
}

function gasketPrice(partId, heightString, widthString, discountLevel) {
  console.log("DiscountLevel in GasketPrice", discountLevel)
  const standardPrice = [
    [51, 32, 26], // extra small
    [64, 40, 32], // small
    [75, 46, 40], // medium
    [86, 53, 48], // large
    [98, 65, 58] // extra large
  ];
  const price_MG064S = [
    [59, 38, 34], // extra small
    [77, 50, 44], // small
    [92, 61, 54], // medium
    [106, 72, 66], // large
    [125, 83, 77] // extra large
  ];
  const price_0214160 = [
    [93, 51, 44], // extra small
    [112, 65, 55], // small
    [132, 74, 68], // medium
    [151, 87, 81], // large
    [166, 107, 96] // extra large
  ];

  const priceLevelIndex = discountLevel === "Wholesale" ? 2 : discountLevel === "Dealer" ? 1 : 0;
  const parsedHeight = parseFraction(heightString);
  const parsedWidth = parseFraction(widthString);
  if (!parsedHeight || !parsedWidth) {
    return null;
  }
  const height = parsedHeight;
  const width = parsedWidth;
  const size = height > width ? height : width;
  const sizeIndex = size < 31 ? 0 : size < 51 ? 1 : size < 71 ? 2 : size < 81 ? 3 : 4;
  const priceTable = partId === "MG064S" ? price_MG064S : partId === "02-14160" ? price_0214160 : standardPrice;
  const price = priceTable[sizeIndex][priceLevelIndex];
  
  return price;
}

export { isGasket, getGasket, customPartType, isCustomPart, hasCustomParts, profileUrl, gasketPrice };
