import AppSettings from "../AppSettings";
import partData from "./catalog.json";
import Catalog from "./CatalogManager";

async function LoadCatalog() {
  const label = "Part data";
  let data;
  if (!AppSettings.UseLiveCatalog && typeof partData !== "undefined") {
    data = await FetchLocal(partData, label);
  } else {
    const url = AppSettings.CatalogRequest;
    data = await FetchJson(url, label);
  }
  const catalog = new Catalog(data);
  return catalog;
}

async function FetchJson(url, label) {
  try {
    if (!url) {
      throw Error("Missing URL");
    }
    console.log(`Fetching ${label} from ${url}...`);
    const response = await fetch(url);
    const data = await response.json();
    console.log(`Fetched ${label}`);
    return data;
  } catch (error) {
    const errorMessage = `Fetch ${label} failed for ${url}: ${error}`;
    throw errorMessage;
  }
}

function FetchLocal(data, label) {
  console.log(`Fetched local ${label}`);
  return Promise.resolve(data);
}

export { LoadCatalog };
