import { useEffect } from "react";
import { withRouter } from "react-router";

const ScrollToTop = ({ container, children, location: { pathname } }) => {
  useEffect(() => {
    container ? container.current.scrollIntoView({ behavior: "smooth" }) : window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default withRouter(ScrollToTop);
