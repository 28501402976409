import React, { useContext, useEffect } from "react"
import AppContext from "../../../context/AppContext"
import { Link } from "react-router-dom"
import styled from "styled-components";

export default function InfoPage(props) {
    const page = window.location.pathname
    const { claims } = useContext(AppContext)
    const isEmployee = claims.UserType === "Employee"

    const Icon = styled.img`
        margin-right: 8px;
        width: 50px;
        height: 49px;
        float: left;
    `;
    const Title = styled.div`
        height: 25px;
        font-size: 22px;
        font-weight: 600;
        color: var(--black);
    `;
    const Subtitle = styled.div`
        margin-top: 4px;
        height: 16px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--greyish-brown);
    `;

    const Item = ({ to, children }) =>
        <li className={page === to ? "selected" : ""}>
            <Link to={to}>{children}</Link>
        </li>

    // In React, the browser doesn't automatically scroll to anchor tags. We need to call scrollIntoView
    // Based on https://stackoverflow.com/a/57018055/26553
    useEffect(() => {
        const href = window.location.href
        if (href.includes("#")) {
            const id = `${href.substring(href.indexOf("#") + 1)}`
            const anchor = document.getElementById(id)
            if (anchor) {
                anchor.scrollIntoView({ behavior: "smooth" })
            }
        }
    }, [])

    return (
        <div className="content infoPage">
            <div className="toc">
                <>
                    <Icon src="/images/icon-catalog.png" />
                    <Title>FYI</Title>
                    <Subtitle>General information</Subtitle>
                </>
                <ul>
                    <Item to="/faq">Frequently Asked Questions</Item>
                    <Item to="/about">About Case Parts</Item>
                    <Item to="/contact">Contact Us</Item>
                    <Item to="/returns">Returns</Item>
                    <Item to="/salestax">Sales Tax</Item>
                    <Item to="/privacy">Privacy Policy</Item>
                    <Item to="/terms">Terms of Use</Item>
                </ul>
                {isEmployee && <fieldset>
                    <legend>Employee Pages</legend>
                    <ul>
                        <Item to="/cpc">CPC Employee FAQ</Item>
                        <Item to="/csr">Customer Service FAQ</Item>
                        <Item to="/policies">CPC Policies</Item>
                    </ul>
                </fieldset>
                }

            </div>
            <div className="page">
                <div className={props.className}>{props.children}</div>
            </div>
        </div>
    )
}
