import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../../ui/navigation/Breadcrumb";
import DimensionField from "./DimensionField";
import DoorSides from "./DoorSides";
import DartToDart from "./DartToDart";
import NoMagnet from "./NoMagnet";
import Inverted from "./Inverted";
import ProfileImage from "./ProfileImage";
import GasketPreview from "./GasketPreview";
import Quantity from "../Quantity";
import { profileUrl, gasketPrice } from "../helpers";
import { parseFraction, formatFraction } from "./dimensions";
import "../customParts.css";

const Button = styled.button`
  color: white;
  margin-left: 8px;
  margin-top: 16px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;

export default function GasketEditorForm({ part, addOrUpdatePartInCart, history, discountLevel }) {
  const submit = (values, setGasketSpecs) => {
    console.log("Submit", part);
    part.specs = {
      Height: parseFraction(values.height),
      Width: parseFraction(values.width),
      Inverted: values.inverted,
      Sides: Number(values.sides),
      DartToDart: values.dartToDart,
      NoMagnetSide: values.noMagnetSide
    };
    part.attributes = [
      { name: "Color", value: part.color },
      { name: "Material", value: part.material }
    ];
    part.custom = "Gasket";
    part.quantity = part.quantity ? part.quantity : 1;
    part.price = gasketPrice(part.partId, values.height, values.width, discountLevel);
    part.quantity = values.quantity;
    addOrUpdatePartInCart(part);
    setGasketSpecs(values);
    window.history.back();
  };

  const [gasketSpecs, setGasketSpecs] = useState(
    part.specs
      ? {
          isNew: false,
          height: formatFraction(part.specs.Height),
          width: formatFraction(part.specs.Width),
          inverted: part.specs.Inverted,
          sides: part.specs.Sides,
          dartToDart: part.specs.DartToDart,
          noMagnet: !!part.specs.NoMagnetSide,
          noMagnetSide: part.specs.NoMagnetSide,
          quantity: part.quantity
        }
      : {
          isNew: true,
          height: "",
          width: "",
          inverted: false,
          sides: "",
          dartToDart: false,
          noMagnet: false,
          noMagnetSide: "",
          quantity: 1
        }
  );

  const minValue = 6;
  const maxValue = part.type === "Compression" ? 132 : 96;

  function validate(state, props) {
    if (state.noMagnet && !state.noMagnetSide) {
      return { noMagnetSide: "Please specify which side of the door is hinged" };
    }
  }

  let validations = {
    width: Yup.string()
      .required(`Please enter a width between ${minValue}" and ${maxValue}"`)
      .test("isWidth", "Please enter width as a number in fraction or decimal form", value => !!parseFraction(value))
      .test("minWidth", `Width must be at least ${minValue}"`, value => parseFraction(value) >= minValue)
      .test("maxWidth", `Width must not exceeed ${maxValue}"`, value => parseFraction(value) <= maxValue),
    height: Yup.string()
      .required(`Please enter a height between ${minValue}" and ${maxValue}"`)
      .test("isHeight", "Please enter height as a number in fraction or decimal form", value => !!parseFraction(value))
      .test("minHeight", `Height must be at least ${minValue}"`, value => parseFraction(value) >= minValue)
      .test("maxHeight", `Height must not exceeed ${maxValue}"`, value => parseFraction(value) <= maxValue)
  };

  const inputSchema = Yup.object().shape(validations);
  return (
    <div>
      <Formik
        onSubmit={values => submit(values, setGasketSpecs)}
        validationSchema={inputSchema}
        initialValues={gasketSpecs}
        validate={validate}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched, handleBlur }) => {
          const unitPrice = gasketPrice(part.partId, values.height, values.width, discountLevel) * values.quantity;
          const formattedPrice = unitPrice ? `$${Number.parseFloat(unitPrice).toFixed(2)}` : null;

          return (
            <Form className="gasketForm">
              <div>
                <div>
                  <h1>
                    {part.partId}:{" "}
                    <span className="smaller">
                      {part.color} {part.material} {part.style} {part.type} Gasket
                    </span>
                  </h1>
                </div>
                <ProfileImage url={profileUrl(part)} isInvertable={part.isInvertable} inverted={values.inverted} />
                {part.isInvertable && <Inverted fieldName="inverted" label="Reverse-mount the gasket (aka INVERTED)" />}
                <label>
                  <b>Enter gasket dimensions</b>{" "}
                  <span className="smaller">
                    (e.g., <i>72 3/8"</i> or <i>92cm</i>)
                  </span>
                </label>
                <DimensionField
                  fieldName="width"
                  label="Width"
                  min={minValue}
                  max={maxValue}
                  defaultValue={gasketSpecs.width}
                  onBlur={() => setFieldTouched("width")}
                />
                <DimensionField
                  fieldName="height"
                  label="Height"
                  min={minValue}
                  max={maxValue}
                  defaultValue={gasketSpecs.height}
                  onBlur={() => setFieldTouched("height")}
                />
                {!!part.hasDart && (
                  <DartToDart
                    fieldName="dartToDart"
                    label="Measurements are from dart-to-dart"
                    defaultValue={gasketSpecs.dartToDart}
                    hasDart={part.hasPart}
                  />
                )}
                <DoorSides fieldName="sides" label={<b>Should the gasket cover all four sides or only three?</b>} />
                {part.type === "Magnetic" && (
                  <NoMagnet
                    fieldName1="noMagnet"
                    fieldName2="noMagnetSide"
                    label1="No magnet on the hinged side of the door"
                    label2="Which side of the door is hinged?"
                    showOptions={!!values.noMagnet}
                  />
                )}
                {formattedPrice && (
                  <div className="price">
                    Price: <b>{formattedPrice}</b>
                  </div>
                )}
                <Quantity quantity={values.quantity} onUpdateQuantity={qty => setFieldValue("quantity", qty)} />
                <Button type="submit">{values.isNew ? "Add to Cart" : "Update"}</Button>
                <Breadcrumb title="Cancel" />
              </div>
              <div className="norefund">
                <b>Note</b>: Custom gaskets are non-returnable.
              </div>
              {!errors.width && !errors.height && parseFraction(values.width) && parseFraction(values.height) && (
                <GasketPreview
                  width={parseFraction(values.width)}
                  height={parseFraction(values.height)}
                  dartToDart={values.dartToDart}
                  sides={values.sides}
                  noMagnet={values.noMagnetSide}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
