/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import "../requestManager.css";
import Popup from "reactjs-popup";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const apiFormat = "MM/DD/YYYY";
const isoFormat = "YYYY-MM-DD";
const currentYearFormat = "ddd M/D";
const pastYearFormat = "ddd M/D/YY";

function Option({ label, start, end, onSelect }) {
  return (
    <>
      &nbsp;&nbsp;
      <a href="#" onClick={() => onSelect(start.format(apiFormat), end.format(apiFormat))}>
        {label}
      </a>
      &nbsp;&nbsp;
    </>
  );
}

function formatDate(date) {
  return date.format(date.year() === moment().year() ? currentYearFormat : pastYearFormat);
}

function formatRange(start, end) {
  if (end.isSameOrAfter(moment().format(isoFormat)) && start.isBefore(moment("2019-10-01", isoFormat))) {
    return "Any / All";
  } else {
    return start.isSame(end) ? formatDate(start) : `${formatDate(start)} - ${formatDate(end)}`;
  }
}

function Picker({ range, onSelect, close }) {
  const handleSelect = ({ start, end }) => {
    close();
    onSelect(start.format(apiFormat), end.format(apiFormat));
  };

  return (
    <DateRangePicker
      singleDateRange={true}
      initialFromValue={true}
      maximumDate={new Date()}
      firstOfWeek={1}
      numberOfCalendars={3}
      value={range}
      onSelect={handleSelect}
    />
  );
}

export default function DateRange({ start, end, onSelect }) {
  const from = moment(start, apiFormat);
  const to = moment(end, apiFormat);
  const range = moment.range(from, to);

  return (
    <span>
      <Popup
        trigger={<span className="boxedText">{formatRange(from, to)}</span>}
        on="hover"
        mouseLeaveDelay={500}
        mouseEnterDelay={100}
        offsetX={100}
        contentStyle={{ width: "950px" }}
      >
        {close => (
          <>
            <div className="daterangeOptions">
              <Option label="Today" start={moment()} end={moment()} onSelect={onSelect} />|
              <Option
                label="Yesterday"
                start={moment().subtract(1, "days")}
                end={moment().subtract(1, "days")}
                onSelect={onSelect}
              />
              |
              <Option
                label="This Week"
                start={moment().startOf("week").add(1, "days")}
                end={moment().endOf("week").add(1, "days")}
                onSelect={onSelect}
              />
              |
              <Option
                label="Last Week"
                start={moment().startOf("week").subtract(1, "week").add(1, "days")}
                end={moment().endOf("week").subtract(1, "week").add(1, "days")}
                onSelect={onSelect}
              />
              |
              <Option
                label="This Month"
                start={moment().startOf("month")}
                end={moment().endOf("month")}
                onSelect={onSelect}
              />
              |
              <Option
                label="Last Month"
                start={moment().startOf("month").subtract(1, "month")}
                end={moment().endOf("month").subtract(1, "month")}
                onSelect={onSelect}
              />
              |
              <Option
                label="This Year"
                start={moment().startOf("year")}
                end={moment().endOf("year")}
                onSelect={onSelect}
              />
              |
              <Option
                label="Last Year"
                start={moment().startOf("year").subtract(1, "year")}
                end={moment().endOf("year").subtract(1, "year")}
                onSelect={onSelect}
              />
              |
              <Option label="All Available" start={moment("7/1/2019", "M/D/YYYY")} end={moment()} onSelect={onSelect} />
            </div>
            <Picker range={range} onSelect={onSelect} close={close} />
          </>
        )}
      </Popup>
    </span>
  );
}
