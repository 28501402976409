import React from "react";
import getStateCode from "../../requests/hooks/getStateCode";
import CheckoutNote from "./CheckoutNote";

export default function Prop65({ zipcode }) {
  return getStateCode(zipcode) === "CA" ? (
    <CheckoutNote title="CA Prop 65 Warning">
      Some items sold by Case Parts Company may contain chemicals known to the
      State of California to cause cancer or reproductive harm.
      <br />
      <a href="www.P65Warnings.ca.gov">www.P65Warnings.ca.gov</a>
    </CheckoutNote>
  ) : (
    ""
  );
}
