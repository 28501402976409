import React from "react";
import { Field } from "formik";

const DartToDart = ({ fieldName, label, hasDart }) => (
  <div className="dartToDart">
    <Field name={fieldName} id={fieldName}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <label>
          <input type="checkbox" checked={value} onChange={() => setFieldValue(fieldName, !value)} />
          {label}
        </label>
      )}
    </Field>
  </div>
);

export default DartToDart;
