import { useState, useEffect } from "react";
import AppSettings from "../../AppSettings";

export default function useInventory(partIds, token) {
  const [inventory, setInventory] = useState(null);
  const isBatch = Array.isArray(partIds);
  const haveIds = isBatch ? partIds.length > 0 : !!partIds;
  const dependency = isBatch ? partIds.join(",") : partIds;

  useEffect(() => {
    if (haveIds && token) {
      const url = AppSettings.InventoryRequest(isBatch ? "" : partIds);
      const options = {
        method: isBatch ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
          // Make sure we don't cache data!
          // https://trello.com/c/J3jMfC5Z/240-stale-inventory-data
          "Cache-Control": "no-store",
          Authorization: `Bearer ${token}`
        }
      };
      if (isBatch) {
        options.body = JSON.stringify(partIds);
      }
      // console.log("useInventory", isBatch, dependency)
      fetch(url, options)
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then(responseJson => {
          setInventory(responseJson);
        })
        .catch(err => {
          console.log("ERROR fetching inventory", err);
        });
    }
  }, [dependency, token]);

  return inventory;
}
