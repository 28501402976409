import React, { useContext } from "react"
import AppContext from "../../../context/AppContext"
import InfoPage from "./InfoPage"
import FaqEntry from "./FaqEntry"

export default function Policies(props) {
    const { claims } = useContext(AppContext)
    const isEmployee = claims.UserType === "Employee"

    // Redirect non-employees to faq
    if (!isEmployee) {
        window.location = "/faq"
    }

    return <InfoPage className="faq">
        <h1>Case Parts Policies (Internal Use Only)</h1>
        <FaqEntry history={props.history} title="Personal Time Off (PTO)">
            <p>
                Whenever you miss time from work, whether due to sickness, vacation, or to attend to personal matters, your PTO account is charged. You are encouraged to adhere to two basic guidelines: provide as much notice as possible when you are not going to be able to work your scheduled hours, and don't take off more time than this policy permits.  A negative PTO balance, (when you have missed more time than you have accrued), is a serious matter.  Your ability to satisfactorily perform your job depends on your adhering to the policy, and failure to do so is considered reasonable grounds for termination.
            </p><p>
                You accrue (earn) PTO on the first paycheck of each month according to the following schedule: between your fourth and sixth months of employment (inclusive), you earn 6.67 hours of PTO per month, beginning in your seventh month and forever more, you earn 13.34 hours of PTO per month.  So, starting in your seventh month, you accrue 4 weeks  per year of paid time off (13.34 hours per month x 12 months per year = 160 hours).
            </p><p>
                Your pay stub indicates any PTO that was charged during the pay period and your current PTO balance.  Your balance is also available from this Intranet site.
            </p><p>
                If there is a discrepancy, you should bring it up first with your supervisor and then with Michael, the payroll clerk.  Please do this immediately.
            </p><p>
                You may 'Cash In' any unused PTO - basically, we will pay you for accrued PTO.  This is taxable income, so payroll taxes and 401K deductions are withheld.  You must have available PTO and 10 hours is the minimum amount of PTO that may be cashed in at any time.  To calculate the hourly rate used to determine PTOCI (PTO Cashed In), we take your current monthly salary and divide it by 170 (the number of hours in an average month).</p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Jury Duty">
            <p>
                In an effort to balance the company's civic responsibility with its responsibilities to its non-jury serving employees and to its customers, Case Parts Company establishes its policy with respect to employees jury service as follows:
            </p><p>
                Case Parts will treat as hours worked, to a maximum of five hours per day for a maximum of ten days, time spent by its employees actually serving on a jury or waiting to serve in response to a bona fide summons. Absence beyond these maximums will be treated as Paid Time Off in accordance with the policy for such time described in the January 6, 1988 memorandum. Employees are expected to report for work whenever they are not required to be at court and to furnish the standard proof of attendance issued by the jury authorities.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Inclement Weather">
            <p>
                When Case Parts is open, if you cannot make it to work due to inclement weather, that time missed will be treated as PTO. If Case Parts is closed during regular business hours due to inclement weather, that time missed will be treated as time worked. Whether Case Parts is open or closed is the sole determination of the Branch Manager. The Branch Manager may override this policy on a case-by-case basis when extraordinary circumstances are presented.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Harassment">
            <p>
                It is the policy of Case Parts Company that harassment of employees or others working for the Company by another employee or supervisor is not condoned and will not be tolerated. It is imperative that we maintain a comfortable work environment that is free of harassment.
            </p><p>
                Harassment consists of unwelcome conduct, whether verbal, physical, or visual, that is based upon a person’s protected status, such as sex, color, race, ancestry, religion, national origin, age, disability, veteran status, citizen status, or other protected group status. The Company will not tolerate harassing conduct that affects tangible job benefits, that interferes unreasonably with an individual’s work performance or that creates an intimidating, hostile or offensive work environment.
            </p><p>
                Sexual harassment deserves special mention. Unwelcome sexual advances, requests for sexual favors and other physical, verbal or visual conduct based on sex constitute sexual harassment when:
            </p><ol>
                <li>
                    submission to the conduct is an explicit or implicit condition of an individual’s initial or continued employment;
                </li><li>
                    submission to or refusal to submit to such conduct affects the individual’s employment, evaluations, wages, or other terms or conditions of employment; or
                </li><li>
                    the conduct interferes with an individual’s work performance or creates an intimidating, hostile or offensive work environment.
                </li></ol><p>
                Any person who feels that she/he has been the subject of sexual harassment should immediately notify either Jon Jilg, Jake Wagner, or Bob Goldstein. A prompt and thorough investigation into the allegations or complaints of sexual harassment will be conducted. Appropriate action against the offender, up to and including discharge, shall be taken within a reasonable period of time, if called for, based on the facts revealed by the investigation. The Company will make certain that anyone who reports such an incident in good faith will be protected from any employment-related reprisal.
            </p>
        </FaqEntry>
        <FaqEntry history={props.history} title="Document Retention">
            <h3>No Retention Required</h3>
            <p>Documents that can be readily reproduced with 100% fidelity do not need to have printed versions stored. These include:</p>
            <ul>
                <li>Invoices mailed/faxed to customers</li>
                <li>Purchase Orders</li>
                <li>Receivers</li>
                <li>Vendor Invoice PDFs</li>
                <li>Sales Orders (OP and Acuity)</li>
                <li>Will Call Pick Lists</li>
            </ul>
            <h3>Retain for 2 Years</h3>
            <p>Non-tax related documents that cannot be reproduced because they are not stored in our computer or contain valuable information entered by hand should be retained for 2 years. These documents are principally used in support of customer service and A/P. These include:</p>
            <ul>
                <li>Shipment Pick Lists</li>
                <li>Customer Check Stubs</li>
                <li>Signed Customer 2-Copy receipts</li>
                <li>Post Office Shipment Receipts</li>
                <li>Signed Customer Will Call Receipts</li>
                <li>Bills of Lading (outbound and inbound shipments)</li>
                <li>Vendor Packing Lists</li>
                <li>Cash and Credit Card Customer Invoices</li>
                <li>Credit Card Purchase Receipts (Home Depot, etc.)</li>
            </ul>
            <p>Note: Whenever a customer is asked to sign a non credit card document, they should print their name in addition to signing.</p>
            <h3>Retain for 5 years</h3>
            <p>Tax related documents that cannot be reproduced need to be retained for 5 years. These include:</p>
            <ul>
                <li>Bank Statements</li>
                <li>Credit Card Statements</li>
                <li>401K Statements</li>
                <li>Vendor Invoices (Printed and PDF)</li>
                <li> Cash and Credit Card Customer Invoices</li>
            </ul>
            <h3>Retain Forever</h3>
            <p>These documents should be kept, uh, forever:</p>
            <ul>
                <li>Employment (W2, I9, etc.)</li>
                <li>Beneficiary</li>
                <li>Worker’s Comp Claim</li>
            </ul>
            <h3>For Discussion</h3>
            <ul>
                <li>Scanned and deposited customer check</li>
            </ul>
        </FaqEntry>
    </InfoPage>
}