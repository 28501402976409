import React, {useState} from "react"

const  createName = title => title.toLowerCase().replace(/[^\sa-z]/g, "").replace(/\s\s*/g, "_")

export default function FaqEntry({title, nobox, history, children}) {
    if(!history && title !== "Read More") console.log("OOPS, you forgot to pass history prop", title)
    const id = createName(title)
    const href = window.location.href
    const isCurrent = href.includes("#") && href.substring(href.indexOf("#") + 1) === id

    const [open, setOpen] = useState(isCurrent)

    function handleClick() {
        setOpen(!open)
        if (history && !open) {
            const pathname = window.location.pathname + "#" + id
            history.replace(pathname)
        }
    }
    return <div id={id} className={`faqEntry ${nobox ? "nobox" : "box"} ${open ? "open" : "closed"}`}>
        <div className="question" onClick={handleClick}>{title}</div>
        <div className="answer">{children}</div>
    </div>
}