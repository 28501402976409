import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-bottom: 0px;
`;
const Annotation = styled.div`
  display: inline-block;
  margin-left: 65px;
  text-align: right;
  line-height: 13px;
`;
const Button = styled.span`
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
  color: var(--CP-blue);
  cursor: pointer;
  &:hover {
    background-color: var(--highlight-orange);
    color: black;
  }
`;
const Prompt = styled.span`
  margin-left: 24px;
  margin-right: 0px;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
`;

export default function CheckoutTitle({ isQuote, setIsQuote }) {
  return (
    <Title>
      Review Your {isQuote ? "Quote" : "Order"}
      <Annotation>
        <Prompt>{isQuote ? "Ready to place your order?" : "Just need a quote for now?"}</Prompt>
        <br />
        <Button onClick={() => setIsQuote(!isQuote)}>Convert to {isQuote ? "Order" : "Quote"}</Button>
      </Annotation>
    </Title>
  );
}
