import React from "react";
import { Field } from "formik";

function validate(fieldName, input) {
  if (input !== 3 && input !== 4) {
    return "Must specify whether wire path is 3-sided or 4-sided";
  }
}

const WireSides = ({ fieldName, label, defaultValue }) => (
  <>
    <Field name={fieldName} id={fieldName} validate={input => validate(fieldName, input)}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => (
        <div className="wireSides">
          {label}
          {touched[fieldName] && errors[fieldName] && <div className="errorMessage">{errors[fieldName]}</div>}
          <div>
            <label>
              <img src="/images/3-sided.png" alt="3-sided/double-pass circuit" />{" "}
              <input
                className="nudgeDown"
                type="radio"
                name="threeSides"
                value={3}
                checked={value === 3}
                onChange={() => setFieldValue(fieldName, 3)}
              />
              <div className="nudgeDown">3-Sided / Double-Pass</div>
            </label>
            <label>
              {/* HACK: Not sure why the radio buttons weren't aligning vertically */}
              <img src="/images/4-sided.png" alt="4-sided/single-pass circuit" style={{ marginRight: "6px" }} />
              <input
                className="nudgeDown"
                type="radio"
                name="fourSides"
                value={4}
                checked={value === 4}
                onChange={() => setFieldValue(fieldName, 4)}
              />
              <div className="nudgeDown">4-Sided / Single-Pass</div>
            </label>
          </div>
        </div>
      )}
    </Field>
  </>
);

export default WireSides;
