import React from "react";
import { hasCustomParts } from "../../customParts/helpers";

export default function ApproveShipping({
  setFieldValue,
  values,
  shippingMethod,
  shippingCost,
  isLoadingShipping,
  shippingCustomerAccount,
  cartParts
}) {
  // Shipping approval is not needed and the option should not be displayed if any of the following are true

  // No need if shipping address not specified
  if (values && !values.addressIndex && !(values.address && values.city && values.zipcode)) {
    //console.log("No ApproveShipping when shipping address not specified", values);
    return "";
  }

  // irrelevant for Will Call or Contact Me shipping methods
  if (!shippingMethod || !shippingMethod.startsWith("UPS")) {
    //console.log("No ApproveShipping when not using UPS", shippingMethod);
    return "";
  }

  // This option doesn't apply when ordering non-refundable parts
  if (hasCustomParts(cartParts)) {
    //console.log("No ApproveShipping when cart contains custom parts");
    return "";
  }

  // Don't show option if shipping is billed directly to customer account
  // because by the time ConnectShip has rated the shipment, it's too late to back out the charge
  if (shippingCustomerAccount) {
    //console.log("No ApproveShipping when BillRecipient customer");
    return "";
  }

  // Don't show the checkbox if we're still waiting for shipping cost to be retrieved
  if (isLoadingShipping) {
    //console.log("No approve checkbox because isLoadingShipping");
    return "";
  }

  if (shippingCost && shippingCost !== "TBD") {
    // Add shipping cost when known and the user has requested cost approval

    // No need to show anything if we already know shipping cost
    //console.log("No ApproveShipping when shippingCost known");
    return "";
  }

  return (
    <div style={{ marginTop: "-20px", marginBottom: "16px" }}>
      <input type="checkbox" checked={values.approveShipping} onChange={() => setFieldValue("approveShipping", !values.approveShipping)} />
      Contact me to approve shipping costs
    </div>
  );
}
