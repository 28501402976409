import React from "react"
import InjectHtml from "./InjectHtml"

export default function HolidayMessage({ date }) {
    const today = getToday(date)
    const year = today.getFullYear()

    if (isUpcoming(getMemorialDay(year), today)) {
        return <Closure messagePath="/alerts/holidays/MemorialDay.html" />
    } else if (isUpcoming(getIndependenceDay(year), today)) {
        return <Closure messagePath="/alerts/holidays/IndependenceDay.html" />
    } else if (isUpcoming(getLaborDay(year), today)) {
        return <Closure messagePath="/alerts/holidays/LaborDay.html" />
    } else if (isUpcoming(getThanksgiving(year), today)) {
        return <Closure messagePath="/alerts/holidays/Thanksgiving.html" />
    } else if (isUpcoming(getChristmas(year), today)) {
        return <Closure messagePath="/alerts/holidays/Christmas.html" />
    } else if (isUpcoming(getNewYears(year), today) || isUpcoming(getNewYears(year + 1), today)) {
        return <Closure messagePath="/alerts/holidays/NewYears.html" />
    } else {
        return <Closure messagePath="/alerts/Home.html" />
    }
}

function Closure({ messagePath }) {
    return <InjectHtml url={messagePath}>
        <div style={{ margin: "0em 0em", padding: "1em", backgroundColor: "#D40000", color: "white" }} />
    </InjectHtml>
}

// getMemorialDay returns the date of Memorial Day for the current or specified year
// Memorial Day is always on the last Monday in May
function getMemorialDay(year) {
    const targetYear = getYear(year);
    const memorialDay = new Date(targetYear, 4, 31); // Set to a date beyond the last day of May

    // Find the preceding Monday
    while (memorialDay.getDay() !== 1) {
        memorialDay.setDate(memorialDay.getDate() - 1);
    }

    return memorialDay;
}

function getIndependenceDay(year) {
    return new Date(getYear(year), 6, 4);
}

function getLaborDay(year) {
    const laborDay = new Date(year, 8, 1); // September 1st of the specified year
    const dayOfWeek = laborDay.getDay();

    if (dayOfWeek > 1) {
        laborDay.setDate(laborDay.getDate() + (8 - dayOfWeek));
    } else if (dayOfWeek === 0) {
        laborDay.setDate(laborDay.getDate() + 1);
    }
    return laborDay;
}

function getThanksgiving(year) {
    // Thanksgiving is the fourth Thursday in November
    const thanksgivingDate = new Date(getYear(year), 10, 1);
    thanksgivingDate.setDate(
        thanksgivingDate.getDate() + ((11 - thanksgivingDate.getDay()) % 7) + 21
    );

    // Get the date of the Friday following Thanksgiving
    const fridayFollowingThanksgiving = new Date(thanksgivingDate);
    fridayFollowingThanksgiving.setDate(
        fridayFollowingThanksgiving.getDate() + 1
    );

    return fridayFollowingThanksgiving;
}

function getChristmas(year) {
    const target = new Date(getYear(year), 11, 25);
    return adjustDate(target);
}

function getNewYears(year) {
    const target = new Date(getYear(year), 0, 1);
    return adjustDate(target);
}

function isUpcoming(date, today) {
    if (!(date instanceof Date)) {
        date = new Date(date); // Convert date string to Date object
        if (isNaN(date.getTime())) {
            throw new Error(
                "Invalid date string. Please provide a valid date string or Date object."
            );
        }
    }

    const weekInMillis = 7 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week

    // Calculate the reference date for comparison
    const referenceDate = new Date(date.getTime() - weekInMillis); // Subtract a full week

    // Adjust the reference date to the start of the business week (Monday)
    const dayOfWeek = referenceDate.getDay();
    const diff = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    referenceDate.setDate(referenceDate.getDate() - diff);

    const currentDate = getToday(today);
    return (
        currentDate >= referenceDate &&
        currentDate <= date
    );
}

// adjustDate(date) returns the following day for Sunday
// and the previous day for Saturday
function adjustDate(date) {
    if (date.getDay() === 0) {
        // if Sunday, celebrate on Monday
        date.setDate(date.getDate() + 1);
    } else if (date.getDay() === 6) {
        // if Saturday, observe on Friday
        date.setDate(date.getDate() - 1);
    }
    return date;
}

// getToday() returns a Date object for the current date.
// getToday(date) returns the passed Date object or date string.
// accepting an optional date is useful for testing purposes.
function getToday(date) {
    if (!date) {
        return new Date();
    }

    if (date instanceof Date) {
        return date;
    }

    if (typeof date === "string") {
        return new Date(date);
    }

    throw new Error(
        "Invalid date format. Please provide a Date object or a date string."
    );
}

// getYear() returns the current year as an integer
// getYear(year) when passed an integer, returns that value
// accepting an optional year is useful for testing purposes.
function getYear(year) {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    if (year && typeof year === "number") {
        currentYear = year;
    }

    return currentYear;
}
