import React from "react";
import CartPart from "./CartPart";

export default function CartParts(props) {
  if (props.parts.length === 0) {
    return <div style={{ padding: 10 }}>Your cart is empty.</div>;
  } else {
    return (
      <>
        {props.parts.map((part, n) => (
          <CartPart
            part={part}
            history={props.history}
            key={n}
            showDetails={true}
            onUpdateQuantity={props.onUpdateQuantity}
            onRemoveFromCart={props.onRemoveFromCart}
            inventory={props.inventory ? props.inventory[n] : null}
          />
        ))}
      </>
    );
  }
}
