import isNumber from "../../data/isNumber";

function RoundToSixteenth(number) {
  if (isNumber(number)) {
    const rounded = (number * 16).toFixed() / 16;
    return rounded;
  }
}

function parseFraction(value) {
  // Try parsing input as a decimal value
  const numberRegex = /^\s*(\d*)(\.\d*)?(\s*cm)?[\s"]*$/;
  const numberMatches = numberRegex.exec(value);
  if (numberMatches) {
    const whole = numberMatches[1];
    const decimal = numberMatches[2];
    let number = `${whole}${decimal}`;
    if (numberMatches[3]) number *= 0.394;
    var rounded = RoundToSixteenth(number);
    if (rounded) {
      return rounded;
    }
  }

  // Try parsing input as a fractional value
  const regex = /^\s*(\d+)([\s-]+(\d+)\/(\d+))?(\s*cm)?(\s*")?\s*$/;
  const matches = regex.exec(value);
  if (matches) {
    let number = Number(matches[1]);
    if (matches[3] && matches[4]) number += Number(matches[3] / matches[4]);
    if (matches[5]) number *= 0.394;
    rounded = RoundToSixteenth(number);
    return rounded;
  }

  // If we failed both, return default undefined value to trigger error
}

function formatFraction(value) {
  if (!parseFraction(value)) return value;

  const inches = Math.trunc(value);
  const sixteenths = ((value - inches) * 16).toFixed() / 16;
  const fraction = (function (eigths) {
    switch (eigths) {
      case 0:
        return "";
      case 1 / 16:
        return "1/16";
      case 1 / 8:
        return "1/8";
      case 3 / 16:
        return "3/16";
      case 1 / 4:
        return "1/4";
      case 5 / 16:
        return "5/16";
      case 3 / 8:
        return "3/8";
      case 7 / 16:
        return "7/16";
      case 1 / 2:
        return "1/2";
      case 9 / 16:
        return "9/16";
      case 5 / 8:
        return "5/8";
      case 11 / 16:
        return "11/16";
      case 3 / 4:
        return "3/4";
      case 13 / 16:
        return "13/16";
      case 7 / 8:
        return "7/8";
      case 15 / 16:
        return "15/16";
      default:
        return "";
    }
  })(sixteenths);

  var text = inches ? inches : "";
  if (sixteenths) text += ` ${fraction}`;
  text += '"';
  return text;
}

export { isNumber, parseFraction, formatFraction };
