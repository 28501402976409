import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import AppContext from "./AppContext";
import { LoadCatalog } from "../data/CatalogLoader";
import { LoadBlock } from "../data/BlockLoader";
import AppSettings from "../AppSettings";
import queryString from "query-string";
import ContactInfo from "../data/ContactInfo";
import { extractCSStoHTML } from "./extractCSStoHTML";
import useLocalStorage from "./useLocalStorage";
import {GA_Initialize, GA_Pageview} from "./GoogleAnalytics";

const cartStorageKey = "cpc-shoppingCart";
const contactStorageKey = "cpc-contactInfo";

  // Extends Error to include statusCode
class FetchError extends Error {
  constructor(statusCode, message) {
    super(message)
    this.name = "FetchError"
    this.statusCode = statusCode
  }
}

// Provides default error messages for common status codes
function defaultErrorMessage(status, url) {
  switch (status) {
    case 401: return "You are not authenticated"
    case 403: return "You do not have permission to access this page"
    case 404: return "Page does not exist: " + url
    default: return "Unexpected Error"
  }
}

function AppProvider({ children, history, location }) {
  //#region Login/Logout with related helpers
  const [localToken, setLocalToken] = useLocalStorage("token", "")

  function isLocal() {
    // Curiously, hostname isn't passed through useLocation
    const hostname = window.location.hostname
    return hostname === "localhost" || hostname === "127.0.0.1"
  }

  function parseNested(claims, name) {
    if (claims && typeof claims[name] === 'string' && claims[name].length > 0) {
      claims[name] = JSON.parse(claims[name])
    }
  }

  const assignToken = jwt => {
    // Check that the input is a string and has three parts separated by dots
    if (!jwt || typeof jwt !== "string" || jwt.split(".").length !== 3) {
      throw new Error("Invalid JWT");
    }

    // Remove the leading and trailing quotes from the string
    jwt = jwt.replace(/^["']|["']$/g, "");

    // Split the JWT into three parts: header, payload, and signature
    const [/*header*/, payload, /*signature*/] = jwt.split(".");
    // Decode the payload from base64 to JSON
    var claims = JSON.parse(atob(payload));
    parseNested(claims, 'Customer')
    parseNested(claims, 'Employee')

    handleSetToken(jwt)
    handleSetClaims(claims)

    if (isLocal() && claims.UserType !== "Guest") {
      console.log("Assign local token", claims)
      setLocalToken(jwt)
    }

    return claims
  }

  async function login(email, password) {
    //Localhost implicit login
    if (isLocal() && localToken && !email) {
      console.log("using localstorage token")
      assignToken(localToken)

      // For consistency, return a promise
      return new Promise(function (resolve) {
        resolve(localToken)
      })
    }

    const url = AppSettings.Api.LoginUrl;
    var options = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'ApiKey': process.env.REACT_APP_APIKEY
      }
    }
    if (email) {
      options.body = JSON.stringify({
        UserName: email,
        Password: password,
        RememberMe: false
      })
    }
    console.log("login", url, options, email)
    return fetch(url, options)
      .then(res => {
        if(res.status < 400) { return res.text() }
        else{
          return res
          .text()
          .then(text => {
            var message = text ? String(text).replace(/['"]+/g, '')
            : res.statusText ? res.statusText
              : defaultErrorMessage(res.status, url)
            throw new FetchError(res.status, message)
          })
        }
      })
      .then(jwt => jwt ? jwt.replace(/['"]+/g, '') : null)
      .then(jwt => assignToken(jwt))
  }

  const logout = () => {
    console.log("Logout")

    // clear localToken if running locally
    if (isLocal()) {
      console.log("Clearing localstorage token")
      setLocalToken() // no parameter === undefined which triggers removeItem
    }

    var options = {
      method: 'POST',
      credentials: 'include'
    }

    // Logout of server to clear cookie
    fetch(AppSettings.Api.LogoutUrl, options)
      .then(() => {
        // Setting token null triggers useEffect to reauthenticate as an anonymous user
        handleSetToken(null);
        handleSetClaims({ Customer: "", Employee: ""});
        handleClearCart();
        login();
        history.push("/");
      })
  }

  const impersonate = (token, email) => {
    console.log(email ? "Impersonate " + email : "Cancel impersonation")
    var options = {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    return fetch(AppSettings.Api.Impersonate(email), options)
      .then(res => res ? res.text() : null)
      .then(jwt => jwt ? jwt.replace(/['"]+/g, '') : null)
      .then(jwt => assignToken(jwt))
  }

  //#endregion Login/Logout with related helpers

  // See https://codesandbox.io/s/y042wvl05x for details on updating shared context
  const [catalog, setCatalog] = useState(null);
  const [sharedState, setSharedState] = useState({
    initialized: false,
    cartParts: JSON.parse(localStorage.getItem(cartStorageKey)) || [],
    parts: [],
    sections: [],
    block: null,
    query: "",
    blockHtml: "",
    token: "",
    claims: {},
    account: "",
    discountLevel: "",
    contactInfo: new ContactInfo(),
    login: (email, password) => login(email, password),
    logout: () => logout(),
    impersonate: (email) => impersonate(email)
  });

  function handleSetToken(token) {
    setSharedState(prevState => {
      return {
        ...prevState,
        token: token
      };
    });
  }

  function handleSetClaims(claims) {
    setSharedState(prevState => {
      console.log("CLAIMS", claims)

      // Update GA4 userId property with custId
      // https://youtu.be/cpZMDkZ2mGA?t=183
      const account = claims && claims.Customer ? claims.Customer.CustId : ""
      if (window.dataLayer && account !== prevState.account) {
        window.dataLayer.push({
          event: 'userLogin',
          user_id: account
        });

        if (typeof window.gtag === 'function') {
          window.gtag('set', {'user_id': account });
        } else {
          console.log('gtag function not available');
        }
      }

      return {
        ...prevState,
        claims: claims,
        account: claims.Customer && claims.Customer.CustId ? claims.Customer.CustId : "",
        discountLevel: claims.Customer ? claims.Customer.CustClass : "EndUser",
        contactInfo: new ContactInfo(claims)
      };
    });
  }

  // This effect is called just once when we first start
  // It initiates the loading of the catalog and sets up
  // a history listener.
  useEffect(
    () => {
      window.prerenderReady = false;
      console.log("Catalog initializing...");
      login();
      // Creating an async function inside our effect, then calling it...
      // credit to this: https://github.com/facebook/react/issues/14326
      async function initialize() {
        try {
          GA_Initialize(window.location);
          console.log("Loading catalog...");
          let catalog = await LoadCatalog();
          setCatalog(catalog);
          console.log("Catalog loaded");
        } catch (error) {
          console.log(`Catalog load FAILED: ${error}`);
          throw error;
        }
      }
      initialize();
    },
    [
      // Since we want this to only fire once, we explicitly specify no dependencies
    ]
  );

  // This effect depends on catalog state and fires twice.
  // It initially fires when the component is loaded,
  // then once more when catalog is initialized
  useEffect(() => {
    if (!catalog || !sharedState.claims) {
      return;
    }
    try {
      const discountLevel = sharedState.claims.Customer ? sharedState.claims.Customer.CustClass : "EndUser";
      catalog.SetPricingTier(discountLevel);
      RefreshShoppingCart(catalog);
      
      // const [newParts, newSections] = catalog.GetSearchResults("");
      setSharedState(prevState => ({
        ...prevState,
        initialized: true,
        // parts: newParts,
        // sections: newSections ? newSections : [],

        // We define functions here to ensure the catalog is defined
        // when they are bound
        search: query => handleSearch(query),
        getKeywordOptions: token => handleGetKeywordOptions(token),
        goto: location => handleGoTo(location),
        addToCart: (partId, quantity) => handleAddToCart(partId, quantity),
        addOrUpdatePartInCart: part => handleAddOrUpdatePartInCart(part),
        removeFromCart: partId => handleRemoveFromCart(partId),
        updateQuantity: (partId, quantity) => handleUpdateQuantity(partId, quantity),
        updateNotes: (partId, notes) => handleUpdateNotes(partId, notes),
        clearCart: () => handleClearCart(),
        updateContactInfo: parameters => handleUpdateContactInfo(parameters),
        findPart: partId => handleFindPart(partId)
      }));

      console.log("initialization complete");
    } catch (error) {
      console.log(`Phase 2 Initialization FAILED: ${error}`);
      throw error;
    }
  }, [
    // catalog is a dependency causing this effect to fire once it is loaded
    catalog, sharedState.claims
  ]);

  // Given a URL, return the search query, page name, and partId
  function ParseUrl(path, query) {
    if (!query) {
      query = "";
    }
    const segments = String(path).split("/");
    const command = segments[1].toUpperCase();
    let result = {
      path: path,
      command: command,
      query: query,
      page: undefined,
      partId: undefined
    };

    switch (command) {
      case "SEARCH":
        result.query = segments.length > 2 ? segments[2] : "";
        break;
      case "PAGE":
        result.page = segments.length > 2 ? segments[2] : "";
        result.query = query;
        break;
      case "PART":
        result.partId = segments.length > 2 ? segments[2] : "";
        result.page = segments.length > 3 ? segments[3] : "";
        result.query = query;
        break;
      default:
        break;
    }
    return result;
  }

  useEffect(() => {
    if (!catalog || !sharedState.token) {
      console.log("Catalog is not loaded yet!");
      return;
    }

    GA_Pageview(window.location, sharedState.claims)

    // Begin by parsing location
    let params = queryString.parse(location.search);
    const { command, partId, page, query } = ParseUrl(location.pathname, params.query);
    let [newParts, newSections] = catalog.GetSearchResults(query);
    let partIds = query ? new Set(newParts.map(part => part.partId)) : new Set();
    const block = page ? catalog.FindBlock(page) : null;
    // TODO: Explorer if we really need to wait before calling extractCSStoHTML

    switch (command) {
      case "SEARCH":
        setSharedState(prevState => {
          setTimeout(() => {
            extractCSStoHTML();
          }, 1000);
          return {
            ...prevState,
            query: query,
            parts: newParts ? newParts : [],
            partIds: partIds,
            sections: newSections ? newSections : [],
            block: null,
            blockHtml: "",
            selectedPart: null
          };
        });
        break;
      case "PAGE":
        if (!block) {
          history.replace("/");
        } else {
          //window.prerenderReady = false;
          newParts = catalog.GetBlockParts(block.id);
          setSharedState(prevState => ({
            ...prevState,
            query: query,
            parts: newParts ? newParts : [],
            partIds: partIds,
            // sections: newSections ? newSections : [],
            block: block,
            blockHtml: "",
            selectedPart: null
          }));
          setTimeout(() => {
            LoadBlock(block.path, sharedState.account, sharedState.token).then(html => {
              setSharedState(prevState => {
                setTimeout(() => {
                  extractCSStoHTML();
                }, 1000);
                return {
                  ...prevState,
                  blockHtml: html
                };
              });
            });
          }, 0);
        }
        break;
      case "PART":
        // Update sections to reflect the pages that include this part;
        newSections = catalog.GetSectionsForPartId(partId);
        let part = catalog.GetPartById(partId);
        if (!part) {
          part = {
            partId: partId
          };
          newSections = [];
        }
        // When the user clicks among parts on a catalog page, we want to stay on that page
        // But if a part is selected from a list, then we should display the first block associated
        // with the part, which is typically a page in the Part Types are of the catalog and, therefore, a good default.
        const defaultBlock = part && part.blocks ? part.blocks[0] : null;
        // if (defaultBlock && !block) {
        //   let url = `/Part/${partId}/${defaultBlock.path}`;
        //   if (query) {
        //     url += `?query=${query}`;
        //   }
        //   console.log(`redirecting to ${url}`);
        //   history.replace(url);
        //   return;
        // }

        const newBlock = block ? block : defaultBlock;
        // If we don't have a match on page, then we want to return a soft 404
        const unknownPage = !block;
        newParts = newBlock ? catalog.GetBlockParts(newBlock.id) : [];
        setSharedState(prevState => ({
          ...prevState,
          query: query,
          parts: newParts,
          partIds: partIds,
          sections: newSections,
          block: newBlock,
          blockHtml: "",
          selectedPart: part,
          unknownPage: unknownPage
        }));
        setTimeout(() => {
          LoadBlock(newBlock ? newBlock.path : page, sharedState.account, sharedState.token).then(html => {
            setSharedState(prevState => {
              console.log(`extractCSS for ${page}`);
              return {
                ...prevState,
                blockHtml: html
              };
            });
          });
        }, 0);
        setTimeout(() => {
          extractCSStoHTML();
        }, 1000);
        break;
      default:
        setSharedState(prevState => {
          setTimeout(() => {
            extractCSStoHTML();
          }, 1000);
          return {
            ...prevState,
            query: query,
            parts: newParts ? newParts : [],
            partIds: partIds,
            sections: newSections ? newSections : [],
            block: null,
            blockHtml: "",
            selectedPart: null
          };
        });
        break;
    }
  }, [location.pathname, location.search, catalog, sharedState.token]);

  function handleGetKeywordOptions(token) {
    return catalog.GetKeywordOptions(token);
  }

  function handleGoTo(url) {
    history.push(url);
  }

  function handleSearch(query) {
    console.log(`handleSearch(${query})`);
    history.push(`/Search/${query}`);
  }

  function handleAddToCart(partId, quantity) {
    console.log(`handleAddToCart(${partId},${quantity})`);
    const catalogPart = catalog.GetPartById(partId);
    if (!catalogPart) {
      console.log(`addToCart(${partId}) ignored because part does not exist in catalog`);
      return;
    }
    const newPart = { ...catalogPart, quantity: quantity };
    setSharedState(prevState => {
      const existingPart = prevState.cartParts.filter(part => part.partId === partId);
      if (existingPart.length > 0) {
        newPart.quantity = Number(newPart.quantity) + Number(existingPart[0].quantity);
      }
      const otherCartParts = prevState.cartParts.filter(part => part.partId !== partId);
      const updatedCartParts = [...otherCartParts, newPart];
      localStorage.setItem(cartStorageKey, JSON.stringify(updatedCartParts));
      return {
        ...prevState,
        cartParts: updatedCartParts
      };
    });

    if (typeof window.gtag === 'function') {
      const eventModel = {
        currency: "USD",
        item_id: newPart.itemId,
        item_name: newPart.title,
        value: newPart.price,
        quantity: newPart.quantity,
        items: [{
          item_id: newPart.itemId,
          item_name: newPart.title,
          price: newPart.price,
          quantity: newPart.quantity
        }]
      }
      window.gtag('event', 'add_to_cart', eventModel)
    } else {
      console.log('gtag function not available');
    }
  }

  function handleAddOrUpdatePartInCart(part) {
    console.log(`handleAddOrUpdatePartInCart`, part);
    // Assign timestamp when first adding a part so we can differentiate it on subsequent update
    if (!part.uniqueId) {
      part.uniqueId = Date.now().toString();
    }
    setSharedState(prevState => {
      const isUpdate = !!prevState.cartParts.find(p => part.uniqueId === p.uniqueId);
      const updatedCartParts =
        part.quantity > 0
          ? isUpdate
            ? // the next three lines will update, add, or delete a part, respectively
            prevState.cartParts.map(p => (part.uniqueId === p.uniqueId ? part : p))
            : [...prevState.cartParts, part]
          : prevState.cartParts.filter(p => part.uniqueId !== p.uniqueId);
      localStorage.setItem(cartStorageKey, JSON.stringify(updatedCartParts));
      return {
        ...prevState,
        cartParts: updatedCartParts
      };
    });
  }

  function handleRemoveFromCart(partId) {
    console.log(`handleRemoveFromCart(${partId})`);
    setSharedState(prevState => {
      const uniquePart = prevState.cartParts.find(p => partId === p.uniqueId);
      const updatedCartParts = prevState.cartParts.filter(part =>
        uniquePart ? part.uniqueId !== partId : part.partId !== partId
      );
      localStorage.setItem(cartStorageKey, JSON.stringify(updatedCartParts));
      return {
        ...prevState,
        cartParts: updatedCartParts
      };
    });
  }

  // Search for part by PartId, but if not found, check for uniqueId in shopping cart
  function handleFindPart(partId) {
    var part = catalog.GetPartById(partId);
    return part;
  }

  // Refresh all the server-specified properties of parts while retaining any client-specified
  // properties such as quantity or notes
  function RefreshShoppingCart(catalog) {
    console.log(`RefreshShoppingCart`);
    setSharedState(prevState => {
      const updatedCartParts = prevState.cartParts.map(part => {
        return part.custom
          ? {
            ...part,
            price: catalog.GetCustomPartPrice(part)
          }
          : {
            ...part,
            ...catalog.GetPartById(part.partId)
          };
      });
      localStorage.setItem(cartStorageKey, JSON.stringify(updatedCartParts));
      return {
        ...prevState,
        cartParts: updatedCartParts
      };
    });
  }

  function handleUpdateQuantity(partId, quantity) {
    console.log(`handleUpdateQuantity(${partId},${quantity})`);
    setSharedState(prevState => {
      const updatedCartParts = prevState.cartParts.map(part =>
        part.partId === partId ? { ...part, quantity: quantity } : part
      );
      localStorage.setItem(cartStorageKey, JSON.stringify(updatedCartParts));
      return {
        ...prevState,
        cartParts: updatedCartParts
      };
    });
  }

  function handleUpdateNotes(partId, notes) {
    console.log("handleUpdateQuantity", partId, notes);
    setSharedState(prevState => {
      const updatedCartParts = prevState.cartParts.map(part =>
        part.partId === partId ? { ...part, notes: notes } : part
      );
      localStorage.setItem(cartStorageKey, JSON.stringify(updatedCartParts));
      return {
        ...prevState,
        cartParts: updatedCartParts
      };
    });
  }

  function handleClearCart() {
    console.log(`handleClearCart()`);

    setSharedState(prevState => {
      localStorage.setItem(cartStorageKey, JSON.stringify([]));
      return {
        ...prevState,
        cartParts: []
      };
    });
    return true; // Need to return truthy value for use in anchor tag for Log Off
  }

  function handleUpdateContactInfo(parameters) {
    console.log("handleUpdateContactInfo()", parameters);
    sharedState.contactInfo.Merge(parameters);
    localStorage.setItem(contactStorageKey, JSON.stringify(sharedState.contactInfo));
  }

  return <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>;
}

export default withRouter(AppProvider);
