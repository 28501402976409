import React from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "../../styling/styles.css";
import "../../styling/formik.css";
import { useAlert } from "react-alert";
import AppSettings from "../../../AppSettings";

const PopupWrapper = styled.div`
  margin: 0px;
  padding: 16px 16px 0px 16px;
`;
const PopupHeading = styled.div`
	color: black;
  position: relative;
  top: -22px;
  left: -21px;
  width: 417px;
  margin: 0px;
  padding 22px;
  background-color: #ffd564;
`;
const Heading = styled.div`
  font-size: 24px;
  margin-bottom: 8px;
`;
const BodyContainer = styled.div`
  font-size: 18px;
  color: #615b5b;
`;
const RegisterLink = styled.a`
  padding: 8px 16px;
  background-color: #1380B1
  color:white;
  border-radius: 5px;
  margin-right: 16px;
`;
const CloseButton = styled.button`
  width: fit-content !important;
  padding: 8px 16px !important;
`;
const ActionContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
`;

const popupContents = (close) => {
  return (
    <PopupWrapper className="checkout">
      <PopupHeading>
        <Heading>Are you in the trade?</Heading>
        <BodyContainer>
          It's easy to set up a trade account to receive discounted pricing on all your purchases.
          <ActionContainer>
            <RegisterLink href={AppSettings.BenefitsPage}>Register Today</RegisterLink>
            <CloseButton onClick={close}>Close</CloseButton>
          </ActionContainer>
        </BodyContainer>
      </PopupHeading>
    </PopupWrapper>
  );
};

export default function NewAccountPopup({ trigger }) {
  const alert = useAlert();

  return (
    <Popup
      trigger={trigger}
      modal
      closeOnEscape
      closeOnDocumentClick={false}
      contentStyle={{ width: "450px", backgroundColor: "#f7e8bf" }}
    >
      {close => popupContents(close)}
    </Popup>
  );
}
