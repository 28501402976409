import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import Breadcrumb from "../navigation/Breadcrumb";
import Block from "../components/parts/Block";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 16px;
`;
const HeadingArea = styled.div`
  margin: 8px 6px 18px 0px;
`;
const Icon = styled.img`
  margin-right: 8px;
  width: 50px;
  height: 45px;
  object-fit: contain;
  float: left;
`;
const HeadingTitle = styled.div`
  height: 25px;
  font-size: 22px;
  font-weight: 600;
  color: var(--black);
`;
const Subtitle = styled.div`
  margin-top: 4px;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
`;

const Hint = styled.div`
  padding: 16px;
  margin-left: 8px;
  margin-bottom: 0px;
  min-height: 40px;
  width: 400px;
  box-shadow: 0 2px 4px 0 var(--black-19);
  border: solid 1px var(--very-light-grey);
  background-color: var(--highlight-yellow);
  display: flex;
`;
const HintIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  margin-right: 16px;
  color: orange;
`;
const HintText = styled.div`
  font-size: 16px;
  font-style: normal;
  max-width: 300px;
`;

export default function CatalogPage(props) {
  const context = useContext(AppContext);
  const { query, parts, partIds, block, blockHtml } = context;
  const needsHint = block && blockHtml && blockHtml.search("ptable") > 0;

  // When viewing for for a page block, highlight the parts that match the search query
  function isHighlighted(id) {
    return query && partIds ? partIds.has(id) : false;
  }

  // Infer title and breadcrumbs from current location.pathname
  let breadcrumbs = [{ title: "Home", path: "/" }];
  if (query) {
    breadcrumbs.push({ title: `Search: ${query.toUpperCase()}`, path: `/Search/${query}` });
  }

  // Tailor the message when only some of the displayed parts match the search query
  const partCountMessage = (function() {
    // count of parts that match the search query
    const matchCount = block ? parts.filter(part => partIds.has(part.partId)).length : parts.length;
    const partText = matchCount === 1 ? "part" : "parts";
    const matchText = matchCount === 1 ? "matches" : "match";
    if (query) {
      if (matchCount < parts.length) {
        return `${matchCount} ${partText} (of ${parts.length}) on this page ${matchText} your search`;
      } else {
        return `${matchCount} ${partText} on this page ${matchText} your search`;
      }
    } else {
      return parts.length > 0 ? `${parts.length} ${partText} on this page` : "";
    }
  })();

  const title = `${block ? block.title + " - " : ""}Case Parts Commercial Refrigeration Parts Specialists`;
  const description = `${title}. Latches, Hinges, Gaskets, Heaters, Pans, Walk-In Equipment, Electrical, Thermometers, Alarms, Shelving`;

  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <HeadingArea>
        <Icon
          src={block ? (block.info ? "/images/icon-info2.svg" : "/images/icon-page.svg") : "/images/icon-wrench.svg"}
        />
        <HeadingTitle>
          {block
            ? block.category === "Manufacturers"
              ? `Replacement Parts for ${block.title}`
              : block.title
            : "Matching Parts"}
          <Breadcrumb
            title={`${block && query ? "Return to Search Results" : "Return to Home page"}`}
            path={block && query ? `/Search/${query}` : "/"}
          />
        </HeadingTitle>
        <Subtitle>{partCountMessage}</Subtitle>
      </HeadingArea>
      {needsHint && <Hint>
        <HintIcon icon={["fas", "lightbulb-on"]} />
        <HintText>
          Click any <b>part number</b> for details and the option to add it to your cart
        </HintText>
      </Hint>}
      <Block
        blockHtml={blockHtml}
        partIds={partIds}
        isHighlighted={isHighlighted}
        // selectedPart={selectedPart} not used in this context
        block={block}
      />
    </Wrapper>
  );
}
