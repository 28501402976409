import React from 'react'
import CheckoutNote from './CheckoutNote'
import Disclaimer from './Disclaimer'

export default function InventoryDisclaimer({ shipMethod, shippingEstimates }){
  const invalid = !shippingEstimates || (shippingEstimates && !shippingEstimates.InStockWhse && !shippingEstimates.UPSRates)
  if(invalid) { return null }

  const { getInventoryDisclaimer } = Disclaimer
  const { partsNotInStock, multiwarehouseShipment} = getInventoryDisclaimer(
    shipMethod, 
    shippingEstimates.InStockWhse, 
    shippingEstimates.InStock,
    !shippingEstimates)

  return (
    <React.Fragment>
      <PartsNotInStockDisclaimer disclaimer={partsNotInStock} />
      <SplitOrderDisclaimer disclaimer={multiwarehouseShipment} />
    </React.Fragment>
  )
}

function PartsNotInStockDisclaimer({ disclaimer }){
  if(!disclaimer) { return null }

  return(
    <CheckoutNote icon="triangle-exclamation"  /*backgroundColor="#fffbd0a6"*/
        title={disclaimer.header}>
      {disclaimer.message}
    </CheckoutNote>
  )
}

function SplitOrderDisclaimer({ disclaimer }){
  if(!disclaimer) { return null }

  return (
    <CheckoutNote icon="exclamation-triangle" iconColor="#EFCC00" title={disclaimer.header}>
      {disclaimer.message}    
    </CheckoutNote>
  )
}