import React, { useState, useContext } from "react"
import AppContext from "../../context/AppContext";
import AppSettings from "../../AppSettings"
import queryString from "query-string";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";

export default function Login(props) {
  const { login } = useContext(AppContext)
  const [error, setError] = useState()
  const initialValues = { email: "", password: "" }
  let { returnUrl } = queryString.parse(props.location.search);
  const alert = useAlert();
  const [emailResend, setEmailForResend] = useState()

  const onEmailConfirmationResend = () => {
    const url = AppSettings.Api.ResendConfirmationEmail
    const payload = {
      email: emailResend
    }
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify(payload)
    }

    fetch(url, options)
    .then(() => handleConfirmationSuccess())
    .catch(err => alert.error("Sorry, something went wrong. Unable to resend email confirmation. " + err.message))
  }

  const handleConfirmationSuccess = () => {
    alert.success("Email confirmation resent!")
    setError(null)
  }

  let validations = {
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
  };
  const inputSchema = Yup.object().shape(validations);

  const PasswordFormField = ({ name, required, caption, type, component, placeholder, errors, touched }) => (
    <>
      <div className="login__password-container">
        <label htmlFor={name}>
          {caption}
          {required && <strong>*</strong>}
        </label>
        <a className="login__forgotpw link" href={AppSettings.ForgotPasswordPage}>Forgot password?</a>
      </div>
      <ErrorMessage name={name} className="error" component="div" />
      <PasswordField
        name={name}
        placeholder={placeholder}
        className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      />
    </>
  );

function PasswordField({ 
    name, 
    placeholder, 
    disabled,
    className
   }) {
    const [showPassword, setShowPassword] = useState(false)
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword)
    }

    const ShutEye = () => <img src="/images/shut-eye.svg" alt="" />
    const Eye = () => <img src="/images/eye.svg" alt="" />
  
    return (
      <div className='login__password-field'>
        <Field
          type={showPassword ? 'text' : 'password'}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          />
        <span onClick={togglePasswordVisibility}>
          {showPassword ? <ShutEye /> : <Eye />}
        </span>
      </div>
    )
  }

  const FormField = ({ name, required, caption, type, component, placeholder, errors, touched }) => (
    <>
      <label htmlFor={name}>
        {caption}
        {required && <strong>*</strong>}
      </label>
      <ErrorMessage name={name} className="error" component="div" />
      <Field
        component={component || "input"}
        type={type || "text"}
        name={name}
        placeholder={placeholder}
        className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      />
    </>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={inputSchema}
      onSubmit={(values, { setSubmitting }) => {
        setEmailForResend(values.email)
        login(values.email, values.password)
          .then(() => { 
            if(returnUrl === "/"){
              window.location.href = AppSettings.AccountPage
              return
            }

            props.history.push(returnUrl ? returnUrl : "/") 
          })
          .catch(e => setError(e))
          .finally(() => setSubmitting(false))
      }}
    >
      {props => {
        const {
          //values,
          touched,
          errors,
          //dirty,
          isSubmitting
          //handleChange,
          //handleBlur,
          //handleSubmit,
          //handleReset
        } = props;
        return (
          <div className="checkout login">
            <h1>Sign in</h1>
            <Form>
              <div>
                <LoginError error={error} onEmailConfirmationResend={onEmailConfirmationResend} />

                <FormField
                  name="email"
                  required
                  caption="Email"
                  type="email"
                  placeholder="Enter email address"
                  errors={errors}
                  touched={touched}
                />
                <PasswordFormField
                  name="password"
                  required
                  caption="Password"
                  type="password"
                  placeholder="Enter your password"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <button type="submit" disabled={isSubmitting} style={{ width: '380px'}}>
                Sign in
              </button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}


function LoginError({error, onEmailConfirmationResend}){
  const errorServerMessages = {
    requiresConfirmation: (err) => err && err.message === "Email needs to be confirmed",
    invalidCredentials: (err) =>  err && err.message === "Invalid UserName or Password",
    inactivatedCustomer: (err) =>  err && err.message.includes("Inactive Customer"),
    internalServerError: (err) =>  err && err.statusCode >= 500
  }

  const inactivatedCustomerError = () => {
    if(!error)
      return null
    
    const custId = error.message.split(":")[1]
    const href = `mailto:accounting@caseparts.com?subject=Inactivated Account ${custId}`
    return (
      <div className={pre("inactivated-error")}>
        <p>Your account {custId} has been inactivated.</p>
        <p>If you think this a mistake please contact our accounting department.</p>
        <p>(800) 421-0271 extension 1</p>
        <a href={href}>accounting@caseparts.com</a>
      </div>
    )
  }

  console.log(error)
  console.log("Requires Confirmation", errorServerMessages.requiresConfirmation(error))
  console.log("Invalid Creds", errorServerMessages.invalidCredentials(error))
  console.log("Inactivated Customer", errorServerMessages.inactivatedCustomer(error))

  const pre = c => "login__" + c

  const When = ({ prop, children }) => !prop ? null : <>{children}</>
  const WhenDiv = ({ prop, className, children }) => !prop ? null : <div className={className}>{children}</div>

  return (
    <WhenDiv prop={error} className="login__error">

      <When prop={errorServerMessages.requiresConfirmation(error)}>
        <p>Your email has not been confirmed.</p>
        <a className={pre("resend-btn")} onClick={onEmailConfirmationResend} href="#">
          Click here to resend
        </a>
      </When>

      <When prop={errorServerMessages.invalidCredentials(error)}>
        <p>Invalid email or password.</p>
      </When>

      <When prop={errorServerMessages.inactivatedCustomer(error)} className={pre("inactivated-error")}>
        {inactivatedCustomerError()}
      </When>

      <When prop={errorServerMessages.internalServerError(error)}>
        <p>Sorry, something went wrong. Please try again.</p>
      </When>

    </WhenDiv>
  )
}