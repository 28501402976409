import React from "react";
import styled from "styled-components";
import "../../styling/styles.css";

const Wrapper = styled.div`
  text-align: left;
  min-width: 120px;
  padding: 0px;
  margin: 2px 12px;
`;
const AttributeName = styled.span`
  color: #000;
  font-weight: 300;
`;
const AttributeValue = styled.span`
  color: var(--black);
  font-weight: 700;
  margin-left: 4px;
`;

export default function Attribute(props) {
  return (
    <Wrapper>
      <AttributeName>{props.name}:</AttributeName>
      <AttributeValue>{props.value}</AttributeValue>
    </Wrapper>
  );
}
