import React from "react"
import styled from "styled-components";
import "../styling/styles.css";
import useSessionStorage from "../../context/useSessionStorage";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: left;
  background-color: #ffebb2;
  color: black;
  margin: 0 0 0 0;
  top: -8px;
  padding: 1px 0px 10px 180px;
  gap: 2rem;
`;
const CloseButton = styled.div`
  margin: 12px 20px;
  cursor: pointer;
  color: var(--CP-blue);
  &:hover {
    color: var(--black);
    background-color: var(--highlight-orange);
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}
`;

export default function Notice(props) {
    const [showNotice, setShowNotice] = useSessionStorage(`Notice: ${props.id}`, true);
    if (!showNotice) {
        return "";
    }

    return (
        <Wrapper>
            <div>
            {props.children}
            </div>
            <CloseButton onClick={()=>setShowNotice(false)}>DISMISS</CloseButton>
        </Wrapper>
    );
}

