import React from "react";
import styled from "styled-components";
import "../styling/styles.css";
import AppSettings from "../../AppSettings";

const Animation = styled.img`
  margin-top: 40vh;
  margin-left: 45vw;
  //width: 860px;
  padding: 0px;
`;

// https://www.demilked.com/gif-animations-replace-loading-screen/
const animations = [
  "/images/Loading.gif"
  // Light background
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-6.gif", // Spinning Lines
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-9.gif", // Extruded Spiral
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-11.gif", // Hexagonal Spiral
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-7.gif" // Happy Robot

  // Dark background
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-2.gif", // Big Bang
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-3.gif", // Hexagonal Scan
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-4.gif", // Spinning Addition
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-10.gif", // Red Neurons
  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-13.gif" // Migrating Cubes

  //"https://www.demilked.com/magazine/wp-content/uploads/2016/06/gif-animations-replace-loading-screen-12.gif", // Superheros
];

const Loading = props => {
  return (
    <>
      {AppSettings.UseLiveCatalog && AppSettings.UseLoadingAnimations && (
        <Animation alt="" src={animations[Math.floor(Math.random() * animations.length)]} />
      )}
    </>
  );
};

export default Loading;
