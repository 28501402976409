import React from "react";
import Select from "react-select";
import styled from "styled-components";

const Offices = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  width: 380px;
`;
const Office = styled.div`
  margin: 0px 0px 12px 20px;
  padding: 0px 8px;
  cursor: pointer;
`;
const OfficeName = styled.div`
  font-size: 14;
  font-weight: bold;
  margin: 0px;
`;
const OfficePhone = styled.div`
  font-size: 11px;
`;
const OfficeAddress = styled.div`
  font-size: 13px;
`;
const OfficeHours = styled.div`
  font-size: 11px;
`;

const options = [
  { label: "Case Parts - Los Angeles", value: "MPK" },
  { label: "Case Parts - St Louis", value: "STL" },
  { label: "Case Parts - Seattle", value: "SEA" }
];

// doc: https://react-select.com/styles
const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "23px",
    "min-height": "23px",
    marginTop: "-5px"
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "36px",
    marginTop: "0px"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "36px",
    width: "380px"
  }),
  container: (provided, state) => ({
    ...provided,
    height: "34px",
    marginBottom: "10px"
  })
};

const WillCallLocation = ({ field, form }) => (
  <>
    <Select
      required
      isSearchable={false}
      isClearable={true}
      className="text-input"
      placeholder="Select Will Call Location"
      styles={customStyles}
      options={options}
      value={options.find(option => option.value === field.value)}
      onChange={option => form.setFieldValue(field.name, option ? option.value : "")}
      onBlur={() => form.setFieldTouched(field.name)}
    />

    <Offices className="offices">
      <Office className={field.value === "MPK" ? "active" : ""} onClick={() => form.setFieldValue(field.name, "MPK")}>
        <OfficeName>
          Case Parts - Los Angeles&nbsp;&nbsp;
          <small>
            <a href="https://goo.gl/maps/PzF4o1beyyTanKVk6" target="_blank" rel="noopener noreferrer">
              map
            </a>
          </small>
        </OfficeName>
        <OfficeAddress>877 Monterey Pass Road, Monterey Park, CA 91754</OfficeAddress>
        <OfficeHours>Monday - Friday 7:30AM - 5:00PM PT</OfficeHours>
        <OfficePhone>
          <a href="tel:+18004210271">(800) 421-0271</a>
        </OfficePhone>
      </Office>

      <Office className={field.value === "STL" ? "active" : ""} onClick={() => form.setFieldValue(field.name, "STL")}>
        <OfficeName>
          Case Parts - St Louis&nbsp;&nbsp;
          <small>
            <a href="https://goo.gl/maps/JgBTWQ8LyaXR2UrS9" target="_blank" rel="noopener noreferrer">
              map
            </a>
          </small>
        </OfficeName>
        <OfficeAddress>3218 Rider Trail South, Earth City, MO 63045</OfficeAddress>
        <OfficeHours>Monday - Friday 7:00AM - 4:30PM CT</OfficeHours>
        <OfficePhone>
          <a href="tel:+18004239337">(800) 423-9337</a>
        </OfficePhone>
      </Office>

      <Office className={field.value === "SEA" ? "active" : ""} onClick={() => form.setFieldValue(field.name, "SEA")}>
        <OfficeName>
          Case Parts - Seattle&nbsp;&nbsp;
          <small>
            <a href="https://goo.gl/maps/BhvC7NY94vXQG8uW8" target="_blank" rel="noopener noreferrer">
              map
            </a>
          </small>
        </OfficeName>
        <OfficeAddress>25315 74th Ave South, Suite 101, Kent, WA 98032</OfficeAddress>
        <OfficeHours>Monday - Friday 7:30AM - 5:00PM PT</OfficeHours>
        <OfficePhone>
          <a href="tel:+18007153416">(800) 715-3416</a>
        </OfficePhone>
      </Office>
    </Offices>
  </>
);

export default WillCallLocation;
