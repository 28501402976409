import { findByZipCode } from "../../data/states";

// https://stackoverflow.com/questions/28821804/how-can-i-quickly-determine-the-state-for-a-given-zipcode
export default function getStateCode(zipcode, returnState) {
  // Validate US zipcode
  var isValidZip = /(^\d{5}$)|(^\d{5}-?\d{4}$)/.test(zipcode);
  if (!isValidZip) {
    return "";
  }

  // For extended zipcodes, just evaluate the first five numbers
  zipcode = zipcode.substr(0, 5);

  // Ensure we have exactly 5 characters to parse
  if (zipcode.length !== 5) {
    console.log("Must pass a 5-digit zipcode.");
    return "";
  }

  // Ensure we don't parse strings starting with 0 as octal values
  const thiszip = parseInt(zipcode, 10);
  const stateInfo = findByZipCode(thiszip);
  return stateInfo
  ? (returnState ? stateInfo.name : stateInfo.code)
  : (returnState ? `${zipcode} is not a valid US zipcode` : "");
}
