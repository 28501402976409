import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import "../styling/formik.css";
import SubmitResearchRequest from "./SubmitResearchRequest";
import { useAlert } from "react-alert";
import AppSettings from "../../AppSettings";
import Makes from "../components/Makes";
import ContactPreference from "../components/ContactPreference";
import StateOrCountry from "../components/StateOrCountry";
import { Link } from "react-router-dom";

const AlertText = styled.div`
  text-transform: none;
  font-size: 14px;
`;
const FormColumn = styled.div`
  width: 412px;
  float: left;
`;

export default function ResearchForm() {
  const context = useContext(AppContext);
  const { account, goto } = context;

  const alert = useAlert();
  const initialValues = {
    make: "",
    model: "",
    serial: "",
    description: "",
    location: "",
    email: context.contactInfo.email ? context.contactInfo.email : "",
    name: context.contactInfo.name ? context.contactInfo.name : "",
    phone: context.contactInfo.phone ? context.contactInfo.phone : "",
    company: context.contactInfo.company ? context.contactInfo.company : "",
    preference: ""
  };

  const onSubmit = error => {
    if (error) {
      console.log(`Research request failed for ${AppSettings.EmailRequest}: ${error}`);
      alert.error(<AlertText>Your research request failed. Try again and contact us if error persists</AlertText>);
    } else {
      goto("/ThankYou?message=research");
    }
  };

  // Accept US, Canada and Mexican phone numbers
  const phoneRegExp = /^(\+?(011[. -]?)?\+?52[. -]?([0-9]{3}[. -]?[0-9]{3}[. -]?[0-9]{4}|[0-9]{2}[. -]?[0-9]{4}[. -]?[0-9]{4})|(\+?(1[. -]?)?(\(?[0-9]{3}\)?[. -]?[0-9]{3}[. -]?[0-9]{4})))(\s?(x|ext|ext.)\s?[0-9]{1,5})?$/;
  // Require first and last name
  const nameRegExp = /\w\w+\s+\w\w+/;
  // Allows US and Canadian zipcodes
  const zipcodeRegExp = /^(\d{5}(?:[-\s]\d{4})?)|([A-Za-z]\d[A-Za-z][-\s]?\d[A-Za-z]\d)$/;

  let validations = {
    make: Yup.string().required("Manufacturer name is required"),
    model: Yup.string().required("Model number is required"),
    location: Yup.string().required("Selecting your state or country is required"),
    email: Yup.string().required("Email address is required").email("Invalid email"),
    name: Yup.string().required("Full name is required").matches(nameRegExp, "Please provide your first and last name"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
    zipcode: Yup.string().matches(zipcodeRegExp, "Zipcode is not valid")
  };

  const inputSchema = Yup.object().shape(validations);

  const FormField = ({ name, required, caption, type, component, placeholder, errors, touched }) => (
    <>
      <label htmlFor={name}>
        {caption}
        {required && <strong>*</strong>}
      </label>
      <ErrorMessage name={name} className="error" component="div" />
      <Field
        component={component || "input"}
        type={type || "text"}
        name={name}
        spellCheck="off"
        autoCorrect="off"
        placeholder={placeholder}
        className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      />
    </>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={inputSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        context.updateContactInfo(values);
        SubmitResearchRequest(values, onSubmit, account, resetForm, context.token);
        window._chatlio.identify(values.email, {
          name: values.name ? values.name : "",
          account: account ? account : "none",
          email: values.email ? values.email : "",
          phone: values.phone ? values.phone : "",
          prefers: values.preference ? values.preference : "phone or email",
          company: values.company ? values.company : "none"
        });
      }}
    >
      {props => {
        const {
          //values,
          touched,
          errors,
          dirty,
          isSubmitting
          //handleChange,
          //handleBlur,
          //handleSubmit,
          //handleReset
        } = props;
        return (
          <div className="checkout research" style={{ overflow: "hidden" }}>
            <Form autoComplete="off">
              <FormColumn>
                <FormField
                  name="make"
                  caption="Make"
                  placeholder="Enter manufacturer name"
                  required
                  component={Makes}
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="model"
                  caption="Model"
                  placeholder="Enter model number"
                  required
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="serial"
                  caption="Serial Number"
                  placeholder="Enter serial number, if known"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="description"
                  caption="Part Description"
                  placeholder="Enter description of part(s) to replace"
                  required
                  component="textarea"
                  errors={errors}
                  touched={touched}
                />
              </FormColumn>
              <FormColumn>
                <FormField
                  name="name"
                  required
                  caption="Name"
                  placeholder="Enter your full name"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="company"
                  caption="Company"
                  placeholder="Enter company name"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="location"
                  required
                  caption="State or Country"
                  component={StateOrCountry}
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="email"
                  required
                  caption="Email"
                  type="email"
                  placeholder="Enter email address"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="phone"
                  required
                  caption="Phone"
                  placeholder="Enter phone number"
                  errors={errors}
                  touched={touched}
                />
                <FormField
                  name="preference"
                  caption="Preferred Contact Method"
                  component={ContactPreference}
                  errors={errors}
                  touched={touched}
                />
              </FormColumn>
              <div style={{ clear: "both" }}>
                <button type="submit" disabled={!dirty || isSubmitting} style={{ marginTop: "12px" }}>
                  Submit Part Research Request
                </button>
                <h2 style={{ marginTop: "-8px" }}>
                  <b>
                    If you have a photo, you can upload it via our{" "}
                    <Link onClick={() => window._chatlio.show({ expanded: true })} to="#">
                      chat
                    </Link>{" "}
                    tool.
                  </b>
                </h2>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
