import findMatchingKeywords from "./findMatchingKeywords";
import * as setOperation from "./sortedArraySetOperations";
import OrderForms from "./OrderForms";
import { gasketPrice } from "../customParts/helpers";
import { GetWirePrice } from "../customParts/warmerWire/WarmerWireCalcs";

export default class CatalogManager {
  constructor(data) {
    this._pricingTier = "ListPrice";
    this._keywords = data.Keywords;
    this._sortedKeywords = Object.keys(data.Keywords).sort();
    this._attributes = data.Attributes;
    this._blocks = data.Blocks;
    // Merge in order forms
    Object.keys(OrderForms).forEach(key => (this._blocks[key] = OrderForms[key]));

    const getSortKey = key => {
      const block = this._blocks[key];
      return (block.Category === "Manufacturers" ? 100000000 : 0) + block.SectionSeq * 1000000 + block.BlockSeq;
    };
    this._blockKeys = Object.keys(this._blocks).sort(function(a, b) {
      return getSortKey(a) - getSortKey(b);
    });
    this._infoBlocks = this._blockKeys.filter(key => this._blocks[key].Info).map(key => this._blocks[key]);
    this._parts = data.Parts;
    const partKeys = Object.keys(this._parts);
    this._sortedPartKeys = Int32Array.from(partKeys).sort();
    this._partKeysById = {};
    partKeys.forEach(key => {
      const part = this._parts[key];
      this._partKeysById[part.Id] = part.Key;
    });
  }

  SetPricingTier(discountLevel) {
    if (discountLevel === "Dealer") {
      this._pricingTier = "DealerPrice";
    } else if (discountLevel === "Wholesale") {
      this._pricingTier = "WholesalePrice";
    } else if (discountLevel === "Employee") {
      this._pricingTier = "ListPrice";
    } else {
      this._pricingTier = "ListPrice";
    }
    console.log(`Pricing Tier: ${this._pricingTier} for ${discountLevel}`);
  }

  Part(key) {
    return this._parts[key];
  }

  GetPrice(part) {
    var price = part.ListPrice;
    if (this._pricingTier === "DealerPrice") {
      price = part.DealerPrice;
    } else if (this._pricingTier === "WholesalePrice") {
      price = part.WholesalePrice;
    }
    //console.log(this._pricingTier, price, part);
    return price;
  }

  GetPartById(partId) {
    const key = this._partKeysById[partId];
    if (!key) {
      console.log(`No part found for partId ${partId}`);
      return null;
    }

    const part = this._parts[key];
    // return an array of related Blocks rather than just the block keys
    const blockKeys = part.Blocks;
    const blocks = blockKeys.map(key => {
      const block = this._blocks[key];
      return {
        title: block.Title,
        id: block.Key,
        path: block.Path,
        section: block.Section,
        category: block.Category,
        info: block.Info
      };
    });

    return {
      partId: part.Id,
      title: part.Title,
      price: this.GetPrice(part),
      partStatus: part.Status,
      attributes: this.GetAttributes(part.Attributes),
      blocks: blocks
    };
  }

  GetCustomPartPrice(part){
    const discountLevel = this._pricingTier === "WholesalePrice" ? "Wholesale" : this._pricingTier === "DealerPrice" ? "Dealer" : "EndUser"
    switch (part.custom) {
      case "Gasket":
        return gasketPrice(
          part.partId, 
          part.specs.Height, 
          part.specs.Width, 
          discountLevel);
    
      case "Wire":
        return GetWirePrice(
          part.specs.Sides === 4, 
          part.specs.Width, 
          part.specs.Height, 
          discountLevel);
      default:
        return part.price
    }
  }

  Attributes() {
    return this._attributes;
  }

  // Given a string consisting of one or more search tokens,
  //return the list of matching parts
  Parts(searchString) {
    // split the string into tokens and remove ignored words
    const ignoredWords = ["and", "for", "in", "the", "-"];
    const tokens = (searchString ? searchString : "")
      .toLowerCase()
      .split(/ +/)
      .filter(token => ignoredWords.indexOf(token) < 0);

    if (tokens.length === 0) {
      console.log("No tokens")
      return [];
    }

    let partKeys = this._sortedPartKeys;
    tokens.forEach(token => {
      let keywords = this.Keywords(token);
      let tokenParts = [];
      keywords.forEach(keyword => {
        tokenParts = setOperation.union(tokenParts, this._keywords[keyword]);
      });
      partKeys = setOperation.intersection(tokenParts, partKeys);
    });
    let parts = partKeys.map(key => this._parts[key]).sort((a, b) => (a.Id > b.Id ? 1 : -1));
    return parts;
  }

  GetTableOfContents() {
    // We need to create a nested structure of blocks within sections
    // while iterating across a flat list of block keys. The keys have
    // already been sorted to properly sequence blocks within sections
    // matching the ordering in the paper catalog.
    const sections = [];
    let currentSection = undefined;
    let blocks = this._blocks;
    this._blockKeys.forEach(key => {
      let block = blocks[key];

      // Filter out unused blocks
      if (block.Section) {
        // Check for the start of a new section
        if (!currentSection || block.SectionKey !== currentSection.id) {
          currentSection = {
            title: block.Section,
            id: block.SectionKey,
            category: block.Category,
            blocks: []
          };
          sections.push(currentSection);
        }

        // the current block into the current section
        currentSection.blocks.push({
          title: block.Title,
          category: block.Category,
          id: block.Key,
          path: block.Path,
          info: block.Info
        });
      }
    });

    // We don't need to return any parts for the TOC, just the master list of sections
    const parts = [];
    return [parts, sections];
  }

  // Run a parts query, then reshape the data from the form returned from the server
  // into the format expected by the UI
  GetSearchResults(searchString) {
    if (!searchString) {
      return this.GetTableOfContents();
    }
    const parts = this.Parts(searchString);
    const reshapedParts = parts.map(part => {
      return {
        partId: part.Id,
        partStatus: part.Status,
        title: part.Title,
        price: this.GetPrice(part),
        attributes: this.GetAttributes(part.Attributes)
      };
    });

    const sections = this.GetSectionsForParts(parts);
    return [reshapedParts, sections];
  }

  GetSectionsForPartId(partId) {
    const key = this._partKeysById[partId];
    if (!key) {
      console.log(`No part found for partId ${partId}`);
      return null;
    }

    const part = this._parts[key];
    const sections = this.GetSectionsForParts([part]);
    return sections;
  }

  GetSectionsForParts(parts) {
    // Returns the list of matching blocks grouped by section
    const blocks = this.PartBlocks(parts);
    const reshapedBlocks = this.GetBlocksGroupedBySection(blocks);
    const sections = reshapedBlocks.map(section => {
      return {
        title: section.Title,
        category: section.Category,
        id: section.Key,
        blocks: section.Blocks.map(block => {
          return {
            title: block.Title,
            category: block.Category,
            section: block.Section,
            id: block.Key,
            path: block.Path,
            info: block.Info
          };
        })
      };
    });
    this.AddInfoPages(sections);
    return sections;
  }

  AddInfoPages(sections) {
    sections.forEach(section => {
      this._infoBlocks.forEach(block => {
        if (block.Section === section.title) {
          section.blocks.push({
            title: block.Title,
            category: block.Category,
            id: block.Key,
            path: block.Path,
            info: block.Info
          });
        }
      });
    });
  }

  // Get the parts associated with the specified block
  GetBlockParts(id) {
    const parts = this.PartsForBlock(id);
    const reshapedParts = parts.map(part => {
      return {
        partId: part.Id,
        title: part.Title,
        price: this.GetPrice(part),
        attributes: this.GetAttributes(part.Attributes)
      };
    });

    return reshapedParts;
  }

  // There's a little extra compexity associated with attributes
  // because we need to do a lookup on the attribute name key
  GetAttributes(rawAttributes) {
    const keys = Object.keys(rawAttributes);
    const attributes = keys
      .map(key => {
        return {
          name: this._attributes[key],
          value: rawAttributes[key]
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      // Mask out attributes that are useless to users
      .filter(attribute => attribute.name !== "Base Description" && attribute.name !== "Image");
    return attributes;
  }

  PartsForBlock(blockKey) {
    blockKey = Number(blockKey); // ensure it is treated as a number
    let partKeys = Object.keys(this._parts);
    let parts = partKeys
      .map(k => this._parts[k])
      .filter(p => p.Blocks.includes(blockKey))
      .sort((a, b) => (a.Id > b.Id ? 1 : -1));
    return parts;
  }

  // This is the function SearchBox.js needs
  GetKeywordOptions(token) {
    return token ? this.filterKeywords(token) : [];
  }

  filterKeywords = token => {
    let keywords = this.Keywords(token.toLowerCase());
    let results = keywords.map(opt => ({ label: opt, value: opt }));
    return results;
  };

  // Get the keywords associated with a single search token
  Keywords(token) {
    return findMatchingKeywords(this._sortedKeywords, token);
  }

  PartBlocks(parts) {
    let blocks = [];
    parts.forEach(part => {
      blocks = setOperation.union(part.Blocks, blocks);
    });
    return blocks;
  }

  // The methods below came from CatalogSearch.js

  FindBlock(path) {
    path = path ? path.toLowerCase() : "";
    //console.log(`FindBlock(${path})`);
    const blockKey = Object.keys(this._blocks).filter(key => {
      return this._blocks[key].Path.toLowerCase() === path;
    });

    let block = blockKey ? this._blocks[blockKey[0]] : null;
    if (block) {
      block = {
        title: block.Title,
        id: block.Key,
        path: block.Path,
        section: block.Section,
        category: block.Category,
        info: block.Info
      };
    }
    return block;
  }

  GetBlocksGroupedBySection(partBlocks) {
    // Iterate across each keyword building a consolidated list of matching blockKeys
    let matches = partBlocks;

    // Sort the list of blocks by blockKey grouped by section
    let blocks = this._blocks; // class variables not visible to comparison function
    matches.sort(function(a, b) {
      let keyA = blocks[a].SectionSeq * 1000000 + blocks[a].BlockSeq;
      let keyB = blocks[b].SectionSeq * 1000000 + blocks[b].BlockSeq;
      return keyA - keyB;
    });

    // Build the resultsTree
    const resultTree = [];
    let currentSection = undefined;
    let thisBlocks = this._blocks;
    matches.forEach(key => {
      let block = thisBlocks[key];
      if (!currentSection || block.SectionKey !== currentSection.Key) {
        currentSection = {
          Title: block.Section,
          Key: block.SectionKey,
          Category: block.Category,
          Blocks: []
        };
        resultTree.push(currentSection);
      }
      currentSection.Blocks.push(block);
    });
    return resultTree;
  }
}
