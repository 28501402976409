import React from "react";
import Select from "react-select";
import { listByStateName } from "../../data/states";

const getOption = val => ({ label: val, value: val });
const stateOptions = listByStateName().map(x => ({label: x.name, value: x.code}));
const otherOptions = [
  { label: "----------------", value: "" },
  { label: "Canada", value: "CAN" },
  { label: "Mexico", value: "MEX" },
  { label: "Other Country", value: "INTL" }
];
const options = [ ...stateOptions, ...otherOptions];

// doc: https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontFamily: "Roboto, sans-serif",
    width: "380px",
    height: "18px",
    color: "black",
    fontSize: "14px"
  }),
  input: (provided, state) => ({
    ...provided,
    height: "18px"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "22px",
    width: "380px"
  }),
  container: (provided, state) => ({
    ...provided,
    height: "18px",
    marginBottom: "32px"
  })
};

const StateOrCountry = ({ field, form }) => (
  <Select
    className="text-input"
    placeholder="Select State or Country"
    styles={customStyles}
    defaultValue={field.value ? getOption(field.value) : null}
    isClearable
    options={options}
    isOptionDisabled={option => !option.value}
    onChange={option => form.setFieldValue(field.name, option ? option.value : "")}
    onBlur={() => form.setFieldTouched(field.name)}
  />
);

export default StateOrCountry;
