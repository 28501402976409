import React from "react";
import Home from "./Home";
import { Helmet } from "react-helmet";

export default function NotFound() {
    return <>
        <Helmet>
            <meta name="prerender-status-code" content="404" />
        </Helmet>
        <Home />
    </>
}