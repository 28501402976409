import releaseDate from "./releaseDate";
import environment from "./environment";

const version = "3.17";

const isProd = environment === "Prod" ? true : false;
const isLocalCatalogServer = false;
const isLocalRequestServer = false;
const isLocalCheckoutServer = false;
const isLocalPartServer = false;
const isLocalReportClient = false;
const isLocalOrderLinkClient = false;
const isLocalMobileLinkClient = false;

const catalogServer = isLocalCatalogServer
  ? "http://localhost:9000"
  : isProd
  ? "https://my.caseparts.com"
  : "https://mydev.caseparts.com";
const serverRequestApi = isLocalRequestServer
  ? "https://localhost:44340"
  : isProd
  ? "https://my.caseparts.com/core"
  : "https://mydev.caseparts.com/core";
const serverCheckoutApi = isLocalCheckoutServer
  ? "https://localhost:44340"
  : isProd
  ? "https://my.caseparts.com/core"
  : "https://mydev.caseparts.com/core";
const partRequestApi = isLocalPartServer
  ? "https://localhost:44340"
  : isProd
  ? "https://my.caseparts.com/core"
  : "https://mydev.caseparts.com/core";
const reportClient = isLocalReportClient
  ? "http://localhost:9001"
  : isProd
  ? "https://my.caseparts.com/reports"
  : "https://mydev.caseparts.com/reports"
const orderLinkClient = isLocalOrderLinkClient
  ? "http://localhost:9001"
  : isProd
  ? "https://www.caseparts.com/account"
  : "https://dev.caseparts.com/account"
  const mobileClient = isLocalMobileLinkClient
  ? "http://localhost:3000"
  : isProd
  ? "https://mobile.caseparts.com"
  : "https://mobiledev.caseparts.com"
const imageServer = isLocalMobileLinkClient
? "http://localhost:44340"
: isProd
? "https://www.caseparts.com"
: "https://dev.caseparts.com"

const getCurrentLocation = () => {
  // https://stackoverflow.com/questions/6941533/get-protocol-domain-and-port-from-url
  var location = window.location;
  var full = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
  return full;
};
const clientApp = getCurrentLocation();

const AppSettings = {
  Api: {
    LoginUrl: `${serverCheckoutApi}/api/authenticate/login`,
    LogoutUrl: `${serverCheckoutApi}/api/authenticate/logout`,
    Impersonate: email => email
      ? `${serverCheckoutApi}/api/authenticate/impersonate?email=` + email
      : `${serverCheckoutApi}/api/authenticate/impersonate`,
    ResendConfirmationEmail: `${catalogServer}/authenticate/resendconfirmemail`,

  },

  Email: {
    QuoteTo: isProd ? "WebOrders@caseparts.com" : "dev@caseparts.com",
    QuoteFrom: "CaseParts-No-Reply@caseparts.com",
    ReceiptFrom: isProd ? "WebOrders@caseparts.com" : "dev@caseparts.com",
    AccountTo: isProd ? "accounting@caseparts.com" : "dev@caseparts.com",
    AccountFrom: "CaseParts-No-Reply@caseparts.com",
    AccountReceiptFrom: isProd ? "accounting@caseparts.com" : "dev@caseparts.com",
    QuoteBcc: isProd ? "operations@caseparts.com;jay.cincotta+prod@gmail.com" : "jay.cincotta+dev@gmail.com",
    ReceiptBcc: isProd ? "operations@caseparts.com" : "jay.cincotta+dev@gmail.com"
  },

  SlackWebHook: isProd
    ? "https://hooks.slack.com/services/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"
    : "https://hooks.slack.com/services/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",
  ViewSlackChannel: channelId => `https://app.slack.com/client/T4D2REXJR/${channelId}`,

  Version: version,
  Env: environment,
  IsProd: isProd,
  AppVersion: `PartFinder v${version} ${environment} - ${releaseDate}`,
  VerboseLoupeLogging: false,
  UseLiveCatalog: false,
  UseLoadingAnimations: true,

  Loupe: `${catalogServer}/api/Loupe`,
  SignalR: `${serverRequestApi}/signalr/`,
  TradeApplication: `${catalogServer}/AccountingForms/tradeapplication.pdf`,
  ImageService: catalogServer,
  CatalogRequest: `${partRequestApi}/api/part/`,
  BlockRequest: path => `${partRequestApi}/api/part/${path}`,
  InventoryRequest: partId => `${partRequestApi}/api/part/inventory/${partId}`,
  EmailRequest: `${catalogServer}/api/email/`,
  PartImagesRequest: partId => `${partRequestApi}/api/mobilecatalog/parts/${partId}`,

  PartImage: (name) =>`${imageServer}/graphics/${name}`,
  WebSpin: (itemId) => `${imageServer}/Ortery/360/Optimized/${itemId}_/`,
  SpinStill: (itemId) => `${imageServer}/Ortery/360/Optimized/${itemId}_/`,
  Supplemental: (itemId) => `${imageServer}/Ortery/2D/Optimized/${itemId}/`,


  RequestStatusPage: requestId => `${clientApp}/dash/${requestId}`,

  RequestMaxId: `${serverRequestApi}/api/requests/maxId`,
  RequestStatus: requestId => `${serverRequestApi}/api/requests/status/${requestId}`,
  RequestUpdate: requestId => `${serverRequestApi}/api/requests/${requestId}`,
  RequestPost: `${serverRequestApi}/api/requests/`,
  RequestHistory: `${serverRequestApi}/api/requests/customer/`,
  RequestReport: `${serverRequestApi}/api/requests/report/`,
  RequestMetrics: `${serverRequestApi}/api/requests/metrics/`,
  OrderMetrics: `${serverRequestApi}/api/orders/metrics/`,
  OrderReport: `${serverRequestApi}/api/orders/report/`,
  Cybersource: `${serverRequestApi}/api/cybersource/`,
  ReportClient: reportClient,
  OrderLinkClient: orderLinkClient,

  ApiGetCheckoutInfo: customerId => `${serverCheckoutApi}/api/checkout/GetCheckoutInfo?customerid=${customerId}`,
  ApiVerifyOrder: `${serverCheckoutApi}/api/checkout/VerifyOrder`,
  ApiCreateQuote: `${serverCheckoutApi}/api/checkout/CreateQuote`,
  ApiAssignQuote: `${serverCheckoutApi}/api/checkout/AssignQuote`,

  EnableChatlio: true,
  GAHostname: "www.caseparts.com",
  LoginPage: `${catalogServer}/Account/Login/`,
  LogoffPage: `${catalogServer}/Account/LogOff/`,
  BenefitsPage: `${orderLinkClient}/benefits/`,
  RegistrationPage: `${orderLinkClient}/register/`,
  RegisterUserPage: `${orderLinkClient}/register-user/`,
  ForgotPasswordPage: `${orderLinkClient}/ForgotPassword/`,
  ContactUs: `${orderLinkClient}/contactus/`,
  //RegisterPage: `${catalogServer}/Account/Register/`,
  //ForgotPasswordPage: `${catalogServer}/Account/ForgotPassword/`,
  AccountingForms: `${catalogServer}/AccountingForms/`,
  AccountPage: `${orderLinkClient}`,
  ForgotPasswordPage: `${orderLinkClient}/ForgotPassword`,
  IntranetPage: `${catalogServer}`,
  PdfPath: `${catalogServer}/pdf`,
  MobileSite: `${mobileClient}`
};

export default AppSettings;
