import React from "react";
import { Link } from "react-router-dom";
import Page from "../widgets/Page";
import ContactForm from "../../ui/checkout/ContactForm";

export default function HeaterWizard({ title }) {
  return (
    <Page
      title="Custom Formed Tubular Defrost Heaters"
      icon="Heater"
      description="Case Parts offers wide range of customized commercial refrigeration parts in addition to extensive catalog of stock parts."
    >
      <div className="flexContainer">
        <div className="customPartColumn">
          {/* <h2>If you need a tubular heater, we can probably supply it!</h2>
          <p>
            We offer an extensive selection of tubular heaters in various lengths and wattages. Based on our years of
            experience, we have assembled an inventory of sufficient heaters to handle over 95% of requests for
            replacement commercial refrigeration heaters.
          </p> */}
          <h2>Here's how we solve your replacement heater problems</h2>
          <p>
            We offer an extensive inventory of <Link to="/Page/Tubular-Heaters">tubular heaters</Link>. Here are a few{" "}
            <Link to="/Page/Sample-Bent-Heater-Configurations">typical configurations</Link>. Please note that these are
            only the more popular shapes and we will bend your heater to whatever specifications you desire.
          </p>
          <p>
            <Link to="/Page/Custom-Formed-Tubular-Defrost-Heaters">
              Tips for specifying custom formed tubular defrost heaters
            </Link>
          </p>
          <p>
            If you know exactly which heater you want and how you want it bent, we'll gladly take your order. But if
            you're not sure, let us lend a hand with the engineering. When you call or email, we'll need to know the
            voltage, a rough idea of the wattage or amperage, and the application - including the measurement
            specifications.
          </p>

          <p>FYI, We also carry...</p>
          <ul>
            <li>
              <Link to="/Page/Aluminum-Finned-Defrost-Heaters">Aluminum Finned Defrost Heaters</Link>
            </li>
            <li>
              <Link to="/Page/Tubular-Heater-Replacements-for-Epoxy-Heaters">
                Tubular Heater Replacements for Epoxy Heaters
              </Link>
            </li>
            <li>
              <Link to="/Page/Epoxy-Heaters-and-Replacements">Epoxy Heaters and Replacements</Link>
            </li>
            <li>
              <Link to="/Page/Popular-Drain-Line-Heaters">Popular Drain Line Heaters</Link>
            </li>
            <li>
              <Link to="/Page/Flexible-Drain-Line-Heaters">Flexible Drain Line Heaters</Link>
            </li>
            <li>
              <Link to="/Page/Popular-Condensate-Pan-Heaters">Popular Condensate Pan Heaters</Link>
            </li>
            <br />
            &nbsp;
          </ul>
        </div>
        <div className="customPartColumn">
          <h2>
            If none of our stock parts meet your needs,
            <br />
            Let us help you find or build a solution
          </h2>
          <ContactForm
            topic="Tubular Heaters"
            messageLabel="Heater Specifications"
            messageHints="Please enter what details you can about the specifications for your custom heater"
          />
        </div>
      </div>
    </Page>
  );
}
